import React, { useEffect } from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";
import { IncumbenciasSearchRowTernas } from "./IncumbenciasSearchRowTernas";

export const IncumbenciasSearchTableTernas = ({ ternas, cantidadResultados, page, changePage, ...props }) => {

    const renderTableRows = (ternas) => {
        return ternas.map((terna) => {
            return (
                <React.Fragment key={terna.id}>
                    <IncumbenciasSearchRowTernas
                        onSubmit={props.onSubmit}
                        terna={terna}
                    ></IncumbenciasSearchRowTernas>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            {!ternas[0] ? (
                <Row className="form-group">
                    <Col>
                        <Alert variant="info">No se encontraron ternas.</Alert>
                    </Col>
                </Row>
            ) : (
                !!ternas && (
                    <>
                        <Row className="form-group">
                            <Col>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Apendice</th>
                                            <th>Folio</th>
                                            <th>Nombre de título</th>
                                            <th>Procedencia</th>
                                            <th>Resolucion</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableRows(ternas)}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Paginacion totalItems={cantidadResultados} currentPage={page} onChangePage={changePage} />
                    </>
                )
            )}
            {!!ternas[0] && <Row>
                <Col>
                    <Button
                        variant="primary"
                        content="Reporte"
                        type="submit"
                        disabled
                    >
                        Informe Total
                    </Button>
                </Col>
            </Row>}
        </>
    );
};