import { incumbenciasTypes } from "../../actions/types";

const initialState = "";

const incumbenciasReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case incumbenciasTypes.SAVE_DATA: {
            return { ...state, ...payload };
        }

        case incumbenciasTypes.CLEAN_DATA: {
            return "";
        }


        /* INCUMBENCIAS */

        case incumbenciasTypes.REPLACE_ARR: {
            let newSt = { ...state }
            const concurrenciaId = payload.extraConfig?.concurrenciaId
            const subPath = payload.extraConfig?.subPath

            if (subPath) {
                newSt[payload.arrWithAll][concurrenciaId][subPath] = [...payload.arrElements]
            } else {
                newSt[payload.arrWithAll] = [...payload.arrElements]
            }
            return newSt;
        }

        case incumbenciasTypes.ADD_TERNA: {
            if (payload.overwrite) return { ...state, [payload.arrWithAllTernas]: [payload.ternaToAdd] }
            const arrAllTernas = state[payload.arrWithAllTernas] ?? [];
            return { ...state, [payload.arrWithAllTernas]: [...arrAllTernas, payload.ternaToAdd] };
        }

        case incumbenciasTypes.ADD_CARGO: {
            console.log("ADD_CARGO", { arrCargos: payload.cargoToAdd })
            if (payload.overwrite) return { ...state, [payload.arrWithAllCargos]: [payload.cargoToAdd] }
            console.log("SIGUE")
            const arrAllCargos = state[payload.arrWithAllCargos] ?? [];
            if (payload.cargoToAdd.type == "multi") {
                const currentCargoIndex = arrAllCargos.findIndex(current => current.cargo.id == payload.cargoToAdd.cargo.id)
                const arrVariantesValidadas = payload.verificarPermisoEditar ?
                    payload.cargoToAdd.variantes.filter(ctVariante => !(ctVariante?.acciones?.editar === false))
                    :
                    payload.cargoToAdd.variantes

                if (currentCargoIndex >= 0) {
                    arrAllCargos[currentCargoIndex].variantes = [...arrAllCargos[currentCargoIndex].variantes, ...arrVariantesValidadas];
                    return { ...state, [payload.arrWithAllCargos]: arrAllCargos };
                } else {
                    const newCargo = { ...payload.cargoToAdd, variantes: arrVariantesValidadas }
                    return { ...state, [payload.arrWithAllCargos]: [...arrAllCargos, newCargo] };
                }
            }
            return { ...state, [payload.arrWithAllCargos]: [...arrAllCargos, payload.cargoToAdd] };
        }

        case incumbenciasTypes.ADD_VARIANTE: {
            const arrCargosWithVariantes = state[payload.arrWithAllCargos] ? JSON.parse(JSON.stringify(state[payload.arrWithAllCargos])) : [];
            const currentCargoIndex = arrCargosWithVariantes.findIndex(current => current.cargo.id == payload.cargoWithVariante.cargo.id)
            if (currentCargoIndex >= 0) {
                arrCargosWithVariantes[currentCargoIndex].variantes = [...arrCargosWithVariantes[currentCargoIndex].variantes, payload.varianteToAdd];
                return { ...state, [payload.arrWithAllCargos]: arrCargosWithVariantes };
            }
            const cargoToAdd = { ...payload.cargoWithVariante, variantes: [payload.varianteToAdd] }
            return { ...state, [payload.arrWithAllCargos]: [...arrCargosWithVariantes, cargoToAdd] };
        }

        case incumbenciasTypes.ADD_VARIANTES_WITH_INDEX: {
            const arrAllCargosWithVariantes = state[payload.arrWithAllCargos] ? JSON.parse(JSON.stringify(state[payload.arrWithAllCargos])) : [];
            if (payload.overwrite) {
                arrAllCargosWithVariantes[payload.cargoIndex].variantes = [...payload.variantesToAdd];
            } else {
                arrAllCargosWithVariantes[payload.cargoIndex].variantes = [...arrAllCargosWithVariantes[payload.cargoIndex].variantes, ...payload.variantesToAdd];
            }
            return { ...state, [payload.arrWithAllCargos]: arrAllCargosWithVariantes };
        }

        case incumbenciasTypes.REMOVE_CARGO: {
            const arrCargos = state[payload.arrWithAllCargos] ?? [];
            console.log("REMOVE_CARGO", { arrCargos, payload })
            let arrFilterCargos = []
            console.log({ payload, arrCargos })

            arrCargos.forEach(currentElement => {
                /* Si es distinto al que queremos bloquear => lo dejamos */
                if (currentElement.cargo.id != payload.cargoToRemove.cargo.id) {
                    return arrFilterCargos.push(currentElement)
                }

                if (currentElement.type == "multi") {
                    /* Si es el que uqeremos eliminar => analisamos que sus variantes puedan ser eliminadas por nuestro rol */
                    console.log("ENTRAMOS ACA 1", { verificarPermisoEditar: payload.verificarPermisoEditar })

                    if (payload.verificarPermisoEditar) {
                        const arrVariantesValidadas = currentElement.variantes.filter(ctVariante => ctVariante.acciones && ctVariante?.acciones?.editar === false)
                        /* Si hay alguna variante que no podamos borrar => las dejamos */
                        if (arrVariantesValidadas.length > 0) return arrFilterCargos.push({ ...currentElement, variantes: arrVariantesValidadas })
                    }

                } else {
                    console.log("REMOVE_CARGO: ES UN CARGO SIMPLE",)
                }
            });
            console.log("RESULTADO REMOVE_CARGO", arrFilterCargos)
            return { ...state, [payload.arrWithAllCargos]: arrFilterCargos };
        }

        case incumbenciasTypes.REMOVE_TERNA: {
            const arrTernas = state[payload.arrWithAllTernas] ?? [];
            const arrFilterTernas = arrTernas.filter(currentElement => currentElement.terna.id != payload.ternaToRemove.terna.id)
            return { ...state, [payload.arrWithAllTernas]: arrFilterTernas };
        }

        case incumbenciasTypes.REMOVE_VARIANTE: {
            let emptyCargoIndex = -1;
            const newArrCargos = state[payload.arrWithAllCargos].map((currentElement, index) => {
                if (payload.currentCargo.cargo.id == currentElement.cargo.id) {
                    if (currentElement.variantes.length == 1) emptyCargoIndex = index;
                    currentElement.variantes.splice(payload.varianteToRemoveIndex, 1)
                }
                return currentElement
            })
            if (payload.removeCargoIfIsEmpty && emptyCargoIndex >= 0) newArrCargos.splice(emptyCargoIndex, 1)
            return { ...state, [payload.arrWithAllCargos]: newArrCargos };
        }

        /* CONCURRENCIAS */

        case incumbenciasTypes.ADD_CONCURRENCIA_EMPTY_ROW: {
            const concurrenciaEmptyRow = { ternasSeleccionadas: [], cargosSeleccionados: [] }
            let arrConcurrencias = state.concurrenciasSeleccionadas ?? []
            arrConcurrencias = [...arrConcurrencias, concurrenciaEmptyRow]
            return { ...state, concurrenciasSeleccionadas: arrConcurrencias };
        }

        case incumbenciasTypes.ADD_TERNA_TO_CONCURRENCIA: {
            let arrConcurrencias = state.concurrenciasSeleccionadas;
            arrConcurrencias[payload.concurrenciaIndex].ternasSeleccionadas.push({ ...payload.terna })
            return { ...state, concurrenciasSeleccionadas: arrConcurrencias }
        }

        case incumbenciasTypes.ADD_CARGO_TO_CONCURRENCIA: {
            let arrConcurrencias = state.concurrenciasSeleccionadas;
            arrConcurrencias[payload.concurrenciaIndex].cargosSeleccionados.push({ ...payload.cargo })
            return { ...state, concurrenciasSeleccionadas: arrConcurrencias }
        }

        case incumbenciasTypes.ADD_CARGO_VARIANTE_TO_CONCURRENCIA: {
            let newSt = { ...state };
            const variantesExistentes = newSt.concurrenciasSeleccionadas[payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex].variantes ?? []

            newSt.concurrenciasSeleccionadas[payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex].variantes = [
                ...payload.variante
            ]
            return newSt;
        }

        case incumbenciasTypes.REMOVE_TERNA_FROM_CONCURRENCIA: {
            const newSt = { ...state };
            newSt.concurrenciasSeleccionadas[payload.concurrenciaIndex].ternasSeleccionadas.splice(payload.ternaId, 1);
            return newSt;
        }

        case incumbenciasTypes.REMOVE_CARGO_FROM_CONCURRENCIA: {
            let newSt = { ...state };
            console.log("REMOVE_CARGO_FROM_CONCURRENCIA", { newSt, payload })
            const cargoAEliminar = newSt.concurrenciasSeleccionadas[payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex]

            console.log({ cargoAEliminar })

            newSt.concurrenciasSeleccionadas[payload.concurrenciaIndex].cargosSeleccionados.splice(payload.cargoIndex, 1);

            return newSt;
        }

        case incumbenciasTypes.ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_TERNA: {
            // concurrenciaIndex, cargoIndex, varianteIndex, inputData, typeOfCargo
            let newSt = { ...state };
            if (payload.typeOfCargo == "multi") {
                newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex].variantes[payload.varianteIndex] = {
                    ...newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex].variantes[payload.varianteIndex],
                    ...payload.inputData
                };
            } else {
                newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex] = {
                    ...newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].cargosSeleccionados[payload.cargoIndex],
                    ...payload.inputData
                };
            }
            return newSt;
        }

        case incumbenciasTypes.ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_CARGO: {
            // concurrenciaIndex, inputData, arrWithAllCargos
            let newSt = { ...state };
            newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].inputs = {
                ...newSt[payload.arrWithAllCargos][payload.concurrenciaIndex].inputs,
                ...payload.inputData
            };
            return newSt;
        }


        case incumbenciasTypes.REMOVE_CONCURRENCIA: {
            // concurrenciaIndex, arrWithAllCargos 

            console.log("REMOVE_CONCURRENCIA", { state, payload })
            let newSt = { ...state };

            const eliminarConcurrencia = () => {
                newSt[payload.arrWithAllCargos].splice(payload.concurrenciaIndex, 1);
            }

            if (payload.verificarPermisoEditar) {
                console.log("SI VERIFICAMOS PERMISO EDITAR => SUPONIENDO MAIN CARGO")
                const concurrenciaToDelete = newSt[payload.arrWithAllCargos][payload.concurrenciaIndex]
                const cargosValidados = []
                concurrenciaToDelete.cargosSeleccionados.forEach(ctCargo => {
                    if (ctCargo.type == "multi") {
                        const variantesBloqueadasPorPermiso = ctCargo.variantes.filter(ctVariante => ctVariante?.acciones?.editar === false)
                        console.log("variantesBloqueadasPorPermiso", { variantesBloqueadasPorPermiso })
                        if (variantesBloqueadasPorPermiso.length > 0) {
                            console.log("AGREGAMOS CARGO VALIDADO", { ...ctCargo, variantes: variantesBloqueadasPorPermiso })
                            cargosValidados.push({ ...ctCargo, variantes: variantesBloqueadasPorPermiso })
                        }
                    } else {
                        console.log("ES CARGO SIMPLE")
                    }
                })
                if (cargosValidados.length > 0) {
                    console.log("SI TIENE CARGO SIMPLE O MULTI CON ALGO BLOQUEADO", { cargosValidados })
                    newSt[payload.arrWithAllCargos][payload.concurrenciaIndex] = {
                        ...newSt[payload.arrWithAllCargos][payload.concurrenciaIndex],
                        cargosSeleccionados: cargosValidados
                    }
                } else {
                    console.log("NO, TODOS LOS CARGOS SE PUEDEN BORRAR")
                    eliminarConcurrencia()
                }
                return newSt;
            } else {
                console.log("NO VERIFICAMOS PERMISO EDITAR")
                eliminarConcurrencia()
                return newSt;
            }
        }

        case incumbenciasTypes.REMOVE_CARGO_CONCURRENCIA: {
            let newSt = { ...state };
            console.log("REMOVE_CARGO_CONCURRENCIA", { payload, newSt })

            const eliminarCargo = () => {
                newSt[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados.splice(payload.currentCargoIndex, 1);
            }

            if (payload.verificarPermisoEditar) {
                console.log("VALIDAMOS PERMISO EDITAR")
                const cargoToDelete = newSt[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados[payload.currentCargoIndex]

                if (cargoToDelete.type == "multi") {
                    const variantesBloqueadasPorPermiso = cargoToDelete.variantes.filter(ctVariante =>
                        ctVariante?.acciones?.editar === false
                    )

                    if (variantesBloqueadasPorPermiso.length > 0) {
                        /* Dejamos cargo solo con variantes bloqueadas */
                        console.log("DEJAMOS VARIANTES", variantesBloqueadasPorPermiso)
                        newSt[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados[payload.currentCargoIndex].variantes = variantesBloqueadasPorPermiso;
                    } else {
                        console.log("SACAMOS TODO")
                        /* Sacamos el cargo con todas sus varriantes */
                        eliminarCargo()
                    }

                } else {
                    console.log("ES CARGO SIMPLE")
                    eliminarCargo()
                }

            } else {
                console.log("NO VALIDAMOS PERMISO EDITAR => ELIMINAMOS")
                eliminarCargo()
            }


            const wasEmptyConcurrencia = newSt[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados == 0
            if (wasEmptyConcurrencia) {
                console.log("SACAMOS CONCURRENCIA => NO CARGOS")
                newSt[payload.arrWithAllCargos].splice(payload.currentConcurrenciaIndex, 1);
            }
            return newSt;
        }

        case incumbenciasTypes.ADD_CARGO_CONCURRENCIA: {
            const arrConcurrenciasWithVariantes = state[payload.arrWithAllCargos] ? JSON.parse(JSON.stringify(state[payload.arrWithAllCargos])) : [];

            console.log("ADD_CARGO_CONCURRENCIA", { state, payload })

            /* VALIDAR PERMISOS EDITAR */
            let cargoValidado = payload.cargoToAdd;
            if (payload.verificarPermisoEditar) {
                console.log("VALIDAMOS CARGO")
                if (payload.cargoToAdd.type == "multi") {
                    console.log("VALIDAMOS CARGO - ES MULTI")
                    const variantesValidadas = payload.cargoToAdd.variantes.filter(ctVariante => ctVariante?.acciones?.editar !== false)
                    if (variantesValidadas.length > 0) {
                        console.log("DEJAMOS VARIANTES", variantesValidadas)
                        cargoValidado = { ...payload.cargoToAdd, variantes: variantesValidadas }
                    } else {
                        console.log("NO AGREGAMOS, TODAS VARIATNES BLOQUEADAS", variantesValidadas)
                        return
                    }
                } else {
                    console.log("SIMPLE")
                }
            }

            const currentConcurrenciaIndex = arrConcurrenciasWithVariantes.findIndex(ctConcurrencia => {
                return ctConcurrencia.combinacionTernas.terna_id == payload.concurrenciaToAdd.combinacionTernas.terna_id &&
                    ctConcurrencia.combinacionTernas.terna2_id == payload.concurrenciaToAdd.combinacionTernas.terna2_id &&
                    ctConcurrencia.combinacionTernas.terna3_id == payload.concurrenciaToAdd.combinacionTernas.terna3_id
            })

            if (currentConcurrenciaIndex >= 0) {
                const currentCargoIndex = arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.findIndex(ctCargo => {
                    return ctCargo.cargo.id == cargoValidado.cargo.id
                })

                if (currentCargoIndex >= 0) {
                    /* SI existe la concurrencia (t1, t2, t3)  y SI existe el cargo => solamente agregamos la variante restante */
                    arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados[currentCargoIndex].variantes = [
                        ...arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados[currentCargoIndex].variantes,
                        ...cargoValidado.variantes
                    ]
                    return { ...state, [payload.arrWithAllCargos]: JSON.parse(JSON.stringify(arrConcurrenciasWithVariantes)) };
                } else {
                    /* SI existe la concurrencia (t1, t2, t3), pero NO existe el cargo => agregamos el cargo con la variante */
                    arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.push(cargoValidado)
                    return { ...state, [payload.arrWithAllCargos]: JSON.parse(JSON.stringify(arrConcurrenciasWithVariantes)) };
                }
            }
            /* NO existe la concurrencia (t1, t2, t3) => agregamos toda la concurrencia de 0 */
            const newConcurrenciaToAdd = { ...payload.concurrenciaToAdd, cargosSeleccionados: [cargoValidado] }
            const newConcurrencia = JSON.parse(JSON.stringify([...arrConcurrenciasWithVariantes, newConcurrenciaToAdd]))
            return { ...state, [payload.arrWithAllCargos]: newConcurrencia };
        }

        case incumbenciasTypes.ADD_ARR_CONCURRENCIAS: {
            console.log("ADD_ARR_CONCURRENCIAS", { state, payload })

            let arrConcurrencias = payload.arrConcurrencias
            if (arrConcurrencias.length == 0) return state
            console.log("ADD_ARR_CONCURRENCIAS 1")

            if (payload.isTernaMainElement != false) {
                if (payload.verificarPermisoEditar) {
                    const concurrenciasValidadas = []
                    arrConcurrencias.forEach(ctConc => {
                        console.log("ACAAAAA", { ctConc, arrConcurrencias })
                        const cargosValidados = []
                        ctConc.cargosSeleccionados.forEach(ctCargo => {
                            if (ctCargo.type == "multi") {
                                console.log("VALIDAMOS CARGO - ES MULTI")
                                const variantesValidadas = ctCargo.variantes.filter(ctVariante => ctVariante?.acciones?.editar !== false)
                                if (variantesValidadas.length > 0) {
                                    console.log("DEJAMOS VARIANTES", variantesValidadas)
                                    cargosValidados.push({ ...ctCargo, variantes: variantesValidadas })
                                } else {
                                    console.log("NO AGREGAMOS, TODAS VARIATNES BLOQUEADAS", variantesValidadas)
                                    return
                                }
                            } else {
                                console.log("SIMPLE")
                            }
                        })
                        if (cargosValidados.length > 0) {
                            console.log()
                            concurrenciasValidadas.push({ ...ctConc, cargosSeleccionados: cargosValidados })
                        }
                    })

                    if (arrConcurrencias.length == 0) return
                    arrConcurrencias = concurrenciasValidadas;
                }
                console.log("ADD_ARR_CONCURRENCIAS 2")

                let arrConcurrenciasWithVariantes = state[payload.arrWithAllCargos] ? JSON.parse(JSON.stringify(state[payload.arrWithAllCargos])) : [];

                let newSt = { ...state }

                arrConcurrencias.forEach(ctConcurrenciaToAdd => {
                    const currentConcurrenciaIndex = arrConcurrenciasWithVariantes.findIndex(ctConcurrenciaExistente => {
                        let arrIdsTernasConcurrenciatoAdd = ctConcurrenciaToAdd.ternasSeleccionadas.map(stTerna => stTerna.id);
                        let mismaCombinacionFlag = true;

                        ctConcurrenciaExistente.ternasSeleccionadas.forEach(ctTernaSeleccionada => {
                            const ternaIndex = arrIdsTernasConcurrenciatoAdd.findIndex(ctId => ctTernaSeleccionada.id == ctId)
                            if (ternaIndex >= 0) {
                                arrIdsTernasConcurrenciatoAdd.splice(ternaIndex, 1)
                            } else {
                                mismaCombinacionFlag = false;
                            }
                        })

                        if (arrIdsTernasConcurrenciatoAdd.length != 0) mismaCombinacionFlag = false

                        return mismaCombinacionFlag
                    })

                    if (currentConcurrenciaIndex >= 0) {
                        ctConcurrenciaToAdd.cargosSeleccionados.forEach(ctCargoToAdd => {
                            const cargoExistenteIndex = arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.findIndex(ctCargoExistente =>
                                ctCargoToAdd.cargo.id == ctCargoExistente.cargo.id
                            )

                            if (cargoExistenteIndex >= 0) {
                                /* Existe concurrencia y cargo => agregamos las variantes nuevas al cargo existente */
                                return arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados[cargoExistenteIndex].variantes = [
                                    ...arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados[cargoExistenteIndex].variantes,
                                    ...ctCargoToAdd.variantes
                                ]
                            }

                            /* Existe concurrencia, pero no cargo => agregamos el cargo nuevo entero */
                            return arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.push(ctCargoToAdd)
                        })
                        return newSt = { ...newSt, [payload.arrWithAllCargos]: [...arrConcurrenciasWithVariantes] }
                    }
                    /* NO existe la concurrencia (t1, t2, t3) => agregamos toda la concurrencia de 0 */
                    return newSt = { ...newSt, [payload.arrWithAllCargos]: [...arrConcurrenciasWithVariantes, ctConcurrenciaToAdd] };
                })
                return newSt
            } else {
                if (payload.verificarPermisoEditar) {
                    arrConcurrencias = arrConcurrencias.filter(ctConc => ctConc?.acciones?.editar !== false)
                    if (arrConcurrencias.length == 0) return
                }
                let newSt = { ...state }
                const arrConcurrenciasIniciales = newSt[payload.arrWithAllCargos] ?? []
                return newSt = { ...newSt, [payload.arrWithAllCargos]: [...arrConcurrenciasIniciales, ...arrConcurrencias] };
            }
        }

        case incumbenciasTypes.REMOVE_VARIANTE_CONCURRENCIA: {
            let emptyCargoIndex = -1;
            const newArrCargos = state[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados.map((currentElement, index) => {
                if (payload.currentCargo.cargo.id == currentElement.cargo.id) {
                    if (currentElement.variantes.length == 1) emptyCargoIndex = index;
                    currentElement.variantes.splice(payload.varianteToRemoveIndex, 1)
                }
                return currentElement
            })
            if (payload.removeCargoIfIsEmpty && emptyCargoIndex >= 0) newArrCargos.splice(emptyCargoIndex, 1)
            let newSt = { ...state }
            if (newArrCargos.length == 0) {
                newSt[payload.arrWithAllCargos].splice(payload.currentConcurrenciaIndex, 1)
            } else {
                newSt[payload.arrWithAllCargos][payload.currentConcurrenciaIndex].cargosSeleccionados = newArrCargos
            }
            return newSt;
        }

        case incumbenciasTypes.ADD_VARIANTE_CONCURRENCIA: {
            const arrConcurrenciasWithVariantes = state[payload.arrWithAllCargos] ? JSON.parse(JSON.stringify(state[payload.arrWithAllCargos])) : [];
            const currentConcurrenciaIndex = arrConcurrenciasWithVariantes.findIndex(ctConcurrencia => {
                return ctConcurrencia.combinacionTernas.terna_id == payload.concurrenciaToAdd.combinacionTernas.terna_id &&
                    ctConcurrencia.combinacionTernas.terna2_id == payload.concurrenciaToAdd.combinacionTernas.terna2_id &&
                    ctConcurrencia.combinacionTernas.terna3_id == payload.concurrenciaToAdd.combinacionTernas.terna3_id
            })
            if (currentConcurrenciaIndex >= 0) {
                const currentCargoIndex = arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.findIndex(ctCargo => {
                    return ctCargo.cargo.id == payload.cargoToAdd.cargo.id
                })

                if (currentCargoIndex >= 0) {
                    /* SI existe la concurrencia (t1, t2, t3)  y SI existe el cargo => solamente agregamos la variante restante */
                    arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados[currentCargoIndex].variantes.push(payload.varianteToAdd)
                    return { ...state, [payload.arrWithAllCargos]: arrConcurrenciasWithVariantes };
                } else {
                    /* SI existe la concurrencia (t1, t2, t3), pero NO existe el cargo => agregamos el cargo con la variante */

                    arrConcurrenciasWithVariantes[currentConcurrenciaIndex].cargosSeleccionados.push({ ...payload.cargoToAdd, variantes: [payload.varianteToAdd] })
                    return { ...state, [payload.arrWithAllCargos]: arrConcurrenciasWithVariantes };
                }
            }
            /* NO existe la concurrencia (t1, t2, t3) => agregamos toda la concurrencia de 0 */
            const newConcurrenciaToAdd = { ...payload.concurrenciaToAdd, cargosSeleccionados: [{ ...payload.cargoToAdd, variantes: [payload.varianteToAdd] }] }
            return { ...state, [payload.arrWithAllCargos]: [...arrConcurrenciasWithVariantes, newConcurrenciaToAdd] };
        }

        default: return state;
    }
}

export default incumbenciasReducer