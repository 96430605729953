import Input from "../../ui/Input";
import React, { useState } from "react";

const {
  default: PrivateComponent,
} = require("../../authentication/PrivateComponent");
const { default: Dialog } = require("../../generics/dialog");
//TODO-pasarlo a GenericDialog
const EspecialidadesDialog = ({
  functionToSearch,
  onSubmit,
  title,
  especialidad,
  items,
  children,
  btnConfirmText,
  permisos = [],
  variant = "primary",
  ...props
}) => {
  const [selected, setSelected] = useState();

  const handleChange = (e) => {
    setSelected(e.target.value)
  }

  const renderSelect = () => {
    return (
      <Input 
      type="select"
      controlId={(props.name).toLowerCase()}
      name={(props.name).toLowerCase()}
      label={props.name}
      onChange={handleChange}
      elements={items}
      value={selected}
      default="Todos"
      required></Input>
    )
  }

  return (
    <PrivateComponent permisos={permisos}>
      <Dialog
        action={() => onSubmit(selected)}
        target={especialidad}
        title={title ? title : "Confirmar Acción"}
        body={renderSelect()}
        variant={variant}
        btnText={children}
        btnConfirmText={btnConfirmText}
        size="md"
      />
    </PrivateComponent>
  );
};

export default EspecialidadesDialog;
