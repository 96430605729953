import React, { useLayoutEffect, useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'

export const TdWithVerMas = ({ data, maxHeight = 44, rowSpan }) => {

  const [withVerMas, setWithVerMas] = useState(false);
  const [showAll, setShowAll] = useState();

  const divRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const switchInfoToShow = () => {
    setShowAll(st => !st)
  }

  useEffect(() => {
    if (withVerMas) {
      if (showAll) {
        containerRef.current.style.height = "auto";
      } else {
        containerRef.current.style.height = (maxHeight + buttonRef.current.offsetHeight) + "px";
      }
      containerRef.current.style.paddingBottom = buttonRef.current.offsetHeight + "px";
    }
  }, [showAll])

  useEffect(() => {
    if (divRef.current.offsetHeight > maxHeight) {
      setWithVerMas(true)
    } else {
      setWithVerMas(false)
    }
    setShowAll(false);
  }, [])

  return (<>
    <td rowSpan={rowSpan} >
      <div className="position-relative overflow-hidden" ref={containerRef}>
        <div ref={divRef}>
          {data}
        </div>
        {(withVerMas) &&
          <div className="position-absolute bottom-0 bg-white w-100" ref={buttonRef}>
            <Button variant="link" onClick={switchInfoToShow}>{showAll ? "Ver menos" : "Ver mas"}</Button>
          </div>
        }
      </div>
    </td>
  </>)
}
