import React, { useState } from "react";
import IconButton from "../../generics/IconButton";
import { faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import ApendiceDetailBody from "../details/ApendiceDetailBody";
import { publish } from "../EscuelaService";

const ApendiceDetailModal = ({apendice, withPublicar = false, ...props}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handlePublish = (apendice, resolve) => {
    publish(apendice);
    handleClose();
    resolve();
  };
  return (
    <>
      <IconButton {...props} icon={withPublicar?faUpload:faSearch} onClick={handleShow} >
        {props.children}
      </IconButton>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
          <span className="font-weight-light">Detalle:</span> {apendice.nombre}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApendiceDetailBody apendice={apendice}></ApendiceDetailBody>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
          {withPublicar && <Button variant="primary" onClick={() => {handlePublish(apendice, props.onSubmit)}}>
            Publicar
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApendiceDetailModal