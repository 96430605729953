import React from 'react'
import { TicketsEditModal } from '../abm/TicketsEditModal'
import { TicketsDetailModal } from '../detail/TicketsDetailModal'
import PrivateComponent from '../../authentication/PrivateComponent'
import { PERMISOS_MAP } from '../../utils/Permisos'

export const TicketsRowActions = ({ ticket, accionesPermitidas = {} }) => {

    return (
        <>
            {accionesPermitidas.cerrar &&
                <PrivateComponent permisos={PERMISOS_MAP.TICKET_CERRAR}>
                    <TicketsDetailModal
                        ticketId={ticket.id}
                        accionCerrarTicket
                    />
                </PrivateComponent>
            }

            {accionesPermitidas.editar &&
                <PrivateComponent permisos={PERMISOS_MAP.TICKET_EDITAR}>
                    <TicketsEditModal
                        ticket={ticket}
                    />
                </PrivateComponent>

            }

            <TicketsDetailModal
                ticketId={ticket.id}
            />
        </>
    )
}




