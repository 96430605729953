import React, { useState, useEffect, useRef } from "react";
import Input from "../ui/Input";
import * as genericService from "../GenericService";
import { useDispatch, useSelector } from "react-redux";
import { resetChecks } from "../store/screen/screenActions";

const CheckBoxEstados = ({ inputsForm, setInputsForm, }) => {
  const [allEstadosOptions, setAllEstadosOptions] = useState([]); //{id, nombre, checked}
  const [checksState, setChecksState] = useState({ checkboxAll: { id: "checkboxAll", nombre: "Todos", checked: true } }) //{id: {id, nombre, checked}}
  const isMountedRef = useRef(false)

  const screenState = useSelector(st => st.screen);
  const dispatch = useDispatch();

  const isAnyChecked = (newCheckState) => {
    return Object.values(newCheckState).some(currentEstado => currentEstado.checked)
  }

  const turnFalseAllChecks = (newCheckState) => {
    Object.keys(newCheckState).forEach(currentCheck => {
      if (currentCheck != "checkboxAll") newCheckState[currentCheck].checked = false;
    })
    return newCheckState;
  }

  const toggleCheckState = (event, id, bool) => { /* tooglea el estado del check mandando: (event, id) o (loquesea, id, bool) */
    const booleanToToogle = (bool !== undefined) ? bool : event.target.checked;
    if (isMountedRef.current) {
      setChecksState(st => {
        let newCheckState = { ...st, [id]: { ...st[id], checked: booleanToToogle } };
        if (id != "checkboxAll") {
          newCheckState.checkboxAll.checked = false;
        } else if (booleanToToogle) {
          newCheckState = turnFalseAllChecks(newCheckState)
        }
        if (!isAnyChecked(newCheckState)) { newCheckState.checkboxAll.checked = true; }
        return newCheckState;
      });
    }
  }

  const formatCheckStateToInputsForm = (newCheckState) => {
    if (newCheckState.checkboxAll.checked) {
      return [];
    } else {
      const arrChecked = Object.keys(newCheckState).filter(currentKey => newCheckState[currentKey].checked);
      return arrChecked;
    }
  }

  const resetAllEstadosOptions = () => {
    // const defaultCheckState = allEstadosOptions.reduce((acum, currentCheck) => { return { ...acum, [currentCheck.nombre]: false } }, {});
    if (isMountedRef.current) {
      setChecksState(st => { return allEstadosOptions });
    }
  }

  useEffect(() => {
    const getAllEstadosOptions = async () => {
      let resp = await genericService.getEstados();
      let defaultCheckState = resp.reduce((acum, currentCheck) => { return { ...acum, [currentCheck.id]: { ...currentCheck, checked: false } } }, {});
      if (inputsForm.estado) {
        inputsForm.estado.forEach(ctId => {
          const currentEstado = resp.find(ctEs => ctEs.id == ctId);
          if (currentEstado) defaultCheckState[currentEstado.id] = { ...currentEstado, checked: true };
        })
        defaultCheckState["checkboxAll"] = { id: "checkboxAll", nombre: "Todos", checked: false };
      }
      console.log({
        estado: inputsForm.estado,
        defaultCheckState,
        resp,
      })

      // if (isMountedRef.current) {
      setChecksState(st => { return { ...st, ...defaultCheckState } });
      setAllEstadosOptions(resp);
      // }
      isMountedRef.current = true;
    };

    getAllEstadosOptions();
    return () => { isMountedRef.current = false };
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      setInputsForm(st => {
        const newCheckStateFormated = formatCheckStateToInputsForm(checksState);
        const newSt = { ...st }
        if (newCheckStateFormated.length == 0) {
          delete newSt.estado
        } else {
          newSt.estado = newCheckStateFormated
        }
        console.log("ACA", newSt)
        return newSt
      });
    }
  }, [checksState, setInputsForm])

  useEffect(() => {
    if (screenState.resetChecks) {
      resetAllEstadosOptions();
      dispatch(resetChecks(false));
    }
  }, [screenState, dispatch])

  return (
    <div className="form-checkbox-row">
      {allEstadosOptions.map(currentEstado =>
        <div key={currentEstado.id} className="form-checkbox-container">
          <Input
            className="checkboxEstados"
            type="checkbox"
            controlId={currentEstado.id}
            label={currentEstado.nombre}
            onChange={(event) => toggleCheckState(event, currentEstado.id)}
            value={checksState.checkboxAll}
            checked={checksState[currentEstado.id].checked}
          ></Input>
        </div>
      )}

      <div className="form-checkbox-container">
        <Input
          type="checkbox"
          label="Todos"
          controlId="checkboxAll"
          onChange={(event) => toggleCheckState(event, "checkboxAll")}
          value={checksState.checkboxAll}
          checked={checksState.checkboxAll.checked}
        ></Input>
      </div>
    </div>
  );
};

export default CheckBoxEstados;
