import React from 'react'
import {Row, Col} from 'react-bootstrap'

export const Loader = () => {

    return(
        <Row>
            <Col>
                <div className=" d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </Col>
        </Row>
    )

}