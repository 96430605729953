import Organismo, { createOrganismo } from "./Organismo"
import TipoResolucion, { createTipoResolucion } from "./TipoResolucion"
import Acciones, {createAcciones} from "./Acciones";

class Resolucion {
    constructor( ) {
        this.activo = true;
        this.id = "";
        this.numero = "";
        this.organismo = Organismo;
        this.anio = "";
        this.tipoResolucion = TipoResolucion;
        this.nombre = "";
        this.acciones = [];
    }

    getNombreOrganismo(){
        return this.organismo ? this.organismo.nombre : "";
    }
    getNombreTipoResolucion(){
        return this.tipoResolucion ? this.tipoResolucion.nombre : "";
    }
}

export default Resolucion

export const createResolucion = async (resolucion) => {
    let organismo = resolucion.organismo ? await createOrganismo(resolucion.organismo):{};
    let tipoResolucion = resolucion.tipoResolucion ? await createTipoResolucion(resolucion.tipoResolucion):{};
    let acciones = resolucion.acciones ? await createAcciones(resolucion.acciones):new Acciones();
    const r = Object.assign(new Resolucion(), {...resolucion, organismo, tipoResolucion,acciones })

    return r;
}

