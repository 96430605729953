import { ticketsService } from "./TicketsService";

const objModuloNoEncontrado = {
    id: 0
}

const OPTIONS_MODULOS_NAME = {
    TERNAS: "Ternas",
    INCUMBENCIAS: "Incumbencias",
}

const OPTIONS_TIPOS_TICKETS_NAME = {
    ALTA: "Alta",
    BAJA: "Baja",
    MODIFICACION: "Modificar",
    ELIMINACION: "Eliminar",
}

const getStoredModuloTickets = async (allStoredModulos, findStoredModuloName, getOnlyThisAttributeKey) => {
    const arrAllModulos = allStoredModulos ?? await ticketsService.getModulosTickets();
    const foundModulo = arrAllModulos.find(modulo => modulo.nombre == findStoredModuloName);

    if (getOnlyThisAttributeKey) {
        return foundModulo[getOnlyThisAttributeKey] ?? objModuloNoEncontrado[getOnlyThisAttributeKey]
    }

    return foundModulo ?? objModuloNoEncontrado
}

const filterStoredTiposTickets = ({ allStoredTipos, arrNombresTiposToRemove, arrNombresTiposToReturn }) => {
    const arrAllTipos = allStoredTipos ?? [];
    if (arrNombresTiposToRemove) {
        return arrAllTipos.filter(modulo => !arrNombresTiposToRemove.find(currentNombreToRemove => currentNombreToRemove == modulo.nombre));
    } else {
        return arrAllTipos.filter(modulo => !arrNombresTiposToReturn.find(currentNombreToRemove => currentNombreToRemove != modulo.nombre));
    }
}

export const ticketsUtils = {
    OPTIONS_MODULOS_NAME,
    OPTIONS_TIPOS_TICKETS_NAME,
    getStoredModuloTickets,
    filterStoredTiposTickets,
}