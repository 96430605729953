import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { error } from "../../store/alerts/alertActions";
import { clearFilters } from "../../store/search/searchActions";
import { Loader } from "../../ui/Loader";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import UserService from "../../utils/user.service";
import UserABMForm from "./UserABMForm";
import UserABMTable from "./UserABMTable";

const UserABMSearch = (props) => {
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const [cantidadUsuarios, setCantidadUsuarios] = useState(null);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();

  const filtrosGuardados = useSelector((state) => state.search);

  useEffect(() => {
    buscarSiHayFiltros();
    return () => { dispatch(clearFilters()) }
  }, []);

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      await searchUsers(filtrosGuardados)
      setPage(filtrosGuardados.page);
    }
  }

  const handleSearch = async (filters) => {
    searchUsers(filters);
  };

  const searchUsers = async (filtros) => {
    try {
      let filtro = filtros ? filtros : [];
      filtro["all"] = true;

      //Reset page
      setPage(1);

      setSearching(true)
      const users = await UserService.getUsers(filtro);
      setSearching(false)
      setUsers(users.data);
      setCantidadUsuarios(users.cantidad);
    } catch (e) {
      dispatch(error("Ups, ha ocurrido un error al realizar la busqueda."));
    }
  };

  const handleChangePage = (event, value) => {
    filtrosGuardados["page"] = value;
    searchUsers(filtrosGuardados);
    setPage(value);
  };

  return (
    <ScreenWithFabButtons
      returnPath={location.state?.returnPath}
      hidePlusButton={false}
      hideSearchButton={false}
      hideCleanButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !users}
    >

      <Row>
        <Col>
          <h1>Usuarios</h1>
          <UserABMForm
            // submitValue="Buscar"
            onSubmit={handleSearch}
            volverBtn={false}
            validate={false}
            filtros={filtrosGuardados}
          ></UserABMForm>
          {
            users[0] && !searching ?
              <UserABMTable
                onSubmit={searchUsers}
                users={users}
                cantidadUsuarios={cantidadUsuarios}
                changePage={handleChangePage}
                page={page}
              >
              </UserABMTable> :
              searching && <Loader></Loader>
          }
        </Col>
      </Row>

    </ScreenWithFabButtons>
  );
};

export default UserABMSearch;
