import { Form, Col, Row } from "react-bootstrap";
import Input from "../../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidNumeric, isValidText, handlerChange } from "../../../utils/commons";
import { Loader } from "../../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../../store/alerts/alertActions";
import { saveFilters } from "../../../store/search/searchActions";
import * as ternaService from "../../../ternas/TernaService"

import { estadosConstans } from "../../../utils/Constants";
import { cleanFab, searchFab } from "../../../store/screen/screenActions";

export const IncumbenciasFormSearchTernas = ({
    setTernas,
    setCantidadResultados,
    setPage,
    onSubmit
}) => {
    const defaultInputsValues = {
        // estado: [estadosConstans.APROBADO, estadosConstans.MIGRADO],
        apendiceId: "",
        folio: "",
        codDad: "",
        nombreTituloSeleccionado: ""
    }

    const [inputsForm, setInputsForm] = useState(defaultInputsValues);
    const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");
    const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
    const [tituloSeleccionado, setTituloSeleccionado] = useState("");

    const isMountedRef = useRef(null);

    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);

    const cargarInputsFormConSeleccionados = () => {
        inputsForm.procedenciaSeleccionada = procedenciaSeleccionada;
        inputsForm.resolucionSeleccionada = resolucionSeleccionada;
    }

    const resetForm = () => {
        setInputsForm(defaultInputsValues);
        setResolucionSeleccionada("");
        setProcedenciaSeleccionada("");
        setTernas("");
        setCantidadResultados("");
        setTituloSeleccionado("");
    }

    const processSubmit = async () => {
        dispatch(clear());
        inputsForm["page"] = 1;      //Para el paginado
        cargarInputsFormConSeleccionados();
        dispatch(saveFilters(inputsForm));
        await onSubmit(inputsForm).catch((err) => {
            dispatch(error(err.response.data.message));
        });
    };

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    };

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false
            setTernas("");
            setCantidadResultados("");
            setPage(1);
        }
    }, [dispatch]);

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            processSubmit();
            dispatch(searchFab(false));
        }
    }, [screenState])

    return (
        <Row>
            <Col>
                {true ? (
                    <Form id="formSearchAsignaturas">
                        <Row>
                            <Col sm={12} md={3} lg={2}>
                                <Input
                                    type="input"
                                    controlId="apendice"
                                    label="Apendice"
                                    value={inputsForm.apendice}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                ></Input>
                            </Col>

                            <Col sm={12} md={3} lg={2}>
                                <Input
                                    type="input"
                                    controlId="folio"
                                    label="Folio"
                                    maxLength="100"
                                    value={inputsForm.folio}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="nombre"
                                    name="nombre"
                                    label="Nombre de título"
                                    value={tituloSeleccionado}
                                    methodOnChange={handleChange}
                                    elements={ternaService.getTitulos}
                                    nameElementsResponse="elementos"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setTituloSeleccionado}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="procedencia"
                                    name="procedencia"
                                    label="Procedencia"
                                    value={procedenciaSeleccionada}
                                    methodOnChange={handleChange}
                                    elements={ternaService.getProcedencias}
                                    nameElementsResponse="elementos"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setProcedenciaSeleccionada}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="resolucion"
                                    name="resolucion"
                                    label="Resolución"
                                    countCaracters={2}
                                    value={resolucionSeleccionada}
                                    methodOnChange={handleChange}
                                    elements={ternaService.getResolucionesCombo}
                                    nameElementsResponse="elementos"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setResolucionSeleccionada}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loader></Loader>
                )}
            </Col>
        </Row>
    );
};
