import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import { createApendice } from "../dtos/apendice/Apendice";
const { apiURLs, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");

const saveDraft = async (apendice, resolve) => {
    resolve(apendice);
    await store.dispatch(success(<>Se guardó correctamente el apendice <b>{apendice.nombre}</b>.</>))
}

const getCurrentApendice = async () => {
    return await requestService.sendRequest('GET', apiURLs.API_MAESTROS_APENDICE_ACTUAL)
        .then(resp => resp.data.elementos[0])
        .catch(e => {
            store.dispatch(error(e.response.data.message))
            return "Error"
        })
}

const getApendices = async (filtros = {}) => {
    /* all en true */

    let apendices = []
    if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS;
    if (filtros['all'] === undefined) filtros['all'] = true;

    let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_APENDICE, filtros)
    response.data.elementos.forEach(apendice => {
        apendices.push(createApendice(apendice))
    });

    let apendicesConcretos = await Promise.all(apendices);

    return {
        cantidad: response.data.cantidad,
        elementos: apendicesConcretos,
        page: Number(response.data.page),
    }
}

const createNewApendice = async (apendice, resolve) => {
    return await createEditApendice(armarApendice(apendice), resolve, 'POST', apiURLs.API_MAESTROS_APENDICE);
}

const editApendice = async (apendice, resolve) => {
    return await createEditApendice(armarApendice(apendice), resolve, 'POST', apiURLs.API_MAESTROS_APENDICE);
}

const armarApendice = (apendice) => {
    let { nombre, fecha_desde, ...body } = apendice;
    return {
        nombre: nombre,
        fecha_desde: fecha_desde,
        ...body
    };
}

const createEditApendice = async (apendice, resolve, typeReuest, apiURL) => {
    const apendiceArmado = armarApendice(apendice);
    try {
        const response = await requestService.sendRequest(typeReuest, apiURL, apendiceArmado)
        resolve(response.data)
        return true
    }
    catch (err) {
        store.dispatch(error(err.response.data.message));
        return false
    }
}

export {
    saveDraft,
    getApendices,
    createNewApendice,
    editApendice,
    getCurrentApendice
}