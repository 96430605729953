import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { error, success } from '../store/alerts/alertActions'
import { cancelarDescargas, completarDescargas, iniciarDescargas, turnOffAlert } from '../store/descargas/descargasActions.'
import { MAPA_DESCARGAS } from './DescargasPanel'
import axios from 'axios'

export const DescargasButton = () => {

    const { colaDescargas, arrActivas, showAlert } = useSelector(st => st.descargas)

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        dispatch(turnOffAlert())
    }

    useEffect(() => {
        if (colaDescargas.length > 0) {
            const arrDescargasIniciadas = [];
            colaDescargas.forEach(ctDescarga => {
                const abortSource = axios.CancelToken.source();
                const downloadFunc = MAPA_DESCARGAS[ctDescarga.opcion];
                const resolve = () => {
                    dispatch(completarDescargas([ctDescarga]));
                }
                const reject = () => {
                    dispatch(cancelarDescargas([ctDescarga]));
                }
                console.log({ ctDescarga })
                downloadFunc ? downloadFunc(ctDescarga.filtros, resolve, reject, abortSource, ctDescarga) : dispatch(error("No se encontro la funcion para realizar la descarga"));
                arrDescargasIniciadas.push({ ...ctDescarga, abortSource })
            })
            dispatch(iniciarDescargas(arrDescargasIniciadas));
        }
    }, [colaDescargas])


    return (
        <button onClick={handleClickOpen} class={`btn btn-secondary descargas__main-btn ${arrActivas.length > 0 ? "downloading" : arrActivas.length == 0 && showAlert ? "alerting" : ""}`}>
            <FontAwesomeIcon icon={faCloudDownloadAlt} size="s" />
        </button>
    )
}
