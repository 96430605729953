import React from "react";
import { Table, Row, Col, Alert, Button, Accordion } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";

const EquivalenciaTable = ({
  titulos,
  cantidadTitulos,
  page,
  changePage,
  renderTableRows,
  ...props
}) => {
  return (
    <>
      <Row>
        <Col>
            {renderTableRows(titulos)}
        </Col>
      </Row>
      <Paginacion
        totalItems={cantidadTitulos}
        currentPage={page}
        onChangePage={(event, value) => {
          changePage(event, value);
        }}
      />
    </>
  );
};

export default EquivalenciaTable;