export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const screenTypes = {
    CLEAN_FAB: "CLEAN_FAB",
    SEARCH_FAB: "SEARCH_FAB",
    RESET_FAB: "RESET_FAB",
    RESET_CHECKS: "RESET_CHECKS",
}

export const incumbenciasTypes = {
    SAVE_DATA: "SAVE_DATA",
    CLEAN_DATA: "CLEAN_DATA",

    REPLACE_ARR: "REPLACE:ARR",

    ADD_TERNA: "ADD_TERNA",
    ADD_CARGO: "ADD_CARGO",
    ADD_VARIANTE: "ADD_VARIANTE",
    ADD_VARIANTES_WITH_INDEX: "ADD_VARIANTES_WITH_INDEX",

    ADD_DELETED_LIST: "ADD_DELETED_LIST",

    REMOVE_CARGO: "REMOVE_CARGO",
    REMOVE_TERNA: "REMOVE_TERNA",
    REMOVE_VARIANTE: "REMOVE_VARIANTE",

    ADD_CONCURRENCIA_EMPTY_ROW: "ADD_CONCURRENCIA_EMPTY_ROW",
    ADD_TERNA_TO_CONCURRENCIA: "ADD_TERNA_TO_CONCURRENCIA",
    ADD_CARGO_TO_CONCURRENCIA: "ADD_CARGO_TO_CONCURRENCIA",
    ADD_CARGO_VARIANTE_TO_CONCURRENCIA: "ADD_CARGO_VARIANTE_TO_CONCURRENCIA",
    ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_TERNA: "ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_TERNA",
    ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_CARGO: "ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_CARGO",
    REMOVE_TERNA_FROM_CONCURRENCIA: "REMOVE_TERNA_FROM_CONCURRENCIA",
    REMOVE_CARGO_FROM_CONCURRENCIA: "REMOVE_CARGO_FROM_CONCURRENCIA",
    REMOVE_VARIANTE_FROM_CONCURRENCIA: "REMOVE_VARIANTE_FROM_CONCURRENCIA",

    REMOVE_CONCURRENCIA: "REMOVE_CONCURRENCIA",
    REMOVE_CARGO_CONCURRENCIA: "REMOVE_CARGO_CONCURRENCIA",
    ADD_CARGO_CONCURRENCIA: "ADD_CARGO_CONCURRENCIA",
    REMOVE_VARIANTE_CONCURRENCIA: "REMOVE_VARIANTE_CONCURRENCIA",
    ADD_VARIANTE_CONCURRENCIA: "ADD_VARIANTE_CONCURRENCIA",

    ADD_ARR_CONCURRENCIAS: "ADD_ARR_CONCURRENCIAS",
}

export const dataTypes = {
    ADD_DATA: "ADD_DATA",
    REMOVE_DATA: "REMOVE_DATA",
    CLEAN_ALL_DATA: "CLEAN_ALL_DATA",
}

export const descargasTypes = {
    AGREGAR_DESCARGAS: "AGREGAR_DESCARGAS",
    INICIAR_DESCARGAS: "INICIAR_DESCARGAS",
    CANCELAR_DESCARGAS: "CANCELAR_DESCARGAS",
    ELIMINAR_DESCARGAS: "ELIMINAR_DESCARGAS",
    COMPLETAR_DESCARGAS: "COMPLETAR_DESCARGAS",
    REINICIAR_DESCARGAS: "REINICIAR_DESCARGAS",
    TURN_ON_ALERT: "TURN_ON_ALERT",
    TURN_OFF_ALERT: "TURN_OFF_ALERT",
    TOGGLE_SHOW_DESCRIPCION_DESCARGAS: "TOGGLE_SHOW_DESCRIPCION_DESCARGAS",
}
