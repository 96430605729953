import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Table, Alert } from "react-bootstrap";
import { Loader } from '../../../ui/Loader';
import { IncumbenciasDetailRowActions } from './IncumbenciasDetailRowActions';
import { TrEnConcurrenciaCon } from './TrEnConcurrenciaCon';

export const IncumbenciasDetailBodyTernas = ({
    incumbencias,
    resolveTicket,
    setErrorMsg,
    setSuccessMsg,
    clearErrorMsg,
    recargarIncumbencias,
}) => {

    return (
        <>
            <Row className="form-group">
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Apendice</th>
                                <th>Folio</th>
                                <th>Area</th>
                                <th>Tipo escuela</th>
                                <th>Cargo</th>
                                <th>Asignatura</th>
                                <th>Especialidad</th>
                                <th>Condicion</th>
                                <th>Puntaje</th>
                                <th>Suma OT</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                incumbencias.map(currentIncumbencia =>
                                    <>
                                        <tr key={currentIncumbencia.id}>
                                            <td>{currentIncumbencia.apendice.nombre}</td>
                                            <td>{currentIncumbencia.folio || "-"}</td>
                                            <td>{currentIncumbencia.area.nombre}</td>
                                            <td>{currentIncumbencia.tipoEscuela.nombre}</td>
                                            <td>{currentIncumbencia.cargo.nombre || "-"}</td>
                                            <td>{currentIncumbencia.asignatura.nombre || "SIN ASIGNATURA"}</td>
                                            <td>{currentIncumbencia.especialidad.nombre || "SIN ESPECIALIDAD"}</td>
                                            <td>{currentIncumbencia.calidad || "-"}</td>
                                            <td>{currentIncumbencia.puntaje || "-"}</td>
                                            <td>{currentIncumbencia.sumaOT ? "SI" : "NO"}</td>
                                            <td>
                                                {
                                                    currentIncumbencia.estado ?
                                                        <Badge variant={currentIncumbencia.estado.color}>
                                                            {currentIncumbencia.estado.nombre}
                                                        </Badge>
                                                        :
                                                        "-"
                                                }
                                            </td>

                                            <td>
                                                {currentIncumbencia &&
                                                    <div className="table-button-group">
                                                        <IncumbenciasDetailRowActions
                                                            incumbencia={currentIncumbencia}
                                                            resolveTicket={resolveTicket}
                                                            setErrorMsg={setErrorMsg}
                                                            setSuccessMsg={setSuccessMsg}
                                                            clearErrorMsg={clearErrorMsg}
                                                            recargarIncumbencias={recargarIncumbencias}
                                                        />
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        {currentIncumbencia.concurrencia &&
                                            <TrEnConcurrenciaCon data={currentIncumbencia} colSpan={11} />
                                        }
                                    </>
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};


