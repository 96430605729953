import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants"
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import EscuelaABM from "./EscuelaABM"
import { useLocation } from "react-router-dom";
import Escuela, { createEscuela } from "../../dtos/escuela/Escuela";

const EscuelaABMEdit = (props) => {
  let location = useLocation();
  const [escuela, setEscuela] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (user) => {
    //await processEditUser(user)
    dispatch(success("Se editó el escuela con éxito."));
  }

  const crearEscuela = async (escuela) => {
    const escuelaReloaded = await createEscuela(escuela);
    return escuelaReloaded;
  }

  const asignarEscuela = (escuela) => {
    setEscuela(escuela);
    if (escuela instanceof Escuela)
      setLoading(false);
  }
  
  useEffect(() => {
    dispatch(clear());
    setLoading(true);
    if (location.state && location.state.escuela) {
      let escuela = location.state.escuela;
      if (!(escuela instanceof Escuela)) {
        escuela = crearEscuela(escuela)
      }
      asignarEscuela(escuela);
    }
  }, [dispatch, location.state]);

  return ( !loading &&
    <EscuelaABM escuela={escuela} title="Editar escuela" typeOfAction={typeOfActions.EDIT} onSubmit={handleSubmit} returnPath={routePaths.ABM_ESCUELA_SEARCH}/>
  );
};

export default EscuelaABMEdit;
