import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import { cleanFab, searchFab } from "../../store/screen/screenActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { handlerChange, isValidNumeric, isValidText } from "../../utils/commons";
import * as asignaturaService from "../AsignaturaService";

const AsignaturaFormSearch = ({
  setAsignaturas,
  onSubmit,
  hideChecks,
  defaultInputsForm,
}) => {
  const [inputsForm, setInputsForm] = useState(defaultInputsForm ? { ...defaultInputsForm } : {});
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [nombreAsignaturaSeleccionada, setNombreAsignaturaSeleccionada] = useState("");
  const [resetChecks, setResetChecks] = useState(false);
  const cleanRelatedFiltersRef = useRef(false)

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);
  const filtrosGuardados = useSelector((state) => state.search);

  const getAreaOptions = async () => {
    const resp = await especialidadesService.getAreas();
    setAreaOptions(resp.data);
  }

  const processSubmit = async () => {
    let data;

    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      data = filtrosGuardados
    } else {
      const filtrosDefault = { page: 1 }
      data = {
        inputsForm: { ...inputsForm, ...filtrosDefault },
        areaSeleccionada,
        nombreAsignaturaSeleccionada
      }
    }

    await onSubmit(data)
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handleChange(e);
    }
  };

  const handleValidChangeSelect = (isValid, e) => {
    if (isValid(e.target.label)) {
      let eTarget = { target: { id: e.target.id, value: e.target.label } };
      handleChange(eTarget);
    }
  };

  const resetForm = () => {
    setAsignaturas(null);
    setInputsForm({});
    setNombreAsignaturaSeleccionada("");
    setAreaSeleccionada("");
    setResetChecks(true);
  }

  const loadForm = () => {
    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      setInputsForm(filtrosGuardados.inputsForm)
      setAreaSeleccionada(filtrosGuardados.areaSeleccionada);
      setNombreAsignaturaSeleccionada(filtrosGuardados.nombreAsignaturaSeleccionada);
    }
  }

  useEffect(() => {
    getAreaOptions();
    loadForm();
  }, []);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchAsignaturas">
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nombre"
                  name="nombre"
                  label="Nombre de la asignatura"
                  value={nombreAsignaturaSeleccionada}
                  methodOnChange={handleChange}
                  elements={asignaturaService.getAsignaturas}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setNombreAsignaturaSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={3} lg={2}>
                <Input
                  type="input"
                  controlId="cod_dad"
                  label="Código DAD"
                  value={inputsForm.cod_dad ? inputsForm.cod_dad : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="area"
                  name="area"
                  label="Área"
                  value={areaSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getAreas}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setAreaSeleccionada}
                  config={{ isSearchable: true, isClearable: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
            </Row>
            <Row className="form-group">
              {!hideChecks &&
                <Col sm={12}>
                  <CheckBoxEstados
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    resetChecks={resetChecks}
                    setResetChecks={setResetChecks}
                  />
                </Col>}
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default AsignaturaFormSearch;
