import dateFormat from 'dateformat';

export const adaptGetTernasConIncumbencias = (arrElementos) => {
    return arrElementos.map(ctElement => {
        return {
            apendice: ctElement.apendice,
            folio: ctElement.folio,
            id: ctElement.terna_id,
            createdAt: dateFormat(ctElement.created_at, "dd/mm/yyyy"),
            estado: {
                color: ctElement.estado_color,
                nombre: ctElement.estado_nombre,
            },
            titulo: {
                nombre: ctElement.titulo_nombre,
            },
            procedencia: {
                nombre: ctElement.procedencia_nombre,
            },
            resolucion: {
                nombre: ctElement.resolucion_completa
            },
            final: ctElement.final,
            troncal: ctElement.troncal,
            nota: ctElement.nota,
            regla: ctElement.regla,
        }
    })
}

export const adaptGetInformeCruzadoPorArea = (arrElementos) => {
    return arrElementos.map(ctElement => {
        let formatedResponse = {}
        formatedResponse.DyM = ctElement.DyM;
        formatedResponse.d = ctElement.d;
        formatedResponse.nm = ctElement.nm;
        formatedResponse.h = ctElement.h;
        formatedResponse.s = ctElement.s;
        formatedResponse.ot = ctElement.ot;
        formatedResponse.iol = ctElement.iol;

        formatedResponse.folio = ctElement.folio;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.calidad = ctElement.calidad;

        formatedResponse.estado = {
            nombre: ctElement.estado,
            color: null,
        }

        formatedResponse.area = {
            nombre: ctElement.area,
            area: ctElement.area_area,
        };

        formatedResponse.createdAt = dateFormat(ctElement.fecha_creacion, "dd/mm/yyyy");

        formatedResponse.apendice = {
            id: null,
            nombre: ctElement.DyM,
        };

        formatedResponse.cargo = {
            id: ctElement.cargo_id,
            codDad: ctElement.cod_dad,
            nombre: ctElement.cargo,
        };

        formatedResponse.asignatura = {
            id: ctElement.asignatura_id,
            codDad: ctElement.codigo_asignatura,
            nombre: ctElement.asignatura,
        };

        formatedResponse.especialidad = {
            id: ctElement.especialidad_id,
            nombre: ctElement.especialidad,
            codDad: ctElement.codigo_especialidad,
        };

        formatedResponse.tipoEscuela = {
            id: ctElement.id_tipo_escuela,
            nombre: ctElement.tipo_escuela,
        };

        return formatedResponse;
    })
}

export const adaptGetInformeTernasPorAreaCargo = (arrElementos) => {
    return arrElementos.map(ctElement => {
        let formatedResponse = {}
        formatedResponse.id = ctElement.incumbencia_id;
        formatedResponse.Municipal = ctElement.Municipal;
        formatedResponse.apendice = {
            id: null,
            nombre: ctElement.apendice
        };
        formatedResponse.folio = ctElement.folio;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.createdAt = dateFormat(ctElement.fecha_creacion, "dd/mm/yyyy");
        formatedResponse.calidad = ctElement.tipo_incumbencia_descripcion;

        formatedResponse.concurrencia = Boolean(Number(ctElement.concurrencia));

        formatedResponse.terna = ctElement.terna ? {
            id: ctElement.terna,
            titulo: { nombre: ctElement.titulo_terna },
            procedencia: { nombre: ctElement.procedencia_nombre },
            resolucion: { nombre: ctElement.resolucion_nombre_completo },
            regla: ctElement.regla_terna_1,
        } : null

        formatedResponse.terna2 = ctElement.terna2_id ? {
            id: ctElement.terna2_id,
            titulo: { nombre: ctElement.titulo_terna_2 },
            procedencia: { nombre: ctElement.procedencia_terna_2 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_2 },
            regla: ctElement.regla_terna_2,
        } : null

        formatedResponse.terna3 = ctElement.terna3_id ? {
            id: ctElement.terna3_id,
            titulo: { nombre: ctElement.titulo_terna_3 },
            procedencia: { nombre: ctElement.procedencia_terna_3 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_3 },
            regla: ctElement.regla_terna_3,
        } : null

        formatedResponse.cargo = {
            nombre: ctElement.cargo,
            codDad: ctElement.codigo_cargo,
        }

        formatedResponse.estado = {
            nombre: ctElement.estado,
            color: ctElement.estado_color,
        }

        formatedResponse.asignatura = {
            nombre: ctElement.asignatura,
            codDad: ctElement.codigo_asignatura,
            nombreInvertido: ctElement.asignatura_nombre_invertido,
        }

        formatedResponse.especialidad = {
            nombre: ctElement.especialidad,
            codDad: ctElement.codigo_especialidad,
        }

        formatedResponse.area = {
            nombre: ctElement.area,
        }

        formatedResponse.tipoEscuela = {
            nombre: ctElement.nombre_tipo_escuela,
        }
        return formatedResponse
    })
}

export const adaptGetInformeTopologico = (arrElementos) => {
    return arrElementos.map(ctElement => {
        let formatedResponse = {}
        formatedResponse.type = ctElement.type;
        formatedResponse.folio = ctElement.folio;
        formatedResponse.puntaje = ctElement.puntaje;
        formatedResponse.calidad = ctElement.calidad;
        formatedResponse.iol = ctElement.iol;
        formatedResponse.createdAt = dateFormat(ctElement.fecha_creacion, "dd/mm/yyyy");
        formatedResponse.apendice = {
            id: null,
            nombre: ctElement.apendice,
        };
        formatedResponse.terna = ctElement.terna_id ? {
            id: ctElement.terna_id,
            titulo: { nombre: ctElement.titulo_nombre },
            procedencia: { nombre: ctElement.procedencia_nombre },
            resolucion: { nombre: ctElement.resolucion_nombre_completo },
            regla: ctElement.regla_terna_1,
        } : null;

        formatedResponse.terna2 = ctElement.terna2_id ? {
            id: ctElement.terna2_id,
            titulo: { nombre: ctElement.titulo_terna_2 },
            procedencia: { nombre: ctElement.procedencia_terna_2 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_2 },
            regla: ctElement.regla_terna_2,
        } : null;

        formatedResponse.terna3 = ctElement.terna3_id ? {
            id: ctElement.terna3_id,
            titulo: { nombre: ctElement.titulo_terna_3 },
            procedencia: { nombre: ctElement.procedencia_terna_3 },
            resolucion: { nombre: ctElement.resolucion_completa_terna_3 },
            regla: ctElement.regla_terna_3,
        } : null;
        
        formatedResponse.cargo = {
            id: null,
            codDad: ctElement.codigo_cargo,         
            nombre: ctElement.cargo,
        };
        formatedResponse.asignatura = {
            id: null,
            codDad: ctElement.codigo_asignatura,
            nombre: ctElement.asignatura,
        };
        formatedResponse.especialidad = {
            id: null,
            codDad: ctElement.codigo_especialidad,
            nombre: ctElement.especialidad,
        };
        formatedResponse.tipoEscuela = {
            id: null,
            nombre: ctElement.nombre_escuela,
        };
        formatedResponse.area = {
            id: null,
            nombre: ctElement.area,
        };
        formatedResponse.nivel = {
            id: null,
            nombre: ctElement.nivel_nombre,
        };
        formatedResponse.estado = {
            color: ctElement.estado_color,
            nombre: ctElement.estado,
        };
        formatedResponse.concurrencia = Boolean(Number(ctElement.concurrencia));
        return formatedResponse
    })
}