import { makeValidFileName } from "../utils/commons";
import { apiURLs } from "../utils/Constants";
import requestService from "../utils/request.service";

export const downloadPDFInformeCruzado = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "area_id"
        "cargo_id"
    */
    let defaultFileName = `informe-vademecum`
    if (filtros.area_id) defaultFileName = defaultFileName + `-area-${filtros.area_id}`
    if (filtros.cargo_id) defaultFileName = defaultFileName + `-cargo-${filtros.cargo_id}`

    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS;
    try {
        const response = await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVInformeCruzado = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        csv: bool
    */
    const defaultFiltros = { csv: true }
    let defaultFileName = `informe-vademecum`
    if (filtros.area_id) defaultFileName = defaultFileName + `-area-${filtros.area_id}`
    if (filtros.cargo_id) defaultFileName = defaultFileName + `-cargo-${filtros.cargo_id}`

    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS;

    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeIncumbByTernaPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        titulo
        procedencia_id
        resolucion_id
        area_id
        otros_titulos
        titulos
    */
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA_1RA_VISTA;
    try {
        await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }

}

export const downloadCSVInformeIncumbByTernaPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        csv: bool
    */
    const defaultFiltros = { csv: true }
    const defaultFileName = `informe-incumbencias-terna-sin-filtros`;

    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA_1RA_VISTA;

    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadCSVInformeIncumbByTernaSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFiltros = { csv: true }

    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;

    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeIncumbByTernaSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "terna_id"
        "ternas_equivalentes_id"
        "ternas_intermedios_id"
        "ternas_troncales_id"
    */
    console.log("asd", { config })
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;
    try {
        await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTopologico = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "apendice"
        "folio"
        "area_id"
    */
    const defaultFileName = `informe-topologico`;
    const url = apiURLs.API_INFORMES_GENERAR_TOPOLOGICO;
    try {
        await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadCSVInformeTopologico = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "apendice"
        "folio"
        "area_id"
    */
    const defaultFiltros = { csv: true }
    const defaultFileName = `informe-topologico`;
    const url = apiURLs.API_INFORMES_GENERAR_TOPOLOGICO;
    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVTernasByAreaCargoPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista`;
    const defaultFiltros = { csv: true }
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA;
    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadPDFTernasByAreaCargoPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo"
        "asignatura"
        "especialidad"
        "area"
        "tipo_escuela"
    */
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista`;
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA;
    try {
        await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFTernasByAreaCargoPrimeraVistaInformativo = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo_id"
        "asignatura_id"
        "especialidad_id"
        "area_id"
        "tipo_escuela_id"
    */
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista`;
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA;
    try {
        await requestService.downloadPDF(
            url,
            { ...filtros, informativo: true },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFTernasByAreaCargoSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo_id"
        "asignatura_id"
        "especialidad_id"
        "area_id"
        "tipo_escuela_id"
    */
    const defaultFileName = `informe-ternas-area-cargo`;
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO;
    console.log("ACA", { config })

    try {
        await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeCruzadoPorCalidad = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        area_id
        asignatura_id
        cargo_id
        especialidad_id
        tipo_incumbencia_id
        serializeAll
    */
    const params = { ...filtros }
    if (params.page) delete params.page
    if (params.limit) delete params.limit

    const defaultFileName = `informe-ternas-area-cargo`;
    console.log({config})
    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS_POR_CALIDAD;
    try {
        await requestService.downloadPDF(
            url,
            params,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVTernasByAreaCargoSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-ternas-area-cargo`;
    const defaultFiltros = { csv: true }
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO;
    console.log("ACA", { config })
    try {
        await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVInformeTernaTitulos = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-terna-titulos`
    const defaultFiltros = { csv: true }

    const url = apiURLs.API_INFORMES_GENERAR_TERNA_TITULOS;
    try {
        const response = await requestService.downloadCSV(
            url,
            { ...filtros, ...defaultFiltros },
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaTitulos = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-terna-titulos`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_TITULOS;
    try {
        const response = await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaResoluciones = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:

    */
    const defaultFileName = `informe-terna-resoluciones`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_RESOLUCIONES;
    try {
        const response = await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaProcedencias = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:

    */
    const defaultFileName = `informe-terna-procedencias`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_PROCEDENCIAS;
    try {
        const response = await requestService.downloadPDF(
            url,
            filtros,
            config.fileName ? makeValidFileName(config.fileName) : defaultFileName,
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadTESTEOCompletar = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    setTimeout(() => {
        resolve()
    }, 3000)
}

export const downloadTESTEOCancelar = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    setTimeout(() => {
        reject()
    }, 3000)
}
