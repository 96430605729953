import React, { useState } from "react";
import { Alert, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import Paginacion from "../../generics/Paginacion";
import AsignaturaSearchRow from "./AsignaturaSearchRow";

const AsignaturaSearchTable = ({
  asignaturas,
  cantidadAsignaturas,
  page,
  changePage,
  visibleCheckbox,
  handleClickSeleccionar,
}) => {

  const [seleccion, setSeleccion] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const renderTableRows = (asignaturas) => {
    return asignaturas.map((asignatura) => {
      return (
        <AsignaturaSearchRow
          visibleCheckbox={visibleCheckbox}
          key={asignatura.id}
          asignatura={asignatura}
          setSeleccion={setSeleccion}
        ></AsignaturaSearchRow>
      );
    });
  };

  return (
    <>
      {!asignaturas[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron asignaturas.</Alert>
          </Col>
        </Row>
      ) : (
        !!asignaturas && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      {visibleCheckbox && <th></th>}
                      <th>Nombre de la asignatura</th>
                      <th>Código DAD</th>
                      <th className="width-lg-30">Área</th>
                      <th>Fecha</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(asignaturas)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadAsignaturas} currentPage={page} onChangePage={changePage} />
          </>
        )
      )}
      {!!asignaturas[0] && <Row>
        {visibleCheckbox &&
          <Col>
            <Button
              variant="primary"
              content="Seleccionar"
              type="button"
              onClick={() => handleClickSeleccionar(seleccion, setIsLoading)}
              disabled={isLoading}
            >
              {isLoading ?
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="secondary"
                  /> Cargando
                </>
                :
                "Seleccionar"
              }
            </Button>
          </Col>
        }
      </Row>}
    </>
  );
};

export default AsignaturaSearchTable;