import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Col, Table } from 'react-bootstrap'
import IconButton from '../../../generics/IconButton'

export const IncumbenciasDetailBodyVariantes = ({ title, variantes = [], setVariantes, hideTipoEscuelaFilter }) => {

    const removeVarianteFromList = (index) => {
        setVariantes(arrVariantes => {
            const newVariantes = [...arrVariantes];
            newVariantes.splice(index, 1);
            return newVariantes;
        })
    }

    return (
        <Col>
            <h4>{title}</h4>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>Especialidad</th>
                        <th>Area</th>
                        {!hideTipoEscuelaFilter && <th>Tipo Escuela</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (variantes.length) ?
                            <>
                                {variantes.map((nVariante, index) =>
                                    <tr key={nVariante.asignatura.id + "-" + nVariante.especialidad.id + "-" + index}>
                                        <td>{nVariante.asignatura.nombre || "SIN ASIGNATURA"}</td>
                                        <td>{nVariante.especialidad.nombre || "SIN ESPECIALIDAD"}</td>
                                        <td>{nVariante.area.nombre || "-"}</td>
                                        {!hideTipoEscuelaFilter && <td>{nVariante.tipoEscuela.nombre || "-"}</td>}
                                        <td><IconButton icon={faTrash} onClick={() => removeVarianteFromList(index)} /></td>
                                    </tr>
                                )}
                            </>
                            :
                            <>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </>
                    }
                </tbody>
            </Table>


        </Col>

    )
}
