import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import { createTerna } from "../dtos/terna/Terna";
import { adaptUnificacionTerna } from "./TernaAdapter";
const { apiURLs, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");

const getTernas = async (filtros = {}, isMountedRef) => {
  let ternas = []
  filtros['limit'] = paginado.FILAS_POR_PAGINAS;

  try {
    let response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_TERNA, filtros, isMountedRef)
    response.data.elementos.forEach(terna => {
      ternas.push(createTerna(terna));
    });

    let ternasConcretas = await Promise.all(ternas);
    const ternasConcretadasFormated = ternasConcretas.map(current => {
      current["nombre"] = current.titulo.nombre;
      return current
    })
    return { cantidad: response.data.cantidad, data: ternasConcretadasFormated, page: response.data.page };
  } catch (err) {
    throw new Error(err.message);
  }
}

const getTerna = async (idTerna) => {
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_TERNA + `/${idTerna}`)
    .then(resp => createTerna(resp.data))
    .catch(e => { })
}

const getProcedencias = async (filtros = {}) => {
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_PROCEDENCIA, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}
const getResoluciones = async (filtros = {}) => {
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_RESOLUCION, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}
const getResolucionesCombo = async (filtros = {}) => {
  filtros['numero'] = null;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_RESOLUCION, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}
const getTitulos = async (filtros = {}) => {
  //filtros['limit'] = 0;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_TITULO, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getOrganismos = async (filtros = {}) => {
  //filtros['limit'] = 0;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_ORGANISMO, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getTiposResolucion = async (filtros = {}) => {
  //filtros['limit'] = 0;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_TIPO_RESOLUCION, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getReglas = async (filtros = {}) => {
  filtros['limit'] = 0;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_REGLAS, filtros)
    .then(resp => {
      return resp.data.elementos;
    }).catch(e => { return [] })
}

const getNotas = async (filtros = {}) => {
  const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_NOTAS, filtros)
  return {
    cantidad: response.data.cantidad,
    page: response.data.page,
    elementos: response.data.elementos,
  }
}

const createNewTerna = async (terna, resolve) => {
  const resp = await requestService.sendRequest('POST', apiURLs.API_TERNAS_TERNA, terna)
  if (resolve) await resolve(resp.data);
}

const saveDraft = async (terna) => {
  store.dispatch(success("Se guardó correctamente la terna como borrador."))
}

const changeState = async (params = {}, newState) => {
  await requestService.sendRequest('PUT', apiURLs.API_TERNAS_TERNA + `/${params.terna.id}/estado`, { ...params, estado: newState })
}

const deleteTerna = async (terna, resolve) => {
  await requestService.sendRequest('DELETE', apiURLs.API_TERNAS_TERNA + `/${terna.id}`)
    .then(resp => {
      if (resolve) resolve();
      store.dispatch(success(<>Se elimino la terna <b>{terna.nombre}</b> correctamente.</>))
    }).catch(e => {
      store.dispatch(error(e.response.data.message))
    })
}

const editTerna = async (terna, resolve) => {
  //TODO: REVISAR QUE ESTO FUNCIONE OK
  let { ...body } = terna;
  let titulo = Object.keys(terna.titulo).length === 0 ? terna.titulo : null;
  let procedencia = Object.keys(terna.procedencia).length === 0 ? terna.procedencia : null;
  let resolucion = Object.keys(terna.resolucion).length === 0 ? terna.resolucion : null;
  let regla = terna.regla && Object.keys(terna.regla).length === 1 ? terna.regla : null;
  let nota = terna.nota && Object.keys(terna.nota).length === 1 ? terna.nota : null;

  const resp = await requestService.sendRequest('PATCH', apiURLs.API_TERNAS_TERNA + `/${terna.id}`, { ...body, 'nota': nota, 'regla': regla, 'titulo': titulo, 'procedencia': procedencia, 'resolucion': resolucion })
  if (resolve) await resolve(resp.data)
}

const deactivate = async (params, resolve) => {
  await changeState(params, estadosConstans.DESHABILITADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se desactivo la terna <b>{params.terna.nombre}</b> correctamente.</p>))
}

const activate = async (params, resolve) => {
  await changeState(params, estadosConstans.BORRADOR)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se activo la terna <b>{params.terna.nombre}</b> correctamente.</p>))
}

const publish = async (terna, resolve) => {
  await changeState({ terna }, estadosConstans.PUBLICADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se publicó la terna <b>{terna.nombre}</b> correctamente.</p>))
}

const sendToAuthorize = async (terna, resolve) => {
  await changeState({ terna }, estadosConstans.PARA_SER_AUTORIZADO);
  if (resolve) resolve();
  await store.dispatch(success(<p>Se envio a autorizar la terna <b>{terna.nombre}</b> correctamente.</p>))
}

const sendToRevice = async (terna) => {
  await changeState({ terna }, estadosConstans.EN_REVISION, "Se envió a revisar la terna correctamente.")
}

const approve = async (terna, resolve) => {
  await changeState({ terna }, estadosConstans.APROBADO)
  if (resolve) await resolve();
  await store.dispatch(success(<p>Se aprobo la terna <b>{terna.nombre}</b> correctamente.</p>))
}


const createNewProcedencia = async (nombre, resolve) => {
  const response = await requestService.sendRequest('POST', apiURLs.API_TERNAS_PROCEDENCIA, { 'nombre': nombre })
  if (resolve) resolve(response.data);
  return response
}

const createNewOrganismo = async (nombre) => {
  console.log({ nombre })
  const response = await requestService.sendRequest('POST', apiURLs.API_TERNAS_ORGANISMO, { nombre })
  return response.data
}


const createNewTitulo = async (nivel, nombre, resolve) => {
  const response = await requestService.sendRequest('POST', apiURLs.API_TERNAS_TITULO, { 'nivel': nivel, 'nombre': nombre })
  if (resolve) resolve(response.data);
  return response
}

const editTitulo = async (titulo_id, nivel, titulo_nuevo_nombre, resolve) => {
  const response = await requestService.sendRequest('PATCH', apiURLs.API_TERNAS_TITULO + "/" + titulo_id, { id: titulo_id, nivel, nombre: titulo_nuevo_nombre })
  if (resolve) resolve(response.data);
  return response
}

const getUnificacionesTerna = async (filtros = {}) => {
  const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_UNIFICACION, filtros)
  return {
    ...response.data,
    elementos: response.data.elementos.map(ctEl => adaptUnificacionTerna(ctEl)),
  }
}

const createNewResolucion = async (resolucion, resolve) => {
  const { ...body } = resolucion
  const response = await requestService.sendRequest('POST', apiURLs.API_TERNAS_RESOLUCION, { ...body })
  if (resolve) resolve(response.data);
  return response
}

export {
  getTernas,
  getTerna,
  getTitulos,
  getProcedencias,
  getResoluciones,
  getResolucionesCombo,
  createNewTerna,
  deleteTerna,
  editTerna,
  deactivate,
  activate,
  publish,
  saveDraft,
  sendToAuthorize,
  sendToRevice,
  approve,

  createNewProcedencia,
  createNewTitulo,
  editTitulo,
  createNewOrganismo,

  createNewResolucion,
  getTiposResolucion,
  getReglas,
  getNotas,
  getOrganismos,
  getUnificacionesTerna,
}