import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { routePaths } from "../../utils/Constants";
import ApendiceSearchTable from "./ApendiceSearchTable";
import ApendiceFormSearch from "./ApendiceFormSearch";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getApendices } from "../ApendiceService";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { clearFilters } from "../../store/search/searchActions";

const ApendiceSearch = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [apendice, setApendice] = useState(null);
  const [cantidadApendices, setCantidadApendices] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [handleClickOnFabSearch, setHandleClickOnFabSearch] = useState(false);
  const [handleClickOnFabClean, setHandleClickOnFabClean] = useState(false);

  const dispatch = useDispatch();

  const filtrosGuardados = useSelector((state) => state.search);

  const searchApendices = async (filtros) => {
    let filtro = filtros ? filtros : [];
    filtro["all"] = true; //Si no no trae los desactivos (corregir por que también trae los eliminados)
    filtro['nombre'] = filtro.nombre ? filtro.nombre : "";
    filtro['apendiceId'] = filtro.apendiceId ? filtro.apendiceId : "";
    filtro['fecha_desde'] = filtro.fecha_desde ? filtro.fecha_desde : "";
    filtro['fecha_hasta'] = filtro.fecha_hasta ? filtro.fecha_hasta : "";

    //Reset page
    setSearching(true);
    const response = await getApendices(filtro).catch(e => { setSearching(false); return [] });
    setApendice(response.elementos);
    setCantidadApendices(response.cantidad);
    setPage(response.page)
    setSearching(false);
  }

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      await searchApendices(filtrosGuardados)
      setPage(filtrosGuardados.page);
    }
  }

  const handleSearch = async (filters) => {
    searchApendices(filters);
  };

  const handleChangePage = (event, value) => {
    filtrosGuardados["page"] = value;
    searchApendices(filtrosGuardados);
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
    return () => { dispatch(clearFilters()) }
  }, [dispatch]);

  return (
    <ScreenWithFabButtons
      hidePlusButton={false}
      hideSearchButton={false}
      hideCleanButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching /* || (typeOfSearch == incumbenciasConstants.TERNAS_INCUMBENCIA ? !ternas : !cargos) */}
    >
      <Row>
        <Col>
          <h1>Apendice</h1>
          <ApendiceFormSearch
            onSubmit={handleSearch}
            filtros={filtrosGuardados}
          ></ApendiceFormSearch>
        </Col>
      </Row>
      <Row>
        <Col>
          {apendice && !searching ? (
            <ApendiceSearchTable
              onSubmit={() => handleSearch()}
              apendices={apendice}
              cantidadApendices={cantidadApendices}
              changePage={handleChangePage}
              page={page}
            ></ApendiceSearchTable>
          ) : (
            searching && <Loader></Loader>
          )}
        </Col>
      </Row>
    </ScreenWithFabButtons>

  );
};

export default ApendiceSearch;
