import { faPen, faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import IconButton from "../../../generics/IconButton";
import { addVariantesInCargosArrWithIndex } from "../../../store/incumbencias/incumbenciasActions";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import Input from "../../../ui/Input";
import { CondicionGeneralForm } from "./CondicionGeneralForm";
import { IncumbenciasDetailBodyVariantes } from "./IncumbenciasDetailBodyVariantes";
import { IncumbenciasDetailFormVariantes } from "./IncumbenciasDetailFormVariantes";
import { idsTipoIncumbenciaConPuntaje } from "../../abm/IncumbenciasSelectRow";

export const IncumbenciasModalSelectVarianteCargo = ({
    data,
    modalTitle,
    withIconSearch,
    buttonText,
    cargoIndex,
    mainElement,
    handleClickFinalizarCargaFunction,
    withIconEdit,
    actionButtonText,
    hideTipoEscuela,
    ...props
}) => {
    const [show, setShow] = useState(false);
    const [variantes, setVariantes] = useState([])
    const [withInfoGeneral, setWithInfoGeneral] = useState(false)
    const [condicionGeneral, setCondicionGeneral] = useState({})
    const [validated, setValidated] = useState(false)

    const [isLoading, setIsloading] = useState(false)

    const { cargo } = data;
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setWithInfoGeneral(false);
        setVariantes([...data.variantes]);
        setShow(true);
    };

    const isCondicionCompleted = true
        // withInfoGeneral ?
        //     // (!!condicionGeneral.condicion &&
        //     (idsTipoIncumbenciaConPuntaje.some(ctCondicionConPuntajeId => ctCondicionConPuntajeId == condicionGeneral.condicion)
        //         ?
        //         !!condicionGeneral.puntaje
        //         : true
        //     )
        //     // )
        //     : true

    const handleClickFinalizarCarga = async () => {
        let sortedVariantes = [...variantes].sort((varianteA, varianteB) => varianteA.asignatura.nombre.localeCompare(varianteB.asignatura.nombre))
        if (withInfoGeneral) sortedVariantes = sortedVariantes.map(ctVariante => ({
            ...ctVariante,
            ...condicionGeneral,
        }))
        setValidated(true)
        if (isCondicionCompleted) {
            if (handleClickFinalizarCargaFunction) {
                try {
                    setIsloading(true)
                    await handleClickFinalizarCargaFunction(sortedVariantes)
                }
                catch (err) {
                    console.log("Error al cargar variante: handleClickFinalizarCarga")
                }
                finally {
                    setIsloading(false)
                }
            } else {
                dispatch(addVariantesInCargosArrWithIndex(sortedVariantes, "cargoSeleccionado", cargoIndex, true))
            }

            handleClose();
        }
    }

    useEffect(() => {
        setVariantes([...data.variantes])
    }, []);

    return (
        <>
            {buttonText ?
                <Button
                    onClick={handleShow}
                    variant={"secondary"}
                >
                    {buttonText}
                </Button>
                :
                <CustomTooltip text={withIconEdit ? "Editar" : "Buscar"}>
                    <IconButton {...props} icon={
                        withIconSearch && faSearch
                        ||
                        withIconEdit && faPen
                    } onClick={handleShow} />
                </CustomTooltip>
            }

            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="font-weight-light">{modalTitle ? modalTitle : "Detalle:"}</span> {cargo.nombre}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IncumbenciasDetailFormVariantes
                        cargo={cargo}
                        elements={variantes}
                        setterFunction={setVariantes}
                        // autoAddVariante={mainElement == incumbenciasConstants.CARGOS_INCUMBENCIA}
                        mainElement={mainElement}
                        actionButtonLabel={"Agregar"}
                        hideTipoEscuelaFilter={hideTipoEscuela}
                    />
                    {/* {mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA && <> */}
                    <hr></hr>
                    <IncumbenciasDetailBodyVariantes
                        title={"Resumen"}
                        variantes={variantes}
                        setVariantes={setVariantes}
                        hideTipoEscuelaFilter={hideTipoEscuela}
                    />
                    {/* </> 
                    }*/}
                    <hr></hr>
                    <Col>
                        <Input
                            type="checkbox"
                            controlId="withInfoGeneral"
                            name="withInfoGeneral"
                            label="Agregar condicion general a variantes"
                            value={withInfoGeneral}
                            onChange={(e) => setWithInfoGeneral(e.target.checked)}
                            style={{ width: "fit-content", marginBottom: 0 }}
                        ></Input>
                        {
                            withInfoGeneral &&
                            <>
                                <CondicionGeneralForm
                                    state={condicionGeneral}
                                    setter={setCondicionGeneral}
                                    validated={validated}
                                />
                            </>
                        }
                    </Col>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            content="Reporte"
                            type="submit"
                            disabled={variantes.length == 0 || isLoading}
                            onClick={handleClickFinalizarCarga}
                        >
                            {isLoading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        variant={"secondary"}
                                    />
                                    <span className="ml-1">Cargando</span>
                                </>
                                :
                                actionButtonText ?? "Cargar agregados"
                            }
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Volver
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}