import React from 'react'
import { useEffect } from 'react';
import { Alert, Badge, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../generics/dialog';
import Paginacion from '../../generics/Paginacion';
import { TrEnConcurrenciaCon } from '../../incumbencias/details/ternas/TrEnConcurrenciaCon';
import { error } from '../../store/alerts/alertActions';

const FormTableHeader = () => {
    return (
        <tr>
            <th>Área</th>
            <th>Escuela</th>
            <th>Cargo</th>
            <th>Asignatura</th>
            <th>Especialidad</th>
            <th>Titulo</th>
            <th>Procedencia</th>
            <th>Resolucion</th>
            <th>Folio</th>
            <th>Calidad</th>
            <th>Pts</th>
            <th>Creacion</th>
            <th>Estado</th>
        </tr>
    )
};

const FormRow = ({ element }) => {

    return (<>
        <tr>
            <td>{element.area.nombre ?? "-"}</td>
            <td>{element.tipoEscuela.nombre ?? "-"}</td>
            <td>{element.cargo.nombre ?? "-"}</td>
            <td>{element.asignatura.nombre ?? "-"}</td>
            <td>{element.especialidad.nombre ?? "-"}</td>
            <td>{element.terna.titulo.nombre ?? "-"}</td>
            <td>{element.terna.procedencia.nombre ?? "-"}</td>
            <td>{element.terna.resolucion.nombre ?? "-"}</td>
            <td>{element.folio ?? "-"}</td>
            <td>{element.calidad ?? "-"}</td>
            <td>{element.puntaje ?? "-"}</td>
            <td>{element.createdAt ?? "-"}</td>
            <td>
                {
                    element.estado ?
                        <Badge variant={element.estado.color}>
                            {element.estado.nombre}
                        </Badge>
                        :
                        "-"
                }
            </td>
        </tr>


        {element.terna.regla &&
            <tr style={{ border: "none" }}>
                <td className="border-0 pt-1" colSpan={10}><p style={{ marginBottom: "0.4rem" }}><b>Regla: </b> {element.terna.regla}</p></td>
            </tr>
        }

        {element.concurrencia &&
            <TrEnConcurrenciaCon data={element} colSpan={13} />
        }
    </>
    )
}

export const FormTableTopologico = ({
    elementos = [],
    cantidadElementos,
    page,
    handleChangePage,
}) => {

    return (
        <>
            <Row className="form-group">
                <Col>
                    <Table responsive>
                        <thead>
                            <FormTableHeader />
                        </thead>
                        <tbody>
                            {elementos.map((currentElement, index) =>
                                <React.Fragment key={`TopologicoRow-${index}`}>
                                    <FormRow element={currentElement} />
                                </React.Fragment>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Paginacion totalItems={cantidadElementos} currentPage={Number(page)} onChangePage={handleChangePage} />
        </>
    );

}