import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { error, info } from "../../store/alerts/alertActions";
import TitulosSearch from "./TitulosSearch";
import { getTernas } from "../../ternas/TernaService";


export const SearchRelacion = ({
    title,
    setter,
    setterValidations,
    esMultipleSeleccion,
    arrCurrentSeleccionado,
    returnCallBack,
    renderRow,
    renderTable,
}) => {
    const [seleccionados, setSeleccionados] = useState(arrCurrentSeleccionado ?? []);
    const dispatch = useDispatch();

    const onChangeSeleccionado = (titulo, evento) => {
        if (esMultipleSeleccion) {
            setSeleccionados(st => {
                let newSt = [...st]
                if (evento.target.checked) {
                    newSt = [...newSt, titulo]
                } else {
                    newSt = newSt.filter(tituloSeleccionado => tituloSeleccionado.id != titulo.id)
                }
                return newSt
            })
        } else {
            setSeleccionados([titulo])
        }
    }

    const handleSeleccionar = () => {
        if (seleccionados.length > 0) {
            let arrSeleccionados = [...seleccionados]
            const cantidadArrSeleccionados = arrSeleccionados.length;

            if (cantidadArrSeleccionados == 0) return setter(null)

            const arrSeleccionadosSinRepetir = setterValidations ? setterValidations(arrSeleccionados) : arrSeleccionados
            const cantidadSeleccionadaSinRepetir = arrSeleccionadosSinRepetir.length;

            if (cantidadSeleccionadaSinRepetir == 0) return dispatch(error("Las ternas relacionadas seleccionadas no pueden ser las mismas que las ternas principales."))
            if (cantidadSeleccionadaSinRepetir < cantidadArrSeleccionados) return dispatch(info(`${cantidadArrSeleccionados - cantidadSeleccionadaSinRepetir} ternas relacionadas no pudieron ser agregadas ya que se encuentran como ternas principales`))
            setter(arrSeleccionadosSinRepetir);

            returnCallBack();
        } else dispatch(error("Debe seleccionar al menos un elemento."))
    }

    const renderSelectRow = (titulo, seleccionados) => {
        return renderRow(titulo, seleccionados, onChangeSeleccionado)
    };

    const controlIds = {
        titulo: "titulo_id",
        procedencia: "procedencia",
        resolucion: "resolucion",
        nivel: "nivel",
    };

    return (
        <TitulosSearch
            title={title ?? "Buscar título"}
            renderRow={renderSelectRow}
            renderTable={renderTable}
            getTitulos={getTernas}
            controlIds={controlIds}
            nombreElementoResponse="data"
            conCrear={false}
            masInputs={true}
            conGenerarReporte={false}
            handleChangeSeleccionar={() => { }} /* Valida el handleChange -> En este caso no lo necesitamos */
            urlSeleccionar={true} /* No se usa en este caso, pero sino no muestra boton seleccionar */
            handleSeleccionar={handleSeleccionar}
            returnCallBack={returnCallBack}
            cleanFiltersOnReturn={false}
        />
    );
};