import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import Paginacion from '../../generics/Paginacion'

const FormTableHeader = ({ columnas }) => {
    return <tr>
        <th>Codigo Asignatura</th>
        <th>Asignatura</th>
        <th>Especialidad</th>
        <th>Tipo escuela</th>
        {columnas.map(ctCol => <th key={`tabla-cruzados-th-col-${ctCol}`}>{ctCol}</th>)}
    </tr>
}

const FormTableRow = ({ elemento, columnas }) => {
    return <tr>
        <td>{elemento.codigo_asignatura}</td>
        <td>{elemento.asignatura}</td>
        <td>{elemento.especialidad}</td>
        <td>{elemento.tipo_escuela}</td>
        {columnas.map(ctCol => <td key={`tabla-cruzados-td-col-${ctCol}-`}>{elemento[ctCol]}</td>)}
    </tr>
}

export const FormTableCruzadoCargo = ({
    elementos,
    cantidadElementos,
    page,
    handleChangePage,
    hidePaginacion,
    columnas,
}) => {
    return (
        <>
            <Row className="form-group">
                <Col className="w-100">
                    <Table responsive>
                        <thead>
                            <FormTableHeader columnas={columnas} />
                        </thead>
                        <tbody>
                            {elementos.map((currentElemento, index) =>
                                <React.Fragment key={`FormTableRowCruzadoCargo${currentElemento.codigo_asignatura}/${index}`}>
                                    <FormTableRow elemento={currentElemento} columnas={columnas} />
                                </React.Fragment>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {!hidePaginacion && (
                <Row>
                    <Col>
                        <Paginacion totalItems={cantidadElementos} currentPage={page} onChangePage={handleChangePage} />
                    </Col>
                </Row>
            )}
        </>
    )
}

