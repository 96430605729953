class Regla{
    constructor(){
        this.id = "";
        this.descripcion = "";
    }
}

export default Regla

export const createRegla = (props) => {
    return Object.assign(new Regla(), {...props})
}
