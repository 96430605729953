import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Cargo, { createCargo } from "../../dtos/cargo/Cargo";
import { clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { routePaths, typeOfActions } from "../../utils/Constants";
import CargosABMForm from "./CargosABMForm";

const CargosABMApproval = (props) => {
  let location = useLocation();
  const [cargo, setCargo] = useState();

  const [disabled, setDisabled] = useState(true);
  const [availableCerrarMod, setAvailableCerrarMod] = useState(true);
  const [discardChanges, setDiscardChanges] = useState(false);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos data de los filtrosGuardados para forzar refresh al cargar la data en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.data
    dispatch(saveFilters(newFiltrosGuardados))
  };

  const loadCargo = () => {
    if (location.state && location.state.cargo) {
      let cargo = location.state.cargo;
      if (!(cargo instanceof Cargo)) {
        cargo = createCargo(cargo)
      }
      setCargo({ ...cargo });
    }
  }


  const handleModificar = () => {
    if (!disabled && !availableCerrarMod) {
      setDiscardChanges(true);
      setAvailableCerrarMod(true);
    }
    setDisabled(st => !st)
  }

  useEffect(() => {
    dispatch(clear());
    loadCargo();
  }, [dispatch, location.state]);

  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
    >
      <Row>
        <Col>
          <Row className="form-group">
            <Col md="12">
              <h1>Aprobar Cargo
                {/* <IconTextButton
                  className="btn-icon-rounded"
                  icon={faPen}
                  type="submit"
                  variant="secondary"
                  text={disabled ? "Modificar cargo" : (availableCerrarMod ? "Cerrar Modificación" : "Descartar cambios")}
                  onClick={handleModificar} >
                </IconTextButton> */}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <CargosABMForm
                typeOfAction={typeOfActions.APPROVAL}
                onSubmit={handleSubmit}
                cargo={cargo}
                returnPath={routePaths.ABM_CARGOS_SEARCH}
                disabled={disabled}
                setDisabled={setDisabled}
                availableCerrarMod={availableCerrarMod}
                setAvailableCerrarMod={setAvailableCerrarMod}
                discardChanges={discardChanges}
                setDiscardChanges={setDiscardChanges}
                setCargo={setCargo}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};

export default CargosABMApproval;
