import React from 'react';
import { Row, Col, Badge } from "react-bootstrap";
import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";
import { DetailNumber } from "../../ui/DetailNumber";

const EspecialidadesDetailBody = ({ especialidad, ...props }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            <Badge variant="primary">{especialidad.getNombreEstado()}</Badge>
            {especialidad.createdAt && (

              <small>
                Creado el: <b>{especialidad.createdAt}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />

      <Row className="form-group">
        <Col md={12}>
          <DetailFact label="Nombre" input={especialidad.nombre} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <DetailFact label="Área" input={especialidad.getNombreArea()} />
        </Col>
        {especialidad.getDescripcionNivel() && (

          <Col md={4}>
            <DetailFact label="Nivel" input={especialidad.getDescripcionNivel()} />
          </Col>
        )}
      </Row>
      <hr />
      
      <Row>
        {especialidad.codDad && (
          <Col >
            <DetailNumber label="Código DAD" input={especialidad.codDad} />
          </Col>
        )}
        {especialidad.apendice && (

          <Col>
            <DetailNumber label="Apéndice" input={especialidad.apendice?.nombre} />
          </Col>
        )}
        {especialidad.folio && (

          <Col>
            <DetailNumber label="Folio" input={especialidad.folio} />
          </Col>
        )}
      </Row>
      { especialidad.descripcion && (
        <Row>
          <Col>
            <hr />
          </Col>
          <Col md={12}>
            <DetailFact label="Descripción" input={especialidad.descripcion} />
          </Col>
        </Row> 
      )}
      {/**/}
    </>
  );
};

export default EspecialidadesDetailBody;
