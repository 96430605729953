import React from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";
import { IncumbenciasSearchRowCargos } from "./IncumbenciasSearchRowCargos";

export const IncumbenciasSearchTableCargos = ({ cargos, cantidadResultados, page, changePage, ...props }) => {

    const renderTableRows = (cargos) => {
        return cargos.map((cargo) => {
            return (
                <React.Fragment key={cargo.id}>
                    <IncumbenciasSearchRowCargos
                        onSubmit={props.onSubmit}
                        cargo={cargo}
                    ></IncumbenciasSearchRowCargos>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            {!cargos[0] ? (
                <Row className="form-group">
                    <Col>
                        <Alert variant="info">No se encontraron cargos.</Alert>
                    </Col>
                </Row>
            ) : (
                !!cargos && (
                    <>
                        <Row className="form-group">
                            <Col>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Apendice</th>
                                            <th>Folio</th>
                                            <th>Cod Dad</th>
                                            <th>Area</th>
                                            <th>Tipo de Escuela</th>
                                            <th>Nombre del cargo</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableRows(cargos)}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Paginacion totalItems={cantidadResultados} currentPage={page} onChangePage={changePage} />
                    </>
                )
            )}
            {!!cargos[0] && <Row>
                <Col>
                    <Button
                        variant="primary"
                        content="Reporte"
                        type="submit"
                        disabled
                    >
                        Informe Total
                    </Button>
                </Col>
            </Row>}
        </>
    );
};