import { Form, Col, Row } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useEffect } from "react";
import { isValidNumeric, isValidText, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import * as especialidadesService from "../EspecialidadesService"
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import { cleanFab, searchFab } from "../../store/screen/screenActions";

const EspecialidadesFormSearch = ({
  setEspecialidades,
  onSubmit,
}) => {

  const [inputsForm, setInputsForm] = useState([]);
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");
  const [resetChecks, setResetChecks] = useState(false);

  const filtrosGuardados = useSelector((state) => state.search);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);

  const processSubmit = async () => {
    let data;

    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      data = filtrosGuardados
    } else {
      const filtrosDefault = { page: 1 }

      data = {
        inputsForm: { ...inputsForm, ...filtrosDefault },
        areaSeleccionada,
        especialidadSeleccionada,
      }
    }
    await onSubmit(data)
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChangeSelect = (isValid, e) => {
    if (isValid(e.target.label)) {
      let eTarget = { target: { id: e.target.id, value: e.target.label } };
      handleChange(eTarget);
    }
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handleChange(e);
    }
  };

  const resetForm = () => {
    setEspecialidades(null);
    setEspecialidadSeleccionada("")
    setInputsForm({});
    setAreaSeleccionada("");
    setResetChecks(true);
  }

  const loadForm = () => {
    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      setInputsForm(filtrosGuardados.inputsForm)
      setAreaSeleccionada(filtrosGuardados.areaSeleccionada);
      setEspecialidadSeleccionada(filtrosGuardados.especialidadSeleccionada);
    }
  }

  useEffect(() => {
    loadForm();
  }, []);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchEspecialidades">
            <Row className="form-group">
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nombre"
                  name="nombre"
                  label="Nombre de la especialidad"
                  value={especialidadSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getEspecialidades}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setEspecialidadSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={3} lg={2}>
                <Input
                  type="input"
                  controlId="codDad"
                  label="Código DAD"
                  value={inputsForm.codDad ? inputsForm.codDad : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="area"
                  name="area"
                  label="Área"
                  value={areaSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getAreas}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setAreaSeleccionada}
                  config={{ isSearchable: true, isClearable: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} lg={8}>
                <CheckBoxEstados
                  inputsForm={inputsForm}
                  setInputsForm={setInputsForm}
                  resetChecks={resetChecks}
                  setResetChecks={setResetChecks}
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default EspecialidadesFormSearch;
