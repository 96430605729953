import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Dialog from "../../generics/dialog";
import Input from "../../ui/Input";
import { handlerChange } from "../../utils/commons";
import * as ternasService from "../TernaService";


export const SeleccionarTernaNota = ({ show, closeModal, nota, setSelectedTitulo }) => {
    const [tituloSeleccionado, setTituloSeleccionado] = useState("");
    const [inputsForm, setInputsForm] = useState({})

    const handleClose = () => {
        closeModal();
        resetModal();
    }

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    const guardar = async (event) => {
        setSelectedTitulo({
            notaLabel: generateCustomNota(nota.label, tituloSeleccionado.label),
            tituloSeleccionado
        })
        resetModal();
    }

    const resetModal = () => {
        setTituloSeleccionado("")
    }

    const generateCustomNota = (nota = "", titulo) => {
        const inicioTag = "&";
        const finTag = "&";
        let inicioIndex = nota.indexOf(inicioTag);
        const finIndex = nota.indexOf(finTag, inicioIndex + 1);
        const textoReemplazar = nota.substring(inicioIndex, finIndex + 1);
        const notaReemplazandoNombreTitutlo = nota.replace(textoReemplazar, titulo);
        return notaReemplazandoNombreTitutlo
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Seleccionar titulo para nota
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Input
                                type="react-select"
                                controlId="titulo"
                                name="titulo"
                                label="Título"
                                value={tituloSeleccionado}
                                methodOnChange={handleChange}
                                elements={ternasService.getTitulos}
                                nameElementsResponse="elementos"
                                propertiesForFormat={{ value: "id", label: "nombre" }}
                                setterElementSelect={setTituloSeleccionado}
                                config={{ isSearchable: true, isClearable: true }}
                                required
                            ></Input>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>
                    <Dialog
                        action={guardar}
                        target={{}}
                        title={"Seleccionar titulo"}
                        variant="primary"
                        size="sm"
                        btnConfirmText="Selecionar"
                        btnVariant="success"
                        btnText="Selecionar"
                        isLoadingText={"Seleccionando"}
                        disabled={!tituloSeleccionado}
                        body={
                            <span>
                                ¿Estás seguro de seleccionar el título <b>{tituloSeleccionado.label}</b>? <br></br><br></br>
                                {
                                    nota?.label && <>
                                        La nota resultante sera:<br></br>
                                        {generateCustomNota(nota.label, tituloSeleccionado.label)}
                                    </>
                                }
                            </span>
                        }
                    />
                </Modal.Footer>
            </Modal >
        </>
    );
}