import React, { useState, useEffect } from "react";
import * as ternaService from "../../ternas/TernaService"
import * as cargosService from "../../cargos/CargosService"

import { incumbenciasConstants, routePaths } from "../../utils/Constants";
import { useHistory, useParams } from "react-router-dom";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { useDispatch } from "react-redux";
import { clearFilters } from "../../store/search/searchActions";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { IncumbenciasFormSearchTernas } from "./ternas/IncumbenciasFormSearchTernas";
import { IncumbenciasFormSearchCargos } from "./cargos/IncumbenciasFormSearchCargos";
import { IncumbenciasSearchTableTernas } from "./ternas/IncumbenciasSearchTableTernas";
import { Loader } from "../../ui/Loader";
import { IncumbenciasSearchTableCargos } from "./cargos/IncumbenciasSearchTableCargos";
import { cleanFab } from "../../store/screen/screenActions";
import { cleanIncumbenciasData } from "../../store/incumbencias/incumbenciasActions";


export const IncumbenciasSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { typeOfSearch } = useParams();

    const [searching, setSearching] = useState(false);
    const [savedFilters, setSavedFilters] = useState({})
    const [ternas, setTernas] = useState(null);
    const [cargos, setCargos] = useState(null);
    const [page, setPage] = useState(1);

    const [cantidadResultados, setCantidadResultados] = useState(null);

    const handleBtnClick = (changePath) => {
        dispatch(cleanFab(true))
        setSearching(false)
        history.push(changePath)
    }

    const handleChangePageTernas = (event, value) => {
        setSavedFilters(filters => {
            const newFilters = { ...filters, page: value }
            searchTernas(newFilters);
            return newFilters
        })
        setPage(value);
    }

    const handleChangePageCargos = (event, value) => {
        setSavedFilters(filters => {
            const newFilters = { ...filters, page: value }
            searchCargos(newFilters);
            return newFilters
        })
        setPage(value);
    }

    const searchTernas = async (newFiltros = savedFilters) => {
        const defaultFiltros = { all: true }
        setSearching(true);
        setPage(1);    //Reset page

        const filtros = { ...newFiltros, ...defaultFiltros }
        const ternas = await ternaService.getTernas(filtros).catch(e => { setSearching(false); return [] });
        setTernas(ternas.data);
        setCantidadResultados(ternas.cantidad);
        setSavedFilters(filtros);
        setSearching(false);
    }

    const searchCargos = async (newFiltros = savedFilters) => {
        // filtros["all"] = true; //Si no no trae los desactivos (corregir por que también trae los eliminados)
        const defaultFiltros = { all: true }
        setSearching(true);
        setPage(1);    //Reset page

        const filtros = { ...newFiltros, ...defaultFiltros }
        const cargos = await cargosService.getCargos(filtros).catch(e => { setSearching(false); return [] });
        setCargos(cargos.data);
        setCantidadResultados(cargos.cantidad);
        setSavedFilters(filtros);
        setSearching(false);
    }

    useEffect(() => {
        return () => { dispatch(clearFilters()) }
    }, [dispatch]);

    useEffect(() => {
        if (!typeOfSearch) {
            history.push("/" + incumbenciasConstants.TERNAS_INCUMBENCIA)
        }
        dispatch(cleanIncumbenciasData());
    }, [typeOfSearch])

    return (
        <ScreenWithFabButtons
            hidePlusButton={false}
            hideSearchButton={false}
            hideCleanButton={false}
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || (typeOfSearch == incumbenciasConstants.TERNAS_INCUMBENCIA ? !ternas : !cargos)}
        >
            <h1>Incumbencias</h1>
            <h3>Buscar por</h3>
            <ButtonGroup className="mb-2">
                <ToggleButton
                    id={`radio-1`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="ternas"
                    checked={typeOfSearch == incumbenciasConstants.TERNAS_INCUMBENCIA}
                    onChange={() => handleBtnClick(routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS)}
                >
                    Ternas
                </ToggleButton>
                <ToggleButton
                    id={`radio-2`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="cargos"
                    checked={typeOfSearch == incumbenciasConstants.CARGOS_INCUMBENCIA}
                    onChange={() => handleBtnClick(routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS)}
                >
                    Cargos
                </ToggleButton>
            </ButtonGroup>
            <hr></hr>
            {
                (typeOfSearch == incumbenciasConstants.TERNAS_INCUMBENCIA) && <>
                    <IncumbenciasFormSearchTernas
                        setTernas={setTernas}
                        setCantidadResultados={setCantidadResultados}
                        setSearching={setSearching}
                        setPage={setPage}
                        onSubmit={searchTernas}
                    ></IncumbenciasFormSearchTernas>

                    {(ternas && !searching) ? (
                        <IncumbenciasSearchTableTernas
                            onSubmit={() => { }/* searchIncumbencias */}
                            ternas={ternas}
                            cantidadResultados={cantidadResultados}
                            changePage={handleChangePageTernas}
                            page={page}
                        ></IncumbenciasSearchTableTernas >
                    ) : (
                        searching && <Loader></Loader>
                    )}
                </>
            }

            {
                (typeOfSearch == incumbenciasConstants.CARGOS_INCUMBENCIA) &&
                <>
                    <IncumbenciasFormSearchCargos
                        setCargos={setCargos}
                        setCantidadResultados={setCantidadResultados}
                        setSearching={setSearching}
                        setPage={setPage}
                        onSubmit={searchCargos}
                    ></IncumbenciasFormSearchCargos>

                    {(cargos && !searching) ? (
                        <IncumbenciasSearchTableCargos
                            onSubmit={() => { }} /* searchIncumbencias */
                            cargos={cargos}
                            cantidadResultados={cantidadResultados}
                            changePage={handleChangePageCargos}
                            page={page}
                        ></IncumbenciasSearchTableCargos>)
                        : (
                            searching && <Loader></Loader>
                        )
                    }
                </>
            }
        </ScreenWithFabButtons >

    );
};





