import PrivateComponent from "../../authentication/PrivateComponent";
import AsignaturaDetailModal from "../details/AsignaturaDetailModal";
import { activate, deactivate } from "../AsignaturaService";
import DialogActivate from "../../ui/DialogActivate";
import React, { useState } from "react";
import { faPen, faTrash, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { routePaths, tooltipsText } from "../../utils/Constants";
import IconButton from "../../generics/IconButton";
import { useDispatch } from "react-redux";
import { error } from "../../store/alerts/alertActions";
import { Redirect } from "react-router-dom";
import Dialog from "../../generics/dialog";
import { CustomTooltip } from "../../ui/CustomTooltip";
import { deleteAsignatura } from "../AsignaturaService";
import { searchFab } from "../../store/screen/screenActions";
import { PERMISOS_MAP } from "../../utils/Permisos";

const AsignaturaSearchRowActions = ({
    asignatura,
    visibleCheckbox,
}) => {
    const [redirect, setRedirect] = useState("");

    const dispatch = useDispatch();

    const resolveCallBack = () => {
        dispatch(searchFab(true))
    }

    const redirectToEdit = (asignatura) => {
        setRedirect(routePaths.ABM_ASIGNATURAS_EDIT);
    };

    const redirectToApproval = (asignatura) => {
        setRedirect(routePaths.ABM_ASIGNATURAS_APPROVAL);
    };

    const changeState = async (asignatura) => {
        try {
            if (asignatura.activo) {
                await deactivate(asignatura, resolveCallBack)
            } else {
                await activate(asignatura, resolveCallBack)
            }
        } catch (e) {
            dispatch(error(e.response.data.message));
        }
    };

    const deleteElement = async (asignatura) => {
        await deleteAsignatura(asignatura, resolveCallBack);
    }

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        asignatura: asignatura,
                        abmTitle: "Editar",
                        submitValue: "Aceptar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <>
            {
                (asignatura.acciones.alta || asignatura.acciones.baja) && !visibleCheckbox &&
                <PrivateComponent permisos={asignatura.acciones.alta ? PERMISOS_MAP.ASIGNATURA_ALTA : PERMISOS_MAP.ASIGNATURA_BAJA}>
                    <CustomTooltip text={asignatura.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                        <DialogActivate
                            target={asignatura}
                            title={asignatura.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            action={changeState}
                            size="md"
                            body={<span>
                                ¿Estás seguro de dar de {asignatura.acciones.alta ? "alta " : "baja "}
                                la asignatura
                                <b> {asignatura ? asignatura.nombre : ""}</b>
                                ?
                            </span>
                            }
                            isLoadingText={asignatura.acciones.alta ? "Dando de alta" : "Dando de baja"}
                            btnConfirmText={asignatura.acciones.alta ? "Dar de alta" : "Dar de baja"}
                        >
                        </DialogActivate>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {
                asignatura.acciones.eliminar &&
                <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_ELIMINAR}>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={deleteElement}
                            target={asignatura}
                            title={"Eliminar asignatura"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar la asignatura
                                <b> {asignatura ? asignatura.nombre : ""}</b>
                                ?
                            </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                            isLoadingText={"Eliminando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }


            {asignatura.acciones.aprobar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_APROBAR}>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <IconButton
                            icon={faCheckDouble}
                            onClick={() => redirectToApproval(asignatura)}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {
                asignatura.acciones.publicar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_PUBLICAR}>
                    <CustomTooltip text={tooltipsText.PUBLICAR}>
                        <AsignaturaDetailModal
                            asignatura={asignatura}
                            withPublicar={true}
                            onSubmit={resolveCallBack}
                        ></AsignaturaDetailModal>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {
                asignatura.acciones.editar &&
                <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_EDITAR}>
                    <CustomTooltip text={tooltipsText.EDITAR}>
                        <IconButton icon={faPen} onClick={() => redirectToEdit(asignatura)} />
                    </CustomTooltip>
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <AsignaturaDetailModal
                    asignatura={asignatura}
                ></AsignaturaDetailModal>
            </CustomTooltip>

        </>
    );
};

export default AsignaturaSearchRowActions;
