import dateFormat from "dateformat";

export const adapterGetTitulosITF = (arrElements) => {
    console.log("adapterGetTitulosITF", arrElements)
    return arrElements.map(elemento => {
        return {
            id: elemento.terna1_id,
            original: {
                id: elemento.terna1_id,
                titulo: {
                    id: elemento.titulo_id,
                    nombre: elemento.titulo,
                    nivel: {
                        id: elemento.nivel_id,
                        nombre: elemento.nivel_nombre,
                    },
                },
                resolucion: {
                    id: elemento.resolucion_id,
                    nombre: elemento.resolucion,
                },
                procedencia: {
                    id: elemento.procedencia_id,
                    nombre: elemento.procedencia,
                },
                createdAt: dateFormat(elemento.created_at, "dd/mm/yyyy"),
                estado: {
                    nombre: elemento.estado_nombre,
                    color: elemento.estado_color
                },
            },
            relacionados: elemento.titulos_relacionados.map(ctTitulo => {
                return {
                    folio: ctTitulo.relacion_folio,
                    id: ctTitulo.relacion_id,
                    apendice: { id: ctTitulo.apendice_id, nombre: ctTitulo.apendice },
                    acciones: ctTitulo.acciones,
                    activo: ctTitulo.activo == "1",
                    estado: { nombre: ctTitulo.relacion_estado_nombre, color: ctTitulo.relacion_estado_color },
                    terna: {
                        createdAt: dateFormat(ctTitulo.created_at, "dd/mm/yyyy"),
                        id: ctTitulo.terna2_id,
                        estado: { nombre: ctTitulo.estado_nombre, color: ctTitulo.estado_color },
                        titulo: {
                            nombre: ctTitulo.nombre_titulo,
                            nivel: {
                                id: ctTitulo.nivel_id,
                                nombre: ctTitulo.nivel_nombre,
                            },
                        },
                        resolucion: {
                            nombre: ctTitulo.tipo_resolucion + (ctTitulo.nro_resolucion == "null" || ctTitulo.nro_resolucion == null ? "" : " " + ctTitulo.nro_resolucion)
                        },
                        procedencia: {
                            nombre: ctTitulo.procedencia_nombre
                        },
                    }
                }
            })
        }
    })
}

export const adaptGtTitulosITFFromTerna = (arrElementos) => {
    // "factor_correccion" llega este param pero no sabemos si sigue vigente para la app
    console.log("adaptGtTitulosITFFromTerna", arrElementos)
    return arrElementos.map(ctElemento => ({
        terna: {
            id: ctElemento.id_terna2,
            titulo: {
                id: ctElemento.titulo_id,
                nombre: ctElemento.nombre_titulo,
                nivel: {
                    // id: ctTitulo.nivel_id,
                    nombre: ctElemento.nivel,
                },
            },
            resolucion: {
                // TODO: VER SI ESTA PRESENTANDO BIEN EL VALOR
                nombre: ctElemento.tipo_resolucion + (ctElemento.nro_resolucion == "null" || ctElemento.nro_resolucion == null ? "" : " " + ctElemento.nro_resolucion)
            },
            procedencia: {
                nombre: ctElemento.procedencia_nombre
            },
            estado: { nombre: ctElemento.estado_nombre, color: ctElemento.estado_color },
        },
        folio: ctElemento.relacion_folio,
        id: ctElemento.relacion_id,

    }))
}