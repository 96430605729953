import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { error } from '../../store/alerts/alertActions';
import { clonadorConstants } from '../../utils/Constants';
import IncumbenciasService, { formatCargoToIncumbencia, getIncumbenciasByTerna } from '../IncumbenciasService';
import { ClonadorCargosSearch } from './cargos/ClonadorCargosSearch';
import { ClonadorTernasSearch } from './ternas/ClonadorTernasSearch';

const agregarIncumbenciasDeTerna = async (terna) => {
    const incumbencias = await getIncumbenciasByTerna({ terna_id: terna.id })
    return {
        ...terna,
        cantidadIncumbencias: incumbencias.cantidad,
        incumbenciasPrimerPagina: incumbencias.elementos,
        incluir_relacionadas: false,
    }
}

const agregarIncumbenciasDeCargoSimple = async (cargoSimple) => {
    const incumbencias = await IncumbenciasService.getIncumbencias({
        asignatura_id: cargoSimple.asignatura.id,
        especialidad_id: cargoSimple.especialidad.id,
        cargo_id: cargoSimple.cargo.id,
    });

    return {
        ...cargoSimple,
        cantidadIncumbencias: incumbencias.cantidad,
        incumbenciasPrimerPagina: incumbencias.elementos,
        incluir_relacionadas: false,
    }
}

export const ClonadorSearchElement = ({
    selectDocument,
    handleClickSeleccionar,
    returnCallBack,
    isValidElementSelection,
}) => {

    const { cloneType } = useParams();
    const dispatch = useDispatch();
    const [selectedElement, setSelectedElement] = useState();

    const handleClickSeleccionarTernas = async (a, b, evento, setIsLoading) => {
        const validationErrorMsg = isValidElementSelection(selectedElement);
        if (validationErrorMsg) return dispatch(error(validationErrorMsg));
        try {
            setIsLoading(true);
            const ternaConIncumbencias = await agregarIncumbenciasDeTerna(selectedElement)
            handleClickSeleccionar(ternaConIncumbencias, setIsLoading)
        } catch (err) {
            dispatch(error(err.response.data.message));
        } finally {
            setIsLoading(false);
            setSelectedElement("")
        }
    }

    const handleClickSeleccionarCargos = async (arrElementosSeleccionados, setIsLoading) => {
        // try {
        setIsLoading(true);
        const elementoSeleccionado = arrElementosSeleccionados[0]
        let cargoToAdd = await formatCargoToIncumbencia(elementoSeleccionado, setIsLoading);
        if (cargoToAdd.type == "simple") {
            const cargoConIncumbencias = await agregarIncumbenciasDeCargoSimple(cargoToAdd)
            cargoToAdd = cargoConIncumbencias
        }
        handleClickSeleccionar(cargoToAdd, setIsLoading)
        // }
        // catch (err) {
        //     console.log("ERROR AL SELECCIONAR CARGO")
        // } finally {
        setIsLoading(false);
        // setSelectedElement("")
        // }
    }


    const handleChangeSeleccionar = (evento, elemento) => {
        setSelectedElement(elemento)
    }

    return (
        <>
            {cloneType == clonadorConstants.TERNAS &&
                <ClonadorTernasSearch
                    selectDocument={selectDocument}
                    handleClickSeleccionar={handleClickSeleccionarTernas}
                    returnCallBack={returnCallBack}
                    handleChangeSeleccionar={handleChangeSeleccionar}
                />
            }

            {cloneType == clonadorConstants.CARGOS &&
                <ClonadorCargosSearch
                    selectDocument={selectDocument}
                    handleClickSeleccionar={handleClickSeleccionarCargos}
                    returnCallBack={returnCallBack}
                />
            }
        </>
    )
}