import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FabButton = ({ icon, className, variant, size, children, iconJsx, disabled, ...props }) => {

  return (
    <Button
      className={`fabButton ${className}`}
      variant={variant ? variant : "primary"}
      size={size ? size : "lg"}
      disabled={disabled}
      {...props}
    >

      {iconJsx ? iconJsx : <FontAwesomeIcon icon={icon} />}

    </Button>
  );
};

export default FabButton;
