import React from 'react'
import { useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Paginacion from '../../generics/Paginacion'
import { DetailCruzado } from './DetailCruzado'

const FormTableHeader = () => {
    return <tr>
        <th>Cod. DAD</th>
        <th>Nombre del cargo</th>
        <th>Asignatura</th>
        <th>Especialidad</th>
        <th className="text-center">D</th>
        <th className="text-center">H</th>
        <th className="text-center">S</th>
        <th className="text-center">OT</th>
        <th className="text-center">DyM</th>
    </tr>
}

const FormTableRowCruzadoArea = ({
    elemento,
}) => {
    const { tipoIncumbencias } = useSelector(st => st.data)
    /* TODO: Cambiar el tipoIncumbencia hardcodeado, y usar el tipoIncumbencias que se pide x fetch al back y se guarda en la store */
    const tipoIncumbencia = {
        docente: 1,
        habilitante: 2,
        supletorio: 3,
        ot: 4,
        dym: 7,
    }

    const filtrosBase = {
        asignatura_id: elemento.asignatura.id,
        especialidad_id: elemento.especialidad.id,
        serializeAll: false, /* Para que cargue rapido la respuesta del back */
        conTipos: true, /* Para que agrupe tipos incumbencia: DOCENTE -> DOCENTE + DOCENTE x regla + etc etc */
        cargo_id: elemento.cargo.id,
        min: true,
    }

    return <tr>
        <td>{elemento.cargo.codDad}</td>
        <td>{elemento.cargo.nombre}</td>
        <td>{elemento.asignatura?.nombre ?? "SIN ASIGNATURA"}</td>
        <td>{elemento.especialidad.nombre}</td>
        <td className="text-center">
            <DetailCruzado
                filtrosDefault={{
                    ...filtrosBase,
                    tipo_incumbencia_id: tipoIncumbencia.docente,
                }}
                label={elemento.d}
                elemento={elemento}
            />
        </td>
        <td className="text-center">
            <DetailCruzado
                filtrosDefault={{
                    ...filtrosBase,
                    tipo_incumbencia_id: tipoIncumbencia.habilitante,
                }}
                label={elemento.h}
                elemento={elemento}
            />
        </td>
        <td className="text-center">
            <DetailCruzado
                filtrosDefault={{
                    ...filtrosBase,
                    tipo_incumbencia_id: tipoIncumbencia.supletorio,
                }}
                label={elemento.s}
                elemento={elemento}
            />
        </td>
        <td className="text-center">
            <DetailCruzado
                filtrosDefault={{
                    ...filtrosBase,
                    tipo_incumbencia_id: tipoIncumbencia.ot,
                }}
                label={elemento.ot}
                elemento={elemento}
            />
        </td>
        <td className="text-center">
            <DetailCruzado
                filtrosDefault={{
                    ...filtrosBase,
                    tipo_incumbencia_id: tipoIncumbencia.dym,
                }}
                label={elemento.DyM}
                elemento={elemento}
            />
        </td>

    </tr >
}

export const FormTableCruzadoArea = ({
    elementos,
    cantidadElementos,
    page,
    handleChangePage,
    hidePaginacion,
}) => {
    return (
        <>
            <Row className="form-group">
                <Col className="w-100">
                    {elementos.map((ctGrupo, ctGrupoIndex) =>
                        <React.Fragment key={`FormTableCruzadoArea${ctGrupoIndex}`}>
                            <h3 style={{ lineHeight: "1em !important", marginBottom: 0, marginTop: "1rem", color: "#404041" }}>
                                {ctGrupo.area.area} - {ctGrupo.area.nombre}
                            </h3>
                            <h3>
                                {ctGrupo.tipoEscuela.id} - {ctGrupo.tipoEscuela.nombre}
                            </h3>
                            <Table responsive>
                                <thead>
                                    <FormTableHeader />
                                </thead>
                                <tbody>
                                    {ctGrupo.elementos.map((ctIncumb, ctRowIndex) =>
                                        <React.Fragment key={`FormTableRowCruzadoArea${ctGrupoIndex}/${ctRowIndex}`}>
                                            <FormTableRowCruzadoArea
                                                elemento={ctIncumb}
                                            />
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </Table>
                        </React.Fragment>
                    )}
                </Col>
            </Row>

            {!hidePaginacion && (
                <Row>
                    <Col>
                        <Paginacion totalItems={cantidadElementos} currentPage={Number(page)} onChangePage={handleChangePage} />
                    </Col>
                </Row>
            )}
        </>
    )
}
