import React, { useEffect, useRef, useState } from 'react'
import { Alert, ButtonGroup, Col, Row, ToggleButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { cleanFab } from '../../store/screen/screenActions';
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { routePaths, ticketsConstants } from '../../utils/Constants';
import { TicketsFormSearch } from './TicketsFormSearch';
import { TicketsFormTable } from './TicketsFormTable';
import { ticketsService } from '../TicketsService';
import { TicketsCreateModal } from '../abm/TicketsCreateModal';
import { PERMISOS_MAP } from '../../utils/Permisos';

export const TicketsSearch = () => {
    const [tickets, setTickets] = useState();
    const [cantidadTickets, setCantidadTickets] = useState();
    const [page, setPage] = useState(1);

    const [showModalCreateTicket, setShowModalCreateTicket] = useState();

    const [searching, setSearching] = useState();

    const isMountedRef = useRef(true);

    const filtrosGuardados = useSelector((state) => state.search);
    const { typeOfSearch } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const resetTable = () => {
        setTickets(null)
        setCantidadTickets(null)
        setPage(null)
    }

    const handleBtnClick = (changePath) => {
        resetTable()
        dispatch(cleanFab(true))
        setSearching(false)
        history.push(changePath)
    }

    const searchTickets = async (filtros = {}) => {
        if (!filtros.page) filtros.page = 1;

        try {
            setSearching(true);
            const response = await ticketsService.getTickets(filtros, isMountedRef);
            setTickets(response.elementos)
            setCantidadTickets(response.cantidad)
            setPage(response.page)
        }
        catch (err) { console.log(err.message) }
        finally {
            setSearching(false);
        }
    }

    const hideModalFunction = () => {
        setShowModalCreateTicket(false)
    }

    const handleChangePage = (event, value) => {
        const filtros = { ...filtrosGuardados, page: value }
        searchTickets(filtros);
        setPage(value);
    };

    const handleClickPlus = () => {
        setShowModalCreateTicket(true)
    }

    useEffect(() => {
        return (() => { isMountedRef.current = false })
    }, [])

    return (
        <ScreenWithFabButtons
            hidePlusButton={false}
            hideSearchButton={false}
            hideCleanButton={false}
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !tickets}
            plusCallBack={handleClickPlus}
            plusPermisos={PERMISOS_MAP.TICKET_CREAR}
        >
            <h1>Tickets</h1>

            <h3>Buscar por</h3>
            <ButtonGroup className="mb-2">
                <ToggleButton
                    id={`radio-1`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="pendientes"
                    checked={typeOfSearch == ticketsConstants.PENDIENTES.label}
                    onChange={() => handleBtnClick(routePaths.ABM_TICKETS_SEARCH_PENDIENTES)}
                >
                    Pendientes
                </ToggleButton>
                <ToggleButton
                    id={`radio-2`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="resueltos"
                    checked={typeOfSearch == ticketsConstants.RESUELTOS.label}
                    onChange={() => handleBtnClick(routePaths.ABM_TICKETS_SEARCH_RESUELTOS)}
                >
                    Resueltos
                </ToggleButton>
            </ButtonGroup>
            <hr></hr>

            <TicketsFormSearch
                onSubmit={searchTickets}
                resetTable={resetTable}
            />

            {!searching &&
                <div className="mt-2">
                    {cantidadTickets > 0 &&
                        <div className="mt-4">
                            <TicketsFormTable
                                tickets={tickets}
                                cantidadTickets={cantidadTickets}
                                page={page}
                                handleChangePage={handleChangePage}
                            />
                        </div>
                    }

                    {cantidadTickets == 0 &&
                        <Row className="form-group">
                            <Col>
                                <Alert variant="info">No se encontraron tickets.</Alert>
                            </Col>
                        </Row>
                    }
                </div>
            }

            {searching && <Loader></Loader>}

            <TicketsCreateModal
                objectId={null}
                objectApendice={null}
                hideIconButton={true}
                onlyAlta
                hideModalFunction={hideModalFunction}
                showModal={showModalCreateTicket}
                modalStyle={{ zIndex: 1051 }}
            />
        </ScreenWithFabButtons>
    )
}
