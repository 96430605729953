import { screenTypes } from "../../actions/types"

export const cleanFab = (bol) => {
    return {
        type: screenTypes.CLEAN_FAB,
        payload: bol,
    }
}

export const searchFab = (bol) => {
    return {
        type: screenTypes.SEARCH_FAB,
        payload: bol,
    }
}

export const resetFab = () => {
    return {
        type: screenTypes.RESET_FAB,
    }
}

export const resetChecks = (bol) => {
    return {
        type: screenTypes.RESET_CHECKS,
        payload: bol,
    }
}