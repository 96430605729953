import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import * as cargosService from "../../cargos/CargosService";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import Dialog from "../../generics/dialog";
import { validateReactInputs } from "../../helpers/validations";
import { clear } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { clearFilters } from "../../store/search/searchActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { formatElements } from '../../ui/ReactSelect';
import { routePaths, typeOfActions } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos";
import { handlerChange, isValidNumeric, isValidText } from "../../utils/commons";
import { RelacionesCargosForm } from "./RelacionesCargos/RelacionesCargosForm";
import { RelacionesCargosTable } from "./RelacionesCargos/RelacionesCargosTable";

const CargosABMForm = ({
  cargo,
  setCargo,
  returnPath,
  typeOfAction = typeOfActions.CREATE,
  availableCerrarMod,
  setAvailableCerrarMod,
  setDisabled,
  discardChanges,
  setDiscardChanges,
  ...props
}) => {

  const defaultInputsForm = {
    paraAsignatura: false
  }

  const [inputsForm, setInputsForm] = useState([]);
  const [validated, setValidated] = useState(false);
  const [wasModified, setWasModified] = useState(false);
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const isMountedRef = useRef(null);
  const cleanRelatedFiltersRef = useRef(false)

  const [relacionesConEspecialidad, setRelacionesConEspecialidad] = useState([]);
  const [relacionesArea, setRelacionesArea] = useState([]);
  const [apendiceSeleccionado, setApendiceSeleccionado] = useState()

  const dispatch = useDispatch();
  const history = useHistory();

  const submitResolve = (cargo) => {
    history.push(routePaths.ABM_CARGOS_SEARCH);
    dispatch(searchFab(true));
  }

  const submitResolveWithClearFilters = () => {
    dispatch(clearFilters())
    submitResolve()
  }

  const handleSubmit = async () => {
    await handleValidation(cargo => cargosService.approve(cargo, submitResolve));
  };

  const handlePublish = (event) => {
    handleValidation(cargosService.publish);
  };

  const handleEnviarRevision = async (analista) => {
    handleValidation(cargosService.sendToRevice);
  };

  const handleBorrador = async (event) => {
    await handleValidation(cargo => cargosService.saveDraft(
      cargo,
      typeOfAction == typeOfActions.CREATE ? submitResolveWithClearFilters : submitResolve
    ));
  };

  const handleEnviarAutorizar = async (supervisor) => {
    handleValidation(cargosService.sendToAuthorize);
  };

  const formatRelacionToFetch = (relacion) => {
    const formated = {
      area_id: relacion.area.id,
      tipo_escuela_id: relacion.tipoEscuela.id,
      especialidad_id: relacion.especialidad.id,
    }
    if (relacion.id) formated.id = relacion.id
    return formated
  }

  const handleValidation = async (resolve) => {
    const form = document.getElementById("formSearchCargos");
    setValidated(true);

    const reactInputsIds = [
      "nivel",
    ];

    if (form.checkValidity() === false) return console.log("FORM INCOMPLETO")
    if (inputsForm.paraAsignatura && relacionesArea.length == 0) return console.log("FORM INCOMPLETO - RELACIONES")
    if (!validateReactInputs(inputsForm, reactInputsIds)) return console.log("FORM INCOMPLETO (REACT INPUTS)")

    const area_id = [];
    const tipo_escuela = [];
    relacionesArea.forEach(ctRelacion => {
      if (!area_id.some(ctAreaId => ctAreaId == ctRelacion.area.id)) area_id.push(ctRelacion.area.id)
    })

    const params = {
      ...inputsForm,
      cod_dad: inputsForm.codDad,
      area_id,
      tipo_escuela,
      only_create_asignatura: inputsForm.paraAsignatura,
      relaciones: relacionesConEspecialidad.map(ctRel => formatRelacionToFetch(ctRel)),
    }

    if (wasModified) {
      if (cargo) {
        const arrRelacionesNuevas = []
        const arrRelacionesBorradas = cargo.relaciones.filter(ctRel => !relacionesConEspecialidad.some(relOriginal => relOriginal.id == ctRel.id))
        relacionesConEspecialidad.forEach(ctRelacion => {
          if (!ctRelacion.id) {
            arrRelacionesNuevas.push(ctRelacion)
          }
        })

        const paramsEdit = {
          ...params,
          id: cargo.id,
          nombre: inputsForm.nombre,
          folio: inputsForm.folio,
          cod_dad: inputsForm.codDad,

        }

        if (!inputsForm.paraAsignatura) {
          paramsEdit.area_id = []
          paramsEdit.relaciones_borradas = arrRelacionesBorradas.map(ctRel => ctRel.id)
          paramsEdit.relaciones_nuevas = arrRelacionesNuevas.map(ctRel => formatRelacionToFetch(ctRel))
        } else {
          paramsEdit.relaciones_nuevas = []
          paramsEdit.relaciones_borradas = cargo.relaciones.map(ctRel => ctRel.id);
        }

        if (await cargosService.editCargo(paramsEdit, resolve)) setWasModified(false);
      } else {
        if (await cargosService.createNewCargo(params, resolve)) setWasModified(false);
      }
    } else {
      await resolve(params);
    }
    setWasModified(false);

  };

  const handleChangeCheckBox = (e) => {
    const eFormated = { target: { id: e.target.id, value: e.target.checked } }
    e.target.checked ? setRelacionesConEspecialidad([]) : setRelacionesArea([])
    handlerChange(setInputsForm, inputsForm, setWasModified, eFormated);
  }

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e);
    if (setAvailableCerrarMod) { setAvailableCerrarMod(false); }
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, setWasModified, e);
      if (setAvailableCerrarMod) {
        setAvailableCerrarMod(false);
      }
    }
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const loadCargoInfo = () => {
    const formato = { value: "id", label: "nombre" };
    const formatoNivel = { value: "id", label: "descripcion" };
    setApendiceSeleccionado({ value: cargo.apendice.id, label: cargo.apendice.nombre })
    setRelacionesConEspecialidad(cargo.relaciones)
    setRelacionesArea(cargo.area.map(ctArea => ({ area: ctArea })));
    setInputsForm({
      ...defaultInputsForm,
      ...cargo,
      paraAsignatura: cargo.onlyCreateAsignatura,
      nivel: cargo.nivel.id,
    });
    if (cargo.nivel) setNivelSeleccionado(formatElements(cargo.nivel, formatoNivel));
  }

  const addRelacion = (newElement) => {
    setRelacionesArea(st => [...st, newElement])
    setWasModified(true)
  }

  const removeRelacion = (index) => {
    setRelacionesArea(st => st.filter((e, ctIndex) => index != ctIndex))
    setWasModified(true)
  }

  const addRelacionEspecialidad = (newElement) => {
    setRelacionesConEspecialidad(st => [...st, newElement])
    setWasModified(true)
  }

  const removeRelacionEspecialidad = (index) => {
    setRelacionesConEspecialidad(st => st.filter((e, ctIndex) => index != ctIndex))
    setWasModified(true)
  }

  const checkIsAvailableOptionEspecialidad = (newElement) => {
    return !relacionesConEspecialidad.some(ctRel =>
      ctRel.area.id == newElement.area.id &&
      ctRel.tipoEscuela.id == newElement.tipoEscuela.id &&
      ctRel.especialidad.id == newElement.especialidad.id
    )
  }

  const checkIsAvailableOption = (newElement) => {
    return !relacionesArea.some(ctRel => ctRel.area.id == newElement.area.id)
  }

  useEffect(() => {
    if (typeOfAction == typeOfActions.CREATE) {
      setInputsForm(defaultInputsForm)
      cleanRelatedFiltersRef.current = true;
    } else if (inputsForm.length < 1 && cargo) {
      loadCargoInfo();
      setWasModified(false);
      cleanRelatedFiltersRef.current = true;
    }
  }, [cargo])

  useEffect(() => {
    if (discardChanges) {
      setDiscardChanges(false);
      setWasModified(false);
      loadCargoInfo();
    }
  }, [discardChanges])

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    return () => (isMountedRef.current = false);
  }, [dispatch]);

  return (
    <Row>
      <Col>
        {true ? (
          <Form
            id="formSearchCargos"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            <Row>
              <Col sm={12} md={8} lg={8}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre del cargo"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  maxLength="200"
                  required
                  {...props}
                ></Input>
              </Col>
              <Col sm={12} md={4} lg={2}>
                <Input
                  type="input"
                  controlId="codDad"
                  label="Código DAD"
                  value={inputsForm.codDad ? inputsForm.codDad : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  disabled={typeOfAction === typeOfActions.EDIT}
                  required
                  {...props}
                ></Input>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Input
                  type="input"
                  controlId="folio"
                  label="Folio"
                  maxLength="100"
                  value={inputsForm.folio ? inputsForm.folio : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  required
                  {...props}
                ></Input>
              </Col>
              <Col md={4} lg={3}>
                <Input
                  type="react-select"
                  controlId="nivel"
                  name="nivel"
                  label="Nivel"
                  value={nivelSeleccionado}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getNivelesCargo}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  validated={validated}
                  required
                  {...props}
                ></Input>
              </Col>
              <Col xs={4} md={4} lg={3}>
                <Input
                  type="react-select"
                  controlId="apendice_id"
                  name="apendice_id"
                  label="Apéndice"
                  value={apendiceSeleccionado}
                  methodOnChange={handleChange}
                  elements={apendiceService.getApendices}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombreInput" }}
                  setterElementSelect={setApendiceSeleccionado}
                  config={{ isSearchable: true }}
                  predictivoAsync={false}
                  defaultIndexSelectedOptionInSyncSelect={
                    typeOfAction == typeOfActions.CREATE
                      ? "0" : undefined
                  }
                ></Input>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <Input
                  type="checkbox"
                  controlId="paraAsignatura"
                  name="paraAsignatura"
                  label="Para asignatura"
                  checked={inputsForm.paraAsignatura ? inputsForm.paraAsignatura : ""}
                  onChange={handleChangeCheckBox}
                  style={{ width: "fit-content" }}
                  {...props}
                ></Input>
              </Col>
              <Col md={12}>
                <Input
                  type="input"
                  controlId="descripcion"
                  label="Descripción"
                  maxLength="500"
                  value={inputsForm.descripcion ? inputsForm.descripcion : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  {...props}
                ></Input>
              </Col>
            </Row>

            <hr style={{ marginTop: 0 }} />


            {
              inputsForm.paraAsignatura ?
                <>
                  <Row>
                    <Col>
                      <h3>Relaciones: Areas</h3>
                      <span
                        className={`invalid-feedback ${validated && relacionesArea.length == 0 ? "show-block" : ""}`}
                      >
                        Es necesario seleccionar por lo menos 1 relacion
                      </span>
                    </Col>
                  </Row>

                  {
                    (typeOfAction != typeOfActions.APPROVAL) &&
                    <>
                      <RelacionesCargosForm
                        key="relaciones-cargos-solo-area"
                        addRelacion={addRelacion}
                        checkIsAvailableOption={checkIsAvailableOption}
                        hideInputs={{ especialidad: true, tipoEscuela: true, }}
                        withResetOnSubmit={false}
                        filtros={{ area: { creaAsignatura: true } }}
                      />
                      <h4>Relaciones seleccionadas:</h4>
                    </>
                  }

                  <RelacionesCargosTable
                    elementos={relacionesArea}
                    handleRemove={typeOfAction == typeOfActions.APPROVAL ? null : removeRelacion}
                    hideInputs={{ especialidad: true, asignatura: true, tipoEscuela: true, }}
                  />
                </>
                :
                <>
                  <Row>
                    <Col>
                      <h3 onClick={() => console.log({ inputsForm })}>Relaciones:</h3>
                    </Col>
                  </Row>

                  {
                    (typeOfAction != typeOfActions.APPROVAL) &&
                    <>
                      <RelacionesCargosForm
                        key="relaciones-cargos-completa"
                        addRelacion={addRelacionEspecialidad}
                        checkIsAvailableOption={checkIsAvailableOptionEspecialidad}
                      />
                      <h4>Relaciones seleccionadas:</h4>
                    </>
                  }

                  <RelacionesCargosTable
                    elementos={relacionesConEspecialidad}
                    handleRemove={typeOfAction == typeOfActions.APPROVAL ? null : removeRelacionEspecialidad}
                  />
                </>
            }

            <Row className="my-4">
              <Col>
                {wasModified &&
                  <PrivateComponent>
                    <Dialog
                      action={handleBorrador}
                      target={cargo}
                      title={"Guardar como borrador"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="secondary"
                      btnText="Guardar como borrador"
                      isLoadingText={"Guardando"}
                      body={<span>
                        ¿Estás seguro de mantener el cargo
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""} </b>
                        como borrador?
                      </span>}
                    />
                  </PrivateComponent>
                }
                {(typeOfAction == typeOfActions.APPROVAL) &&
                  <PrivateComponent permisos={PERMISOS_MAP.CARGO_APROBAR}>
                    <Dialog
                      action={handleSubmit}
                      target={cargo}
                      title="Confirmar aprobación"
                      variant="success"
                      size="md"
                      btnConfirmText="Aprobar"
                      btnText="Aprobar cargo"
                      btnVariant="success"
                      isLoadingText={"Aprobando"}
                      disabled={!props.disabled}
                      body={<span>¿Estás seguro de aprobar el cargo
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""}</b>?
                      </span>}
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {false &&
                  <PrivateComponent>
                    <Dialog
                      action={handlePublish}
                      target={cargo}
                      title="Publicar modificaciones"
                      size="md"
                      btnConfirmText="Confirmar y enviar"
                      btnText="Publicar modificaciones"
                      btnVariant="success"
                      disabled={!props.disabled}
                      body={
                        "Al publicar las modificaciones el cargo volverá " +
                        "a estar en estado Publicado con todas las modificaciones que hayas realizado"
                      }
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default CargosABMForm;
