import React, { useState, useEffect } from "react";
import { getTernas } from "../TernaService";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Loader } from "../../ui/Loader";
import TernaFormSearch from "./TernaFormSearch";
import TernaSearchTable from "./TernaSearchTable";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { error } from "../../store/alerts/alertActions";

const TernasSearch = (props) => {
  const [ternas, setTernas] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [cantidadTernas, setCantidadTernas] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const filtrosGuardados = useSelector((state) => state.search);

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      await searchTernas(filtrosGuardados)
      setPage(filtrosGuardados.page);
    }
  }

  const handleSearch = async (filters) => {
    searchTernas(filters);
  };

  const searchTernas = async (newFiltros = {}) => {
    try {
      const defaultFiltros = { all: true }
      const filtros = { ...defaultFiltros, ...newFiltros }
      setSearching(true);
      setTernas([])
      setPage(1); //Reset page
      const ternas = await getTernas(filtros);
      setTernas(ternas.data);
      setCantidadTernas(ternas.cantidad);
      setSearching(false);
    }
    catch (err) {
      setSearching(false)
      dispatch(error("No se pudieron cargar las ternas correctamente"))
    }
  };

  const handleChangePage = (event, value) => {
    filtrosGuardados["page"] = value;
    searchTernas(filtrosGuardados);
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
  }, []);

  return (
    <ScreenWithFabButtons
      hidePlusButton={false}
      hideSearchButton={false}
      hideCleanButton={false}
      returnPath={location.state?.returnPath}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !ternas}
      hideNewTicketButton={false}
    >
      <Row>
        <Col>
          <h1>Ternas</h1>
          <TernaFormSearch
            setTernas={setTernas}
            onSubmit={handleSearch}
            filtros={filtrosGuardados}
          ></TernaFormSearch>
          {ternas && !searching ? (
            <TernaSearchTable
              onSubmit={searchTernas}
              ternas={ternas}
              cantidadTernas={cantidadTernas}
              changePage={handleChangePage}
              page={page}
            ></TernaSearchTable>)
            : (
              searching && <Loader></Loader>
            )}
        </Col>
      </Row>

    </ScreenWithFabButtons>
  );
};

export default TernasSearch;
