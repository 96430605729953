import React from 'react'
import { ButtonGroup, Row, ToggleButton } from 'react-bootstrap'
import { clonadorConstants, routePaths } from '../../utils/Constants';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const ClonadorNav = () => {
    const { cloneType } = useParams();
    const history = useHistory();

    const handleNavigate = (route) => {
        history.push(route);
    }

    return (
        <>
            <ButtonGroup className="mb-2">
                <ToggleButton
                    id={`radio-1`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="ternas"
                    checked={cloneType == clonadorConstants.TERNAS}
                    onChange={() => handleNavigate(routePaths.ABM_INCUMBENCIAS_CLONADOR_TERNAS)}
                >
                    Ternas
                </ToggleButton>
                <ToggleButton
                    id={`radio-2`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="cargos"
                    checked={cloneType == clonadorConstants.CARGOS}
                    onChange={() => handleNavigate(routePaths.ABM_INCUMBENCIAS_CLONADOR_CARGOS)}
                >
                    Cargos
                </ToggleButton>
            </ButtonGroup>
        </>
    )
}
