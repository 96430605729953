import React from "react";

export const DetailFact = ({
  className,
  label,
  input,
  children,
  style = {},
  wordBreak,
}) => {
  return (
    <div className={`detailFact ${className ? className : ""}`} style={style}>
      <label>{label}</label>
      <span style={{ wordBreak: wordBreak == true ? "break-all" : "normal" }}>{input}</span>
    </div>
  );
};