import { Form, Col, Row, Button } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidNumeric, isValidText, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import * as escuelaService from "../EscuelaService";
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import { cleanFab, searchFab } from "../../store/screen/screenActions";
import { OPCIONES_CAMPO1 } from "../abm/EscuelaABMForm";

const EscuelaFormSearch = ({
  filtros,
  onSubmit,
  setEscuelas,
  ...props
}) => {
  const [inputsForm, setInputsForm] = useState([]);
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [codigoSeleccionado, setCodigoSeleccionado] = useState();
  const [resetChecks, setResetChecks] = useState(false);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);
  const filtrosGuardados = useSelector((state) => state.search);

  const [campo1, setCampo1] = useState("");


  const processSubmit = async () => {
    let data;

    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      data = filtrosGuardados
    } else {
      const filtrosDefault = { page: 1 }

      data = {
        inputsForm: { ...inputsForm, ...filtrosDefault },
        areaSeleccionada,
        codigoSeleccionado,
      }
    }
    await onSubmit(data)
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, null, e);
    }
  };

  const resetForm = () => {
    setInputsForm({});
    setAreaSeleccionada("");
    setCodigoSeleccionado(null);
    setResetChecks(true);
    setEscuelas("");
    setCampo1("")
  }

  const loadForm = () => {
    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      setInputsForm(filtrosGuardados.inputsForm);
      setAreaSeleccionada(filtrosGuardados.areaSeleccionada);
      setCodigoSeleccionado(filtrosGuardados.codigoSeleccionado)
    }
  }

  useEffect(() => {
    loadForm();
  }, [dispatch]);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchEscuela">
            <Row className="form-group">

              <Col sm={12} md={6} lg={6}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Descripcion de Tipo de Escuela"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                ></Input>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Input
                  type="react-select"
                  controlId="area"
                  name="area"
                  label="Área"
                  value={areaSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getAreas}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setAreaSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Input
                  type="react-select"
                  controlId="tipoEscuela"
                  name="tipoEscuela"
                  label="Codigo de Tipo de Escuela"
                  value={codigoSeleccionado}
                  methodOnChange={handleChange}
                  elements={escuelaService.getCodigoEscuela}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setCodigoSeleccionado}
                  config={{ isSearchable: false, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={6}>
                {/* <Input
                  type="select"
                  controlId="campo1"
                  name="campo1"
                  label="Tipo de Nivel"
                  value={inputsForm.campo1 ? inputsForm.campo1.id : 0}
                  onChange={handleChange}
                  elements={tipoCampo}
                  {...props}
                ></Input>

                <Col sm={12} md={6} lg={6}> */}
                <Input
                  type="react-select-sync"
                  controlId="campo1"
                  name="campo1"
                  label="Tipo de Nivel"
                  value={campo1}
                  setterElementSelect={setCampo1}
                  methodOnChange={handleChange}
                  syncElements={OPCIONES_CAMPO1}
                  disabled={OPCIONES_CAMPO1.length == 0}
                  config={{ isSearchable: true, isClearable: true }}
                  required
                  {...props}
                ></Input>
                {/* </Col> */}


              </Col>
              <Col sm={9} lg={12}>
                <CheckBoxEstados
                  inputsForm={inputsForm}
                  setInputsForm={setInputsForm}
                  resetChecks={resetChecks}
                  setResetChecks={setResetChecks}
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default EscuelaFormSearch;
