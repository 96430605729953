import { dataTypes } from "../../actions/types"

const initialState = {
    tipoIncumbencias: "",
}

const dataReducer = (state = initialState, action) => {

    switch (action.type) {
        case dataTypes.ADD_DATA:
            return {
                ...state,
                ...action.payload
            }

        case dataTypes.REMOVE_DATA:
            const newSt = { ...state };
            delete newSt[action.payload];
            return newSt

        case dataTypes.CLEAN_ALL_DATA:
            return initialState;

        default:
            return state;
    }
}

export default dataReducer;

