import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { DetailFact } from '../../ui/DetailFact';
import { ticketsUtils } from '../TicketsUtils';
import { DetailCargo } from './components/DetailCargo';
import { DetailTerna } from './components/DetailTerna';

export const TicketsDetailBody = ({ ticket }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <small>Creado por: <b>{ticket.createdBy.nombreCompleto}</b></small>
        <small>Asignado a: <b>{ticket.assignedTo.nombreCompleto}</b></small>
        <small>Fecha de creación: <b>{ticket.createdAt}</b></small>
      </div>
      <hr></hr>
      <div className="d-flex justify-content-between">
        {
          ticket.apendice && <p>
            <b>Apendice:</b> {ticket.apendice}
          </p>
        }
        <p><b>Modulo:</b> {ticket.modulo.nombre}</p>
        <p><b>Tipo:</b> {ticket.tipo.nombre}</p>
        <p><b>Estado:</b> {ticket.estado.nombre}</p>
      </div>
      {ticket.finishedAt && <small>Fecha de finalización: <b>{ticket.finishedAt}</b></small>}

      {
        ticket.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS && ticket.objectId &&
        <>
          {ticket.object ?
            <>
              <hr />
              <DetailTerna terna={ticket.object} />
            </>
            :
            ticket.tipo.nombre != "Alta" ?
              <>
                <hr />
                La terna ya fue eliminada
              </>
              :
              <></>
          }
        </>
      }
      {
        ticket.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS && ticket.objectId &&
        <>
          {ticket.object ?
            <>
              <hr />
              {
                ticket.object.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS &&
                <DetailTerna terna={ticket.object} />
              }

              {
                ticket.object.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS &&
                <>
                  <DetailTerna terna={ticket.object.terna} />
                  <hr />
                  <DetailCargo
                    cargo={ticket.object.cargo}
                    asignatura={ticket.object.asignatura}
                    especialidad={ticket.object.especialidad}
                    tipoEscuela={ticket.object.tipoEscuela}
                    area={ticket.object.area}
                  />
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p><b>Folio:</b> {ticket.object.folio}</p>
                    <p><b>Calidad:</b> {ticket.object.calidad}</p>
                    <p><b>Puntaje:</b> {ticket.object.puntaje}</p>
                  </div>
                  <p><b>Puntaje:</b> {ticket.object.puntaje}</p>
                  {ticket.object.concurrencia &&
                    <>
                      <hr />
                      <div>
                        <b>En concurrencia con:</b>
                        {ticket.object.terna2 && <DetailTerna terna={ticket.object.terna2} />}
                        {ticket.object.terna3 && <DetailTerna terna={ticket.object.terna3} />}
                      </div>
                    </>
                  }
                </>
              }
            </>
            :
            ticket.tipo.nombre != "Alta" ?
              <>
                <hr />
                La incumbencia ya fue eliminada
              </>
              :
              <></>
          }
        </>
      }
      <Row>
        <Col md={12} className="form-group">
          <hr />
          <label>Observación: </label>
          <p>{ticket.observacion}</p>
        </Col>
      </Row>

      {ticket.observacionCierre && <Row>
        <Col md={12} className="form-group">
          <hr />
          <label>Observación de cierre: </label>
          <p>{ticket.observacionCierre}</p>
        </Col>
      </Row>}
    </>
  );
};
