import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PrivateComponent from "../../../authentication/PrivateComponent";
import IconButton from "../../../generics/IconButton";
import Dialog from "../../../generics/dialog";
import { cleanIncumbenciasData, saveIncumbenciasData } from "../../../store/incumbencias/incumbenciasActions";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import { incumbenciasConstants, routePaths, tooltipsText } from "../../../utils/Constants";
import IncumbenciasService, { formatCargoToIncumbencia, formatConcurrenciasMainCargo, formatIncumbenciasMainCargo } from "../../IncumbenciasService";
import { IncumbenciasDetailFormVariantes } from "../../details/cargos/IncumbenciasDetailFormVariantes";
import { IncumbenciasModalSearchInfoCargo } from "../../details/cargos/IncumbenciasModalSearchInfoCargo";
import { PERMISOS_MAP } from "../../../utils/Permisos";

export const IncumbenciasSearchRowActionsCargos = ({ cargo }) => {
    const [showNoIncumbenciasError, setShowNoIncumbenciasError] = useState(false);
    const [selectedVariante, setSelectedVariante] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    const { tipoIncumbencias } = useSelector(st => st.data)

    const onChangeVariante = (element) => {
        setShowNoIncumbenciasError(false)
        setSelectedVariante(element)
    }

    const loadIncumbenciasToEdit = async (target, setLoading) => {
        setLoading(true);
        const dataPath = cargo.permite_variante ? selectedVariante[0] : cargo.unica_variante
        const resp = await IncumbenciasService.getIncumbencias({
            cargo_id: cargo.id,
            area_id: dataPath.area.id,
            asignatura_id: dataPath.asignatura.id,
            especialidad_id: dataPath.especialidad.id,
            tipo_escuela_id: dataPath.tipoEscuela.id,
            limit: 999
        }, true);
        console.log("LOADING EDIT", { resp })

        if (resp.cantidad == 0) {
            setShowNoIncumbenciasError(true);
            setLoading(false);
            return true; /* impide que el modal se cierre */
        }

        const arrRawIncumbencias = []
        const arrRawConcurrencias = []

        resp.elementos.forEach(currentElement => {
            if (currentElement.concurrencia) {
                arrRawConcurrencias.push(currentElement)
            } else {
                arrRawIncumbencias.push(currentElement)
            }
        })

        const formatedIncumbencias = await formatIncumbenciasMainCargo(arrRawIncumbencias, tipoIncumbencias);
        const formatedConcurrencias = await formatConcurrenciasMainCargo(cargo, arrRawConcurrencias, tipoIncumbencias);
        console.log({ formatedConcurrencias })
        dispatch(cleanIncumbenciasData());
        dispatch(saveIncumbenciasData({
            originalCargoSeleccionado: formatedIncumbencias.cargoSeleccionado.length > 0 ? formatedIncumbencias.cargoSeleccionado : formatedConcurrencias.cargoSeleccionado,
            originalTernaSeleccionada: formatedIncumbencias.ternaSeleccionada,
            originalConcurrenciasSeleccionadas: formatedConcurrencias.concurrenciasSeleccionadas
        }));

        history.push(routePaths.ABM_INCUMBENCIAS_EDIT_CARGOS)
    }

    const handleCreateWithCargoPrecargado = async () => {
        const cargoToAdd = await formatCargoToIncumbencia(cargo);

        dispatch(saveIncumbenciasData({
            "cargoSeleccionado": [cargoToAdd],
            "concurrenciasSeleccionadas": null
        }))

        history.push({
            pathname: routePaths.ABM_INCUMBENCIAS_CREATE_CARGOS,
            state: {
                returnPath: routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS,
            }
        });
    }

    return (
        <>
            {
                cargo.activo &&
                <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_EDITAR}>
                    <Dialog
                        title="Editar incumbencias"
                        isLoadingText={"Cargando..."}
                        btnIcon={faPen}
                        btnTooltipText={tooltipsText.EDITAR}
                        disableBtnConfirmText={!selectedVariante}
                        btnConfirmText={"Editar"}
                        action={loadIncumbenciasToEdit}
                        onShow={cargo.permite_variante ? undefined : loadIncumbenciasToEdit}
                        size={"xl"}
                        body={<>
                            {cargo.permite_variante &&
                                <IncumbenciasDetailFormVariantes
                                    cargo={cargo}
                                    setterFunction={onChangeVariante}
                                    mainElement={incumbenciasConstants.CARGOS_INCUMBENCIA}
                                    checkIsAvailableValidate={false}
                                    autoAddVariante
                                />
                            }
                            {showNoIncumbenciasError &&
                                <Alert variant="info">Este cargo no tiene incumbencias asignadas que editar.</Alert>
                            }
                        </>}
                    />
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <IncumbenciasModalSearchInfoCargo cargo={cargo} withIconSearch={true} />
            </CustomTooltip>

            {cargo.activo &&
                <CustomTooltip text={tooltipsText.ALTA}>
                    <IconButton icon={faPlus} onClick={handleCreateWithCargoPrecargado} />
                </CustomTooltip>
            }
        </>
    );
};