class Organismo{
    constructor(){
        this.id = "";
        this.nombre = "";
    }
}

export default Organismo

export const createOrganismo = (props) => {
    return Object.assign(new Organismo(), {...props})
}
