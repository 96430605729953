
import dateFormat from 'dateformat';
import Acciones, { createAcciones } from "../Acciones";

class Apendice {
  constructor(nombre) {
    this.id = 0;
    this.nombre = "";
    this.fechaDesde = "";
    this.fechaHasta = "";
    this.activo = null;
    this.habilitado = [];
    this.acciones = [];
    this.nombreInput = "";
  }

  getFechaDesde() {
    return this.fechaDesde.split(" ")[0];
  }

  getFechaHasta() {
    return this.fechaHasta.split(" ")[0];
  }

  getNombre() {
    return this.nombre;
  }
}

export default Apendice

export const createApendice = async (apendice) => {
  let acciones = apendice.acciones ? await createAcciones(apendice.acciones) : new Acciones();

  let fechaDesde = dateFormat(apendice.fechaDesde, "dd/mm/yyyy HH:MM");
  let fechaHasta = dateFormat(apendice.fechaHasta, "dd/mm/yyyy HH:MM");

  const nombreInput = `${apendice.nombre} ${apendice.activo ? "(actual)" : ""}`

  return Object.assign(new Apendice(apendice.nombre), { ...apendice, fechaDesde, fechaHasta, acciones, nombreInput });
}
