import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import { Row, Col, Card, Accordion, Badge, Table } from "react-bootstrap";
import TituloRelacionSearchRowActions from "../../shared/TituloRelacionSearchRowActions";
import { routePaths } from "../../../utils/Constants";
import { ITFAccordion } from "./ITFAccordion";


export const ITFSearchRow = ({ titulo, onSubmit }) => {

    const datos = {
        tituloPrimario: "Título principal",
        tituloSecundario: "Título relacionado",
        primario: "original",
        secundario: "relacionados",
    };

    return (
        <Accordion className="form-group">
            <Card key={titulo.id} >

                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <Row>
                        <Col xs={12} md={8} lg={9} xl={10}>
                            <h5>{titulo[datos.primario].titulo.nombre}</h5>

                            <span className="accordion-header-text">
                                {titulo[datos.primario].procedencia.nombre}
                            </span>

                            <span className="accordion-header-text">
                                {titulo[datos.primario].resolucion.nombre}
                            </span>

                            <span className="accordion-header-text">
                                {titulo[datos.primario].createdAt}
                            </span>

                        </Col>
                    </Row>
                </Accordion.Toggle>
                <ITFAccordion
                    titulo={titulo}
                    datos={datos}
                />
            </Card>
        </Accordion>
    );
};