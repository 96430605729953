import React, { useEffect } from "react";
import { Table, Row, Col, Alert, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import store from "../../store/store";
import { error, success } from "../../store/alerts/alertActions";
import Paginacion from "../../generics/Paginacion";
import { useState } from "react";

const TituloSearchTable = ({
  titulos,
  cantidadTitulos,
  page,
  changePage,
  renderRow,
  renderTable,
  conGenerarReporte = true,
  handleChangeSeleccionar,
  urlSeleccionar,
  filtros,
  handleSeleccionar,
  handleVolver,
  handleSearch,
}) => {
  const [tituloSeleccionado, setTituloSeleccionado] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectItem = (titulo) => {
    setTituloSeleccionado(titulo);
  }

  const renderTableRows = (titulos) => {
    return titulos.map((titulo) => {
      return renderRow(titulo, handleSearch, handleChangeSeleccionar, filtros, handleSelectItem);
    });
  };

  useEffect(() => {

  }, [])

  return (
    <>
      {!titulos[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron títulos.</Alert>
          </Col>
        </Row>
      ) : (
        !!titulos &&
        renderTable(titulos, cantidadTitulos, page, changePage, renderTableRows)
      )}
      <Row className="my-4">
        {!!titulos[0] && conGenerarReporte && (
          <Col>
            <Button variant="primary" content="Reporte" type="submit" disabled>
              Informe Total
            </Button>
          </Col>
        )}
        {urlSeleccionar && (
          <Col>
            <Button
              variant="primary"
              content="Seleccionar"
              type="button"
              onClick={() => handleSeleccionar(filtros, urlSeleccionar, tituloSeleccionado, setIsLoading)}
              disabled={filtros.bloquearSeleccionar || isLoading}
            >
              {isLoading ?
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="secondary"
                  /> Cargando
                </>
                :
                "Seleccionar"
              }
            </Button>

          </Col>
        )}
      </Row>
    </>
  );
};

export default TituloSearchTable;
