import React from 'react'
import * as AuthService from './AuthService'
import GoogleLogin from 'react-google-login';
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { error } from "../store/alerts/alertActions";
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const LoginByGoogle = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);

    const login = async (response) => {
        try {
            setIsLoading(true)
            await AuthService.login(response)

            /* TODO: 
            Dejo comentada la linea returnPath, que se encargaria de returnear al path donde te encontrabas
            al expirar el token, ya que al returnear rompe la app (pareciera ser que en cualquier path que no sea "/"
            no se cargan variables de entorno: Cannot read properties of undefined)
            */

            // const returnPath = location?.state?.returnPath ?? "/";

            const returnPath = "/"
            history.push(returnPath);
            window.location.reload();
        } catch (err) {
            //TODO Devuelve un 500, puede ser otro error y no que el usuario no exista necesariamente.
            dispatch(error(`${err.response.data.message}, el usuario no existe`));
            setIsLoading(false)
        }
    }

    return (
        <div>
            {
                !isLoading ?
                    <GoogleLogin
                        clientId={window.Configs.REACT_APP_CLIENT_ID}
                        buttonText="Login con Google"
                        onSuccess={login}>Ingresar
                    </GoogleLogin>
                    :
                    <>
                        <button type="button" className="googleButton" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant={"secondary"}
                            />
                            Ingresando...
                        </button>
                    </>
            }
        </div>
    )
}

export default LoginByGoogle
