import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { Badge, Col, Row, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import IconButton from '../../generics/IconButton';
import Paginacion from '../../generics/Paginacion'
import { CustomTooltip } from '../../ui/CustomTooltip';
import { routePaths, ticketsConstants, tooltipsText } from '../../utils/Constants';
import { TicketsRowActions } from './TicketsRowActions';

const FormTableHeader = ({ hideFinishedAt, hideUpdatedAt }) => {
  return (
    <tr>
      <th>Ticket</th>
      <th>Apendice</th>
      <th>Modulo</th>
      <th>Acción</th>
      <th>Analista</th>
      <th>Fecha de inicio</th>
      {!hideFinishedAt && <th>Ultima modificación</th>}
      {!hideUpdatedAt && <th>Fecha de finalización</th>}
      {/* <th>Estado</th> */}
      <th></th>
    </tr>
  )
};

const FormRow = ({ element, accionesPermitidas, hideFinishedAt, hideUpdatedAt }) => {
  return (
    <tr>
      <td>{element.id ?? "-"}</td>
      <td>{element.apendice ?? "-"}</td>
      <td>{element.modulo.nombre ?? "-"}</td>
      <td>{element.tipo.nombre ?? "-"}</td>
      <td>{element.assignedTo.nombreCompleto ?? "-"}</td>
      <td>{element.createdAt ?? "-"}</td>
      {!hideFinishedAt && <td>{element.updatedAt ?? "-"}</td>}
      {!hideUpdatedAt && <td>{element.finishedAt ?? "-"}</td>}
      {/* <td><Badge variant={element.estado ? element.estado.color : "primary"}> {element.estado.nombre ?? "-"}</Badge></td> */}
      <td>
        <div className="table-button-group">
          <TicketsRowActions ticket={element} accionesPermitidas={accionesPermitidas} />
        </div>
      </td>
    </tr>
  )
}

export const TicketsFormTable = ({
  tickets,
  cantidadTickets,
  page,
  handleChangePage,
}) => {
  const { typeOfSearch } = useParams();

  const accionesPermitidas = {
    editar: typeOfSearch != ticketsConstants.RESUELTOS.label,
    cerrar: typeOfSearch != ticketsConstants.RESUELTOS.label,
  }

  return (
    <>
      <Row className="form-group">
        <Col>
          <Table responsive>
            <thead>
              <FormTableHeader
                hideFinishedAt={!accionesPermitidas.cerrar}
                hideUpdatedAt={accionesPermitidas.editar}
              />
            </thead>
            <tbody>
              {tickets.map((currentTicket, index) =>
                <React.Fragment key={`TicketRow-${index}`}>
                  <FormRow
                    element={currentTicket}
                    hideFinishedAt={!accionesPermitidas.cerrar}
                    hideUpdatedAt={accionesPermitidas.editar}
                    accionesPermitidas={accionesPermitidas}
                  />
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Paginacion totalItems={cantidadTickets} currentPage={Number(page)} onChangePage={handleChangePage} />
    </>
  )
}
