class Acciones{
    constructor(){
        this.alta = false;
        this.baja = false;
        this.editar = false;
        this.eliminar = false;
        this.publicar = false;
        this.aprobar = false;
    }
}

export default Acciones

export const createAcciones = (props) => {
    return Object.assign(new Acciones(), {...props})
}
