import { faCheckDouble, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import * as apendiceService from "../../apendice/ApendiceService"
import PrivateComponent from '../../authentication/PrivateComponent'
import Dialog from '../../generics/dialog'
import { clear } from '../../store/alerts/alertActions'
import { CustomTooltip } from '../../ui/CustomTooltip'
import DialogActivate from '../../ui/DialogActivate'
import Input from '../../ui/Input'
import { tooltipsText } from '../../utils/Constants'
import { PERMISOS_MAP } from '../../utils/Permisos'
import { handlerChange, isValidNumeric } from '../../utils/commons'
import { activateIncumbencia, approvalIncumbencia, deactivateIncumbencia, deleteIncumbencia, publishIncumbencia } from '../IncumbenciasService'


export const IncumbenciaRowActions = ({
    incumbencia,
    setErrorMsg,
    setSuccessMsg,
    clearErrorMsg,
    resolveCallBack,
    withDelete = true,
}) => {

    const dispatch = useDispatch()
    const [inputsForm, setInputsForm] = useState([]);
    const [apendiceSeleccionado, setApendiceSeleccionado] = useState()
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const changeState = async (incumbencia) => {
        try {
            clearErrorMsg()
            if (incumbencia.acciones.alta) {
                await activateIncumbencia(incumbencia, { resolve: resolveCallBack, withMessage: false })
                setSuccessMsg("Se activo la incumbencia correctamente.")
            } else {
                await deactivateIncumbencia(incumbencia, { resolve: resolveCallBack, withMessage: false, params: inputsForm })
                setSuccessMsg("Se desactivo la incumbencia correctamente.")
            }
        } catch (e) {
            setErrorMsg("Error al realizar la accion (Cambio de estado)")
        }
        dispatch(clear())
    };

    const approvalElement = async () => {
        try {
            clearErrorMsg()
            await approvalIncumbencia(incumbencia, { resolve: resolveCallBack, withMessage: false });
            setSuccessMsg("Se aprobo la incumbencia correctamente.")
        } catch (e) {
            setErrorMsg("Error al realizar la accion (Aprobar)")
        }
        dispatch(clear())

    }

    const handleChangeStateValidation = () => {
        setValidated(true)
        console.log({ apendiceSeleccionado, inputsForm })
        if (!apendiceSeleccionado || !inputsForm.folio) {
            throw new Error("Form incompleto")
        }
    }

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e);
    }

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handlerChange(setInputsForm, inputsForm, null, e);
        }
    };

    const publishElement = async () => {
        try {
            clearErrorMsg()
            await publishIncumbencia(incumbencia, { resolve: resolveCallBack, withMessage: false });
            setSuccessMsg("Se publico la incumbencia correctamente.")
        } catch (e) {
            setErrorMsg("Error al realizar la accion (Publicar)")
        }
        dispatch(clear())

    }

    const deleteElement = async (incumbencia) => {
        try {
            clearErrorMsg()
            await deleteIncumbencia(incumbencia, { resolve: resolveCallBack, withMessage: false });
            setSuccessMsg("Se elimino la incumbencia correctamente.")
        } catch (e) {
            setErrorMsg("Error al realizar la accion (Eliminar)")
        }
        dispatch(clear())
    }

    return (
        <>
            {
                (incumbencia.acciones.alta || incumbencia.acciones.baja) &&
                <PrivateComponent permisos={incumbencia.acciones.alta ? PERMISOS_MAP.INCUMBENCIA_ALTA : PERMISOS_MAP.INCUMBENCIA_BAJA}>
                    <CustomTooltip text={incumbencia.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                        <DialogActivate
                            target={incumbencia}
                            title={incumbencia.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            action={changeState}
                            validation={!incumbencia.acciones.alta ? handleChangeStateValidation : undefined}

                            size="md"
                            body={
                                <div>
                                    <span>¿Estás seguro de dar de {incumbencia.acciones.alta ? "alta " : "baja "} la incumbencia?</span>
                                    {
                                        !incumbencia.acciones.alta
                                        &&
                                        <Form
                                            noValidate
                                            validated={validated}
                                            onSubmit={(e) => e.preventDefault()}
                                        >
                                            <Row className="mt-4">
                                                <Col>
                                                    <Input
                                                        type="react-select"
                                                        controlId="apendice_id"
                                                        name="apendice_id"
                                                        label="Apéndice"
                                                        value={apendiceSeleccionado}
                                                        methodOnChange={handleChange}
                                                        elements={apendiceService.getApendices}
                                                        nameElementsResponse="elementos"
                                                        propertiesForFormat={{ value: "id", label: "nombreInput" }}
                                                        setterElementSelect={setApendiceSeleccionado}
                                                        config={{ isSearchable: true }}
                                                        predictivoAsync={false}
                                                        required
                                                        validated={validated}
                                                        defaultIndexSelectedOptionInSyncSelect={"0"}
                                                        disabled={isLoading}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Input
                                                        type="input"
                                                        controlId="folio"
                                                        label="Folio"
                                                        maxLength="100"
                                                        value={inputsForm.folio ? inputsForm.folio : ""}
                                                        onChange={handleValidChange.bind(this, isValidNumeric)}
                                                        required
                                                        disabled={isLoading}
                                                    ></Input>
                                                </Col>
                                            </Row>
                                        </Form>}
                                </div>
                            }
                            isLoadingText={incumbencia.acciones.alta ? "Dando de alta" : "Dando de baja"}
                            btnConfirmText={incumbencia.acciones.alta ? "Dar de alta" : "Dar de baja"}
                        >
                        </DialogActivate>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {
                (incumbencia.acciones.aprobar) &&
                <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_APROBAR}>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <Dialog
                            action={approvalElement}
                            target={incumbencia}
                            title={"Aprobar incumbencia"}
                            body={<span>¿Estás seguro de <b>aprobar</b> la incumbencia?</span>}
                            size="md"
                            btnIcon={faCheckDouble}
                            // btnVariant={"danger"}
                            // variant={"danger"}
                            btnConfirmText="Aprobar"
                            isLoadingText={"Aprobando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {
                (incumbencia.acciones.publicar) &&
                <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_PUBLICAR}>
                    <CustomTooltip text={tooltipsText.PUBLICAR}>
                        <Dialog
                            action={publishElement}
                            target={incumbencia}
                            title={"Publicar incumbencia"}
                            body={<span>¿Estás seguro de <b>publicar</b> la incumbencia?</span>}
                            size="md"
                            btnIcon={faUpload}
                            // btnVariant={"danger"}
                            // variant={"danger"}
                            btnConfirmText="Publicar"
                            isLoadingText={"Publicando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {withDelete && incumbencia.acciones.eliminar &&
                <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_ELIMINAR}>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={deleteElement}
                            target={incumbencia}
                            title={"Eliminar incumbencia"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar la incumbencia?
                            </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                            isLoadingText={"Eliminando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }
        </>
    )
}
