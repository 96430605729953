import { faCheckDouble, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import PrivateComponent from "../../authentication/PrivateComponent";
import IconButton from "../../generics/IconButton";
import Dialog from "../../generics/dialog";
import { error } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import DialogActivate from "../../ui/DialogActivate";
import { routePaths, tooltipsText } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos";
import { activate, deactivate, deleteCargo } from "../CargosService";
import CargoDetailModal from "../details/CargoDetailModal";

const CargosSearchRowActions = ({ cargo, visibleCheckbox }) => {
    const [redirect, setRedirect] = useState("");

    const dispatch = useDispatch();

    const resolveCallBack = () => {
        dispatch(searchFab(true))
    }

    const redirectToEdit = (cargo) => {
        setRedirect(routePaths.ABM_CARGOS_EDIT);
    };

    const redirectToApproval = (cargo) => {
        setRedirect(routePaths.ABM_CARGOS_APPROVAL);
    };

    const changeState = async (cargo) => {
        try {
            if (cargo.activo) {
                await deactivate(cargo, resolveCallBack)
            } else {
                await activate(cargo, resolveCallBack)
            }
        } catch (e) {
            dispatch(error(e.response.data.message));
        }
    };

    const deleteElement = async (cargo) => {
        await deleteCargo(cargo, resolveCallBack);
    }

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        cargo: cargo,
                        abmTitle: "Editar",
                        submitValue: "Aceptar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <>
            {(cargo.acciones.alta || cargo.acciones.baja) && !visibleCheckbox &&
                <PrivateComponent permisos={cargo.acciones.alta ? PERMISOS_MAP.CARGO_ALTA : PERMISOS_MAP.CARGO_BAJA}>
                    <CustomTooltip text={cargo.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                        <DialogActivate
                            target={cargo}
                            title={(cargo.acciones.alta ? "Dar de alta" : "Dar de baja")}
                            action={changeState}
                            size="md"
                            body={<span>
                                ¿Estás seguro de dar de {(cargo.acciones.alta ? "alta " : "baja ")}
                                el cargo
                                <b> {cargo ? cargo.nombre : ""}</b>
                                ?
                            </span>
                            }
                            btnConfirmText={cargo.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            isLoadingText={cargo.acciones.alta ? "Dando de alta" : "Dando de baja"}
                        >
                        </DialogActivate>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {cargo.acciones.eliminar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.CARGO_ELIMINAR}>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={deleteElement}
                            target={cargo}
                            title={"Eliminar cargo"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar el cargo
                                <b> {cargo ? cargo.nombre : ""}</b>
                                ?
                            </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                            isLoadingText={"Eliminando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {cargo.acciones.aprobar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.CARGO_APROBAR}>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <IconButton
                            icon={faCheckDouble}
                            onClick={() => redirectToApproval(cargo)}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {cargo.acciones.publicar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.CARGO_PUBLICAR}>
                    <CustomTooltip text={tooltipsText.PUBLICAR}>
                        <CargoDetailModal
                            cargo={cargo}
                            withPublicar={true}
                            onSubmit={resolveCallBack}
                        ></CargoDetailModal>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {cargo.acciones.editar && !visibleCheckbox &&
                <PrivateComponent permisos={PERMISOS_MAP.CARGO_EDITAR}>
                    <CustomTooltip text={tooltipsText.EDITAR}>
                        <IconButton icon={faPen} onClick={() => redirectToEdit(cargo)} />
                    </CustomTooltip>
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <CargoDetailModal cargo={cargo}></CargoDetailModal>
            </CustomTooltip>



        </>
    );
};

export default CargosSearchRowActions;
