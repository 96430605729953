import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Dialog from '../../generics/dialog';
import Paginacion from '../../generics/Paginacion';
import { CustomTooltip } from '../../ui/CustomTooltip';
import Input from '../../ui/Input';
import { Loader } from '../../ui/Loader';

export const RelacionadosRow = ({
    relacionId,
    terna,
    checkState,
    onChangeCheckState,
    onClickDelete
}) => {

    return (
        <tr>
            <td>{terna.titulo.nombre}</td>
            <td>{terna.procedencia.nombre}</td>
            <td>{terna.resolucion.nombre}</td>
            <td className="d-flex justify-content-center">
                {onChangeCheckState ?
                    <Input
                        className="checkboxEstados d-flex justify-content-center"
                        type="checkbox"
                        controlId={relacionId}
                        onChange={onChangeCheckState}
                        checked={checkState}
                    ></Input>
                    :
                    <CustomTooltip text={"Remover de la lista"}>
                        <Dialog
                            action={onClickDelete}
                            target={relacionId}
                            title={"Remover titulo"}
                            body={<span>¿Estás seguro que quiere remover el titulo del informe?</span>}
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                        />
                    </CustomTooltip>
                }
            </td>
        </tr>
    )
}


export const RelacionadosTable = ({
    children,
    hideAddToInforme,
    cantidadElementos,
    page,
    handleChangePage,
    hidePagination,
}) => {
    return (<>
        <Row className="form-group">
            <Col className="w-100">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Nombre del titulo</th>
                            <th>Procedencia</th>
                            <th>Resolución</th>
                            <th style={{ width: "1%" }}> {!hideAddToInforme && "Agregar al reporte"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </Table>
            </Col>
        </Row>
        {!hidePagination &&
            <Row>
                <Paginacion totalItems={cantidadElementos} currentPage={Number(page)} onChangePage={handleChangePage} filasPorPaginas={5} />
            </Row>}
    </>)
}

export const RelacionadosInfoModal = ({
    selectedRelacionados,
    cantidadRelacionados,
    arrRelacionados,
    page,
    changeSelected,
    loadData,
    nombreRelacion,

}) => {
    const [newSelectedState, setNewSelectedState] = useState(selectedRelacionados);
    const [searching, setSearching] = useState(false);
    const [show, setShow] = useState(false);

    const onChangeCheckState = (event, selectedRelacion) => {
        setNewSelectedState(st => {
            let newSt = [...st]
            if (event.target.checked) {
                newSt = [...newSt, selectedRelacion];
            } else {
                newSt = newSt.filter(ctRelacion => ctRelacion.id != selectedRelacion.id)
            }
            return newSt
        })
    }

    const handleChangePage = async (event, value) => {
        try {
            setSearching(true)
            await loadData(value)
        } catch (err) { }
        finally { setSearching(false) }
    }

    const handleClose = () => {
        setShow(false);
        setNewSelectedState(selectedRelacionados);
    };

    const handleShow = () => {
        setShow(true)
    };

    const handleClickApply = () => {
        changeSelected(newSelectedState)
        handleClose();
    }

    useEffect(() => {
        setNewSelectedState(selectedRelacionados)
    }, [selectedRelacionados])

    return (
        <div className="d-flex align-items-center">
            {cantidadRelacionados >= 0 && (cantidadRelacionados > 0 ?
                <>
                    <button type="button" className="btn btn-link align-self-start" onClick={handleShow}>
                        El titulo presenta {cantidadRelacionados} {nombreRelacion}{cantidadRelacionados == 1 ? "" : "s"}.
                    </button>
                    <Modal
                        onHide={handleClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        show={show}
                        backdrop={true}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span className="font-weight-light">Relaciones {nombreRelacion}s:</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!searching &&
                                <RelacionadosTable
                                    cantidadElementos={cantidadRelacionados}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                >
                                    {arrRelacionados.map(ctRelacionRow => {
                                        return (
                                            <React.Fragment key={"RelacionadoRow-" + nombreRelacion + "-" + ctRelacionRow.id}>
                                                <RelacionadosRow
                                                    relacionId={ctRelacionRow.id}
                                                    terna={ctRelacionRow.terna}
                                                    checkState={newSelectedState.find(ctRelSelected => ctRelSelected.id == ctRelacionRow.id)}
                                                    onChangeCheckState={ev => onChangeCheckState(ev, ctRelacionRow)}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </RelacionadosTable>
                            }

                            {searching && <Loader></Loader>}

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Volver
                            </Button>
                            <Button variant="primary" onClick={handleClickApply}>
                                Aplicar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                :
                <p className="my-0"> El titulo no presenta {nombreRelacion}s.</p>
            )}
        </div >
    )
}