import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { clear, error, info, success } from "../../../store/alerts/alertActions";
import { RelacionABMForm } from "../../shared/RelacionABMForm";
import { SearchRelacion } from "../../shared/SearchRelacion";
import { routePaths, typeOfActions } from "../../../utils/Constants";
import * as apendiceService from "../../../apendice/ApendiceService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EquivalenciaSelectRow } from "./EquivalenciaSelectRow";
import { EquivalenciaABMTable } from "./EquivalenciaABMTable";
import { postEquivalencias } from "../equivalenciaService";

export const EquivalenciaABM = ({
  title = "Crear relación entre títulos (Equivalentes)",
  relacion,
  typeOfAction,
  handleSubmit,
}) => {
  const EQUIVALENCIAABM_SECTIONS = {
    SELECCIONAR_ORIGINAL: "SELECCIONAR_ORIGINAL",
    SELECCIONAR_RELACIONES: "SELECCIONAR_RELACIONES",
    CREAR_RELACION: "CREAR_RELACION",
  }

  const [arrOriginalesSeleccionados, setArrOriginalesSeleccionados] = useState(null);
  const [arrRelacionesSeleccionadas, setArrRelacionesSeleccionadas] = useState(null);
  const [inputsForm, setInputsForm] = useState([]);

  const [selectDocument, setSelectDocument] = useState()

  const isMountedRef = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const isSeleccionarOriginal = selectDocument == EQUIVALENCIAABM_SECTIONS.SELECCIONAR_ORIGINAL

  const returnCallBack = () => {
    dispatch(clear())
    setSelectDocument(null)
  }

  const getCurrentApendice = async () => {
    apendiceService.getCurrentApendice().then(result => {
      if (isMountedRef.current) {
        setInputsForm(data => ({ ...data, apendice: result }))
      }
    });
  };

  const loadCrearForm = () => {
    getCurrentApendice()
  }

  const loadEditarForm = () => {
    console.log("RELACION", relacion)
    setArrOriginalesSeleccionados([relacion.original])
    setArrRelacionesSeleccionadas([relacion.relacionado.terna])
    setInputsForm({
      relacion_id: relacion.relacionado.id,
      apendice: relacion.relacionado.apendice,
      folio: relacion.relacionado.folio
    })
  }

  const renderSearchTable = (
    titulos,
    cantidadTitulos,
    page,
    changePage,
    renderTableRows
  ) => {
    return (
      <EquivalenciaABMTable
        titulos={titulos}
        cantidadTitulos={cantidadTitulos}
        page={page}
        changePage={changePage}
        renderTableRows={renderTableRows}
      />
    );
  };

  const renderSearchRow = (titulo, seleccionados, onChangeSeleccionado) => {
    return (
      <EquivalenciaSelectRow
        titulo={titulo}
        onChangeSeleccionado={onChangeSeleccionado}
        seleccionados={seleccionados}
        visibleCheckbox={false}
        visibleRadio={true}
      />
    );
  };

  const renderElementoABM = (elemento) => {
    return (
      <EquivalenciaSelectRow
        titulo={elemento}
        visibleCheckbox={false}
        visibleRadio={false}
      />
    );
  };

  const renderTableOriginalABM = (titulos) => {
    return (
      <EquivalenciaABMTable
        titulos={titulos}
        renderTableRows={elementos => elementos.map(elemento => renderElementoABM(elemento))}
        visibleCheckbox={false}
        visiblePaginacion={false}
        visibleInputs={false}
      />
    );
  };

  const renderTableRelacionadosABM = (titulos) => {
    return (
      <EquivalenciaABMTable
        titulos={titulos}
        renderTableRows={elementos => elementos.map(elemento => renderElementoABM(elemento))}
        visibleCheckbox={false}
        visiblePaginacion={false}
        visibleInputs={false}
      />
    );
  };

  const checkAndRemoveIfSameAs = (arrSeleccionados) => {
    return arrSeleccionados.filter(elemento => !arrOriginalesSeleccionados.some(ctOriginal => ctOriginal.id == elemento.id))
  }

  const setterValidations = (arrSeleccionados) => {
    return checkAndRemoveIfSameAs(arrSeleccionados)
  }

  const handleCreate = async (arrOriginalesSeleccionados, arrRelacionesSeleccionadas, inputsForm) => {
    const params = {
      original: arrOriginalesSeleccionados[0].id,
      equivalente: arrRelacionesSeleccionadas[0].id,
      folio: inputsForm.folio,
    }

    try {
      const response = await postEquivalencias(params, isMountedRef);
      history.push(routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH)
      dispatch(success("Se creo la relacion equivalente correctamente."));
    }
    catch (err) {
      dispatch(error(err?.response?.data?.message ?? "No se pudo crear la relacion equivalente"))
    }
  }

  useEffect(() => {
    if (typeOfAction == typeOfActions.CREATE) loadCrearForm();
    if (
      typeOfAction == typeOfActions.EDIT || typeOfAction == typeOfActions.APPROVAL) loadEditarForm();

    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  const handleAddOriginalesSeleccionados = (arrTerna) => {
    setArrOriginalesSeleccionados(arrTerna.map(ctTerna => {
      ctTerna.titulo.nivel.nombre = ctTerna.titulo.nivel.descripcion
      return ctTerna
    }))
  }

  const handleAddRelacionadosSeleccionados = (arrTerna) => {
    setArrRelacionesSeleccionadas(arrTerna.map(ctTerna => {
      ctTerna.titulo.nivel.nombre = ctTerna.titulo.nivel.descripcion
      return ctTerna
    }))
  }

  return (
    <>
      {selectDocument ?
        <SearchRelacion
          title={isSeleccionarOriginal ? "Buscar título principal" : "Buscar título equivalente"}
          returnCallBack={returnCallBack}
          setterValidations={isSeleccionarOriginal ? null : setterValidations}
          setter={isSeleccionarOriginal ? handleAddOriginalesSeleccionados : handleAddRelacionadosSeleccionados}
          // esMultipleSeleccion={selectDocument != ITFABM_SECTIONS.SELECCIONAR_ORIGINAL}
          esMultipleSeleccion={false}
          arrCurrentSeleccionado={isSeleccionarOriginal ? arrOriginalesSeleccionados : arrRelacionesSeleccionadas}
          renderRow={renderSearchRow}
          renderTable={renderSearchTable}
        />
        :
        <RelacionABMForm
          title={title}
          inputsForm={inputsForm}
          setInputsForm={setInputsForm}
          handleSubmit={handleSubmit ?? handleCreate}
          typeOfAction={typeOfAction}
          arrOriginalesSeleccionados={arrOriginalesSeleccionados}
          arrRelacionesSeleccionadas={arrRelacionesSeleccionadas}
          changeToSeleccionarOriginalSeccion={() => setSelectDocument(EQUIVALENCIAABM_SECTIONS.SELECCIONAR_ORIGINAL)}
          changeToSeleccionarRelacionesSeccion={() => setSelectDocument(EQUIVALENCIAABM_SECTIONS.SELECCIONAR_RELACIONES)}
          renderTableOriginal={renderTableOriginalABM}
          renderTableRelacionados={renderTableRelacionadosABM}
        />
      }
    </>
  );
};