import React from 'react';
import { Badge } from 'react-bootstrap';
import { CustomTooltip } from '../../../../ui/CustomTooltip';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Dialog from '../../../../generics/dialog';

export const UnificadorTernaRow = ({
    elemento = {},
    removeElement
}) => {
    return (
        <tr className="border-bottom-separator">
            <td>{elemento.titulo?.nombre ?? "-"}</td>
            <td>{elemento.procedencia?.nombre ?? "-"}</td>
            <td>{elemento.resolucion?.nombre ?? "-"}</td>
            <td>{elemento.nivel?.descripcion ?? "-"}</td>
            <td>{elemento.createdAt ?? "-"}</td>
            <td>
                <Badge variant={elemento.estado?.color}>
                    {elemento.estado?.nombre}
                </Badge>
            </td>
            <td>
                <div className="table-button-group">
                    {removeElement && <CustomTooltip text={"Remover de la lista"}>
                        <Dialog
                            action={removeElement}
                            target={elemento}
                            title={"Eliminar terna"}
                            body={<span>¿Estás seguro que quiere remover la terna de la lista?</span>}
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                        />
                    </CustomTooltip>}
                </div>
            </td>
        </tr>
    )
}

