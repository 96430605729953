import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Row, Button, Card, Image, Spinner } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";

const CuadroSeleccion = ({
  title,
  icon,
  text,
  urlBuscador,
  urlState,
  elementos,
  renderTable,
  ocultarBuscador,
  disabledCard,
  firstButtonText,
  secondButtonText,
  validated,
  errorValidatedMessage,
  onClick,
  cardClassName = "",
  isLoading,
  printError,
  getSecondAdditionalContent,
  ...props
}) => {
  const history = useHistory();

  const isAnyElementSelected = () => {
    if (!elementos) return false
    if ((Object.prototype.toString.call(elementos) == '[object Array]') && (elementos.length == 0)) return false
    return true
  }

  const loadingCardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
  }

  return (
    <>
      {
        isLoading ?
          <Card>
            <Card.Header>
              <div className="position-relative">
                <h3>{title}</h3>
                {
                  !(errorValidatedMessage === false) &&
                  <span style={{ bottom: "-4px" }} className={`invalid-feedback ${validated ? "card-show-invalid-feedback" : ""}`}>
                    {errorValidatedMessage ?? "El campo es obligatorio"}
                  </span>
                }
              </div>
            </Card.Header>
            <Card.Body>
              <div className="card-empty" style={loadingCardStyle}>
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  variant={"secondary"}
                />
                <p className="font-weight-bold" style={{ color: "#414142" }}>
                  Cargando
                </p>
              </div>
            </Card.Body>
          </Card>
          :
          <>
            {!isAnyElementSelected() && (
              <Card className={disabledCard ? "disabled-card" : ""} border={validated && "danger"}>
                <Card.Header>
                  <div className="position-relative">
                    <h3>{title}</h3>
                    {
                      !(errorValidatedMessage === false) &&
                      <span className={`invalid-feedback ${validated ? "card-show-invalid-feedback" : ""}`}>
                        {errorValidatedMessage ?? "El campo es obligatorio"}
                      </span>
                    }
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="card-empty">
                    <Image src={icon} />
                    <span className="font-weight-bold">{text}</span>
                    {firstButtonText && <Button
                      variant="primary"
                      type="button"
                      size="md"
                      onClick={() => { onClick ? onClick() : history.push(urlBuscador, urlState) }}
                      disabled={props.disabledBuscar || disabledCard}
                    >
                      {firstButtonText}
                    </Button>
                    }
                  </div>
                </Card.Body>
              </Card>
            )
            }
            {
              isAnyElementSelected() && (
                <Card className={`cardErrorValidate ${cardClassName} ${disabledCard ? "disabled-card" : ""}`} border={printError && "danger"}  >
                  <Card.Header>
                    <div className="position-relative">
                      <h3>{title}</h3>
                      {
                        !(errorValidatedMessage === false) && validated &&
                        <span id="cardErrorMsg" className={`invalid-feedback ${validated ? "card-show-invalid-feedback" : ""}`}>
                          {errorValidatedMessage ?? "El campo es obligatorio"}
                        </span>
                      }
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {renderTable(elementos)}
                    <div className="d-flex align-items-center">
                      {!ocultarBuscador && (
                        <Button
                          variant="primary"
                          type="button"
                          size="md"
                          className="mr-4"
                          onClick={() => { onClick ? onClick() : history.push(urlBuscador, urlState) }}
                          disabled={props.disabledBuscar || disabledCard}
                        >
                          {secondButtonText}
                        </Button>
                      )}

                      {getSecondAdditionalContent && <div>
                        {getSecondAdditionalContent()}
                      </div>
                      }
                    </div>
                  </Card.Body>
                </Card>
              )
            }
          </>
      }
    </>
  );
};

export default CuadroSeleccion;
