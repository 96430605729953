import React, { useRef, useState } from 'react';
import { Alert, ButtonGroup, Col, Row, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../generics/dialog';
import { error } from '../../store/alerts/alertActions';
import { OPCIONES_DESCARGAS, agregarDescargas } from '../../store/descargas/descargasActions.';
import { saveFilters } from '../../store/search/searchActions';
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import * as informesService from "../InformesService";
import { FormSearchCruzadosArea } from './FormSearchCruzadosArea';
import { FormSearchCruzadosCargo } from './FormSearchCruzadosCargo';
import { FormTableCruzadoArea } from './FormTableCruzadoArea';
import { FormTableCruzadoCargo } from './FormTableCruzadoCargo';
import { agruparIncumbenciasPorAreaEscuela } from '../informeIncumByTerna/DetailIncumByTerna';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

const searchByOptions = {
  area: "area",
  cargo: "cargo"
}

export const SearchCruzados = () => {
  const [inputsForm, setInputsForm] = useState({});
  const [elementos, setElementos] = useState();
  const [columnas, setColumnas] = useState();
  const [cantidadElementos, setCantidadElementos] = useState();
  const [gruposIncumbencias, setGruposIncumbencias] = useState()

  const [page, setPage] = useState(1);

  const [searching, setSearching] = useState(false);

  const [searchBy, setSearchBy] = useState(searchByOptions.area);

  const cantidadMaximaElementos = 100;
  const generarEnFormatoPDF = cantidadElementos <= cantidadMaximaElementos

  const isMountedAreaRef = useRef(true);
  const isMountedCargoRef = useRef(false);

  const searchState = useSelector(st => st.search);
  const dispatch = useDispatch();

  const cleanResults = () => {
    setElementos("");
    setCantidadElementos("");
    setPage(1)
    setSearching(false)
  }

  const resetForm = () => {
    setInputsForm({})
    cleanResults();
  }

  const switchSearchBy = (searchOption) => {
    if (searchOption == searchByOptions.area) {
      isMountedAreaRef.current = true;
      isMountedCargoRef.current = false;
    } else {
      isMountedAreaRef.current = false;
      isMountedCargoRef.current = true;
    }
    resetForm();
    setSearchBy(searchOption);
  }

  const handleClickGenerarPDF = async () => {
    let filtros = { ...inputsForm }
    if (filtros.page) delete filtros.page
    const fileName = `Informe Vademecum`
    console.log({ fileName, filtros, inputsForm, searchBy })
    try {
      dispatch(agregarDescargas([{
        title: "Informe / Cruzados por área o cargo (PDF)",
        opcion: OPCIONES_DESCARGAS.PDF.INFORME_CRUZADO,
        filtros: filtros,
        descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
        fileName,
      }]));
    } catch (err) {
      dispatch(error(err.message))
    }
  }

  const handleClickGenerarCSV = async () => {
    let filtros = { ...inputsForm }
    if (filtros.page) delete filtros.page
    const fileName = `Informe Vademecum`
    console.log({ fileName, filtros, inputsForm, searchBy })
    try {
      dispatch(agregarDescargas([{
        title: "Informe / Cruzados por área o cargo (CSV)",
        opcion: OPCIONES_DESCARGAS.CSV.INFORME_CRUZADO,
        filtros: filtros,
        descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
        fileName,
      }]));
    } catch (err) {
      dispatch(error(err.message))
    }
  }

  const searchCruzadosArea = async (filtros = {}) => {
    cleanResults();
    if (!filtros.page) filtros.page = 1;
    setSearching(true);
    dispatch(saveFilters({ inputsForm: filtros }));

    try {
      const resp = await informesService.getInformeCruzadoPorArea(filtros, isMountedAreaRef);

      setElementos(resp.elementos);
      setGruposIncumbencias(agruparIncumbenciasPorAreaEscuela(resp.elementos))
      setCantidadElementos(resp.cantidad);
      setPage(filtros.page)
      setSearching(false);
    } catch (err) { console.log(err.message) }
  }

  const searchCruzadosCargo = async (filtros = {}) => {
    cleanResults();
    if (!filtros.page) filtros.page = 1;
    setSearching(true);
    dispatch(saveFilters({ inputsForm: filtros }));

    if (!filtros.cargo_id) {
      setSearching(false);
      dispatch(error("Es necesario seleccionar un cargo para realizar la busqueda."));
      return
    };
    try {
      const resp = await informesService.getInformeCruzadoPorCargo(filtros, isMountedCargoRef);
      setElementos(resp.elementos);
      setCantidadElementos(resp.cantidad);
      setPage(filtros.page)
      setColumnas(resp.columnas);
      setSearching(false);
    } catch (err) { console.log(err.message) }
  }

  const handleChangePageArea = (event, value) => {
    const filtros = { ...searchState.inputsForm, page: value }
    searchCruzadosArea(filtros);
    setPage(value);
  };

  const handleChangePageCargo = (event, value) => {
    const filtros = { ...searchState.inputsForm, page: value }
    searchCruzadosCargo(filtros);
    setPage(value);
  };

  const isSearchButtonDisabled = (
    (searchByOptions.cargo == searchBy ? (!inputsForm.cargo_id ? true : false) : false)
  )


  return (
    <ScreenWithFabButtons
      searchButtonDisabled={searching || isSearchButtonDisabled}
      cleanButtonDisabled={searching || Object.keys(inputsForm).length == 0}
      hideSearchButton={false}
      hideCleanButton={false}
    >
      <h1>Informes</h1>
      <h3>Vademecum</h3>

      <h3>Buscar por</h3>
      <ButtonGroup className="mb-2">
        <ToggleButton
          id={`radio-1`}
          className="menu-radio"
          type="radio"
          variant="secondary"
          name="radio"
          value="Área"
          checked={searchBy == searchByOptions.area}
          onChange={() => switchSearchBy(searchByOptions.area)
          }
        >
          Área
        </ToggleButton>
        <ToggleButton
          id={`radio-2`}
          className="menu-radio"
          type="radio"
          variant="secondary"
          name="radio"
          value="Cargo"
          checked={searchBy == searchByOptions.cargo}
          onChange={() => switchSearchBy(searchByOptions.cargo)}
        >
          Cargo
        </ToggleButton>
      </ButtonGroup>
      <hr></hr>

      {searchBy == searchByOptions.cargo &&
        <>
          <FormSearchCruzadosCargo
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            searchCruzadosCargo={searchCruzadosCargo}
            resetForm={resetForm}
          />
          {!searching &&
            <>
              {cantidadElementos > 0 &&
                <FormTableCruzadoCargo
                  elementos={elementos}
                  cantidadElementos={cantidadElementos}
                  page={page}
                  columnas={columnas}
                  handleChangePage={handleChangePageCargo}
                />}

              {cantidadElementos === 0 &&
                <Row><Col><Alert variant="info">Este cargo no presenta asignaturas.</Alert></Col></Row>
              }

              {(cantidadElementos > 0 || cantidadElementos === 0) &&
                <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                  <Dialog
                    preventFocus
                    action={handleClickGenerarCSV}
                    title={"Informe Total (.CSV)"}
                    variant="primary"
                    size="md"
                    btnConfirmText="Generar"
                    btnVariant="primary"
                    btnText="Informe Total (.CSV)"
                    isLoadingText={"Generando informe"}
                    body={<span>
                      ¿Estás seguro de generar el informe?
                    </span>}
                  />

                  {generarEnFormatoPDF &&
                    <Dialog
                      preventFocus
                      action={handleClickGenerarPDF}
                      title={"Informe Total"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Generar"
                      btnVariant="primary"
                      btnText="Informe Total"
                      isLoadingText={"Generando informe"}
                      body={<span>
                        ¿Estás seguro de generar el informe?
                      </span>}
                    />
                  }
                </PrivateComponent>
              }
            </>
          }
          {searching && <Loader></Loader>}
        </>
      }

      {searchBy == searchByOptions.area &&
        <>
          <FormSearchCruzadosArea
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            searchCruzadosArea={searchCruzadosArea}
            resetForm={resetForm}
          />
          {!searching &&
            <>
              {cantidadElementos > 0 &&
                <FormTableCruzadoArea
                  elementos={gruposIncumbencias}
                  cantidadElementos={cantidadElementos}
                  page={page}
                  handleChangePage={handleChangePageArea}
                />}

              {cantidadElementos === 0 &&
                <Row><Col><Alert variant="info">Esta Area no presenta cargos con incumbencias asignadas.</Alert></Col></Row>
              }

              {(cantidadElementos > 0 || cantidadElementos === 0) &&
                <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                  <Dialog
                    preventFocus
                    action={handleClickGenerarCSV}
                    title={"Informe Total (.CSV)"}
                    variant="primary"
                    size="md"
                    btnConfirmText="Generar"
                    btnVariant="primary"
                    btnText="Informe Total (.CSV)"
                    isLoadingText={"Generando informe"}
                    className="mt-3"
                    body={<span>
                      ¿Estás seguro de generar el informe?
                    </span>}
                  />

                  {generarEnFormatoPDF &&
                    <Dialog
                      preventFocus
                      action={handleClickGenerarPDF}
                      title={"Informe Total"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Generar"
                      btnVariant="primary"
                      btnText="Informe Total"
                      isLoadingText={"Generando informe"}
                      className="mt-3"
                      body={<span>
                        ¿Estás seguro de generar el informe?
                      </span>}
                    />
                  }
                </PrivateComponent>
              }
            </>
          }
          {searching && <Loader></Loader>}
        </>
      }

    </ScreenWithFabButtons>
  )
}
