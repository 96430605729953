import React, { useState } from "react";
import { Alert, Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Paginacion from "../../generics/Paginacion";
import CargosSearchRow from "./CargosSearchRow";

const CargosSearchTable = ({
  cargos,
  cantidadCargos,
  page,
  changePage,
  visibleCheckbox,
  handleChangeSeleccionarCargo,
  selectMulti,
  selectedElements,
}) => {

  const [cargosSeleccionados, setCargoSeleccionado] = useState(selectedElements ?? []);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChangeCargo = (evento, cargo) => {
    if (evento.target.checked) {
      setCargoSeleccionado(st => ([...st, cargo]))
    } else {
      setCargoSeleccionado(st => st.filter(ctElement => ctElement.cargo ? ctElement.cargo.id != cargo.id : ctElement.id != cargo.id))
    }
  }

  const renderTableRows = (cargos) => {
    return cargos.map((cargo) => {
      return (
        <CargosSearchRow
          visibleCheckbox={visibleCheckbox}
          key={cargo.id}
          cargo={cargo}
          handleOnChangeCargo={handleOnChangeCargo}
          selectMulti={selectMulti}
          selected={selectedElements?.some(ctElemento => ctElemento.cargo.id == cargo.id)}
        ></CargosSearchRow >
      );
    });
  };

  return (
    <>
      {!cargos[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron cargos.</Alert>
          </Col>
        </Row>
      ) : (
        !!cargos && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      {visibleCheckbox && <th></th>}
                      <th>Nombre del cargo</th>
                      <th className="max-width-md-10">Cód. DAD</th>
                      <th>Nivel</th>
                      <th className="max-width-lg-20">Área</th>
                      <th>Especialidad</th>
                      <th>Creación</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(cargos)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadCargos} currentPage={page} onChangePage={changePage} />
          </>
        )
      )}
      {!!cargos[0] && <Row>
        {visibleCheckbox &&
          <Col>
            <Button
              variant="primary"
              content="Seleccionar"
              type="button"
              onClick={() => handleChangeSeleccionarCargo(cargosSeleccionados, setIsLoading)}
              disabled={isLoading}
            >
              {isLoading ?
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="secondary"
                  /> Cargando
                </>
                :
                "Seleccionar"
              }
            </Button>
          </Col>
        }
      </Row>}

    </>
  );
};

export default CargosSearchTable;