import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";

export const ITFTable = ({
    titulos,
    cantidadTitulos,
    page,
    changePage,
    renderTableRows,
}) => {
    return (
        <>
            <Row className="form-group">
                <Col>
                    <Table responsive>
                        <tbody>
                            {renderTableRows(titulos)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Paginacion
                totalItems={cantidadTitulos}
                currentPage={page}
                onChangePage={(event, value) => {
                    changePage(event, value);
                }}
            />
        </>
    );
};
