import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import ApendiceABMForm from "./ApendiceABMForm";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { routePaths } from "../../utils/Constants";
import { useLocation } from "react-router-dom";

const ApendiceABM = ({ title, submitValue, apendice, onSubmit, ...props }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clear());
    }, [dispatch]);

    const handleSubmit = async (apendice) => {
        //await processCreateCargo(escuela)
        dispatch(success("Se creó el Apendice con éxito."));
    }

    return (

        <ScreenWithFabButtons
            returnPath={location.state && location.state.returnPath}
        >
            <Row>
                <Col>
                    <Row className="form-group">
                        <Col>
                            <h1>{title ? title : "Crear Apendice"}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ApendiceABMForm
                                onSubmit={onSubmit ? onSubmit : handleSubmit}
                                apendice={apendice}
                                {...props}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ScreenWithFabButtons>
    );
};

export default ApendiceABM;
