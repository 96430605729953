import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import EspecialidadesABM from "./EspecialidadesABM";
import { useLocation } from "react-router-dom";
import Especialidad from "../../dtos/especialidad/Especialidad";
import { Row, Col, Button, Container } from "react-bootstrap";
import EspecialidadABMForm from "./EspecialidadesABMForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import IconTextButton from "../../generics/IconTextButton";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { saveFilters } from "../../store/search/searchActions";

const EspecialidadesABMApproval = (props) => {
  let location = useLocation();
  const [especialidad, setEspecialidad] = useState();
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos data de los filtrosGuardados para forzar refresh al cargar la data en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.data
    dispatch(saveFilters(newFiltrosGuardados))
  };

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.especialidad) {
      let especialidad = location.state.especialidad;
      if (!(especialidad instanceof Especialidad)) {
        especialidad = Object.assign(
          new Especialidad(especialidad.nombre),
          especialidad
        );
      }
      setEspecialidad(especialidad);
    }
  }, [dispatch, location.state]);

  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
    >
      <Row>
        <Col>
          <Row className="form-group">
            <Col md="12">
              <h1>Aprobar Especialidad
                {/* <IconTextButton
                  className="btn-icon-rounded"
                  icon={faPen}
                  type="submit"
                  variant="secondary"
                  text={disabled ? "Modificar Especialidad" : "Cerrar Modificación"}
                  onClick={() => setDisabled(!disabled)} >
                </IconTextButton> */}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <EspecialidadABMForm
                typeOfAction={typeOfActions.APPROVAL}
                onSubmit={handleSubmit}
                especialidad={especialidad}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};

export default EspecialidadesABMApproval;
