import React, { useEffect, useState } from 'react'
import Input from '../../ui/Input'
import { handlerChange } from '../../utils/commons';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { Col, Row } from 'react-bootstrap';
import CheckBoxEstados from '../../generics/CheckBoxEstados';

export const DetailForm = ({
  inputsForm,
  setInputsForm,
  resetForm,
  handleSearh,
}) => {

  const [tipoIncumbencia, setTipoIncumbencia] = useState();
  const { tipoIncumbencias } = useSelector(st => st.data)
  const screenState = useSelector(st => st.screen);
  const dispatch = useDispatch();

  const [resetChecks, setResetChecks] = useState(false);

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const processSubmit = () => {
    handleSearh(inputsForm);
  }

  useEffect(() => {
    if (screenState.clean) {
      setResetChecks(true);
      resetForm()
      setTipoIncumbencia(null)
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <>
      <Col lg={4}>
        <Input
          type="react-select-sync"
          controlId="tipo_incumbencia_id"
          name="tipo_incumbencia_id"
          label="Condición"
          value={tipoIncumbencia}
          setterElementSelect={setTipoIncumbencia}
          methodOnChange={handleChange}
          syncElements={tipoIncumbencias ?? []}
          config={{ isSearchable: true, isClearable: true }}
        ></Input>
      </Col>

      <Col lg={4} sm={12} className="mb-2rem">
        <CheckBoxEstados
          inputsForm={inputsForm}
          setInputsForm={setInputsForm}
          resetChecks={resetChecks}
          setResetChecks={setResetChecks}
        />
      </Col>
    </>
  )
}
