import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import Dialog from "../../generics/dialog";
import { success } from "../../store/alerts/alertActions";
import Input from "../../ui/Input";
import * as ternaService from "../TernaService";

const TituloAddModal = ({ nivel, titulo, handleClose, show = false, action, modalTitle }) => {
  const [validated, setValidated] = useState(false);
  const [nombre, setNombre] = useState(show == "edit" ? titulo.label : "");
  const [nivelSeleccionado, setNivelSeleccionado] = useState(nivel);
  const [errorMsg, setErrorMsg] = useState("")

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setNombre(value)
  };

  const handleValidation = async (resolve) => {
    setValidated(false);
    setErrorMsg("");
    const form = document.getElementById("formCreateTitulo");
    if (!form.checkValidity() === false) {
      if (nivelSeleccionado) {
        try {
          if (show == "create") {
            await ternaService.createNewTitulo(nivelSeleccionado.value, nombre, resolve);
            dispatch(success(<>Se creó el titulo <b>{nombre}</b> correctamente.</>));
          } else {
            console.log({ titulo })
            await ternaService.editTitulo(titulo.value, nivelSeleccionado.value, nombre, resolve);
            dispatch(success(<>Se edito el titulo <b>{nombre}</b> correctamente.</>));
          }
          handleClose()
        }
        catch (err) {
          setErrorMsg(err?.response?.data?.message ?? "Ha ocurrido un error al enviar la peticion.");
        }
      }
    }
    setValidated(true);
  };

  const guardar = async () => {
    await handleValidation(action)
  }

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const resetModal = () => {
    setValidated(false);
    setErrorMsg("");
    if (show == "create") {
      setNombre('');
      setNivelSeleccionado('');
    } else {
      setNombre(titulo.label);
      setNivelSeleccionado(nivel);
    }
  }

  useEffect(() => {
    if (show) resetModal();
  }, [show])

  return (
    <>
      <Modal
        show={show !== false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="formCreateTitulo"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            {errorMsg && <Alert variant={"danger"}>{errorMsg}</Alert>}
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Input
                  type="react-select"
                  controlId="nivel"
                  name="nivel"
                  label="Nivel"
                  value={nivelSeleccionado}
                  elements={especialidadesService.getNiveles}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  validated={validated}
                  required
                ></Input>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre de título"
                  maxLength="200"
                  value={nombre}
                  onChange={handleChange}
                  required
                ></Input>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
          <Dialog
            action={guardar}
            target={{}}
            title={"Guardar como borrador"}
            variant="primary"
            size="md"
            btnConfirmText="Guardar"
            btnVariant="success"
            btnText="Guardar"
            isLoadingText={"Guardando"}
            body={
              <span>
                ¿Estás seguro de guardar el título?
              </span>
            }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TituloAddModal