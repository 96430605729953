import React from 'react';
import { Badge } from 'react-bootstrap';

export const FormTableRowByTerna = ({
  terna,
  tipoBusqueda,
  onSubmit
}) => {

  return (
    <>
      <tr key={terna.id}>
        <td>{terna.titulo.nombre}</td>
        {/* <td>{terna.nivel.descripcion}</td> */}
        <td>{terna.procedencia.nombre}</td>
        <td>{terna.resolucion.nombre}</td>
        {/* <td>{terna.createdAt}</td> */}
        {/* <td><Badge variant={terna.estado ? terna.estado.color : "primary"}> {terna.getNombreEstado()}</Badge></td> */}
      </tr>

      {/* {tipoBusquedaInforme.TITULO === tipoBusqueda &&
        <tr key={informe.id}>
          <td>{informe.nombre}</td>
          <td>{informe.nivel.descripcion}</td>
          <td>{formatearFechaConBarras(informe.createdAt)}</td>
          <td>
            <div className="table-button-group">
              <InformesSearchRowActions informe={informe} tipoBusqueda={tipoBusqueda} onSubmit={onSubmit} />
            </div>
          </td>
        </tr>
      }
      {tipoBusquedaInforme.RESOLUCIONES === tipoBusqueda &&
        <tr key={informe.id}>
          <td>{informe.numero}</td>
          <td>{informe.tipoResolucion ? informe.tipoResolucion.nombre : ""}</td>
          <td>{informe.organismo ? informe.organismo.nombre : ""}</td>
          <td>{informe.anio}</td>
          <td>{formatearFechaConBarras(informe.createdAt)}</td>
          <td>
            <div className="table-button-group">
              <InformesSearchRowActions informe={informe} tipoBusqueda={tipoBusqueda} onSubmit={onSubmit} />
            </div>
          </td>
        </tr>
      }
      {tipoBusquedaInforme.PROCEDENCIAS === tipoBusqueda &&
        <tr key={informe.id}>
          <td>{informe.nombre}</td>
          <td>{formatearFechaConBarras(informe.createdAt)}</td>
          <td>
            <div className="table-button-group">
              <InformesSearchRowActions informe={informe} tipoBusqueda={tipoBusqueda} onSubmit={onSubmit} />
            </div>
          </td>
        </tr>
      } */}
    </>
  );
};