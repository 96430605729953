import { success } from "../../store/alerts/alertActions";
import store from "../../store/store";
import { apiURLs, estadosConstans } from "../../utils/Constants";
import { adaptGetEquivalenciasFromTerna, adaptGetEquivalentes } from "./equivalenciaAdapter";
const { default: requestService } = require("../../utils/request.service");

export const getEquivalenciasFromTerna = async (filtros = {}, isMountedRef) => {
    /* terna_id, limit, page */

    const uri = apiURLs.API_INFORMES_EQUIVALENCIAS;
    let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
    return {
        elementos: adaptGetEquivalenciasFromTerna(response.data.elementos),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };
}

export const getEquivalentes = async (filtros = {}, isMountedRef) => {
    /* 
    titulo_equivalente:
    titulo_original
    */

    const response = await requestService.sendRequest(
        "GET",
        apiURLs.API_TITULOS_EQUIVALENTE,
        filtros,
        isMountedRef,
    );
    return {
        elementos: adaptGetEquivalentes(response.data.elementos),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };
};

export const postEquivalencias = async (params, isMountedRef) => {
    const response = await requestService.sendRequest("POST", apiURLs.API_TITULOS_EQUIVALENTE, params, isMountedRef)
    return response
};

export const patchEquivalente = async (params, isMountedRef) => {
    const response = await requestService.sendRequest("PATCH", apiURLs.API_TITULOS_EQUIVALENTE + `/${params.id}`, params, isMountedRef)
    return response;
};

const changeState = async (relacionId, newState) => {
    await requestService.sendRequest('PUT', apiURLs.API_TITULOS_EQUIVALENTE + `/${relacionId}/estado`, { estado: newState })
}

export const aprobarEquivalencia = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.APROBADO)
    if (resolve) await resolve();
    await store.dispatch(success(<p>Se aprobo la relacion equivalente correctamente.</p>))
}

export const habilitarEquivalencia = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.BORRADOR)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se activo la relacion equivalente correctamente.</p>))
}

export const deshabilitarEquivalencia = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.DESHABILITADO)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se desactivo la relacion equivalente correctamente.</p>))
}

export const publicarEquivalencia = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.PUBLICADO)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se publicó la relacion equivalente correctamente.</p>))
}

export const eliminarEquivalencia = async (relacionId) => {
    await requestService.sendRequest("DELETE", apiURLs.API_TITULOS_EQUIVALENTE + `/${relacionId}`)
};