import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Terna from "../../dtos/terna/Terna";
import { Row, Col, Button, Container } from "react-bootstrap";
import TernasABMForm from "./TernasABMForm";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import IconTextButton from "../../generics/IconTextButton";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";

const TernaABMApproval = (props) => {
  let location = useLocation();
  const [terna, setTerna] = useState();
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleSubmit = async (user) => {
    dispatch(success("Se editó la terna con éxito."));
  };

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.terna) {
      let terna = location.state.terna;
      if (!(terna instanceof Terna)) {
        // TODO: Crear terna al recargar la página
      }
      setTerna(terna);
    }
  }, [dispatch, location.state]);

  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
    >
      <Row>
        <Col>
          <Row className="form-group">
            <Col md="12">
              <h1>Aprobar Terna
                {/* <IconTextButton
              className="btn-icon-rounded ml-1"
              icon={faPen}
              type="submit"
              variant="secondary"
              text={disabled ? "Modificar Terna" : "Cerrar Modificación"}
              onClick={() => setDisabled(!disabled)} >
            </IconTextButton> */}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <TernasABMForm
                typeOfAction={typeOfActions.APPROVAL}
                onSubmit={handleSubmit}
                terna={terna}
                disabled={disabled}
                returnPath={routePaths.ABM_TERNA_SEARCH}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};

export default TernaABMApproval;
