import React, { useState, useEffect } from "react";
import Usuario from "../../dtos/Usuario";
import UserABM from "./UserABM";
import { processEditUser } from "./UserABMService";
import { routePaths } from "../../utils/Constants"
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";

const UserABMEdit = (props) => {
  let location = useLocation();
  const [user, setUser] = useState();

  const dispatch = useDispatch();

  const handleSubmit = async (user) => {
    await processEditUser(user)
    dispatch(success("Se editó el usuario con éxito."));
  }

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.user) {
      let user = location.state.user;
      if (!(user instanceof Usuario)) {
        user = Object.assign(new Usuario(user.nombre), user);
      }
      setUser(user);
    }
  }, [dispatch, location.state]);

  return (
    <UserABM user={user} title="Editar Usuario" submitValue="Guardar" onSubmit={handleSubmit} returnPath={routePaths.ABM_USER_SEARCH}/>
  );
};

export default UserABMEdit;
