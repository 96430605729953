import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { error } from '../../../store/alerts/alertActions';
import Input from '../../../ui/Input';

import * as escuelaService from "../../../escuela/EscuelaService";
import * as especialidadesService from "../../../especialidades/EspecialidadesService";

export const RelacionesCargosForm = ({
    addRelacion,
    checkIsAvailableOption,
    hideInputs = {},
    withResetOnSubmit = true,
    filtros = {}
}) => {
    const [areasSeleccionadas, setAreasSeleccionadas] = useState("");
    const [tiposEscuelaSeleccionadas, setTiposEscuelaSeleccionadas] = useState("");
    const [especialidadesSeleccionadas, setEspecialidadesSeleccionadas] = useState("");

    const [isAvailable, setIsAvailable] = useState(false)

    const cleanRelatedFiltersRef = useRef(false)

    const dispatch = useDispatch();

    const resetInputsValues = () => {
        setAreasSeleccionadas("")
        !hideInputs.tipoEscuela && setTiposEscuelaSeleccionadas("")
        !hideInputs.especialidad && setEspecialidadesSeleccionadas("")
    }

    const isCompleted = () => {
        return !!areasSeleccionadas &&
            (hideInputs.tipoEscuela ? true : !!tiposEscuelaSeleccionadas) &&
            (hideInputs.especialidad ? true : !!especialidadesSeleccionadas)
    }

    const handleClickAgregar = () => {
        const transformSelectOptionToModel = (selectOption) => ({ id: selectOption.value, nombre: selectOption.label })
        const newElement = {
            area: transformSelectOptionToModel(areasSeleccionadas),
        };

        if (!hideInputs.especialidad) newElement.especialidad = transformSelectOptionToModel(especialidadesSeleccionadas)
        if (!hideInputs.tipoEscuela) newElement.tipoEscuela = transformSelectOptionToModel(tiposEscuelaSeleccionadas)

        if (checkIsAvailableOption(newElement)) {
            addRelacion(newElement)
            if (withResetOnSubmit) resetInputsValues();
        } else {
            dispatch(error("La variante ya existe"))
        }

        setIsAvailable(false)
    }

    useEffect(() => {
        cleanRelatedFiltersRef.current = true;
    }, [])

    useEffect(() => {
        setIsAvailable(isCompleted());
    }, [areasSeleccionadas, tiposEscuelaSeleccionadas, especialidadesSeleccionadas])

    return (
        <>
            <Row>
                <Col xs={12} md={8} lg={12}>
                    <Row>
                        <Col xs={12} md={6} lg={4}>
                            <Input
                                type="react-select"
                                controlId="area_id"
                                name="area_id"
                                label="Area"
                                value={areasSeleccionadas}
                                methodOnChange={() => { }}
                                elements={especialidadesService.getAreas}
                                nameElementsResponse="data"
                                propertiesForFormat={{ value: "id", label: "nombre" }}
                                setterElementSelect={setAreasSeleccionadas}
                                config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                                paramsFilter={filtros.area}
                            ></Input>
                        </Col>
                        {!hideInputs.tipoEscuela &&
                            <Col xs={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="tipoEscuela"
                                    name="tipoEscuela"
                                    label="Tipos de escuela"
                                    value={tiposEscuelaSeleccionadas}
                                    methodOnChange={() => { }}
                                    elements={escuelaService.getEscuelas}
                                    nameElementsResponse="data"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setTiposEscuelaSeleccionadas}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                                    disabled={!areasSeleccionadas}
                                    relatedFilters={{ area: areasSeleccionadas?.value }}
                                    cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                                    paramsFilter={{ area_busquedaAvanzada: false }}
                                    countCaracters={0}
                                ></Input>
                            </Col>
                        }
                        {!hideInputs.especialidad &&
                            <Col xs={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="especialidad"
                                    name="especialidad"
                                    label="Especialidad"
                                    value={especialidadesSeleccionadas}
                                    methodOnChange={() => { }}
                                    elements={especialidadesService.getEspecialidades}
                                    nameElementsResponse="data"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setEspecialidadesSeleccionadas}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                                    disabled={!areasSeleccionadas}
                                    relatedFilters={{ area: areasSeleccionadas?.value, }}
                                    cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                                    countCaracters={0}
                                    paramsFilter={{ all: true, area_busquedaAvanzada: false, creaAsignatura: true, }}
                                ></Input>
                            </Col>
                        }
                    </Row>
                </Col >
            </Row>

            <Row style={{ marginBottom: "40px", marginTop: "-20px" }}>
                <Col xs={12} md={8} lg={12}>
                    <Row>
                        <Col xs={12} md={6} lg={3}>
                            <Button
                                variant="primary"
                                content="Reporte"
                                type="submit"
                                onClick={handleClickAgregar}
                                disabled={!isAvailable}
                            >
                                Agregar
                            </Button>
                        </Col>
                    </Row >
                </Col>
            </Row >
        </>
    )
}

