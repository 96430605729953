import React from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import EscuelaSearchRow from "./EscuelaSearchRow";
import Paginacion from "../../generics/Paginacion";

const EscuelaSearchTable = ({ escuelas, cantidadEscuelas, page, changePage, ...props }) => {
  const renderTableRows = (escuelas) => {
    return escuelas.map((escuela) => {
      return (
        <EscuelaSearchRow
          key={escuela.id}
          escuela={escuela}
        ></EscuelaSearchRow>
      );
    });
  };

  return (
    <>
      {!escuelas[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron tipos de escuela.</Alert>
          </Col>
        </Row>
      ) : (
        !!escuelas && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nombre de la Escuela</th>
                      <th>Tipo de Escuela</th>
                      <th>Tipo de nivel</th>
                      <th className="max-width-lg-20">Área</th>
                      <th>Creación</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(escuelas)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadEscuelas} currentPage={page} onChangePage={changePage} />
          </>
        )
      )}
    </>
  );
};

export default EscuelaSearchTable;