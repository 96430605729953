import { success } from "../../store/alerts/alertActions";
import store from "../../store/store";
import { apiURLs, estadosConstans } from "../../utils/Constants";
import { adaptGtTitulosITFFromTerna, adapterGetTitulosITF, postTitulosITF_REQUEST } from "./ITFAdapter";
const { default: requestService } = require("../../utils/request.service");

export const getTitulosITF = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
    terna_id
    area_id
    page
    */

    // TODO VAMOS A CAMBIAR EL ENDPOINT DEL GET
    const uri = apiURLs.API_GET_TITULOS_ITF_CAMBIAR_ENDPOINT;
    let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
    return {
        elementos: adapterGetTitulosITF(response.data.elementos),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };
};

export const deleteTituloITF = async (params, isMountedRef) => {
    const uri = apiURLs.API_TITULOS_ITF;
    await requestService.sendRequest('DELETE', uri + "/" + params.id, {}, isMountedRef)
}

export const postTitulosITF = async (params = {}, isMountedRef) => {
    /* 
        params:
        {
            terna_original: 123,
            relacionados: [{terna_id, folio}]
        }
    */
    const uri = apiURLs.API_TITULOS_ITF;
    let response = await requestService.sendRequest('POST', uri, params, isMountedRef)

    return { ...response.data }; // {agregados:[], existentes:[]}
}


export const patchTitulosITF = async (params = {}, isMountedRef) => {
    /* 
        params:
        {
            terna_original: 123,
            relacionados: [{terna_id, folio}]
        }
    */
    const uri = apiURLs.API_TITULOS_ITF;
    let response = await requestService.sendRequest('PATCH', uri, params, isMountedRef)
    return { ...response.data }; // {agregados:[], existentes:[]}
}

export const getTitulosITFFromTerna = async (params = {}, isMountedRef) => {
    const uri = apiURLs.API_TITULOS_ITF_POR_TERNA;
    let response = await requestService.sendRequest('GET', uri, params, isMountedRef)

    return {
        elementos: adaptGtTitulosITFFromTerna(response.data.elementos),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };

}

const changeState = async (relacionId, newState) => {
    await requestService.sendRequest('PUT', apiURLs.API_TITULOS_ITF + `/${relacionId}/estado`, { estado: newState })
}

export const aprobarTituloITF = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.APROBADO)
    if (resolve) await resolve();
    await store.dispatch(success(<p>Se aprobo la relacion ITF correctamente.</p>))
}

export const habilitarTituloITF = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.BORRADOR)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se activo la relacion ITF correctamente.</p>))
}

export const deshabilitarTituloITF = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.DESHABILITADO)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se desactivo la relacion ITF correctamente.</p>))
}

export const publicarTituloITF = async (relacionId, resolve) => {
    await changeState(relacionId, estadosConstans.PUBLICADO)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se publicó la relacion ITF correctamente.</p>))
}
