import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import IconButton from "./IconButton";
import { CustomTooltip } from "../ui/CustomTooltip";

const Dialog = ({
  action,
  validation,
  target,
  title,
  body,
  variant,
  btnIcon,
  btnConfirmText,
  disableBtnConfirmText,
  btnText,
  btnVariant,
  children,
  size,
  isLoadingText,
  onShow,
  btnTooltipText,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mainButtonRef = useRef();

  const onAction = async () => {
    try {
      if(validation) validation()
      setIsLoading(true);
      const stayOpen = await action(target, setIsLoading);
      if (!stayOpen) handleClose();
    }
    catch (err) {
      console.log("err", err)
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    mainButtonRef.current?.blur();
    event.preventDefault();
    setShow(true)

  };

  useEffect(() => {
    if (onShow && show) {
      onShow(target, setIsLoading);
    }
  }, [show])

  return (
    <>
      {btnIcon && (
        btnTooltipText ?
          <CustomTooltip text={btnTooltipText}>
            <IconButton ref={mainButtonRef} icon={btnIcon} {...props} onClick={handleShow} variant={btnVariant ? btnVariant : "primary"} />
          </CustomTooltip>
          :
          <IconButton ref={mainButtonRef} icon={btnIcon} {...props} onClick={handleShow} variant={btnVariant ? btnVariant : "primary"} />
      )
      }
      {!btnIcon && btnText && <Button
        disabled={props.disabled}
        onClick={handleShow}
        ref={mainButtonRef}
        type="submit"
        variant={btnVariant ? btnVariant : "primary"}
        data-focus="false"
        {...props}
      >
        {btnText}
      </Button>}
      <Modal
        show={show}
        onHide={!isLoading ? handleClose : null}
        size={size ? size : "lg"}
        backdropClassName="dialog-backdrop-class"
        dialogClassName="dialog-modal-class"
        contentClassName="dialog-content-class"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={isLoading}
        backdrop={isLoading ? 'static' : true}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>{title ? title : "Atención!"}</Modal.Title>
        </Modal.Header>
        {body && <Modal.Body>
          {body}
        </Modal.Body>}
        <Modal.Footer>
          {
            isLoading ?
              <>
                {isLoadingText && <Button variant={variant} disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant={variant == "danger" ? "light" : "secondary"}
                  />
                  <span className="ml-1">{isLoadingText}</span>
                </Button>}
              </>
              :
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Volver
                </Button>
                {btnConfirmText && <Button variant={variant} onClick={onAction} disabled={disableBtnConfirmText} data-focus="false">
                  {btnConfirmText}
                </Button>}
              </>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dialog;
