import React, { useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import * as apendiceService from "../../apendice/ApendiceService"
import Input from '../../ui/Input'
import { handlerChange, isValidNumeric } from '../../utils/commons'

export const ClonadorForm = ({
    setWasModified,
    inputsForm,
    setInputsForm,
    validated,
    apendiceSeleccionado,
    setApendiceSeleccionado,
}) => {

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, setWasModified, e);
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    }

    return (
        <>
            <Row className="mx-auto position-relative zIndex10 pe-none">
                <Col md={6} lg={4} className="pe-all">
                    <Input
                        type="react-select"
                        controlId="apendice_id"
                        name="apendice_id"
                        label="Apéndice"
                        value={apendiceSeleccionado}
                        methodOnChange={handleChange}
                        elements={apendiceService.getApendices}
                        nameElementsResponse="elementos"
                        propertiesForFormat={{ value: "id", label: "nombreInput" }}
                        setterElementSelect={setApendiceSeleccionado}
                        config={{ isSearchable: true }}
                        predictivoAsync={false}
                        defaultIndexSelectedOptionInSyncSelect={"0"}
                    ></Input>
                </Col>
                <Col md={4} lg={2} className="pe-all">
                    <Input
                        type="input"
                        controlId="folio"
                        label="Folio"
                        maxLength="100"
                        value={inputsForm.folio ? inputsForm.folio : ""}
                        onChange={handleValidChange.bind(this, isValidNumeric)}
                        required
                    ></Input>
                </Col>
            </Row>
        </>
    )
}
