import React, { useState } from 'react'
import IconButton from '../../generics/IconButton'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../ui/Loader';
import { IncumbenciasDetailBodyCargos } from '../../incumbencias/details/cargos/IncumbenciasDetailBodyCargos';
import incumbenciasService from "../../incumbencias/IncumbenciasService"
import { useEffect } from 'react';
import Paginacion from '../../generics/Paginacion';
import Dialog from '../../generics/dialog';
import { agregarDescargas, OPCIONES_DESCARGAS } from '../../store/descargas/descargasActions.';
import { error } from '../../store/alerts/alertActions';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

export const DetailCruzado = ({ filtrosDefault, label, elemento }) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [incumbencias, setIncumbencias] = useState();
    const [cantidadIncumbencias, setCantidadIncumbencias] = useState();
    const [page, setPage] = useState()
    const [filtros, setFiltros] = useState(filtrosDefault)
    const filtrosGuardados = useSelector(st => st.search);

    const cantidadMaximaElementos = 100;
    const generarEnFormatoPDF = cantidadIncumbencias <= cantidadMaximaElementos

    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false)
    };

    const getIncumbenciasFrom = async (page) => {
        setPage(page)
        try {
            setIsLoading(true);
            const params = { ...filtros, page }
            filtros.page = page;
            if (filtrosGuardados.inputsForm.area_id) params.area_id = filtrosGuardados.inputsForm.area_id;
            if (filtrosGuardados.inputsForm.tipo_escuela_id) params.tipo_escuela_id = filtrosGuardados.inputsForm.tipo_escuela_id;
            if (params.asignatura_id == "null") delete params.asignatura_id

            setFiltros(params)
            const resp = await incumbenciasService.getIncumbencias(params);
            setCantidadIncumbencias(resp.cantidad);
            setIncumbencias(resp.elementos);
            setIsLoading(false);
        } catch (err) {
            dispatch(error("No se pudieron cargar las incumbencias"))
        }
    }

    const handleShow = async () => {
        setShow(true);
        if (!incumbencias) {
            getIncumbenciasFrom(1)
        }
    };

    const handleChangePage = (event, value) => {
        getIncumbenciasFrom(value)
    }

    const handleClickGenerar = async () => {
        const fileName = `${elemento.cargo.nombre}(${elemento.cargo.codDad})-${elemento.asignatura.nombre}-${elemento.especialidad.nombre}-${elemento.area.nombre}-vademecum`

        try {
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo / Detalle (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_CRUZADO_POR_AREA_VER_CALIDAD,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName,
            }]));

        } catch (err) {
            console.log(err.message)
        }
    }

    return (<>
        {label > 0 ?
            <>
                <button type="button" className="btn btn-link align-self-start" onClick={handleShow}>
                    {label}
                </button>
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-90w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                // keyboard={isLoading}
                // backdrop={isLoading ? 'static' : true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className="font-weight-light">Detalle:</span> {elemento.cargo.nombre} ({elemento.cargo.codDad}) |
                            {elemento.asignatura.nombre} | {elemento.especialidad.nombre} | {elemento.area.nombre}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {!isLoading && cantidadIncumbencias > 0 && <IncumbenciasDetailBodyCargos incumbencias={incumbencias} />}

                        {isLoading && <Loader></Loader>}

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 d-flex flex-row-reverse justify-content-between align-items-center position-relative">
                            {cantidadIncumbencias > 0 &&
                                <div className="w-100 d-flex justify-content-center position-absolute pe-none">
                                    <Paginacion
                                        totalItems={cantidadIncumbencias}
                                        currentPage={Number(page)}
                                        onChangePage={handleChangePage}
                                    />
                                </div>
                            }

                            <Button variant="secondary" onClick={handleClose}>
                                Volver
                            </Button>

                            {!isLoading && generarEnFormatoPDF &&
                                <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                                    <Dialog
                                        preventFocus
                                        action={handleClickGenerar}
                                        title={"Informe Total"}
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Generar"
                                        btnVariant="primary"
                                        btnText="Informe Total"
                                        isLoadingText={"Generando informe"}
                                        body={<span>
                                            ¿Estás seguro de generar el informe?
                                        </span>}
                                    />
                                </PrivateComponent>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <p className="informes__not-result-label">
                {label}
            </p>
        }
    </>)
}
