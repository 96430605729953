import React, { useEffect, useState } from 'react';
import { Alert, Badge, Col, Row, Table } from "react-bootstrap";
import { TrEnConcurrenciaCon } from '../ternas/TrEnConcurrenciaCon';
import { IncumbenciasDetailCargoRowActions } from './IncumbenciasDetailCargoRowActions';

export const IncumbenciasDetailBodyCargos = ({
    incumbencias,
    setSuccessMsg,
    resolveCallBack,
    setErrorMsg,
    clearErrorMsg,
}) => {
    
    return (
        <>
            <Row className="form-group">
                <Col>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Folio</th>
                                <th>Apendice</th>
                                <th>Tipo escuela</th>
                                <th>Nombre del titulo</th>
                                <th>Procedencia</th>
                                <th>Resolucion</th>
                                <th>Condicion</th>
                                <th>Puntaje</th>
                                <th>Suma OT</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {incumbencias.map(currentIncumbencia => <>
                                <tr key={currentIncumbencia.id}>
                                    <td>{currentIncumbencia.folio}</td>
                                    <td>{currentIncumbencia.apendiceNombre}</td>
                                    <td>{currentIncumbencia.tipoEscuela?.nombre ?? "-"}</td>
                                    <td>{currentIncumbencia.terna?.titulo?.nombre}</td>
                                    <td>{currentIncumbencia.terna?.procedencia?.nombre}</td>
                                    <td>{currentIncumbencia.terna?.resolucion?.nombre}</td>
                                    <td>{currentIncumbencia.calidad}</td>
                                    <td>{currentIncumbencia.puntaje || "-"}</td>
                                    <td>{currentIncumbencia.sumaOT ? "SI" : "NO"}</td>
                                    <td>
                                        {
                                            <Badge variant={currentIncumbencia.estado.color}>
                                                {currentIncumbencia.estado.nombre}
                                            </Badge>
                                        }
                                    </td>
                                    <td>
                                        <div className="table-button-group">
                                            <IncumbenciasDetailCargoRowActions
                                                incumbencia={currentIncumbencia}
                                                setErrorMsg={setErrorMsg}
                                                clearErrorMsg={clearErrorMsg}
                                                setSuccessMsg={setSuccessMsg}
                                                resolveCallBack={resolveCallBack}
                                            ></IncumbenciasDetailCargoRowActions>
                                        </div>
                                    </td>
                                </tr>

                                {currentIncumbencia.terna?.regla &&
                                    <tr style={{ border: "none" }}>
                                        <td className="border-0 pt-1" colSpan={10}><p style={{ marginBottom: "0.4rem" }}><b>Regla: </b> {currentIncumbencia.terna.regla}</p></td>
                                    </tr>
                                }

                                {
                                    currentIncumbencia.concurrencia &&
                                    <TrEnConcurrenciaCon data={currentIncumbencia} colSpan={10} />
                                }
                            </>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};


