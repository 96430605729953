import axios from "axios";
import { apiURLs, routePaths } from "../../utils/Constants";
import Usuario from "../../dtos/Usuario";
import authHeader from "../../utils/auth-header";
import requestService from "../../utils/request.service";

const processCreateUser = async (props) => {
    let user = createUser(props);
    return persistUser(user);
};

const processEditUser = async (user) => {
   return editUser(user);
};

const createUser = (props) => {
  let usuario = new Usuario(props.username);
  usuario.nombre = props.nombre;
  usuario.apellido = props.apellido;
  usuario.tipo_documento = props.tipoDocumento;
  usuario.documento = props.documento;
  usuario.sexo = props.sexo;
  usuario.rol = props.rol;
  return usuario;
};

const persistUser = async (user) => {
  return axios.post(apiURLs.API_ADMIN_USUARIOS_URL, user, { headers: authHeader() })
};

const editUser = async ({ rol, ...user }) => {
  rol = rol.map((r) => r.id);
  user["rol"] = rol;
  return requestService.sendRequest("PATCH", apiURLs.API_ADMIN_USUARIOS_URL + `/${user.id}`, user)
};

const changeUserState = async (user) => {
  const response = await requestService.sendRequest(
    "PATCH",
    apiURLs.API_ADMIN_USUARIOS_URL + `/${user.id}`,
    { activo: !user.activo }
  );
  return response.data;
};

export { processCreateUser, processEditUser, changeUserState };
