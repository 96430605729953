import React, { useEffect, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Alert } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";
import IconButton from "../../../generics/IconButton";
import incumbenciasService from "../../IncumbenciasService";
import { IncumbenciasDetailFormVariantes } from "./IncumbenciasDetailFormVariantes";
import { incumbenciasConstants } from "../../../utils/Constants";
import { Loader } from "../../../ui/Loader"
import { IncumbenciasDetailBodyCargos } from "./IncumbenciasDetailBodyCargos";
import { useDispatch } from "react-redux";
import { error } from "../../../store/alerts/alertActions";

export const IncumbenciasModalSearchInfoCargo = ({
    cargo,
    withIconSearch = false,
    buttonText,
    ...props
}) => {
    const [inputsForm, setInputsForm] = useState({})
    const [show, setShow] = useState(false);
    const [cantidadIncumbencias, setCantidadIncumbencias] = useState();
    const [incumbencias, setIncumbencias] = useState([]);
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);
    const [showFilterInputs, setShowFilterInputs] = useState(false);
    const [resolveMessage, setResolveMessage] = useState();

    const [inputsLabelsForm, setInputsLabelsForm] = useState({})

    const dispatch = useDispatch()

    const setErrorMsg = (children) => {
        setResolveMessage({ type: "warning", children })
    }
    const setSuccessMsg = (children) => {
        setResolveMessage({ type: "success", children })
    }
    const clearErrorMsg = () => {
        setResolveMessage("")
    }

    const resetDefaultValues = () => {
        setInputsForm({});
        setCantidadIncumbencias(null);
        setIncumbencias([]);
        setPage(1);
        setSearching(false);
        setShowFilterInputs(false);
    }

    const handleClose = () => {
        setShow(false);
        resetDefaultValues();
        clearErrorMsg();
    };

    const handleShow = () => {
        setShow(true);
    };

    const searchIncumbencias = async (payload) => {
        setInputsForm(payload);
        setSearching(true);
        const resp = await incumbenciasService.getIncumbencias(payload);
        if (show) {
            setIncumbencias(resp.elementos)
            setCantidadIncumbencias(resp.cantidad);
            setPage(Number(resp.page));
            setSearching(false);
        }
    }

    const changePage = async (event, value) => {
        setPage(value)
        const formatedPayload = {
            ...inputsForm,
            page: value
        }
        await searchIncumbencias(formatedPayload);
    }

    const recargarIncumbencias = async () => {
        await searchIncumbencias(inputsForm);
    }

    const loadInfoFromCargo = async (cargo) => {
        // try {
        setSearching(true);
        const filtros = {
            cargo_id: cargo.id,
            asignatura_id: cargo.unica_variante.asignatura.id,
            especialidad_id: cargo.unica_variante.especialidad.id,
            area_id: cargo.unica_variante.area.id,
            tipo_escuela_id: cargo.unica_variante.tipoEscuela.id,
            page: 1,
            limit: 10,
        }

        setInputsLabelsForm({
            asignatura: cargo.unica_variante.asignatura.nombre,
            especialidad: cargo.unica_variante.especialidad.nombre,
            area: cargo.unica_variante.area.nombre,
        })

        await searchIncumbencias(filtros)
        // }
        // catch (err) {
        //     dispatch(error("Ha ocurrido un error al cargar las incumbencias del cargo"))
        // }
        // finally {
        setSearching(false);
        // }
    }

    const handleSelectVariante = async (callback) => {
        // try {
        const arr = callback();
        const combinacionSeleccionada = arr[0]

        setSearching(true);
        console.log({cargo})
        const filtros = {
            cargo_id: cargo.id,
            asignatura_id: combinacionSeleccionada.asignatura?.id,
            especialidad_id: combinacionSeleccionada.especialidad?.id,
            area_id: combinacionSeleccionada.area?.id,
            page: 1,
            limit: 10,
        }

        await searchIncumbencias(filtros)
        // }
        // catch (err) {
        //     dispatch(error("Ha ocurrido un error al cargar las incumbencias del cargo"))
        // }
        // finally {
        setSearching(false);
        // }
    }

    useEffect(() => {
        if (show) {
            setCantidadIncumbencias(null);
            if (cargo.permite_variante) {
                console.log("TIENE MUCHAS VARIANTES")
                setShowFilterInputs(true);
                setSearching(false);
            } else {
                console.log("TIENE UNICA VARIANTE")
                loadInfoFromCargo(cargo);
            }
        }
    }, [show])

    return (
        <>
            {buttonText ?
                <Button
                    onClick={handleShow}
                    variant={"secondary"}
                >
                    {buttonText}
                </Button>
                :
                <IconButton {...props} icon={withIconSearch && faSearch} onClick={handleShow} />
            }

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            incumbencias ?
                                <>
                                    <span className="font-weight-light">Incumbencias del cargo:</span> {cargo.nombre}
                                    {inputsLabelsForm.area ? <>| {inputsLabelsForm.area} </> : ""}
                                    {inputsLabelsForm.asignatura ? <>| {inputsLabelsForm.asignatura} </> : ""}
                                    {inputsLabelsForm.especialidad ? <>| {inputsLabelsForm.especialidad} </> : ""}
                                </>
                                :
                                <>
                                    <span className="font-weight-light">Incumbencias del cargo:</span> {cargo.nombre}
                                </>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(resolveMessage &&
                        <Alert
                            variant={resolveMessage.type}
                            dismissible
                            onClose={() => setResolveMessage(null)}
                        >{
                                resolveMessage.children}
                        </Alert>
                    )}

                    {showFilterInputs &&
                        <IncumbenciasDetailFormVariantes
                            cargo={cargo}
                            elements={incumbencias}
                            setterFunction={handleSelectVariante}
                            mainElement={incumbenciasConstants.CARGOS_INCUMBENCIA}
                            actionButtonLabel={"Cargar"}
                            checkIsAvailableValidate={false}
                            hideTipoEscuelaFilter={true}
                        />
                    }

                    {!searching && (cantidadIncumbencias >= 0) && showFilterInputs && <hr></hr>}

                    {(!searching && cantidadIncumbencias == 0) &&
                        <Alert variant="info">Este cargo no tiene incumbencias asignadas.</Alert>
                    }

                    {(!searching && cantidadIncumbencias > 0) &&
                        <IncumbenciasDetailBodyCargos
                            incumbencias={incumbencias}
                            resolveCallBack={recargarIncumbencias}
                            setSuccessMsg={setSuccessMsg}
                            setErrorMsg={setErrorMsg}
                            clearErrorMsg={clearErrorMsg}
                        />
                    }

                    {searching && <Loader></Loader>}

                </Modal.Body>

                <Modal.Footer>
                    <div className="w-100 d-flex flex-row-reverse justify-content-between align-items-center position-relative">
                        <Button variant="secondary" onClick={handleClose}>
                            Volver
                        </Button>

                        {(cantidadIncumbencias > 0) &&
                            <div className="w-100 d-flex justify-content-center position-absolute pe-none">
                                <Paginacion className={"mb-0"} totalItems={cantidadIncumbencias} currentPage={page} onChangePage={changePage} />
                            </div>
                        }
                        <Button
                            variant="primary"
                            content="Reporte"
                            type="submit"
                            disabled
                            className
                        >
                            Informe Total
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}