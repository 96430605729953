import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { DetailFact } from '../../../ui/DetailFact'

export const DetailTerna = ({ terna }) => {
    console.log({ DetailTerna: terna })
    return (
        <div>
            <>
                <b>Terna:</b> {terna.titulo.nombre} | {terna.procedencia.nombre} | {terna.resolucion.nombre}
            </>
        </div>
    )
}
