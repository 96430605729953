import React from "react";
import { Badge } from "react-bootstrap";
import Input from "../../../ui/Input";
import { tooltipsText } from "../../../utils/Constants";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import TernaDetailModal from "../../../ternas/details/TernaDetailModal";

export const EquivalenciaSelectRow = ({
  titulo,
  onChangeSeleccionado,
  seleccionados,
  visibleCheckbox = false,
  visibleRadio = false,
  visibleInputs = false,
}) => {
  return (
    <>
      <tr key={titulo.id}>
        {visibleCheckbox && (
          <td>
            <div key={titulo.id} className="form-checkbox-container">
              <Input
                type="checkbox"
                name="titulo"
                value={titulo.id}
                onChange={onChangeSeleccionado.bind(this, titulo)}
                defaultChecked={seleccionados.some(seleccionado => seleccionado.id == titulo.id)}
              ></Input>
            </div>
          </td>
        )}
        {visibleRadio && (
          <td>
            <div key={titulo.id} className="form-checkbox-container">
              <Input
                type="radio"
                name="titulo"
                className="option-row"
                value={titulo.id}
                onChange={onChangeSeleccionado.bind(this, titulo)}
                defaultChecked={false}
              ></Input>
            </div>
          </td>
        )}
        <td>{titulo.titulo.nombre}</td>
        <td>{titulo.procedencia.nombre}</td>
        <td>{titulo.resolucion.nombre}</td>
        <td>{titulo.titulo.nivel.nombre}</td>
        <td>{titulo.createdAt}</td>
        <td>
          <Badge variant={titulo.estado ? titulo.estado.color : "primary"}>
            {" "}
            {titulo?.estado?.nombre}
          </Badge>
        </td>
        <td>
          <div className="table-button-group">
            <CustomTooltip text={tooltipsText.DETALLE}>
              <TernaDetailModal terna={titulo}></TernaDetailModal>
            </CustomTooltip>
          </div>
        </td>
      </tr>
    </>
  );
};