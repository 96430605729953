import React, { useState } from "react";
import IconButton from "../../generics/IconButton";
import { faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Spinner } from "react-bootstrap";
import EscuelaDetailBody from "../details/EscuelaDetailBody";
import { publish } from "../EscuelaService";
import { error } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";

const EscuelaDetailModal = ({ escuela, withPublicar = false, ...props }) => {
  const [show, setShow] = useState(false);
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handlePublish = async (escuela, resolve) => {
    try {
      setIsFetching(true)
      await publish(escuela);
      handleClose();
      resolve();
    }
    catch (err) {
      dispatch(error(err.response.data.message));
    }
    finally {
      setIsFetching(false)
    }


  };

  return (
    <>
      <IconButton {...props} icon={withPublicar ? faUpload : faSearch} onClick={handleShow} >
        {props.children}
      </IconButton>

      <Modal
        show={show}
        onHide={!isFetching ? handleClose : null}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={isFetching}
        backdrop={isFetching ? 'static' : true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="font-weight-light">Detalle:</span> {escuela.nombre}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EscuelaDetailBody escuela={escuela}></EscuelaDetailBody>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={isFetching} onClick={handleClose}>
            Volver
          </Button>
          {withPublicar &&
            <Button variant="primary" disabled={isFetching} onClick={() => { handlePublish(escuela, props.onSubmit) }}>
              {
                isFetching ?
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant={"secondary"}
                    />
                    <span className="ml-1">Publicando</span>
                  </>
                  :
                  "Publicar"
              }
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EscuelaDetailModal