import React, { useEffect } from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import Paginacion from "../../generics/Paginacion";
import { incumbenciasConstants, typeOfActions } from "../../utils/Constants";

export const IncumbenciasABMTable = ({
    elementos,
    cantidadElementos,
    page,
    changePage,
    renderTableRows,
    tipo,
    showCheckboxCol = true,
    showCondicionCol = true,
    showFolioCol = true,
    showApendiceCol = true,
    showPaginacion = true,
    showActionsCol = true,
    showCantidadIncumbencias,
    hideTipoEscuela,
    hideColumns = {},
}) => {

    return (
        <>
            <Row>
                <Col>
                    <Table responsive>
                        {(
                            tipo == incumbenciasConstants.TERNAS_INCUMBENCIA
                            ||
                            tipo == incumbenciasConstants.TERNAS_CONCURRENCIA
                        ) &&

                            <thead>
                                <tr>
                                    {showCheckboxCol && <th></th>}
                                    {showApendiceCol && <th>Apendice</th>}
                                    {showFolioCol && <th>Folio</th>}
                                    <th>Id</th>
                                    <th>Nombre de título</th>
                                    <th>Procedencia</th>
                                    <th>Resolucion</th>
                                    {showCondicionCol && <th>Condicion</th>}
                                    {!hideColumns.estado && <th>Estado</th>}
                                    {showActionsCol && <th></th>}
                                </tr>

                            </thead>
                        }
                        {(
                            tipo == incumbenciasConstants.CARGOS_INCUMBENCIA
                            ||
                            tipo == incumbenciasConstants.CARGOS_CONCURRENCIA
                        ) &&
                            <thead>
                                <tr>
                                    {showCheckboxCol && <th></th>}
                                    <th>CodDad</th>
                                    <th>Nombre de cargo</th>
                                    {showApendiceCol && <th>Apendice</th>}
                                    {showFolioCol && <th>Folio</th>}
                                    <th>Area</th>
                                    {!hideTipoEscuela && <th>Tipo de escuela</th>}
                                    <th>CodDad Asig</th>
                                    <th>Asignatura</th>
                                    <th>CodDad Esp</th>
                                    <th>Especialidad</th>
                                    {showCondicionCol && <th>Condicion</th>}
                                    {!hideColumns.estado && <th>Estado</th>}
                                    {showCantidadIncumbencias && <th>Incumbencias</th>}
                                    {showActionsCol && <th></th>}
                                </tr>
                            </thead>
                        }
                        <tbody>{renderTableRows(elementos)}</tbody>
                    </Table>
                </Col>
            </Row>

            {showPaginacion && (
                <Paginacion
                    totalItems={cantidadElementos}
                    currentPage={page}
                    onChangePage={(event, value) => {
                        changePage(event, value);
                    }}
                />
            )}
        </>
    );
};

