import { success } from "../store/alerts/alertActions";
import { addData } from "../store/data/dataActions";
import store from "../store/store";
import { apiURLs } from "../utils/Constants";
import requestService from "../utils/request.service";
import { ticketsAdapter } from "./TicketsAdapter";

const getTicket = async (id, isMountedRef) => {
    const response = await requestService.sendRequest('GET', apiURLs.API_TICKETS + "/ticket/" + id, {}, isMountedRef)
    return ticketsAdapter.adaptGetTicket(response.data);
}

const getTickets = async (filtros, isMountedRef) => {
    const response = await requestService.sendRequest('GET', apiURLs.API_TICKETS, filtros, isMountedRef)
    return {
        cantidad: response.data.cantidad,
        elementos: ticketsAdapter.adaptGetTickets(response.data.elementos),
        page: response.data.page,
    };
}

const createTicket = async (params, resolve) => {
    const response = await requestService.sendRequest('POST', apiURLs.API_TICKETS, params)
    if (resolve) resolve();
    return response;
}

const editTicket = async (params, resolve) => {
    const response = await requestService.sendRequest('PATCH', apiURLs.API_TICKETS + "/ticket/" + params.id, params)
    if (resolve) resolve();
    return response;
}

const getTipoTickets = async () => {
    const response = await requestService.sendRequest('GET', apiURLs.API_TIPO_TICKETS)
    const elementos = ticketsAdapter.adaptGetTipoTickets(response.data.elementos);
    store.dispatch(addData({ tipoTickets: elementos }));
    return elementos
}

const getModulosTickets = async () => {
    const response = await requestService.sendRequest('GET', apiURLs.API_TICKETS_MODULOS)
    const elementos = ticketsAdapter.adaptGetModulosTickets(response.data.elementos);
    store.dispatch(addData({ modulosTickets: elementos }));
    return elementos
}

const closeTicket = async (params, isMountedRef ) => {
    const response = await requestService.sendRequest('PATCH', apiURLs.API_TICKETS + "/ticket/" + params.id + "/cerrar", params, isMountedRef)
    return response;
}

export const ticketsService = {
    getTicket,
    getTickets,
    closeTicket,
    createTicket,
    editTicket,
    getModulosTickets,
    getTipoTickets,
}