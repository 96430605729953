import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../ui/Input'
import { handlerChange } from '../../utils/commons'
import * as apendiceService from "../../apendice/ApendiceService"
import InputDatePicker from '../../generics/InputDatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { error } from '../../store/alerts/alertActions'
import { saveFilters } from '../../store/search/searchActions'
import { cleanFab, searchFab } from '../../store/screen/screenActions'
import CheckBoxEstados from '../../generics/CheckBoxEstados'
import userService from '../../utils/user.service'
import { useParams } from 'react-router-dom'
import { routePaths, ticketsConstants } from '../../utils/Constants'

export const TicketsFormSearch = ({ onSubmit, resetTable }) => {
  const { typeOfSearch } = useParams();

  const defaultInputsValues = {
    estado_id: ticketsConstants[typeOfSearch?.toUpperCase()]?.id,
    apendice: "",
    modulo: "",
    tipoTicket: "",
    fechaInicio: null,
    fechaModificacion: null,
    fechaCierre: null,
  }

  const [inputsForm, setInputsForm] = useState(defaultInputsValues);
  const [apendiceSeleccionado, setApendiceSeleccionado] = useState("");
  const [analistaSeleccionado, setAnalistaSeleccionado] = useState();

  const { tipoTickets, modulosTickets } = useSelector(st => st.data)
  const screenState = useSelector(st => st.screen);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e);
  };

  const handleChangeApendice = (event) => {
    const apendiceNombre = event.target.label.includes(" (actual)") ? event.target.label.replace(" (actual)", "") : event.target.label
    const customEvent = {
      target: {
        id: event.target.id,
        value: apendiceNombre,
      }
    };

    handleChange(customEvent);
  };

  const handleChangeSync = (event) => {
    const customEvent = {
      target: {
        id: event.target.id,
        value: event.target.value ? { value: event.target.value, label: event.target.label } : "",
      }
    };
    handleChange(customEvent);
  }

  const handleChangeDate = (date, controlId) => {
    const customEvent = {
      target: {
        id: controlId,
        value: date,
      }
    }
    handlerChange(setInputsForm, inputsForm, null, customEvent);
  }

  const getUsuariosRolesAnalista = async () => {
    return await userService.getUsers({ rol: [{ id: 1 }] })
  }

  const processSubmit = async () => {
    //Para el paginado
    let filtros = {
      ...inputsForm,
      apendice: inputsForm.apendice,
      model_id: inputsForm.modulo?.value ?? "",
      tipo_id: inputsForm.tipoTicket?.value ?? "",
      page: 1
    }

    // cargarInputsFormConSeleccionados();
    dispatch(saveFilters(filtros));

    delete filtros.apendice_id;
    delete filtros.modulo;
    delete filtros.tipoTicket;

    await onSubmit(filtros).catch((err) => {
      dispatch(error(err.response.data.message));
    });
  };

  const resetForm = () => {
    setInputsForm(defaultInputsValues);
    resetTable();
    setApendiceSeleccionado("");
    setAnalistaSeleccionado("");
  }

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])


  return (
    <Form
      noValidate
      onSubmit={e => e.preventDefault()}
    >
      <Row>
        <Col md={5} lg={3}>
          <Input
            type="react-select"
            controlId="apendice"
            name="apendice"
            label="Apéndice"
            value={apendiceSeleccionado}
            methodOnChange={handleChangeApendice}
            elements={apendiceService.getApendices}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombreInput" }}
            setterElementSelect={setApendiceSeleccionado}
            config={{ isSearchable: true, isClearable: true }}
            predictivoAsync={false}
          ></Input>
        </Col>
        <Col md={3} lg={3}>
          <Input
            type="react-select-sync"
            controlId="modulo"
            name="modulo"
            label="Modulo"
            value={inputsForm.modulo}
            methodOnChange={handleChangeSync}
            syncElements={modulosTickets ?? []}
            config={{ isSearchable: true, isClearable: true }}
          ></Input>
        </Col>
        <Col md={3} lg={3}>
          <Input
            type="react-select-sync"
            controlId="tipoTicket"
            name="tipoTicket"
            label="Tipo ticket"
            value={inputsForm.tipoTicket}
            methodOnChange={handleChangeSync}
            syncElements={tipoTickets ?? []}
            config={{ isSearchable: true, isClearable: true }}
          ></Input>
        </Col>
        <Col sm={6} md={4} lg={3}>
          <Input
            type="react-select"
            controlId="assigned_to"
            name="assigned_to"
            label="Analista asignado"
            value={analistaSeleccionado}
            methodOnChange={handleChange}
            elements={getUsuariosRolesAnalista}
            nameElementsResponse="data"
            propertiesForFormat={{ value: "id", label: "nombreCompleto" }}
            setterElementSelect={setAnalistaSeleccionado}
            config={{ isSearchable: true, isClearable: true }}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <InputDatePicker
            label={"Fecha de Inicio desde"}
            selected={inputsForm.fecha_inicio_desde}
            onChange={date => handleChangeDate(date, "fecha_inicio_desde")}
          />
        </Col>
        <Col sm={6} md={4} lg={3}>
          <InputDatePicker
            label={"Fecha de Inicio hasta"}
            selected={inputsForm.fecha_inicio_hasta}
            onChange={date => handleChangeDate(date, "fecha_inicio_hasta")}
          />
        </Col>
        {inputsForm.estado_id == ticketsConstants.RESUELTOS.id ?
          <>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Fecha de finalizacion desde"}
                selected={inputsForm.fecha_fin_desde}
                onChange={date => handleChangeDate(date, "fecha_fin_desde")}
              />
            </Col>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Fecha de finalizacion hasta"}
                selected={inputsForm.fecha_fin_hasta}
                onChange={date => handleChangeDate(date, "fecha_fin_hasta")}
              />
            </Col>
          </>
          :
          <>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Ultima modificacion desde"}
                selected={inputsForm.fecha_actualizacion_desde}
                onChange={date => handleChangeDate(date, "fecha_actualizacion_desde")}
              />
            </Col>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Ultima modificacion hasta"}
                selected={inputsForm.fecha_actualizacion_hasta}
                onChange={date => handleChangeDate(date, "fecha_actualizacion_hasta")}
              />
            </Col>
          </>
        }
      </Row>
    </Form >
  )
}
