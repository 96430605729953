import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clear } from "../../../store/alerts/alertActions";
import { routePaths } from "../../../utils/Constants";
import TitulosSearch from "../../shared/TitulosSearch";
import { getTitulosITF } from "../ITFService";
import { ITFTable } from "./ITFTable";
import { ITFSearchRow } from "./ITFSearchRow";

export const ITFSearch = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clear());
    }, [dispatch]);

    const controlIds = {
        titulo: "titulo",
        procedencia: "procedencia",
        resolucion: "resolucion",
    };

    const renderRow = (titulo, handleSearch) => {
        return (
            <ITFSearchRow
                key={titulo}
                onSubmit={handleSearch}
                titulo={titulo}
            />
        );
    };

    const renderTable = (
        titulos,
        cantidadTitulos,
        page,
        changePage,
        renderTableRows
    ) => {
        return (
            <ITFTable
                titulos={titulos}
                cantidadTitulos={cantidadTitulos}
                page={page}
                changePage={changePage}
                renderTableRows={renderTableRows}
            />
        );
    };

    return (
        <TitulosSearch
            title="Títulos ITF"
            renderRow={renderRow}
            renderTable={renderTable}
            getTitulos={getTitulosITF}
            controlIds={controlIds}
            nombreElementoResponse="elementos"
            urlCreate={routePaths.ABM_TITULOS_ITF_CREATE}
            returnCreate={location.pathname}
        />
    );
};