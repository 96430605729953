import { typesConstants } from "../../utils/Constants";

export const setMessage = (message) => ({
  type: typesConstants.SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: typesConstants.CLEAR_MESSAGE,
});
