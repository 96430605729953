import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../ui/Input'
import { handlerChange, isValidNumeric } from '../../utils/commons';
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { validateReactInputs } from '../../helpers/validations';
import * as apendiceService from "../../apendice/ApendiceService"
// GENERAR ENDPOINT: informes/topologico?apendice=12&folio=12&area_id=12
// GENERAR PDF: /informes/getPdfTopologico?apendice_id=1&folio=1

export const FormSearchTopologico = ({
    inputsForm = {},
    setInputsForm,
    resetForm,
    searchInfoTopologico,
}) => {
    const [areaSeleccionada, setAreaSeleccionada] = useState("");
    const [apendiceSeleccionado, setApendiceSeleccionado] = useState("");

    const [validated, setValidated] = useState(false);

    const formRef = useRef();

    const reactSelectsInputs = [
        { name: "areaSeleccionada", state: areaSeleccionada, setter: setAreaSeleccionada, required: false },
        { name: "apendiceSeleccionado", state: apendiceSeleccionado, setter: setApendiceSeleccionado, required: true },
    ]

    const resetAllReactSelectState = () => {
        reactSelectsInputs.forEach(currentReactSelect => currentReactSelect.setter(""))
    }

    const validateRequiredReactSelects = () => {
        const isFormValid = reactSelectsInputs.every(ctSelect => {
            if (ctSelect.required) {
                return (!!ctSelect.state)
            }
            return (true)
        })
        return isFormValid;
    }

    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handlerChange(setInputsForm, inputsForm, null, e);
        }
    };

    const processSubmit = () => {
        searchInfoTopologico(inputsForm);
    }

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            resetAllReactSelectState();
            setValidated(false);
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            if (!formRef.current.checkValidity() === false) {
                if (validateRequiredReactSelects()) {
                    processSubmit();
                }
            }
            setValidated(true);

            dispatch(searchFab(false));
        }
    }, [screenState])

    return (
        <Form
            ref={formRef}
            id="formSearchCargos"
            noValidate
            validated={validated}
            onSubmit={(e) => e.preventDefault()}
            className="container mx-auto"
        >
            <Row className="container">
                <Col md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="apendice"
                        name="apendice"
                        label="Apéndice"
                        value={apendiceSeleccionado}
                        methodOnChange={handleChange}
                        elements={apendiceService.getApendices}
                        nameElementsResponse="elementos"
                        propertiesForFormat={{ value: "id", label: "nombreInput" }}
                        setterElementSelect={setApendiceSeleccionado}
                        config={{ isSearchable: true }}
                        validated={validated}
                        required
                        predictivoAsync={false}
                    ></Input>
                </Col>
                <Col sm={12} md={6} lg={2}>
                    <Input
                        type="input"
                        controlId="folio"
                        label="N° de folio"
                        value={inputsForm.folio ? inputsForm.folio : ""}
                        onChange={handleValidChange.bind(this, isValidNumeric)}
                    ></Input>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="area_id"
                        name="area_id"
                        label="Área"
                        value={areaSeleccionada}
                        methodOnChange={handleChange}
                        elements={especialidadesService.getAreas}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setAreaSeleccionada}
                        config={{ isSearchable: true, isClearable: true }}
                        validated={validated}
                    ></Input>
                </Col>
            </Row>
        </Form>
    )
}
