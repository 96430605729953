import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Col, Container, Image, Row } from "react-bootstrap";

import "./App.css";

import { logout } from "./store/auth/authActions";
import { clearMessage } from "./store/messages/messageActions";
import { history } from './helpers/history';
import store from "./store/store";

import LoginByGoogle from './authentication/LoginByGoogle'
import Home from "./HomeScreen";

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../src/images/ba_logo.png'

import PrivateRoute from './authentication/PrivateRoute'
import UserApp from './UserApp'

import logoFooter from "./images/logo-footer.svg";
import logoFooterCiudad from "./images/logo-bsas-ciudad.svg";
import iconFacebook from "./images/social-icons/bxl-facebook.svg";
import iconTwitter from "./images/social-icons/bxl-twitter.svg";
import iconInstagram from "./images/social-icons/bxl-instagram.svg";
import iconYoutube from "./images/social-icons/youtube.svg";
import iconWhatsapp from "./images/social-icons/bxl-whatsapp.svg";
import { getCargos } from "./cargos/CargosService";
import { clear } from "./store/alerts/alertActions";

const App = (props) => {
  const [state, setState] = useState({
    currentUser: undefined,
  });
  const alert = useSelector((state) => state.alert);

  const { currentUser } = state;

  const logOut = () => { store.dispatch(logout()); };
  let logOutBinded = logOut.bind(this);

  const pathnameRef = useRef()

  useEffect(() => {
    const user = props.user;
    if (user) {
      setState({
        currentUser: user,
      });
    }
  }, [props.user]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (pathnameRef.current != history?.location?.pathname) {
        pathnameRef.current = history?.location?.pathname
        store.dispatch(clear());
      }
    });
    return unlisten
  }, [history])

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login">

          <Navbar expand="md">
            <Container>
              <Navbar.Brand href={"/"}>
                <img src={logo} alt="Anexo títulos" />
                <h5>Anexo títulos</h5>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Nav>
                  <div className="nav-userContent">
                    <Nav.Link>
                      <LoginByGoogle></LoginByGoogle>
                    </Nav.Link>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="col-fluid mt-3 d-flex flex-column flex-grow-1 position-relative">
            <Row className="container mx-auto">
              <Col>
                {alert.show && <Alert variant={alert.type}>{alert.message}</Alert>}
              </Col>
            </Row>
          </div>
        </Route>
        <PrivateRoute path="/">
          <UserApp logOut={logOutBinded} nameAndSurName={currentUser != null ? currentUser.nombre + " " + currentUser.apellido : ""}></UserApp>
        </PrivateRoute>
        <Redirect to='/' />
      </Switch>

      <footer className="footer container-fluid">

        <Container>
          <Col className='bottom-divisor'>
            <Row>
              <Image src={logoFooter} alt="Logo Vamos Buenos Aires" className="logo-footer" />

              <div className='icon-container'>
                <a href={window.Configs.LINK_RED_SOCIAL_FACEBOOK} target="_blank" rel="noreferrer">
                  <Image src={iconFacebook} alt="Facebook" className="social-icon" />
                </a>

                <a href={window.Configs.LINK_RED_SOCIAL_TWITTER} target="_blank" rel="noreferrer">
                  <Image src={iconTwitter} alt="Twitter" className="social-icon" />
                </a>

                <a href={window.Configs.LINK_RED_SOCIAL_INSTAGRAM} target="_blank" rel="noreferrer">
                  <Image src={iconInstagram} alt="Instagram" className="social-icon" />
                </a>

                <a href={window.Configs.LINK_RED_SOCIAL_YOUTUBE} target="_blank" rel="noreferrer">
                  <Image src={iconYoutube} alt="Youtube" className="social-icon" />
                </a>

                <a href={window.Configs.LINK_RED_SOCIAL_WHATSAPP} target="_blank" rel="noreferrer">
                  <Image src={iconWhatsapp} alt="Whatsapp" className="social-icon" />
                </a>
              </div>
            </Row>
          </Col>

          <Col className='bottom-divisor'>
            <Row className="dir-container">
              <div className="d-flex  flex-grow-1 justify-content-start"
                onClick={() => getCargos({ generarArchivo: true, cod_dad: 1599 })}>  {/* ELIMINAR */}
                <Image src={logoFooterCiudad} alt="Logo Buenos Aires Ciudad" className="logo-footer" />
              </div>
              <div className="dir-col">
                <p>
                  <span>Ministerio de Educación</span>
                  <br />
                  Mercedes Miguel
                </p>
              </div>
              <div className="dir-col">
                <p>
                  <span>Subsecretaría de Tecnología <br />
                    Educativa y Sustentabilidad</span>
                  <br />
                  Ignacio Sanguinetti
                </p>
              </div>
              <div className="dir-col">
                <p>
                  <span>Dirección General de Proyectos<br />
                    y Tecnología Educativa</span>
                  <br />
                  Mariano Pérez Alfaro
                </p>
              </div>
            </Row>
          </Col>

          <Col>
            <p className="version"> Todos los derechos reservados © 2023 Dirección General de Proyectos y Tecnología Educativa</p>
          </Col>
          <Col>
            <p className="version"> Versión 1.30.0</p>
          </Col>


        </Container>

      </footer>
    </Router >
  );
}
function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(App);
