import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clear, error, success } from "../../store/alerts/alertActions";
import Input from "../../ui/Input";
import { handlerChange, isValidNumeric } from "../../utils/commons";
import * as ternaService from "../TernaService";
import * as ternasService from "../TernaService";
import Dialog from "../../generics/dialog";
import { OrganismoAddModal } from "./OrganismoAddModal";

const ResolucionAddModal = ({ handleClose, show = false, action, ...props }) => {
    const [inputsForm, setInputsForm] = useState([]);
    const isMountedRef = useRef(null);
    const [validated, setValidated] = useState(false);
    const [organismoSeleccionado, setOrganismoSeleccionado] = useState("");
    const [tipoSeleccionado, setTipoSeleccionado] = useState("");
    const [errorMsg, setErrorMsg] = useState("")

    const [opcionesOrganismos, setOpcionesOrganismos] = useState()
    const [isLoadingOrganismos, setIsLoadingOrganismos] = useState(false)

    const [showModalCrearOrganismo, setShowModalCrearOrganismo] = useState(false)

    const handleCloseModalCrearOrganismo = () => {
        setShowModalCrearOrganismo(false)
    }


    const handleShowModalCrearOrganismo = () => {
        setShowModalCrearOrganismo(true)
    }

    const dispatch = useDispatch();

    useEffect(() => {
        isMountedRef.current = true;
        dispatch(clear());
        return () => (isMountedRef.current = false);
    }, [dispatch]);

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    };

    const handleValidation = async (resolve) => {
        setValidated(false);
        setErrorMsg("");
        const form = document.getElementById("formCreateResolucion");
        if (!form.checkValidity() === false) {
            if (tipoSeleccionado && organismoSeleccionado) {
                try {
                    await ternaService.createNewResolucion(inputsForm, resolve);
                    dispatch(success(<>Se creó la resolucion de tipo <b>{tipoSeleccionado.label}</b>, número <b>{inputsForm.numero}</b>, organismo <b>{organismoSeleccionado.label}</b> y año <b>{inputsForm.anio}</b> correctamente.</>));
                    handleClose()
                }
                catch (err) {
                    setErrorMsg(err?.response?.data?.message ?? "Ha ocurrido un error al enviar la peticion.");
                }
            }
        }
        setValidated(true);
    };

    const handleResolveCreateOrganismo = (newOrganismo) => {
        setInputsForm(st => ({ ...st, organismo: newOrganismo.id }))
        console.log({ newOrganismo })
        const organismoFormated = { label: newOrganismo.nombre, value: newOrganismo.id }
        setOrganismoSeleccionado(organismoFormated)
        setOpcionesOrganismos(st => ([...st, organismoFormated]))
    }

    const guardar = async () => {
        await handleValidation(action)
    }
    const preventDefault = (event) => {
        event.preventDefault();
    };

    const loadOpcionesOrganismos = async () => {
        try {
            setIsLoadingOrganismos(true)
            const opciones = await ternasService.getOrganismos()
            const formatedOpciones = opciones.map(ctOp => ({ value: ctOp.id, label: ctOp.nombre }))
            setOpcionesOrganismos(formatedOpciones)

        } catch (err) {
            dispatch(error("No se pudieron cargar las opciones de organismos"))
        } finally {
            setIsLoadingOrganismos(false)
        }
    }

    const resetModal = () => {
        setValidated(false);
        setErrorMsg("");
        setOrganismoSeleccionado('')
        setTipoSeleccionado('')
        setInputsForm("")
        loadOpcionesOrganismos()
    }

    const handleChangeOrganismo = (e) => {
        handleChange(e)
        setOrganismoSeleccionado(e.target)
    }

    useEffect(() => {
        if (show) resetModal();
    }, [show])

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Crear Resolución
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="formCreateResolucion"
                        noValidate
                        validated={validated}
                        onSubmit={preventDefault}
                    >
                        {errorMsg && <Alert variant={"danger"}>{errorMsg}</Alert>}
                        <Row>
                            <Col xs={12} lg={6}>
                                <Input
                                    type="react-select"
                                    controlId="tipo_resolucion"
                                    name="tipo_resolucion"
                                    label="Tipo"
                                    value={tipoSeleccionado}
                                    methodOnChange={handleChange}
                                    elements={ternasService.getTiposResolucion}
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setTipoSeleccionado}
                                    config={{ isSearchable: true, isClearable: true }}
                                    limitPerPage={0}
                                    countCaracters={0}
                                    required
                                    validated={validated}
                                ></Input>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Input
                                    type="input"
                                    controlId="numero"
                                    label="Número"
                                    maxLength="200"
                                    value={inputsForm.numero ? inputsForm.numero : ""}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                    required
                                ></Input>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Input
                                    type="react-select-sync"
                                    controlId="organismo"
                                    name="organismo"
                                    label="Organismo"
                                    value={organismoSeleccionado}
                                    methodOnChange={handleChangeOrganismo}
                                    isLoading={isLoadingOrganismos}
                                    syncElements={opcionesOrganismos ?? []}
                                    config={{ isSearchable: true, isClearable: true }}
                                ></Input>
                                <Button className="w-100" onClick={handleShowModalCrearOrganismo}>
                                    Crear Organismo
                                </Button>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Input
                                    type="input"
                                    controlId="anio"
                                    label="Año"
                                    maxLength="200"
                                    value={inputsForm.anio ? inputsForm.anio : ""}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                    required
                                ></Input>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>
                    <Dialog
                        action={guardar}
                        target={{}}
                        title={"Guardar"}
                        variant="primary"
                        size="md"
                        btnConfirmText="Guardar"
                        btnVariant="success"
                        btnText="Guardar"
                        isLoadingText={"Guardando"}
                        body={
                            <span>
                                ¿Estás seguro de guardar la resolución?
                            </span>
                        }
                    />
                </Modal.Footer>
            </Modal>

            <OrganismoAddModal
                handleClose={handleCloseModalCrearOrganismo}
                show={showModalCrearOrganismo}
                resolve={handleResolveCreateOrganismo}
            />
        </>
    );
}

export default ResolucionAddModal