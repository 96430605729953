class Nota {
    constructor() {
        this.id = "";
        this.descripcion = "";
        this.es_nota_custom = false;
        this.nota_titulo = "";
    }
}

export default Nota

export const createNota = async (props) => {
    const notaInfo = {
        id: !!props.es_nota_custom ? null : props.nota?.id,
        descripcion: props.nota_custom,
        es_nota_custom: !!props.es_nota_custom,
        nota_titulo: {
            nombre: props.nota_titulo_nombre,
            id: props.nota_titulo_id,
        },
    }

    return Object.assign(new Nota(), { ...notaInfo })
}
