import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import EspecialidadABMForm from "./EspecialidadesABMForm";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { useLocation } from "react-router-dom";

const EspecialidadesABM = ({ title, submitValue, especialidad, onSubmit, ...props }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clear());
    }, [dispatch]);

    const handleSubmit = async (especialidad) => {
        dispatch(success("Se creó la especialidad con éxito."));
    }

    return (
        <ScreenWithFabButtons
            returnPath={location.state?.returnPath}
            hideCleanButton={true}
            hideSearchButton={true}
        >
            <Row>
                <Col>
                    <Row className="form-group">
                        <Col>
                            <h1>{title ? title : "Crear Especialidad"}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EspecialidadABMForm
                                onSubmit={onSubmit ? onSubmit : handleSubmit}
                                especialidad={especialidad}
                                {...props}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ScreenWithFabButtons>

    );
};

export default EspecialidadesABM;
