import AreaEspecialidad, { createAreaEspecialidad } from "./AreaEspecialidad"
import NivelEspecialidad, { createNivelEspecialidad } from "./NivelEspecialidad"
import Estado, { createEstado } from "../Estado"
import dateFormat from 'dateformat';

class Especialidad {
    constructor(nombre) {
        this.id = "";
        this.nombre = nombre;
        this.codDad = "1234567890";
        this.area = AreaEspecialidad;
        this.nivel = NivelEspecialidad;
        this.createdAt = "";
        this.updatedAt = "";
        this.descripcion = "";
        this.folio = "";
        this.apendice = "";
        this.estado = Estado;
    }

    getNombreArea() {
        return this.area ? this.area.nombre : "";
    }

    getDescripcionNivel() {
        return this.nivel ? this.nivel.descripcion : "";
    }

    getNombreEstado() {
        return this.estado ? this.estado.nombre : "";
    }
}

export default Especialidad

export const createEspecialidad = async (especialidad) => {
    let estado = especialidad.estado ? await createEstado(especialidad.estado) : null;
    let nivel = especialidad.nivel ? await createNivelEspecialidad(especialidad.nivel) : null;
    let area = await createAreaEspecialidad(especialidad.area);
    let createdAt = dateFormat(especialidad.createdAt, "dd/mm/yyyy HH:MM")
    let udpatedAt = dateFormat(especialidad.udpatedAt, "dd/mm/yyyy HH:MM")
    return Object.assign(new Especialidad(especialidad.nombre), { ...especialidad, udpatedAt, createdAt, estado, nivel, area })
}


