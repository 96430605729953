import { createCargo } from "../cargo/Cargo";
import { createEspecialidad } from "../especialidad/Especialidad";
class CargoEspecialidad {
  constructor() {
    this.cargo = null;
    this.especialidades = [];
  }

  getNombreCargo() {
    return this.cargo.nombre;
  }

  getNombreEspecialidades() {
    return this.especialidades.map(x => x.nombre).join("-");
  }

}

export default CargoEspecialidad

export const createCargoEspecialidad = async (cargoEspecialidad) => {
    let {cargo, especialidades} = cargoEspecialidad;
    cargo = await createCargo(cargo);
    
    let listaEspecialidades = [];
    especialidades.forEach(e => {
      listaEspecialidades.push(createEspecialidad(e));
    });
    
    especialidades = await Promise.all(listaEspecialidades);

    return Object.assign(new CargoEspecialidad(), {cargo, especialidades})
}
