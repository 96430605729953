import Estado, { createEstado } from "../Estado"
import dateFormat from 'dateformat';
import { createAreaEspecialidad } from "../especialidad/AreaEspecialidad";
import { createCargoEspecialidad } from "./CargoEspecialidad";
import Acciones, { createAcciones } from "../Acciones"
class Asignatura {
    constructor(nombre) {
        this.activo = true;
        this.id = "";
        this.nombre = nombre;
        this.nombreInvertido = "";
        this.codDad = "1234567890";
        this.area = [];
        this.nivel = null;
        this.createdAt = "";
        this.updatedAt = "";
        this.descripcion = "";
        this.folio = "";
        this.apendice = "";
        this.relaciones = "";
        this.estado = Estado;
        this.cargosEspecialidad = []; //[{cargo:{}, especialidades:[{},...]}
        this.acciones = Acciones;
    }

    getDescripcionNivel() {
        return this.nivel ? this.nivel.descripcion : "";
    }

    getNombreEstado() {
        return this.estado ? this.estado.nombre : "";
    }

    getNombreAreas() {
        return this.area.map(x => x.nombre).join(" - ");
    }

    getFecha() {
        return this.createdAt.split(" ")[0];
    }

    getNombreNivel() {
        return this.nivel ? this.nivel.nombre : "";
    }
}

export default Asignatura

export const createAsignatura = async (asignatura) => {
    let estado = await createEstado(asignatura.estado);
    let nivel = asignatura.nivel;

    let cargosEspecialidad = armarCargoEspecialidad(asignatura.asignaturaCargoEspecialidades);
    let listaCargoEspecialidad = [];
    cargosEspecialidad.forEach(ce => {
        listaCargoEspecialidad.push(createCargoEspecialidad(ce));
    });

    cargosEspecialidad = await Promise.all(listaCargoEspecialidad);

    let acciones = asignatura.acciones ? await createAcciones(asignatura.acciones) : new Acciones();

    const tipoEscuela = asignatura.tipoEscuela ?? null

    let createdAt = dateFormat(asignatura.createdAt, "dd/mm/yyyy HH:MM")
    let udpatedAt = dateFormat(asignatura.udpatedAt, "dd/mm/yyyy HH:MM")

    const relaciones = asignatura?.relaciones?.map(ctData => ({
        id: ctData.relacion_id,
        apendice: { id: ctData.apendice_id, nombre: ctData.apendice_nombre },
        area: { id: ctData.area_id, nombre: ctData.area_nombre },
        cargo: { id: ctData.cargo_id, nombre: ctData.cargo_nombre },
        especialidad: { id: ctData.especialidad_id, nombre: ctData.especialidad_nombre },
        tipoEscuela: { id: ctData.tipo_escuela_id, nombre: ctData.tipo_escuela_nombre },
    })) ?? []

    let area = []
    relaciones.forEach(ctRelacion => {
        if (!area.some(ctArea => ctArea.id == ctRelacion.area.id)) area.push(ctRelacion.area)
    })

    const r = Object.assign(new Asignatura(asignatura.nombre), { ...asignatura, relaciones, udpatedAt, tipoEscuela, createdAt, estado, nivel, area, cargosEspecialidad, acciones })

    return r;
}

const armarCargoEspecialidad = (asignaturaCargoEspecialidades) => {
    let datos = {}

    asignaturaCargoEspecialidades?.forEach(x => {
        let { cargo, especialidades } = (x.cargo.id in datos) ? datos[x.cargo.id] : { cargo: {}, especialidades: [] };
        especialidades.push(x.especialidad);
        cargo = x.cargo;
        datos[x.cargo.id] = { cargo, especialidades };
    });

    let valores = Object.keys(datos).map(function (key) {
        return datos[key];
    });

    return valores;
}

