import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Pagination from '@material-ui/lab/Pagination';
const { paginado } = require("../utils/Constants");

const Paginacion = ({
  totalItems,
  currentPage,
  filasPorPaginas = paginado.FILAS_POR_PAGINAS,
  onChangePage,
  className,
  ...props
}) => {
  return (
    <Row className={`form-group pe-all ${className ? className : ""}`} >
      <Pagination count={Math.ceil(Number(totalItems) / filasPorPaginas)} page={Number(currentPage)} onChange={onChangePage} variant="outlined" size="large" />
    </Row >
  );
};

export default Paginacion;
