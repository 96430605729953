import { dataTypes } from "../../actions/types"

export const addData = (data) => {
    return {
        type: dataTypes.ADD_DATA,
        payload: data,
    }
}

export const removeData = (key) => {
    return {
        type: dataTypes.REMOVE_DATA,
        payload: key,
    }
}

export const cleanAllData = () => {
    return {
        type: dataTypes.CLEAN_ALL_DATA,
    }
}
