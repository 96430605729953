import { faCheckDouble, faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Badge, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../generics/dialog";
import { addArrConcurrencias, addCargoConcurrenciaToArr, addCargoVarianteToConcurrencia, addInputValueToConcurrencia, addInputValueToConcurrenciaMainCargo, addInputValueToConcurrenciaMainTerna, addVarianteConcurrenciaToArr, removeCargoConcurrenciaFromArr, removeCargoFromConcurrencia, removeConcurrencia, removeTernaFromConcurrencia, removeVarianteConcurrenciaFromArr, removeVarianteFromArrConcurrencias, removeVarianteFromConcurrencia } from "../../store/incumbencias/incumbenciasActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import Input from "../../ui/Input";
import { isValidNumeric, isValidPuntaje } from "../../utils/commons";
import { concurrenciasConstants, incumbenciasConstants, typeOfActions } from "../../utils/Constants";
import { IncumbenciasABMTable } from "./IncumbenciasABMTable";
import { CargoMultiRow, CargoSimpleRow, handleChangeCheckBox, TernaSimpleRow } from "./IncumbenciasSelectRow";

export const ConcurrenciasABMTable = ({
    elementos,
    isTernaMainElement,
    changeSelectedDocument,
    setConcurrenciaId,
    setWasModified,
    validated,
    ocultarBotones,
    ocultarEliminarElementosInternos,
    restoreActionIcon,
    currentKey,
}) => {

    const { typeOfAction } = useSelector(st => st.incumbencias);
    const { tipoIncumbencias, apendices } = useSelector(st => st.data)

    const dispatch = useDispatch();


    const h4Style = {
        marginBottom: "0",
        marginRight: "1rem",
    }

    const handleRemoveTernaFromConcurrencia = (concurrenciaIndex, ternaId) => {
        dispatch(removeTernaFromConcurrencia(concurrenciaIndex, ternaId))
    }

    const removeCargoFromConcurrencia = (currentConcurrenciaIndex, currentCargoIndex) => {
        if (typeOfAction == typeOfActions.EDIT) dispatch(addCargoConcurrenciaToArr(
            elementos[currentConcurrenciaIndex],
            elementos[currentConcurrenciaIndex].cargosSeleccionados[currentCargoIndex],
            "deletedConcurrencias",
            { verificarPermisoEditar: true },
        ))

        dispatch(removeCargoConcurrenciaFromArr(
            currentConcurrenciaIndex,
            currentCargoIndex,
            "concurrenciasSeleccionadas",
            { verificarPermisoEditar: true },
        ))
    }


    const restoreCargoFromConcurrencia = (currentConcurrenciaIndex, currentCargoIndex) => {
        if (typeOfAction == typeOfActions.EDIT) dispatch(addCargoConcurrenciaToArr(
            elementos[currentConcurrenciaIndex],
            elementos[currentConcurrenciaIndex].cargosSeleccionados[currentCargoIndex],
            "concurrenciasSeleccionadas"
        ))

        dispatch(removeCargoConcurrenciaFromArr(
            currentConcurrenciaIndex,
            currentCargoIndex,
            "deletedConcurrencias"
        ))
    }

    const removeVarianteFromConcurrencia = (currentConcurrenciaIndex, currentCargo, varianteToRemoveIndex) => {
        const newCurrentCargo = JSON.parse(JSON.stringify(currentCargo))
        console.log("EJECUTAMOS", { currentConcurrenciaIndex, currentCargo, varianteToRemoveIndex })
        if (typeOfAction == typeOfActions.EDIT) dispatch(addVarianteConcurrenciaToArr(
            elementos[currentConcurrenciaIndex],
            newCurrentCargo,
            newCurrentCargo.variantes[varianteToRemoveIndex],
            "deletedConcurrencias",
        ));

        dispatch(removeVarianteConcurrenciaFromArr(
            currentConcurrenciaIndex,
            newCurrentCargo,
            varianteToRemoveIndex,
            "concurrenciasSeleccionadas",
            typeOfAction == typeOfActions.EDIT
        ));
    }

    const restoreVarianteFromConcurrencia = (currentConcurrenciaIndex, currentCargo, varianteToRemoveIndex) => {
        const newCurrentCargo = JSON.parse(JSON.stringify(currentCargo))

        if (typeOfAction == typeOfActions.EDIT) dispatch(addVarianteConcurrenciaToArr(
            elementos[currentConcurrenciaIndex],
            newCurrentCargo,
            newCurrentCargo.variantes[varianteToRemoveIndex],
            "concurrenciasSeleccionadas",
        ));

        dispatch(removeVarianteConcurrenciaFromArr(
            currentConcurrenciaIndex,
            newCurrentCargo,
            varianteToRemoveIndex,
            "deletedConcurrencias",
            typeOfAction == typeOfActions.EDIT
        ));
    }

    const handleRemoveConcurrencia = (concurrenciaIndex) => {
        if (typeOfAction == typeOfActions.EDIT) {
            dispatch(addArrConcurrencias(
                [elementos[concurrenciaIndex]],
                "deletedConcurrencias",
                { verificarPermisoEditar: true, isTernaMainElement },
            ));
        }
        dispatch(removeConcurrencia(concurrenciaIndex, "concurrenciasSeleccionadas", { verificarPermisoEditar: true },));
    }

    const handleRestoreConcurrencia = (concurrenciaIndex) => {
        dispatch(addArrConcurrencias([elementos[concurrenciaIndex]], "concurrenciasSeleccionadas"));
        dispatch(removeConcurrencia(concurrenciaIndex, "deletedConcurrencias"));
    }

    const handleClickFinalizarCargaFunction = (concurrenciaIndex, cargoIndex, varianteIndex) => {
        dispatch(addCargoVarianteToConcurrencia(concurrenciaIndex, cargoIndex, varianteIndex))
    }

    const handleChangeRowCargoInputs = (
        evento,
        currentCargo,
        isValidMethod,
        typeOfCargo,
        varianteIndex,
        cargoIndex,
        concurrenciaIndex
    ) => {
        if (isValidMethod && evento.target.value) {
            if (!isValidMethod(evento.target.value)) {
                return false
            }
        }

        let inputData = {};
        inputData[evento.target.id] = evento.target.value
        if (evento.target.id == "condicion") {
            inputData["puntaje"] = null
            inputData["sumaOT"] = false
        }

        dispatch(addInputValueToConcurrenciaMainTerna(
            concurrenciaIndex,
            cargoIndex,
            varianteIndex,
            inputData,
            typeOfCargo,
            restoreActionIcon ? "deletedConcurrencias" : "concurrenciasSeleccionadas"
        ))

        if (setWasModified) setWasModified(true)
    }

    const handleChangeRowTernaInputs = (event, concurrenciaIndex, isValidMethod) => {
        if (isValidMethod && event.target.value) {
            if (!isValidMethod(event.target.value)) {
                return false
            }
        }
        let inputData = {};
        inputData[event.target.id] = event.target.value
        if (event.target.id == "condicion") {
            inputData["puntaje"] = null
            inputData["sumaOT"] = false
        }
        dispatch(addInputValueToConcurrenciaMainCargo(
            concurrenciaIndex,
            inputData,
            restoreActionIcon ? "deletedConcurrencias" : "concurrenciasSeleccionadas"
        ))
        if (setWasModified) setWasModified(true)
    }

    const renderTernasRow = (elementos, concurrenciaIndex) => {
        console.log({ elementos })
        return elementos.map((ctTerna, index) =>
            <React.Fragment key={`${currentKey ?? "terna"}-simpleterna-${ctTerna.id}${index}`}>
                <TernaSimpleRow
                    currentTerna={{ terna: ctTerna, }}
                    mainElement={incumbenciasConstants.TERNAS_INCUMBENCIA}
                    handleChangeRowTernaInputs={() => { }}
                    restoreActionIcon={false}
                    removeTernaFromList={() => handleRemoveTernaFromConcurrencia(concurrenciaIndex, index)}
                    restoreTernaFromList={() => { }}
                    showRowActions={!ocultarEliminarElementosInternos}
                    showRowInputs={false}
                    hideColumns={{ "estado": true }}
                />
            </React.Fragment>
        )
    }

    const renderCargoRow = (elementos, concurrenciaIndex) => {
        return elementos.map((ctCargo, cargoIndex) => ctCargo.type == "simple" ?
            <React.Fragment key={`${currentKey ?? "cargo"}-cargosimplerow-${ctCargo.cargo.id}-${cargoIndex}`}>
                <CargoSimpleRow
                    currentCargo={ctCargo}
                    handleChangeRowCargoInputs={(e, currentCargo, isValidMethod, typeOfCargo, multiIndex) => {
                        handleChangeRowCargoInputs(e, currentCargo, isValidMethod, typeOfCargo, multiIndex, cargoIndex, concurrenciaIndex)
                    }}
                    removeCargoFromList={() => removeCargoFromConcurrencia(concurrenciaIndex, cargoIndex, ctCargo)}
                    restoreCargoFromList={() => restoreCargoFromConcurrencia(concurrenciaIndex, cargoIndex, ctCargo)}
                    restoreActionIcon={restoreActionIcon}
                    mainElement={incumbenciasConstants.TERNAS_INCUMBENCIA}
                    hideColumns={{ "estado": typeOfAction == typeOfActions.CREATE }}
                    showInputFolio={true}
                    showInputCondicion={true}
                    showSelectApendice={isTernaMainElement && typeOfAction != typeOfActions.CREATE}
                />
            </React.Fragment>
            :
            <React.Fragment key={`${currentKey ?? "cargo"}-cargomultirow-${ctCargo.cargo.id}${cargoIndex}`}>
                <CargoMultiRow
                    currentKey={`${currentKey ?? "cargo"}-cargomultirow-${ctCargo.cargo.id}${cargoIndex}`}
                    currentCargo={ctCargo}
                    handleChangeRowCargoInputs={(e, currentCargo, isValidMethod, typeOfCargo, multiIndex) => {
                        handleChangeRowCargoInputs(e, currentCargo, isValidMethod, typeOfCargo, multiIndex, cargoIndex, concurrenciaIndex)
                    }}
                    removeCargoFromList={() => removeCargoFromConcurrencia(concurrenciaIndex, cargoIndex, ctCargo)}
                    removeVarianteFromCargo={(currentCargo, varianteToRemoveIndex) => removeVarianteFromConcurrencia(concurrenciaIndex, currentCargo, varianteToRemoveIndex)}
                    restoreCargoFromList={() => restoreCargoFromConcurrencia(concurrenciaIndex, cargoIndex, ctCargo)}
                    restoreVarianteFromCargo={(currentCargo, varianteToRemoveIndex) => restoreVarianteFromConcurrencia(concurrenciaIndex, currentCargo, varianteToRemoveIndex)}
                    restoreActionIcon={restoreActionIcon}
                    mainElement={incumbenciasConstants.TERNAS_INCUMBENCIA}
                    showButtons={!ocultarBotones}
                    showInputFolio={true}
                    showInputCondicion={true}
                    showActionsCol={true}
                    showSelectApendice={isTernaMainElement && typeOfAction != typeOfActions.CREATE}
                    validated={validated}
                    hideColumns={{ "estado": typeOfAction == typeOfActions.CREATE }}
                    handleClickFinalizarCargaFunction={(variante) => handleClickFinalizarCargaFunction(concurrenciaIndex, cargoIndex, variante)}
                />
            </React.Fragment>)
    }

    const cantidadMinimaTernas = isTernaMainElement ? concurrenciasConstants.cantidadMinimaTernasPorConcurrencia - 1 : concurrenciasConstants.cantidadMinimaTernasPorConcurrencia

    const checkAllBloqueadosPorPermisos = (ctElemento) => {
        if (typeOfAction == typeOfActions.CREATE) return false

        let allBloqueadosPorPermisos = false
        if (isTernaMainElement) {
            allBloqueadosPorPermisos = ctElemento.cargosSeleccionados.length > 0 && ctElemento.cargosSeleccionados.every(ctCargo => {
                if (ctCargo.type == "simple") {
                    return true
                } else {
                    return ctCargo.variantes.every(ctVariante => ctVariante?.acciones?.editar === false)
                }
            })
        } else {
            allBloqueadosPorPermisos = ctElemento.acciones?.editar === false
        }
        return allBloqueadosPorPermisos
    }

    return (
        <>
            {
                elementos.map((ctElemento, index) =>
                    <React.Fragment key={`${currentKey}-${index}`}>
                        <div className={`concurrencia-card ${checkAllBloqueadosPorPermisos(ctElemento) ? "disabled" : ""}`}  >
                            <div className="flex-grow-1" style={{ maxWidth: "calc(100% - 38px)" }}>
                                <div className="concurrencia-row">
                                    <div className="concurrencia-btn-section">
                                        <div className="position-relative">
                                            <h4 style={h4Style}>Ternas concurrentes:</h4>
                                            {validated && (ctElemento.ternasSeleccionadas?.length < cantidadMinimaTernas) &&
                                                <span
                                                    id="customErrorMsg"
                                                    className={`invalid-feedback card-show-invalid-feedback`}>
                                                    {(ctElemento.ternasSeleccionadas?.length == 0) ?
                                                        "El campo es obligatorio"
                                                        :
                                                        `Debe agregar al menos ${cantidadMinimaTernas} ternas`
                                                    }
                                                </span>
                                            }
                                        </div>
                                        {!ocultarBotones && <Button
                                            variant="primary"
                                            type="button"
                                            onClick={() => {
                                                setConcurrenciaId(index)
                                                changeSelectedDocument({ documentSelected: incumbenciasConstants.TERNAS_CONCURRENCIA, checkedElements: ctElemento.ternasSeleccionadas })
                                            }}
                                            style={{ marginRight: "" }}
                                            disabled={ctElemento.ternasSeleccionadas?.length >= (
                                                isTernaMainElement ?
                                                    concurrenciasConstants.cantidadMaximaTernasPorConcurrencia - 1 :
                                                    concurrenciasConstants.cantidadMaximaTernasPorConcurrencia
                                            )}
                                        >
                                            Agregar terna
                                        </Button>
                                        }
                                    </div>
                                    {ctElemento.ternasSeleccionadas?.length > 0 &&
                                        <div className="concurrencia-terna-table-container">
                                            {!isTernaMainElement &&
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex">
                                                            {
                                                                typeOfAction != typeOfActions.CREATE &&
                                                                <Input
                                                                    type="select"
                                                                    controlId="apendice_id"
                                                                    style={{ minWidth: "180px", }}
                                                                    value={ctElemento.inputs?.apendice_id}
                                                                    onChange={(event) => handleChangeRowTernaInputs(event, index)}
                                                                    elements={apendices}
                                                                    label="Apendice"
                                                                    required
                                                                    classNameContainer="mr-2"
                                                                ></Input>
                                                            }
                                                            <Input
                                                                type="input"
                                                                controlId="folio"
                                                                value={ctElemento.inputs?.folio}
                                                                required
                                                                onChange={(event) => handleChangeRowTernaInputs(event, index, isValidNumeric)}
                                                                label="Folio"
                                                                classNameContainer="mr-2"
                                                                disabled={ctElemento.acciones?.editar === false}
                                                            ></Input>
                                                            <Input
                                                                type="select"
                                                                controlId="condicion"
                                                                style={{ width: "12rem" }}
                                                                value={ctElemento.inputs?.condicion}
                                                                onChange={(event) => handleChangeRowTernaInputs(event, index)}
                                                                label="Condicion"
                                                                elements={tipoIncumbencias}
                                                                required
                                                                classNameContainer="mr-2"
                                                                disabled={ctElemento.acciones?.editar === false}
                                                            ></Input>
                                                            {tipoIncumbencias?.length > 0 &&
                                                                ctElemento.inputs?.condicion == tipoIncumbencias.find(ctCond => ctCond.descripcion == "OT").id &&
                                                                <>
                                                                    <Input
                                                                        type="input"
                                                                        controlId="puntaje"
                                                                        label="Puntaje"
                                                                        style={{ width: "12rem" }}
                                                                        value={ctElemento.inputs?.puntaje}
                                                                        onChange={(event) => handleChangeRowTernaInputs(event, index, isValidPuntaje)}
                                                                        description={"(Del 0 al 3)"}
                                                                        required
                                                                        classNameContainer="mr-2"
                                                                        disabled={ctElemento.acciones?.editar === false}
                                                                    ></Input>
                                                                    <Input
                                                                        type="checkbox"
                                                                        controlId="sumaOT"
                                                                        name="sumaOT"
                                                                        label="Solo aplica para Otros titulos"
                                                                        value="0"
                                                                        checked={ctElemento.inputs?.sumaOT}
                                                                        onChange={(event) => handleChangeCheckBox(event, (event) => handleChangeRowTernaInputs(event, index))}
                                                                        disabled={ctElemento.acciones?.editar === false}
                                                                    ></Input>
                                                                </>
                                                            }
                                                        </div>
                                                        {typeOfAction != typeOfActions.CREATE &&
                                                            <div style={{ marginRight: "2%" }}>
                                                                <b>Estado concurrencia:</b><br></br>
                                                                <Badge variant={ctElemento.estado ? ctElemento.estado?.color : "primary"}>{" "}{ctElemento.estado?.nombre}</Badge>
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr style={{ width: "100%", marginLeft: 0, marginRight: 0, borderWidth: "2px" }}></hr>
                                                </>
                                            }
                                            <IncumbenciasABMTable
                                                elementos={elementos}
                                                mainElement={incumbenciasConstants.TERNAS_INCUMBENCIA}
                                                renderTableRows={() => renderTernasRow(ctElemento.ternasSeleccionadas, index)}
                                                tipo={incumbenciasConstants.TERNAS_INCUMBENCIA}
                                                showCheckboxCol={false}
                                                showCondicionCol={false}
                                                showFolioCol={false}
                                                showPaginacion={false}
                                                showActionsCol={true}
                                                // showApendiceCol={typeOfAction != typeOfActions.CREATE && isTernaMainElement}
                                                showApendiceCol={false}
                                                hideColumns={{ "estado": true }}
                                            />
                                        </div>
                                    }
                                </div>

                                {isTernaMainElement &&
                                    <>
                                        <hr className="concurrencia-separator"></hr>

                                        <div className="concurrencia-row">
                                            {ctElemento.cargosSeleccionados?.length > 0 ?
                                                <div className="concurrencia-cargo-table-container">
                                                    <IncumbenciasABMTable
                                                        elementos={elementos}
                                                        renderTableRows={() => renderCargoRow(ctElemento.cargosSeleccionados, index)}
                                                        tipo={incumbenciasConstants.CARGOS_INCUMBENCIA}
                                                        showCheckboxCol={false}
                                                        showCondicionCol={true}
                                                        showFolioCol={true}
                                                        showApendiceCol={typeOfAction != typeOfActions.CREATE}
                                                        showPaginacion={false}
                                                        showActionsCol={true}
                                                        hideColumns={{ "estado": typeOfAction == typeOfActions.CREATE }}
                                                    />
                                                    {!ocultarBotones &&
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={() => {
                                                                setConcurrenciaId(index)
                                                                changeSelectedDocument({ documentSelected: incumbenciasConstants.CARGOS_CONCURRENCIA, checkedElements: ctElemento.cargosSeleccionados })
                                                            }}
                                                        >
                                                            Agregar cargo
                                                        </Button>
                                                    }
                                                </div>
                                                :
                                                <div className="concurrencia-btn-section">
                                                    <div className="position-relative">
                                                        <h4 style={{ ...h4Style, width: validated ? "175px" : "auto" }}>Para el cargo:</h4>

                                                        {validated && (ctElemento.cargosSeleccionados?.length == 0) &&
                                                            <span id="customErrorMsg" className={`invalid-feedback card-show-invalid-feedback`}>El campo es obligatorio</span>
                                                        }
                                                    </div>
                                                    {!ocultarBotones &&
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={() => {
                                                                setConcurrenciaId(index)
                                                                changeSelectedDocument({ documentSelected: incumbenciasConstants.CARGOS_CONCURRENCIA, checkedElements: ctElemento.cargosSeleccionados })
                                                            }}
                                                        >
                                                            Agregar cargo
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </>}
                            </div>
                            <div className="concurrencia-delete">
                                {
                                    restoreActionIcon ?
                                        <CustomTooltip text={"Restaurar concurrencia eliminada"}>
                                            <Dialog
                                                action={() => handleRestoreConcurrencia(index)}
                                                target={"currentCargo"}
                                                title={"Restaurar concurrencia"}
                                                body={<span>¿Estás seguro que quiere restaurar la concurrencia de la lista de eliminados?</span>}
                                                size="md"
                                                btnIcon={faCheckDouble}
                                                btnVariant={"danger"}
                                                variant={"warning"}
                                                btnConfirmText="Restaurar"
                                            />
                                        </CustomTooltip>
                                        :
                                        <CustomTooltip text={"Eliminar concurrencia"}>
                                            <Dialog
                                                action={() => handleRemoveConcurrencia(index)}
                                                target={"currentCargo"}
                                                title={"Eliminar concurrencia"}
                                                body={<span>¿Estás seguro que quiere remover la concurrencia de la lista?</span>}
                                                size="md"
                                                btnIcon={faTrash}
                                                btnConfirmText="Eliminar"
                                                btnVariant={"danger"}
                                                variant={"danger"}
                                                disabled={checkAllBloqueadosPorPermisos(ctElemento)}
                                            />
                                        </CustomTooltip>
                                }
                            </div>
                        </div>
                    </React.Fragment >)
            }
        </>
    );
};

