import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { clonadorConstants } from '../../utils/Constants';
import { ClonadorCargosCards } from './cargos/ClonadorCargosCards';
import { ClonadorTernasCards } from './ternas/ClonadorTernasCards';

export const ClonadorCardElements = ({
    original,
    destino,
    onClickSeleccionarOriginal,
    onClickSeleccionarDestino,
    invertirTernas,
    handleAgregarVarianteCargo,
    validated,
    getSelectCondicionComponent,
}) => {

    const { cloneType } = useParams();
    
    return (
        <>
            {cloneType == clonadorConstants.TERNAS &&
                <ClonadorTernasCards
                    original={original}
                    destino={destino}
                    onClickSeleccionarOriginal={onClickSeleccionarOriginal}
                    onClickSeleccionarDestino={onClickSeleccionarDestino}
                    invertirTernas={invertirTernas}
                    validated={validated}
                    getSelectCondicionComponent={getSelectCondicionComponent}
                />
            }

            {cloneType == clonadorConstants.CARGOS &&
                <ClonadorCargosCards
                    original={original}
                    destino={destino}
                    onClickSeleccionarOriginal={onClickSeleccionarOriginal}
                    onClickSeleccionarDestino={onClickSeleccionarDestino}
                    invertirTernas={invertirTernas}
                    handleAgregarVarianteCargo={handleAgregarVarianteCargo}
                    validated={validated}
                    getSelectCondicionComponent={getSelectCondicionComponent}
                />
            }
        </>
    )
}
