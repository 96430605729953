import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import ApendiceSearchRowActions from "./ApendiceSearchRowActions";
import { Badge } from "react-bootstrap";

const ApendiceSearchRow = ({ apendice, ...props }) => {
  const [redirect, setRedirect] = useState("");

  if (redirect) {
    return (

      <Redirect
        to={{
          pathname: redirect,
          state: {
            cargo: apendice,
            abmTitle: "Editar",
            submitValue: "Habilitar",
          },
        }}
      ></Redirect>
    );
  }

  return (
    <>
      <tr key={apendice.id}>
        <td>{apendice.id}</td>
        <td>{apendice.nombre}</td>
        <td>{apendice.getFechaDesde()}</td>
        {
          apendice.habilitado ? <td> Actual </td> : <td>{apendice.getFechaHasta()}</td>
        }
        <td>{apendice.habilitado ? 'Habilitado' : 'No habilitado'}</td>
        <td>
          <div className="table-button-group">
            <ApendiceSearchRowActions apendice={apendice} onSubmit={props.onSubmit}></ApendiceSearchRowActions>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ApendiceSearchRow;
