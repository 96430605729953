import React from 'react';
import { useAuthorization } from "./AuthService";

const PrivateComponent = ({ children, permisos }) => {
    const hasAuthorization = useAuthorization(permisos)
    // if (!hasAuthorization) console.log("NO AUTORIZADO -> OCULTAMOS COMPONENTE", { permisos })

    return (
        hasAuthorization ?
            <>{children}</>
            :
            <></>
    );
};

export default PrivateComponent;
