import { Form, Col, Row } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidNumeric, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import * as especialidadesService from "../../especialidades/EspecialidadesService"
import * as genericService from "../../GenericService"
import * as informesService from "../InformesService"
import InputDatePicker from "../../generics/InputDatePicker";
import { cleanFab, searchFab } from "../../store/screen/screenActions";
import { tipoBusquedaInforme } from "../../utils/Constants";

export const FormSearchByTerna = ({
  inputsForm,
  setInputsForm,
  cleanResults,
  searchInformeByTerna,
}) => {
  const tipoBusquedaDefault = { id: 0, descripcion: tipoBusquedaInforme.TITULO }

  const [tipoSeleccionado, setTipoSeleccionado] = useState(tipoBusquedaDefault);

  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [organismoSeleccionado, setOrganismoSeleccionado] = useState("");
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState("");
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [institucionSeleccionada, setInstitucionSeleccionada] = useState("");

  const isMountedRef = useRef(null);
  const tiposBusquedaSelectOptions = useRef(Object.values(tipoBusquedaInforme).map((currentTipo, index) => {
    return { id: index, descripcion: currentTipo }
  }));

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);

  const reactSelectsInputs = [
    { name: "nivelSeleccionado", state: nivelSeleccionado, setter: setNivelSeleccionado },
    { name: "tipoDocumentoSeleccionado", state: tipoDocumentoSeleccionado, setter: setTipoDocumentoSeleccionado },
    { name: "organismoSeleccionado", state: organismoSeleccionado, setter: setOrganismoSeleccionado },
    { name: "institucionSeleccionada", state: institucionSeleccionada, setter: setInstitucionSeleccionada },
  ]

  const resetAllReactSelectState = () => {
    reactSelectsInputs.forEach(currentReactSelect => currentReactSelect.setter(""))
  }

  const cleanForm = () => {
    // setStartDate(null);
    // setEndDate(null);
    resetAllReactSelectState();
    cleanResults();
    setInputsForm(st => {
      return { tipoBusqueda: st.tipoBusqueda }
    });
  }

  const processSubmit = async () => {
    let filtros = { ...inputsForm };

    if (!filtros.tipoBusqueda) {
      filtros = { ...filtros, tipoBusqueda: tipoBusquedaDefault.descripcion }
      setInputsForm(filtros)
    }

    inputsForm["page"] = 1; //Para el paginado
    await searchInformeByTerna(filtros).catch((err) => {
      dispatch(error(err.response.data.message));
    });
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handleChange(e);
    }
  };

  const handleChangeTipoBusqueda = (e) => {
    cleanResults();
    cleanForm();
    const selectedOption = { id: e.target.value, descripcion: e.target.selectedOptions[0].label };
    setInputsForm(st => ({ ...st, "tipoBusqueda": selectedOption.descripcion }));
    setTipoSeleccionado(selectedOption);
  }

  // const setFechaDesde = (date) => {
  //   setInputsForm(st => {
  //     const nwSt = { ...st };
  //     date ? nwSt.desde = date : delete nwSt.desde;
  //     return nwSt
  //   });
  //   setStartDate(date)
  // }

  // const setFechaHasta = (date) => {
  //   setInputsForm(st => {
  //     const nwSt = { ...st };
  //     date ? nwSt.hasta = date : delete nwSt.hasta;
  //     return nwSt
  //   });
  //   setEndDate(date)
  // }

  useEffect(() => {
    if (screenState.clean) {
      cleanForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])


  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear())
    setInputsForm({ tipoBusqueda: tipoBusquedaDefault.descripcion });
    return () => isMountedRef.current = false;
  }, [dispatch]);


  return (
    <Row className="form-group">
      <Form
        id="formSearchInformes"
        className="w-100"
      >
        <Col sm={8} md={8} lg={8}>
          <Input
            type="select"
            controlId="tipoBusqueda"
            name="tipoBusqueda"
            label="Tipo de búsqueda"
            value={tipoSeleccionado.id}
            onChange={handleChangeTipoBusqueda}
            elements={tiposBusquedaSelectOptions.current}
            default="none"
          ></Input>
        </Col>
        <hr></hr>
        <Row className="form-group container">
          {tipoSeleccionado.descripcion === tipoBusquedaInforme.TITULO &&
            <>
              <Col sm={6} lg={4}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre de título"
                  value={inputsForm.nombre ?? ""}
                  onChange={handleChange}
                />
              </Col>
              <Col sm={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nivel_id"
                  name="nivel"
                  label="Nivel"
                  value={nivelSeleccionado}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getNivelesTerna}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  countCaracters={0}
                ></Input>
              </Col>
            </>
          }
          {tipoSeleccionado.descripcion === tipoBusquedaInforme.RESOLUCIONES &&
            <>
              <Col sm={6} lg={4}>
                <Input
                  type="input"
                  controlId="numero_resolucion"
                  label="Nro de resolución"
                  value={inputsForm.numero_resolucion ?? ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  maxLength="50"
                />
              </Col>

              {/*VER PORUQE ROMPEN ESTOS DOS REACT_SELECTS CON LA NUEVA MODIFICACION DE LOS SIMPLE SELECT CON POCAS OPCIONES */}

              <Col sm={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="tipo_resolucion_id"
                  name="tipo_resolucion_id"
                  label="Tipo de documento"
                  value={tipoDocumentoSeleccionado}
                  methodOnChange={handleChange}
                  elements={genericService.getTipoResoluciones}
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setTipoDocumentoSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  countCaracters={0}
                  maxOptionsBreakAsyncToSync={999}
                  nameElementsResponse="data"
                ></Input>
              </Col>
              <Col sm={6} md={4}>
                <Input
                  type="react-select"
                  controlId="organismo_id"
                  name="organismo_id"
                  label="Organismo aprobador"
                  value={organismoSeleccionado}
                  methodOnChange={handleChange}
                  elements={genericService.getOrganismos}
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setOrganismoSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                  countCaracters={0}
                  maxOptionsBreakAsyncToSync={999}
                  nameElementsResponse="data"
                ></Input>
              </Col>
              <Col sm={6} md={4}>
                <Input
                  type="input"
                  controlId="anio"
                  label="Año"
                  value={inputsForm.anio ? inputsForm.anio : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  maxLength="50"
                />
              </Col>
            </>
          }
          {tipoSeleccionado.descripcion === tipoBusquedaInforme.PROCEDENCIAS &&
            <>

              <Col sm={6} lg={4}>
                <Input
                  type="input"
                  controlId="procedencia_nombre"
                  label="Nombre de la institución"
                  value={inputsForm.procedencia_nombre ?? ""}
                  onChange={handleChange}
                />
              </Col>
            </>
          }
          {/* <Col sm={6} md={4} lg={2}>
            <InputDatePicker label={"Desde"} selected={startDate} onChange={date => setFechaDesde(date)} />
          </Col>
          <Col sm={6} md={4} lg={2}>
            <InputDatePicker label={"Hasta"} selected={endDate} onChange={date => setFechaHasta(date)} />
          </Col> */}
        </Row>
      </Form>
    </Row>
  );
};