import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clear, error } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import { Loader } from "../../ui/Loader";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { getEscuelas } from "../EscuelaService";
import EscuelaFormSearch from "./EscuelaFormSearch";
import EscuelaSearchTable from "./EscuelaSearchTable";

const EscuelaSearch = (props) => {
  const [escuelas, setEscuelas] = useState(null);
  const [cantidadEscuelas, setCantidadEscuelas] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);

  const filtrosGuardados = useSelector((state) => state.search);

  const location = useLocation();
  const dispatch = useDispatch();

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      // El aprobar borra data para forzar refresh de data con mismos filtros guardados
      if (!filtrosGuardados.data) {
        return await searchEscuelas(filtrosGuardados)
      }

      setPage(filtrosGuardados.page);
      setEscuelas(filtrosGuardados.data);
      setCantidadEscuelas(filtrosGuardados.cantidad);
    }
  }

  const searchEscuelas = async (data = {}) => {
    try {
      let filtros = data.inputsForm;
      const filtrosComunes = { all: true }

      setSearching(true);
      const response = await getEscuelas({ ...filtros, ...filtrosComunes });
      setPage(response.page);
      setEscuelas(response.data);
      setCantidadEscuelas(response.cantidad);
      const newData = { ...data, ...response, inputsForm: { ...data.inputsForm, page: response.page } }
      dispatch(saveFilters(newData));
    }
    catch (err) {
      dispatch(error(err.response.data.message));
    }
    finally {
      setSearching(false);
    }
  }

  const handleSearch = async (filters) => {
    searchEscuelas(filters);
  };

  const handleChangePage = (event, value) => {
    dispatch(clear());
    searchEscuelas({ ...filtrosGuardados, inputsForm: { ...filtrosGuardados.inputsForm, page: value } });
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
  }, [dispatch]);

  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
      hideSearchButton={false}
      hideCleanButton={false}
      hidePlusButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching}
    >

      <Row>
        <Col>
          <h1>Tipo de Escuela</h1>
          <EscuelaFormSearch
            setEscuelas={setEscuelas}
            onSubmit={handleSearch}
          ></EscuelaFormSearch>
        </Col>
      </Row>
      <Row>
        <Col>
          {escuelas && !searching ? (
            <EscuelaSearchTable
              escuelas={escuelas}
              cantidadEscuelas={cantidadEscuelas}
              changePage={handleChangePage}
              page={page}
            ></EscuelaSearchTable>

          ) : (
            searching && <Loader></Loader>
          )}
        </Col>
      </Row>

    </ScreenWithFabButtons>
  );
};

export default EscuelaSearch;
