
import { faPen } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import PrivateComponent from "../../authentication/PrivateComponent";
import IconButton from "../../generics/IconButton";
import { error } from "../../store/alerts/alertActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import DialogActivate from "../../ui/DialogActivate.jsx";
import { routePaths, tooltipsText } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos.js";
import UserDetailModal from "../UserDetailModal";
import { changeUserState } from "./UserABMService";

const UserABMRow = ({ user, ...props }) => {
  const [redirect, setRedirect] = useState("");

  const dispatch = useDispatch();

  const changeState = async (user) => {
    await changeUserState(user)
      .then((resp) => {
        props.onSubmit();
      })
      .catch((e) => {
        dispatch(error(e.response.data.message));
      });
  };

  const redirectToEdit = () => {
    setRedirect(routePaths.ABM_USER_EDIT);
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { user: user, abmTitle: "Editar", submitValue: "Aceptar" },
        }}
      ></Redirect>
    );
  }

  const showRolNames = (user) => {
    return user.rol.map((r) => r.nombre).join(" | ");
  };

  return (
    <>
      <tr key={user.id}>
        <td>{user.username}</td>
        <td>{user.nombre}</td>
        <td>{user.apellido}</td>
        <td>{showRolNames(user)}</td>
        <td>
          <div className="table-button-group">
            <CustomTooltip text={(user.activo ? "Deshabilitar" : "Habilitar")}>
              <DialogActivate
                permisos={PERMISOS_MAP.USUARIO_EDITAR}
                target={user}
                title={
                  (user.activo ? "Deshabilitar" : "Habilitar") +
                  ` usuario`
                }

                body={<span>
                  ¿Estás seguro de {(user.activo ? "deshabilitar" : "habilitar")} el usuario <b>{user.nombre} {user.apellido}</b>?
                </span>
                }
                size="sm"
                action={changeState}
              ></DialogActivate>
            </CustomTooltip>

            <PrivateComponent permisos={PERMISOS_MAP.USUARIO_EDITAR}>
              <CustomTooltip text={tooltipsText.EDITAR} placement="top">
                <IconButton icon={faPen} onClick={() => redirectToEdit()} />
              </CustomTooltip>
            </PrivateComponent>

            <CustomTooltip text={tooltipsText.DETALLE} placement="top">
              <UserDetailModal user={user}></UserDetailModal>
            </CustomTooltip>
          </div>
        </td>
      </tr>
    </>
  );
};

export default UserABMRow;
