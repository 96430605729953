import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import Dialog from "../../generics/dialog";
import { clear } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { clearFilters } from "../../store/search/searchActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { routePaths, typeOfActions } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos";
import { handlerChange, isValidNumeric, isValidText } from "../../utils/commons";
import * as asignaturaService from "../AsignaturaService";
import { RelacionesAsignaturaForm } from "./RelacionesAsignatura/RelacionesAsignaturaForm";
import { RelacionesAsignaturaTable } from "./RelacionesAsignatura/RelacionesAsignaturaTable";

const AsignaturasABMForm = ({
  asignatura,
  setAsignatura,
  returnPath,
  typeOfAction = typeOfActions.CREATE,
  availableCerrarMod,
  setAvailableCerrarMod,
  setDisabled,
  discardChanges,
  setDiscardChanges,
  ...props
}) => {
  const [inputsForm, setInputsForm] = useState([]);
  const isMountedRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [wasModified, setWasModified] = useState(false);
  const [relaciones, setRelaciones] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getCurrentApendice = async () => {
    if (typeOfAction == typeOfActions.CREATE) {
      apendiceService.getCurrentApendice().then(result => {
        if (isMountedRef.current) {
          setInputsForm(data => ({ ...data, apendice: result }))
        }
      });
    }
  };

  const submitResolve = (asig) => {
    history.push(routePaths.ABM_ASIGNATURAS_SEARCH);
    dispatch(searchFab(true));
  }

  const submitResolveWithClearFilters = () => {
    dispatch(clearFilters())
    submitResolve()
  }

  const handleAprobacion = async () => {
    await handleValidation(asig => asignaturaService.approve(asig, submitResolve));
  };

  const handlePublish = async (event) => {
    await handleValidation(asignaturaService.publish);
  };

  const handleBorrador = async () => {
    await handleValidation(asig => asignaturaService.saveDraft(
      asig,
      typeOfAction == typeOfActions.CREATE ? submitResolveWithClearFilters : submitResolve
    ));
  };

  const formatRelacionToFetch = (relacion) => {
    const formated = {
      area_id: relacion.area.id,
      tipo_escuela_id: relacion.tipoEscuela.id,
      cargo_id: relacion.cargo.id,
      especialidad_id: relacion.especialidad.id,
    }
    if (relacion.id) formated.id = relacion.id
    return formated
  }


  const handleValidation = async (resolve) => {
    const form = document.getElementById("formCreateAsignatura");
    setValidated(true);

    if (!form.checkValidity() === false) {
      // if (relaciones.length == 0) return
      const params = {
        ...inputsForm,
        cod_dad: inputsForm.codDad,
        relaciones: relaciones.map(ctRel => formatRelacionToFetch(ctRel))
      }

      if (wasModified) {
        if (asignatura) {
          const arrRelacionesNuevas = []
          const arrRelacionesBorradas = asignatura.relaciones.filter(ctRel => !relaciones.some(relOriginal => relOriginal.id == ctRel.id))
          relaciones.forEach(ctRelacion => {
            if (!ctRelacion.id) {
              arrRelacionesNuevas.push(ctRelacion)
            }
          })

          const paramsEdit = {
            id: asignatura.id,
            nombre: inputsForm.nombre,
            folio: inputsForm.folio,
            cod_dad: inputsForm.codDad,
            relaciones_borradas: arrRelacionesBorradas.map(ctRel => ctRel.id),
            relaciones_nuevas: arrRelacionesNuevas.map(ctRel => formatRelacionToFetch(ctRel)),
          }
          if (await asignaturaService.editAsignatura(paramsEdit, resolve)) setWasModified(false);
        } else {
          if (await asignaturaService.createNewAsignatura(params, resolve)) setWasModified(false);
        }

      } else {
        await resolve(params);
      }
      setWasModified(false);
    }
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e)
    if (setAvailableCerrarMod) {
      setAvailableCerrarMod(false);
    }
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, setWasModified, e);
      if (setAvailableCerrarMod) {
        setAvailableCerrarMod(false);
      }
    }
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const loadAsignaturaInfo = () => {
    setRelaciones(asignatura.relaciones)
    setInputsForm({ ...asignatura });
  }

  const addRelacion = (newElement) => {
    setRelaciones(st => [...st, newElement])
    setWasModified(true)
  }

  const removeRelacion = (index) => {
    setRelaciones(st => st.filter((e, ctIndex) => index != ctIndex))
    setWasModified(true)
  }

  const checkIsAvailableOption = (newElement) => {
    return !relaciones.some(ctRel =>
      ctRel.area.id == newElement.area.id &&
      ctRel.tipoEscuela.id == newElement.tipoEscuela.id &&
      ctRel.especialidad.id == newElement.especialidad.id &&
      ctRel.cargo.id == newElement.cargo.id
    )
  }

  useEffect(() => {
    if (inputsForm.length < 1 && asignatura) {
      loadAsignaturaInfo();
      setWasModified(false);
    }
  }, [asignatura])

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    getCurrentApendice();

    return () => (isMountedRef.current = false);
  }, [dispatch]);

  return (
    <Row>
      <Col>
        {true ? (
          <Form
            id="formCreateAsignatura"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            <Row>
              <Col xs={12} md={8} lg={7}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre de la asignatura"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  maxLength="200"
                  required
                  {...props}
                ></Input>
              </Col>
              <Col xs={8} md={4} lg={2}>
                <Input
                  type="input"
                  controlId="folio"
                  label="Folio"
                  maxLength="100"
                  value={inputsForm.folio ? inputsForm.folio : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  required
                  {...props}
                ></Input>
              </Col>
              <Col xs={8} md={4} lg={2}>
                <Input
                  type="input"
                  controlId="codDad"
                  label="Código DAD"
                  value={inputsForm.codDad ? inputsForm.codDad : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                  required
                  disabled={typeOfAction === typeOfActions.EDIT}
                  {...props}
                ></Input>
              </Col>
              <Col xs={4} md={2} xl={1}>
                <Input
                  type="input"
                  controlId="apendice"
                  label="Apéndice"
                  classNameLabel="text-muted"
                  onChange={handleChange}
                  readOnly
                  value={inputsForm.apendice ? inputsForm.apendice.id : "---"}
                ></Input>
              </Col>
            </Row>

            <hr style={{ marginTop: 0 }} />

            <Row>
              <Col>
                <h3>Relaciones:</h3>
                {/* <span
                  className={`invalid-feedback ${validated && relaciones.length == 0 ? "show-block" : ""}`}
                >
                  Es necesario seleccionar por lo menos 1 relacion
                </span> */}
              </Col>
            </Row>

            {
              (typeOfAction != typeOfActions.APPROVAL) &&
              <>
                <RelacionesAsignaturaForm
                  addRelacion={addRelacion}
                  checkIsAvailableOption={checkIsAvailableOption}
                />
                <h4>Relaciones seleccionadas:</h4>
              </>
            }

            <RelacionesAsignaturaTable
              elementos={relaciones}
              handleRemove={typeOfAction == typeOfActions.APPROVAL ? null : removeRelacion}
            />

            <Row className="my-4">
              <Col>
                {wasModified && (typeOfAction == typeOfActions.CREATE || typeOfAction == typeOfActions.EDIT) &&
                  <PrivateComponent>
                    <Dialog
                      action={handleBorrador}
                      target={asignatura}
                      title={"Guardar como borrador"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="secondary"
                      btnText="Guardar como borrador"
                      isLoadingText={"Guardando"}
                      body={<span>
                        ¿Estás seguro de guardar como borrador la asignatura
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""}</b>
                        ?
                      </span>}
                    />
                  </PrivateComponent>
                }

                {(typeOfAction == typeOfActions.APPROVAL) &&
                  <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_APROBAR}>
                    <Dialog
                      action={handleAprobacion}
                      target={asignatura}
                      title="Confirmar aprobación"
                      variant="success"
                      size="md"
                      btnConfirmText="Aprobar"
                      btnText="Aprobar asignatura"
                      btnVariant="success"
                      isLoadingText={"Aprobando"}
                      disabled={!props.disabled}
                      body={<span>¿Estás seguro de aprobar la asignatura
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""}</b>?
                      </span>}
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>

            <Row>
              <Col>
                {false &&
                  <PrivateComponent>
                    <Dialog
                      action={handlePublish}
                      target={asignatura}
                      title="Publicar modificaciones"
                      size="md"
                      btnConfirmText="Confirmar y enviar"
                      btnText="Publicar modificaciones"
                      btnVariant="success"
                      body={
                        "Al publicar las modificaciones el cargo volverá " +
                        "a estar en estado Publicado con todas las modificaciones que hayas realizado"
                      }
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
          </Form >
        ) : (
          <Loader></Loader>
        )}
      </Col >
    </Row >
  );
};

export default AsignaturasABMForm;
