import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./index";

const middleware = [thunk];

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25
    // Specify here name, actionsBlacklist, actionsCreators and other options
});
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
));

export default store;
