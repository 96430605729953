import { faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import IconButton from "../../../../generics/IconButton";
import { error } from "../../../../store/alerts/alertActions";
import { RelacionDetailBody } from "./RelacionDetailBody";

export const RelacionDetailModal = ({
    relacionado,
    original,
    withPublicar = false,
    handlePublicar,
    ...props
}) => {
    const [show, setShow] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        console.log({ relacionado, original })
    };

    const handlePublish = async () => {
        try {
            setIsFetching(true)
            console.log({ relacionado })
            await handlePublicar(relacionado.id)
            handleClose();
        }
        catch (err) {
            dispatch(error(err.response.data.message));
        }
        finally {
            setIsFetching(false)
        }
    };

    return (
        <>
            <IconButton {...props} icon={withPublicar ? faUpload : faSearch} onClick={handleShow} >
                {props.children}
            </IconButton>
            <Modal
                show={show}
                onHide={!isFetching ? handleClose : null}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={isFetching}
                backdrop={isFetching ? 'static' : true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="font-weight-light">Detalle:</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RelacionDetailBody relacionado={relacionado} original={original}></RelacionDetailBody>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={isFetching} onClick={handleClose}>
                        Volver
                    </Button>
                    {withPublicar && <Button variant="primary" disabled={isFetching}
                        onClick={handlePublish}>{
                            isFetching ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        variant={"secondary"}
                                    />
                                    <span className="ml-1">Publicando</span>
                                </>
                                :
                                "Publicar"
                        }
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
    );
}