import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const CustomTooltip = ({ text, placement = "top", children, ...props }) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${placement}`}>
          {text}
        </Tooltip>
      }
      {...props}
    >
      {children}
    </OverlayTrigger>
  );
};
