import React, { useEffect, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import * as apendiceService from "../../apendice/ApendiceService"
import PrivateComponent from "../../authentication/PrivateComponent"
import Dialog from "../../generics/dialog"
import { clear } from "../../store/alerts/alertActions"
import { addConcurrenciaEmptyRow, saveIncumbenciasData } from "../../store/incumbencias/incumbenciasActions"
import { clearMessage } from "../../store/messages/messageActions"
import CuadroSeleccion from "../../titulos/shared/CuadroSeleccion"
import Input from "../../ui/Input"
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons"
import { concurrenciasConstants, incumbenciasConstants, typeOfActions } from "../../utils/Constants"
import { handlerChange, scrollIntoViewFormError, } from "../../utils/commons"
import { titulosIcon } from "../../utils/menuDashboardOptions"
import incumbenciasService from "../IncumbenciasService"
import { ConcurrenciasABMTable } from "./ConcurrenciasABMTable"
import { removeArrElementsFromArrConcurrencias, removeArrElementsFromArrIncumbencias } from "./IncumbenciasABMEdit"
import { IncumbenciasABMTable } from "./IncumbenciasABMTable"
import { IncumbenciasSelectRow } from "./IncumbenciasSelectRow"
import { PERMISOS_MAP } from "../../utils/Permisos"
import { addData, removeData } from "../../store/data/dataActions"

export const IncumbenciasABMForm = ({
  title,
  onSubmit,
  mainElement,
  changeSelectedDocument,
  hideApendice,
  showDeletedElementTable,
  showFabButtons,
  hideButtonsMainElement,
  hideButtonsSecondaryElement,
  hideBuscadoresMainElement,
  hideBuscadoresSecondaryElement,
  showActionsColMainElement,
  setConcurrenciaId,
  apendiceSeleccionado,
  setApendiceSeleccionado,
}) => {
  const [inputsForm, setInputsForm] = useState({})

  const [validated, setValidated] = useState(false)
  const [wasModified, setWasModified] = useState(false)

  const isMountedRef = useRef(null)
  const currentForm = useRef()

  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation()

  const { typeOfAction, ternaSeleccionada, cargoSeleccionado, deletedElements, deletedConcurrencias, concurrenciasSeleccionadas } = useSelector(st => st.incumbencias)
  const isTernaMainElement = mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA;
  const isCargoMainElement = !isTernaMainElement;

  const getCurrentApendice = async () => {
    apendiceService.getCurrentApendice().then((result) => {
      if (isMountedRef.current) {
        setInputsForm((data) => ({ ...data, apendice: result }))
      }
    })
  }

  const resetFormValues = () => {
    setValidated(false)
    setWasModified(false)
  }

  const handleChangeRowCargoInputs = (
    evento,
    currentCargo,
    isValidMethod,
    typeOfCargo,
    multiIndex
  ) => {
    console.log("handleChangeRowCargoInputs", {
      evento,
      currentCargo,
      isValidMethod,
      typeOfCargo,
      multiIndex
    })
    if (isValidMethod && evento.target.value) {
      if (!isValidMethod(evento.target.value)) {
        return false
      }
    }
    const newArrCargos = cargoSeleccionado.map((elemento) => {
      if (elemento.cargo.id == currentCargo.cargo.id) {
        if (typeOfCargo) {
          elemento.variantes[multiIndex][evento.target.id] = evento.target.value
          if (evento.target.id == "condicion") {
            elemento.variantes[multiIndex]["puntaje"] = null
            elemento.variantes[multiIndex]["sumaOT"] = false
          }
        } else {
          elemento[evento.target.id] = evento.target.value
          if (evento.target.id == "condicion") {
            elemento["puntaje"] = null
            elemento["sumaOT"] = false
          }
        }
      }
      return elemento
    })
    dispatch(saveIncumbenciasData({ cargoSeleccionado: newArrCargos }))

    setWasModified(true)
  }

  const handleChangeRowTernaInputs = (evento, currentTerna, isValidMethod) => {
    if (isValidMethod && evento.target.value) {
      if (!isValidMethod(evento.target.value)) {
        return false
      }
    }
    const newArrTernas = ternaSeleccionada.map((elemento) => {
      if (elemento.terna.id == currentTerna.terna.id) {
        elemento[evento.target.id] = evento.target.value
        if (evento.target.id == "condicion") {
          elemento["puntaje"] = null
          elemento["sumaOT"] = false
        }
      }
      return elemento
    })
    dispatch(saveIncumbenciasData({ ternaSeleccionada: newArrTernas }))

    setWasModified(true)
  }

  const renderSelect = (elemento, tipo, showButtons, restoreActionIcon) => {
    return (
      <IncumbenciasSelectRow
        elemento={elemento}
        mainElement={mainElement}
        handleChangeRowCargoInputs={handleChangeRowCargoInputs}
        handleChangeRowTernaInputs={handleChangeRowTernaInputs}
        restoreActionIcon={restoreActionIcon}
        tipo={tipo}
        showButtons={showButtons}
        validated={validated}
        showActionsCol={showActionsColMainElement}
        hideColumns={{
          estado: typeOfAction == typeOfActions.CREATE,
        }}
      />
    )
  }

  const renderTableSelectConcurrencias = (elementos, hideButtonsSecondaryElement, restoreActionIcon, key) => {
    return (
      <ConcurrenciasABMTable
        currentKey={key}
        isTernaMainElement={isTernaMainElement}
        elementos={elementos}
        changeSelectedDocument={changeSelectedDocument}
        setConcurrenciaId={setConcurrenciaId}
        validated={validated}
        restoreActionIcon={restoreActionIcon}
        setWasModified={setWasModified}
        ocultarBotones={hideButtonsSecondaryElement}
        ocultarEliminarElementosInternos={hideButtonsSecondaryElement}
      />
    )
  }

  const renderTableSelectCargo = (elementos, restoreActionIcon) => {
    const tipo = incumbenciasConstants.CARGOS_INCUMBENCIA
    const showButtons = isCargoMainElement ? !hideButtonsMainElement : !hideButtonsSecondaryElement

    return (
      <IncumbenciasABMTable
        elementos={elementos}
        tipo={tipo}
        renderTableRows={() => renderSelect(elementos, tipo, showButtons, restoreActionIcon)}
        showCheckboxCol={false}
        showCondicionCol={!isCargoMainElement}
        showFolioCol={!isCargoMainElement}
        showApendiceCol={!isCargoMainElement && typeOfAction != typeOfActions.CREATE}
        showActionsCol={true}
        showPaginacion={false}
        hideColumns={{ estado: typeOfAction == typeOfActions.CREATE }}
      />
    )
  }

  const renderTableSelectTerna = (elementos, restoreActionIcon) => {
    const tipo = incumbenciasConstants.TERNAS_INCUMBENCIA
    const showButtons = isTernaMainElement ? !hideButtonsMainElement : !hideButtonsSecondaryElement

    return (
      <IncumbenciasABMTable
        elementos={elementos}
        tipo={tipo}
        renderTableRows={() => renderSelect(elementos, tipo, showButtons, restoreActionIcon)}
        showCheckboxCol={false}
        showCondicionCol={!isTernaMainElement}
        showFolioCol={!isTernaMainElement}
        showApendiceCol={!isTernaMainElement && typeOfAction != typeOfActions.CREATE}
        showActionsCol={true}
        showPaginacion={false}
        hideColumns={{ estado: typeOfAction == typeOfActions.CREATE }}
      />
    )
  }

  const isAnyTernaSeleccionada = () => {
    return ternaSeleccionada?.length > 0
  }

  const isAnyCargoSeleccionado = (validateSelectedElements) => {
    if (cargoSeleccionado?.length > 0) {
      if (validateSelectedElements) {
        return !cargoSeleccionado.some(ctCargo => {
          if (ctCargo.type == "multi") return ctCargo.variantes.length == 0;
          return false
        })
      }
      return true;
    }
    return false;
  }

  const isAnyIncumbenciaSeleccionada = (validateSelectedElements) => {
    if (isTernaMainElement) {
      /* incumbencias == cargos */
      return isAnyCargoSeleccionado(validateSelectedElements)
    } else {
      /* incumbencias == ternas */
      return isAnyTernaSeleccionada()
    }
  }

  const isAnyConcurrenciaSeleccionada = (validateSelectedElements) => {
    const tipoConcurrenciasData = Object.prototype.toString.call(concurrenciasSeleccionadas);

    /* Nunca fue seleccionada una concurrencia */
    if (tipoConcurrenciasData == "[object Undefined]") return false;

    if (tipoConcurrenciasData == "[object Array]") {
      /* Fueron seleccionas y removidas todas las concurrencias */
      if (concurrenciasSeleccionadas.length == 0) {
        console.log("NO TIENE CONCURRENCIAS SELECCIONADAS")
        return false
      };

      if (validateSelectedElements) {
        if (isTernaMainElement) {
          /* Validamos que si el cargo es multi tenga variantes cargadas */
          const isInvalidCargo = (ctCargo) => {
            if (ctCargo.type == "multi") return ctCargo.variantes.length == 0;
            return false
          }

          /* validamos que sea concurrencia valida => con alguna terna + algun cargo, y si el cargo es multi, con alguna variante */
          const isInvalidConcurrencia = (ctConc) => {
            return (ctConc.cargosSeleccionados.length == 0) ||
              (ctConc.ternasSeleccionadas.length == 0) ||
              (ctConc.cargosSeleccionados.some(ctCargo => isInvalidCargo(ctCargo)))
          }

          if (concurrenciasSeleccionadas.some(ctConc => isInvalidConcurrencia(ctConc))) return false
        } else {
          /* validamos que sea concurrencia valida => por lo menos 2 ternas */
          const isInvalidConcurrencia = (ctConc) => {
            return (ctConc.ternasSeleccionadas.length < 2)
          }

          if (concurrenciasSeleccionadas.some(ctConc => isInvalidConcurrencia(ctConc))) return false
        }
      }
      return true;
    }
  }

  const handleSubmit = async (params = {}) => {
    dispatch(clearMessage());
    setValidated(true);

    const isAnyTerna = isAnyTernaSeleccionada();
    const isAnyCargo = isAnyCargoSeleccionado(true);
    const isAnyIncumbencia = isAnyIncumbenciaSeleccionada(true);
    const isAnyConcurrencia = isAnyConcurrenciaSeleccionada();
    const isAnyConcurrenciaFullCompleted = isAnyConcurrenciaSeleccionada(true);

    const isValidCreateForm = isTernaMainElement ?
      isAnyTerna &&
      (isAnyConcurrencia ? isAnyConcurrenciaFullCompleted : isAnyIncumbencia) &&
      currentForm.current.checkValidity()
      :
      isAnyCargo &&
      (isAnyConcurrencia ? isAnyConcurrenciaFullCompleted : isAnyIncumbencia) &&
      currentForm.current.checkValidity()

    const isValidEditForm = isTernaMainElement ?
      isAnyTerna && (isAnyConcurrencia ? isAnyConcurrenciaFullCompleted : true) &&
      currentForm.current.checkValidity()
      :
      isAnyCargo && (isAnyConcurrencia ? isAnyConcurrenciaFullCompleted : true) &&
      currentForm.current.checkValidity()

    const isValidForm = typeOfAction == typeOfActions.CREATE ? isValidCreateForm : isValidEditForm

    if (isValidForm) {
      if (onSubmit) {
        /* CASO EDITAR INCUMBENCIA */
        const result = await onSubmit(params);
      } else {
        /* CASO CREAR INCUMBENCIA */
        const resolveAction = (resultData) => {
          const returnPath = location.state?.returnPath

          if (returnPath) {
            /* CASO CREAR INCUMBENCIA DESDE EL EDITAR */
            console.log("CASO CREAR INCUMBENCIA DESDE EL EDITAR", {
              incumbenciasCreateResult: {
                ...resultData,
                agregadosFromData: {
                  cargoSeleccionado,
                  ternaSeleccionada,
                  concurrenciasSeleccionadas: JSON.parse(JSON.stringify(concurrenciasSeleccionadas))
                }
              }
            })

            const addApendiceToCargo = (cargoSeleccionado) => {
              console.log("addApendiceToCargo", cargoSeleccionado)
              return cargoSeleccionado?.map(ctCargo =>
                ctCargo.type == "simple" ?
                  { ...ctCargo, apendice_id: apendiceSeleccionado.value }
                  :
                  ctCargo.variantes.map(ctV => ({ ...ctV, apendice_id: apendiceSeleccionado.value }))
              )
            }

            const addApendiceToTerna = (ternaSeleccionada) => {
              console.log("addApendiceToTerna", ternaSeleccionada)
              return ternaSeleccionada?.map(ctTerna => ({ ...ctTerna, apendice_id: apendiceSeleccionado.value }))
            }

            const addApendiceToConcurrencia = (concurrenciasSeleccionadas) => {
              console.log({ concurrenciasSeleccionadas })
              if (!concurrenciasSeleccionadas) return
              const concurrenciasWithApendice = concurrenciasSeleccionadas.map(ctConc => {
                const elementosConApendice = isTernaMainElement ?
                  addApendiceToCargo(ctConc.cargosSeleccionados)
                  :
                  addApendiceToTerna(ctConc.ternasSeleccionadas)
                const keyToAddApendice = isTernaMainElement ? "cargosSeleccionados" : "ternasSeleccionadas"
                return { ...ctConc, [keyToAddApendice]: elementosConApendice }
              })
              console.log({ concurrenciasSeleccionadas, isTernaMainElement, concurrenciasWithApendice })
              return concurrenciasWithApendice
            }

            const cargoSeleccionadoFormated = isTernaMainElement && cargoSeleccionado ? addApendiceToCargo(cargoSeleccionado) : cargoSeleccionado
            const ternaSeleccionadaFormated = isTernaMainElement ? ternaSeleccionada : addApendiceToTerna(ternaSeleccionada)

            dispatch(saveIncumbenciasData({
              incumbenciasCreateResult: {
                ...resultData,
                agregadosFromData: {
                  cargoSeleccionado: cargoSeleccionadoFormated,
                  ternaSeleccionada: ternaSeleccionadaFormated,
                  concurrenciasSeleccionadas: concurrenciasSeleccionadas ? JSON.parse(JSON.stringify(addApendiceToConcurrencia(concurrenciasSeleccionadas))) : concurrenciasSeleccionadas
                }
              }
            }));
            history.push(returnPath);
          } else {
            console.log("CASO CREAR INCUMBENCIA DESDE +")
            /* CASO CREAR INCUMBENCIA DESDE +*/
            if (resultData.incumbencias?.agregados?.length > 0) {
              let onlyNoCreatedIncumbencias = removeArrElementsFromArrIncumbencias(
                isTernaMainElement ? cargoSeleccionado : ternaSeleccionada,
                isTernaMainElement ? "cargo" : "terna",
                resultData.incumbencias.agregados
              )
              if (isTernaMainElement) {
                onlyNoCreatedIncumbencias = onlyNoCreatedIncumbencias.filter(currentCargo => {
                  if (currentCargo.type == "multi" && currentCargo.variantes.length == 0) {
                    return false
                  }
                  return currentCargo
                })
              }
              const secondaryElementKey = isCargoMainElement ? "ternaSeleccionada" : "cargoSeleccionado"
              dispatch(saveIncumbenciasData({ [secondaryElementKey]: onlyNoCreatedIncumbencias }))
            }

            if (resultData.concurrencias?.agregados?.length > 0) {
              let onlyNoCreatedConcurrencias = removeArrElementsFromArrConcurrencias(
                concurrenciasSeleccionadas,
                isTernaMainElement,
                resultData.concurrencias.agregados,
              )
              dispatch(saveIncumbenciasData({ "concurrenciasSeleccionadas": onlyNoCreatedConcurrencias }))
            }
          }
        }

        const formData = {
          ternaSeleccionada,
          cargoSeleccionado,
          concurrenciasSeleccionadas,
          apendice_id: apendiceSeleccionado.value,
        }
        console.log("ENVIAMOS FORMDATA", formData)
        const result = await incumbenciasService.createNewIncumbencia(formData, mainElement, resolveAction, params);
        if (result) resetFormValues();
      }
    } else {
      scrollIntoViewFormError();
    }
    setWasModified(false)
  }

  const generalFormError = (!isAnyIncumbenciaSeleccionada() && !isAnyConcurrenciaSeleccionada())
    ?
    "Es requerido ingresar por lo menos una Incumbencia o una Concurrencia"
    :
    "El formulario presenta campos sin completar"

  const errorValidateMessageCuadroTerna = isTernaMainElement ? "El campo es obligatorio" : generalFormError;
  const errorValidateMessageCuadroCargo = isCargoMainElement ? "El campo es obligatorio" : generalFormError;


  const isInvalidConcurrencia = (ctConc) => {
    return (ctConc.ternasSeleccionadas.length < 2)
  }

  const allConcurrenciasWithMin2Ternas = () => {
    if (concurrenciasSeleccionadas?.some(ctConc => isInvalidConcurrencia(ctConc))) return false
    return true
  }

  const errorValidateMessageCuadroConcurrencias = (!isAnyIncumbenciaSeleccionada() && !isAnyConcurrenciaSeleccionada())
    ?
    "Es requerido ingresar por lo menos una Incumbencia o una Concurrencia"
    :
    (isCargoMainElement && !allConcurrenciasWithMin2Ternas()) ?
      "Hay concurrencias que no cumplen con el minimo de ternas necesarias. Para ser una concurrencia valida debe tener " + concurrenciasConstants.cantidadMinimaTernasPorConcurrencia + " o mas ternas"
      :
      "El formulario presenta campos sin completar"


  const validatedCuadroTerna = typeOfAction == typeOfActions.CREATE &&
    (
      isTernaMainElement ?
        !isAnyTernaSeleccionada()
        :
        isAnyIncumbenciaSeleccionada()
        ||
        (!isAnyIncumbenciaSeleccionada() && !isAnyConcurrenciaSeleccionada())
    )
    ? validated : false

  const validatedCuadroCargo = typeOfAction == typeOfActions.CREATE &&
    (
      isCargoMainElement ?
        !isAnyCargoSeleccionado()
        :
        isAnyIncumbenciaSeleccionada()
        ||
        (!isAnyIncumbenciaSeleccionada() && !isAnyConcurrenciaSeleccionada())
    )
    ? validated : false

  const validatedCuadroConcurrencias = typeOfAction == typeOfActions.CREATE &&
    (
      isAnyConcurrenciaSeleccionada()
      ||
      (
        !isAnyIncumbenciaSeleccionada() && !isAnyConcurrenciaSeleccionada()
      )
    )
    ? validated : false

  const handleAddConcurrenciaEmptyRow = () => {
    setWasModified(true)
    setValidated(false)
    dispatch(addConcurrenciaEmptyRow())
  }

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e);
  };

  const loadApendices = async () => {
    const response = await apendiceService.getApendices({ limit: 99999 })
    dispatch(addData({ apendices: response.elementos }));
  }

  useEffect(() => {
    setInputsForm(oldData => {
      let dataToAdd = { ...oldData };
      if (ternaSeleccionada) {
        dataToAdd = { ...dataToAdd, ternaSelected: ternaSeleccionada };
      }
      if (cargoSeleccionado) {
        dataToAdd = { ...dataToAdd, cargoSelected: cargoSeleccionado };
      }
      return dataToAdd;
    })
  }, [ternaSeleccionada, cargoSeleccionado])

  useEffect(() => {
    isMountedRef.current = true
    dispatch(clear())
    return () => (isMountedRef.current = false)
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(inputsForm).length > 0) {
      setWasModified(true)
      setValidated(false)
    }
  }, [inputsForm])

  useEffect(() => {
    if (typeOfAction == typeOfActions.CREATE && !hideApendice) getCurrentApendice();
    if (typeOfAction == typeOfActions.EDIT) {
      dispatch(removeData("apendices"))
      loadApendices()
    }
  }, [typeOfAction])

  return (
    <ScreenWithFabButtons
      showFabButtons={showFabButtons}
      containerFluid={true}
      returnPath={location.state?.returnPath}
    >
      <Form
        ref={currentForm}
        noValidate
        validated={validated}
        onSubmit={(event) => event.preventDefault()}
      >
        <Row className="form-group container mx-auto">
          <Col><h1>{title ? title : "Crear incumbencia"}</h1></Col>
        </Row>
        <Row className="container mx-auto">
          {!hideApendice && typeOfAction == typeOfActions.CREATE &&
            <Col md={6} lg={4} className="pe-all">
              <Input
                type="react-select"
                controlId="apendice_id"
                name="apendice_id"
                label="Apéndice"
                value={apendiceSeleccionado}
                methodOnChange={handleChange}
                elements={apendiceService.getApendices}
                nameElementsResponse="elementos"
                propertiesForFormat={{ value: "id", label: "nombreInput" }}
                setterElementSelect={setApendiceSeleccionado}
                config={{ isSearchable: true }}
                predictivoAsync={false}
                defaultIndexSelectedOptionInSyncSelect={"0"}
              ></Input>
            </Col>
          }
        </Row>

        <div className={`d-flex ${isTernaMainElement ? "flex-column" : "flex-column-reverse"}`}>
          <Row className="mb-3 container mx-auto">
            <Col xs={12} md={12} lg={12}>
              <CuadroSeleccion
                title={isTernaMainElement ? "Terna seleccionada" : "Incumbencias con:"}
                secondButtonText={typeOfAction != typeOfActions.CREATE && isTernaMainElement ? "Cambiar" : "Agregar"}
                icon={titulosIcon}
                text={typeOfAction == typeOfActions.CREATE ? "Parece que todavía no hay una terna seleccionada" : "Se han removido todas las ternas"}
                onClick={() => changeSelectedDocument({ documentSelected: incumbenciasConstants.TERNAS_INCUMBENCIA, checkedElements: ternaSeleccionada })}
                validated={validatedCuadroTerna}
                elementos={ternaSeleccionada}
                renderTable={(elementos) => renderTableSelectTerna(elementos)}
                ocultarBuscador={isTernaMainElement ? hideBuscadoresMainElement : hideBuscadoresSecondaryElement}
                firstButtonText={typeOfAction == typeOfActions.CREATE ? "Buscar" : ""}
                errorValidatedMessage={errorValidateMessageCuadroTerna}
              />
            </Col>
          </Row>

          <Row className={`mb-3 ${!(cargoSeleccionado?.length > 0) ? "container mx-auto" : "col-fluid"}`}>
            <Col xs={12} md={12} lg={12}>
              <CuadroSeleccion
                title={isCargoMainElement ? "Cargo seleccionado" : "Incumbencias con:"}
                secondButtonText={typeOfAction != typeOfActions.CREATE && isCargoMainElement ? "Cambiar" : "Agregar"}
                icon={titulosIcon}
                text={typeOfAction == typeOfActions.CREATE ? "Parece que todavía no hay un cargo seleccionado" : "Parece que se han eliminado todos los cargos"}
                onClick={() => changeSelectedDocument({ documentSelected: incumbenciasConstants.CARGOS_INCUMBENCIA, checkedElements: cargoSeleccionado })}
                validated={validatedCuadroCargo}
                elementos={cargoSeleccionado}
                renderTable={(elementos) => renderTableSelectCargo(elementos)}
                ocultarBuscador={isCargoMainElement ? hideBuscadoresMainElement : hideBuscadoresSecondaryElement}
                firstButtonText={typeOfAction == typeOfActions.CREATE ? "Buscar" : ""}
                errorValidatedMessage={errorValidateMessageCuadroCargo}
              // printError={validated ? validated : ""}
              />
            </Col>
          </Row>
        </div>

        <Row className={`mb-3 ${!(concurrenciasSeleccionadas?.length > 0) ? "container mx-auto" : "col-fluid"}`}>
          <Col xs={12} md={12} lg={12}>
            <CuadroSeleccion
              title={"Concurrencias con:"}
              secondButtonText={"Agregar concurrencia"}
              icon={titulosIcon}
              text={typeOfAction == typeOfActions.CREATE ? "Parece que todavía no hay concurrencias asignadas a la terna principal seleccionada" : "Parece que se han eliminado todas las concurrencias"}
              onClick={handleAddConcurrenciaEmptyRow}
              validated={validatedCuadroConcurrencias}
              elementos={concurrenciasSeleccionadas}
              renderTable={(elementos) => renderTableSelectConcurrencias(elementos, hideButtonsSecondaryElement, false, "selectedConcurrencias")}
              firstButtonText={typeOfAction == typeOfActions.CREATE ? "Agregar" : ""}
              errorValidatedMessage={errorValidateMessageCuadroConcurrencias}
              ocultarBuscador={hideButtonsSecondaryElement}
            />
          </Col>
        </Row>
      </Form>

      {
        showDeletedElementTable && deletedElements?.length > 0 &&
        <Row className={`mb-3  ${isCargoMainElement || deletedElements.length == 0 ? "container mx-auto" : "col-fluid"}`}>
          <Col xs={12} md={12} lg={12}>
            <CuadroSeleccion
              title={"Incumbencias a eliminar:"}  /* {isCargoMainElement ? "Terna/s a eliminar" : "Cargo/s a eliminar"} */
              cardClassName="warning-card"
              icon={titulosIcon}
              text={`No hay ${isCargoMainElement ? "ternas seleccionadas" : "cargos seleccionados"} para eliminar`}
              elementos={deletedElements.length > 0 ? deletedElements : ""}
              renderTable={isCargoMainElement ?
                (elementos) => renderTableSelectTerna(elementos, true)
                :
                (elementos) => renderTableSelectCargo(elementos, true)
              }
              ocultarBuscador
              firstButtonText={""}
            />
          </Col>
        </Row>
      }

      {
        showDeletedElementTable && deletedConcurrencias?.length > 0 &&
        <Row className={`mb-3  ${!(deletedConcurrencias?.length > 0) ? "container mx-auto" : "col-fluid"}`}>
          <Col xs={12} md={12} lg={12}>
            <CuadroSeleccion
              title={"Concurrencias a eliminar:"}
              cardClassName="warning-card"
              icon={titulosIcon}
              text={`No hay concurrencias para eliminar`}
              elementos={deletedConcurrencias}
              renderTable={(elementos) => renderTableSelectConcurrencias(elementos, hideButtonsSecondaryElement, true, "deletedConcurrencias")}
              ocultarBuscador
              firstButtonText={""}
            />
          </Col>
        </Row>
      }
      <Row className="container mx-auto mt-4">
        <Col className="pl-4 ml-2">
          <Row>
            {
              typeOfAction == typeOfActions.EDIT &&
              <PrivateComponent>
                <div className="mr-5">
                  <Dialog
                    action={handleSubmit}
                    title={"Guardar cambios"}
                    variant="primary"
                    size="md"
                    btnConfirmText="Guardar"
                    btnVariant="secondary"
                    btnText="Guardar cambios"
                    isLoadingText={"Guardando"}
                    disabled={!wasModified}
                    body={<span>¿Estás seguro de mantener la incumbencia como borrador?</span>}
                  />
                </div>
              </PrivateComponent>
            }

            <div>
              {
                <Dialog
                  action={() => handleSubmit({ borrador: true, aprobada: false, publicada: false })}
                  title={"Guardar como borrador"}
                  variant="primary"
                  size="md"
                  btnConfirmText="Guardar"
                  btnVariant="secondary"
                  btnText="Guardar como borrador"
                  isLoadingText={"Guardando"}
                  body={<span>¿Estás seguro de mantener la incumbencia como borrador?</span>}
                />
              }
              {
                typeOfAction != typeOfActions.CREATE && <>
                  <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_APROBAR}>
                    <Dialog
                      action={() => handleSubmit({ aprobada: true })}
                      title={"Guardar como aprobado"}
                      variant="success"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="success"
                      btnText="Guardar como aprobado"
                      isLoadingText={"Guardando"}
                      body={<span>¿Estás seguro de mantener la incumbencia como aprobado?</span>}
                    />
                  </PrivateComponent>
                  <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_PUBLICAR}>
                    <Dialog
                      action={() => handleSubmit({ publicada: true })}
                      title={"Guardar como publicado"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="primary"
                      btnText="Guardar como publicado"
                      isLoadingText={"Guardando"}
                      body={<span>¿Estás seguro de mantener la incumbencia como publicado?</span>}
                    />
                  </PrivateComponent>
                </>
              }
            </div>

          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons >
  )
}