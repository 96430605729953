import { Form, Col, Row, Button, Container } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routePaths, typeOfActions } from "../../utils/Constants";
import { isValidNumeric, handlerChange } from "../../utils/commons";
import Input from "../../ui/Input";
import CuadroSeleccion from "./CuadroSeleccion";
import PrivateComponent from "../../authentication/PrivateComponent";
import Dialog from "../../generics/dialog";
import titulos from "../../images/titulos-icon.svg";
import intermedios from "../../images/intermedios-icon.svg";
import { error, info, success } from "../../store/alerts/alertActions";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { patchTitulosITF, postTitulosITF } from "../ITF/ITFService";

export const RelacionABMForm = ({
    title,
    inputsForm,
    setInputsForm,

    arrOriginalesSeleccionados,
    arrRelacionesSeleccionadas,

    changeToSeleccionarOriginalSeccion,
    changeToSeleccionarRelacionesSeccion,

    typeOfAction,

    renderTableOriginal,
    renderTableRelacionados,

    handleSubmit,
}) => {

    const isMountedRef = useRef(null);
    const formRef = useRef();
    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e);
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    };

    const handleBorrador = async (event) => {
        await handleValidation();
    };

    const handleAprobar = async (event) => {
        await handleValidation();
    };

    const handlePublicar = async (event) => {
        await handleValidation();
    };

    const handleValidation = async () => {
        if (formRef.current.checkValidity()) {
            await handleSubmit(arrOriginalesSeleccionados, arrRelacionesSeleccionadas, inputsForm)
        }
        setValidated(true);
    };

    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, [dispatch]);

    return (
        <ScreenWithFabButtons
            containerFluid={true}
        >
            <Container>
                <Col>
                    <h1>{title}</h1>
                </Col>

                <Col>
                    <Form
                        ref={formRef}
                        noValidate
                        validated={validated}
                        onSubmit={event => event.preventDefault()}
                    >
                        <Row>
                            <Col md={2} lg={1}>
                                <Input
                                    type="input"
                                    controlId="apendice"
                                    label="Apéndice"
                                    classNameLabel="text-muted"
                                    readOnly
                                    value={inputsForm.apendice ? inputsForm.apendice.id : "---"}
                                    disabled={typeOfAction == typeOfActions.APPROVAL}
                                ></Input>
                            </Col>
                            <Col md={4} lg={2}>
                                <Input
                                    type="input"
                                    controlId="folio"
                                    label="Folio"
                                    maxLength="100"
                                    value={inputsForm.folio ? inputsForm.folio : ""}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                    required
                                    disabled={typeOfAction == typeOfActions.APPROVAL}
                                ></Input>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col xs={12} md={12} lg={12}>
                                <CuadroSeleccion
                                    title="Título Principal"
                                    icon={intermedios}
                                    text="Parece que todavía no hay un titulo seleccionado"
                                    firstButtonText="Buscar"
                                    secondButtonText="Cambiar"
                                    onClick={changeToSeleccionarOriginalSeccion}
                                    elementos={arrOriginalesSeleccionados}
                                    renderTable={renderTableOriginal}
                                    ocultarBuscador={typeOfAction == typeOfActions.EDIT || typeOfAction == typeOfActions.APPROVAL}
                                />
                            </Col>
                        </Row>

                        {arrOriginalesSeleccionados?.length > 0 && (
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <CuadroSeleccion
                                        title="Título Relacionado"
                                        icon={titulos}
                                        text="Parece que todavía no hay ningun título relacionado seleccionado"
                                        firstButtonText="Buscar"
                                        secondButtonText="Cambiar"
                                        onClick={changeToSeleccionarRelacionesSeccion}
                                        elementos={arrRelacionesSeleccionadas}
                                        renderTable={renderTableRelacionados}
                                        ocultarBuscador={typeOfAction == typeOfActions.EDIT || typeOfAction == typeOfActions.APPROVAL}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row className="my-4">
                            <Col>
                                {
                                    (typeOfAction == typeOfActions.CREATE || typeOfAction == typeOfActions.EDIT) &&
                                    arrOriginalesSeleccionados && arrRelacionesSeleccionadas &&
                                    <PrivateComponent>
                                        <Dialog
                                            action={handleBorrador}
                                            target={"titulo"}
                                            title={"Guardar como borrador"}
                                            variant="primary"
                                            size="md"
                                            btnConfirmText="Guardar"
                                            btnVariant="secondary"
                                            isLoadingText="Guardando"
                                            btnText="Guardar como borrador"
                                            body={
                                                <span>
                                                    ¿Estás seguro de guardar como borrador la relación entre los títulos?
                                                </span>
                                            }
                                        />
                                    </PrivateComponent>
                                }
                                {
                                    (typeOfAction == typeOfActions.APPROVAL) &&
                                    arrOriginalesSeleccionados && arrRelacionesSeleccionadas &&
                                    <PrivateComponent>
                                        <Dialog
                                            action={handleAprobar}
                                            target={"titulo"}
                                            title="Confirmar aprobación"
                                            variant="success"
                                            size="md"
                                            btnConfirmText="Aprobar"
                                            btnText="Aprobar"
                                            btnVariant="success"
                                            isLoadingText={"Aprobando"}
                                            body={
                                                <span>
                                                    ¿Estás seguro de aprobar la relación entre los títulos?
                                                </span>
                                            }
                                        />
                                    </PrivateComponent>
                                }
                                {
                                    (typeOfAction == typeOfActions.PUBLISH) &&
                                    arrOriginalesSeleccionados && arrRelacionesSeleccionadas &&
                                    <PrivateComponent>
                                        <Dialog
                                            action={handlePublicar}
                                            target={"titulo"}
                                            title="Confirmar publicación"
                                            variant="success"
                                            size="md"
                                            btnConfirmText="Publicar"
                                            btnText="Publicar"
                                            btnVariant="success"
                                            isLoadingText={"Publicando"}
                                            body={
                                                <span>
                                                    ¿Estás seguro de publicar la relación entre los títulos?
                                                </span>
                                            }
                                        />
                                    </PrivateComponent>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Container>
        </ScreenWithFabButtons>
    );
};