import React from 'react'
import Input from '../../ui/Input'
import { handlerChange } from '../../utils/commons';
import { Form } from 'react-bootstrap';

export const TicketsDetailClose = ({
  inputsForm,
  setInputsForm,
  formCloseRef,
  validated,
}) => {

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  return (
    <Form
      ref={formCloseRef}
      noValidate
      validated={validated}
      onSubmit={(event) => event.preventDefault()}
    >
      <hr />
      <Input
        type="textarea"
        controlId="observacion_cierre"
        name="observacion_cierre"
        label="Observaciones de cierre"
        rows={4}
        value={inputsForm.observacion_cierre}
        onChangeTextArea={handleChange}
        inputTextLength={255}
      ></Input>
    </Form>
  )
}
