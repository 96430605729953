import React, { useEffect } from 'react'

export const TrEnConcurrenciaCon = ({ data, colSpan }) => {
    console.log({ data })
    return (
        <tr style={{ border: "none" }}>
            <td className="text-info border-0 pt-1" colSpan={colSpan}>
                En concurrencia con:<br></br>
                {data.terna2 && <>
                    <b>{data.terna2?.titulo?.nombre} | {data.terna2?.procedencia?.nombre} | {data.terna2?.resolucion?.nombre} </b>
                    {/* {data.terna2?.regla && <>(Regla: {data.terna2.regla})</>} */}
                    <br></br>
                </>}

                {data.terna3 && <>
                    <b>{data.terna3?.titulo?.nombre} | {data.terna3?.procedencia?.nombre} | {data.terna3?.resolucion?.nombre} </b>
                    {/* {data.terna3?.regla && <>(Regla: {data.terna3.regla})</>} */}
                </>}
            </td>
        </tr>
    )
}
