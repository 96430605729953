import React from 'react';
import { Row, Col, Badge, Table } from "react-bootstrap";
import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";

const ApendiceDetailBody = ({ apendice, ...props }) => {

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            {apendice.getFechaDesde() && (

              <small>
                Creado el: <b>{apendice.getFechaDesde()}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />
      <Row>
        <Col md={6} className="form-group">
          <DetailFact label="Apendice Nº" input={apendice.id} />
        </Col>
        <Col md={6} className="form-group">
          <DetailFact label="Nombre" input={apendice.nombre} />
        </Col>
        <Col md={6} className="form-group">
          <DetailFact label="Fecha Desde" input={apendice.fechaDesde} />
        </Col>
        {
          apendice.habilitado ? 
          <Col md={6} className="form-group">
            <DetailFact label="Fecha Hasta" input={'Actual'} />
          </Col>
          :
          <Col md={6} className="form-group">
            <DetailFact label="Fecha Hasta" input={apendice.fechaHasta} />
          </Col>
        }
        <Col md={6} className="form-group">
          <DetailFact label="Fecha Hasta" input={apendice.fechaHasta} />
        </Col>
        <Col md={6} className="form-group">
          <DetailFact label="Habilitado" input={apendice.habilitado} />
        </Col>
      </Row>
      
      <hr />
      {/* Sin Descripcion */}
      {apendice.descripcion && (
        <Row>
          <Col md={12} className="form-group">
            <hr />
            <label>Descripción: </label>
            <p> {apendice.descripcion} </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ApendiceDetailBody;
