
import { Form, Col, Row } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import Input from "../../../ui/Input";
import { isValidNumeric, handlerChange } from "../../../utils/commons";
import { Loader } from "../../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../../store/alerts/alertActions";
import { saveFilters } from "../../../store/search/searchActions";
import * as especialidadesService from "../../../especialidades/EspecialidadesService";
import * as cargoService from "../../../cargos/CargosService"
import * as genericService from "../../../GenericService";

import { estadosConstans } from "../../../utils/Constants";
import { cleanFab, searchFab } from "../../../store/screen/screenActions";

export const IncumbenciasFormSearchCargos = ({
    setCargos,
    setCantidadResultados,
    setPage,
    onSubmit,
}) => {
    const defaultInputsValues = {
        // estado: [estadosConstans.APROBADO, estadosConstans.MIGRADO]
        apendice: "",
        folio: "",
        cod_dad: "",
    }

    const [inputsForm, setInputsForm] = useState(defaultInputsValues);

    const [areaSeleccionada, setAreaSeleccionada] = useState("");
    const [nombreAsignaturaSeleccionada, setNombreAsignaturaSeleccionada] = useState("");
    const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");
    const [tiposEscuelaSeleccionadas, setTiposEscuelaSeleccionadas] = useState([]);
    const [cargoSeleccionado, setCargoSeleccionado] = useState("");

    const isMountedRef = useRef(null);
    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);

    const processSubmit = async () => {
        dispatch(clear());
        inputsForm["page"] = 1;
        dispatch(saveFilters(inputsForm));
        await onSubmit(inputsForm).catch((err) => {
            dispatch(error(err.response.data.message));
        });
    };

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    };

    const resetForm = () => {
        setInputsForm(defaultInputsValues);
        setAreaSeleccionada("")
        setNombreAsignaturaSeleccionada("")
        setEspecialidadSeleccionada("")
        setTiposEscuelaSeleccionadas("")
        setCargoSeleccionado("")
        setCargos("");
        setCantidadResultados("");
    }

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
            setCargos("");
            setCantidadResultados("");
            setPage(1);
        }
    }, [dispatch]);

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            processSubmit();
            dispatch(searchFab(false));
        }
    }, [screenState])

    return (
        <Row>
            <Col>
                {true ? (
                    <Form id="formSearchAsignaturas">
                        <Row>
                            <Col sm={12} md={3} lg={2}>
                                <Input
                                    type="input"
                                    controlId="apendice"
                                    label="Apendice"
                                    value={inputsForm.apendice}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                ></Input>
                            </Col>
                            <Col sm={12} md={3} lg={2}>
                                <Input
                                    type="input"
                                    controlId="folio"
                                    label="Folio"
                                    maxLength="100"
                                    value={inputsForm.folio}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                ></Input>
                            </Col>
                            <Col sm={12} md={3} lg={2}>
                                <Input
                                    type="input"
                                    controlId="cod_dad"
                                    label="Código DAD"
                                    value={inputsForm.cod_dad}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="area"
                                    name="area"
                                    label="Área"
                                    value={areaSeleccionada}
                                    methodOnChange={handleChange}
                                    elements={especialidadesService.getAreas}
                                    nameElementsResponse="data"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setAreaSeleccionada}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="tipo_escuela"
                                    name="tipo_escuela"
                                    label="Tipo de escuela"
                                    value={tiposEscuelaSeleccionadas}
                                    methodOnChange={handleChange}
                                    elements={genericService.getTiposEscuelas}
                                    nameElementsResponse="elementos"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setTiposEscuelaSeleccionadas}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <Input
                                    type="react-select"
                                    controlId="nombre"
                                    name="nombre"
                                    label="Nombre del cargo"
                                    value={cargoSeleccionado}
                                    methodOnChange={handleChange}
                                    elements={cargoService.getCargos}
                                    nameElementsResponse="data"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setCargoSeleccionado}
                                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                                    withBusquedaAvanzada={true}
                                ></Input>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loader></Loader>
                )}
            </Col>
        </Row>
    );
};
