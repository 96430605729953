import { createAreaEspecialidad } from "../especialidad/AreaEspecialidad";
import { createEspecialidad } from "../especialidad/Especialidad";
import dateFormat from 'dateformat';
import { createEstado } from "../Estado";
import Acciones, { createAcciones } from "../Acciones";
import Nivel, { createNivel } from "../Nivel";

class Cargo {
  constructor(nombre) {
    this.acciones = [];
    this.area = [];
    this.asignatura = true;
    this.asignaturas = [];
    this.codDad = "";
    this.createdAt = "";
    this.descripcion = "";
    this.especialidad = [];
    this.estado = null;
    this.id = 0;
    this.nivel = Nivel;
    this.nombre = "";
    this.resolucion = null;
    this.tipoEscuela = [];
    this.tipoCargo = "";
    this.udpatedAt = "";
    this.relaciones = "";
    this.onlyCreateAsignatura = false;
  }

  getNombreEspecialidades() {
    return this.especialidad.map(e => e.nombre).join(" - ");
  }

  getNombreAsignaturas() {
    return this.asignaturas.map(e => e.nombre).join(" - ");
  }

  // getNombreAreasFromAsignaturas(asignaturaId) {
  //   return this.asignaturas[].map(e => e.nombre).join(" - ");
  // }

  getFechaCreacion() {
    return this.createdAt.split(" ")[0];
  }

  getNombreNivel() {
    return this.nivel ? this.nivel.descripcion : "";
  }

  getNombreAreas() {
    return this.area.map(a => a.nombre).join(" - ");
  }

  getNombreEstado() {
    return this.estado.nombre;
  }

  getNombreTipoCargo() {
    return this.tipoCargo.nombre;
  }

  getNombreTipoEscuela() {
    return this.tipoEscuela.map(te => te.nombre).join(" - ");
  }

  getNombreResolucion() {
    return this.resolucion ? this.resolucion.nombre : "";
  }
}

export default Cargo

export const createCargo = async (cargo) => {
  /* Agrupamos las especialidades dentro de las asignaturas */
  let asignaturasConEspecialidadesAgrupadas = [];
  cargo?.asignaturas?.forEach(ctAsignatura => {
    const ctFormatedEspecialidad = {
      id: ctAsignatura['especialidad.id'],
      nombre: ctAsignatura['especialidad.nombre'],
      codDad: ctAsignatura['especialidad.cod_dad'],
    }

    const ctFormatedArea = {
      id: ctAsignatura['area.id'],
      nombre: ctAsignatura['area.nombre']
    }

    const ctFormatedAsignatura = {
      id: ctAsignatura['asignatura.id'],
      nombre: ctAsignatura['asignatura.nombre'],
      codDad: ctAsignatura['asignatura.cod_dad'],
      especialidades: [ctFormatedEspecialidad],
      area: [ctFormatedArea],
    }

    const asignaturaExistenteIndex = asignaturasConEspecialidadesAgrupadas.findIndex(ctAsignaturaExistente => ctAsignaturaExistente.id == ctFormatedAsignatura.id);
    if (asignaturaExistenteIndex >= 0) {
      const existeArea = asignaturasConEspecialidadesAgrupadas[asignaturaExistenteIndex].area.find(ctAreaExistente => ctAreaExistente.id == ctFormatedArea.id);
      if (!existeArea) asignaturasConEspecialidadesAgrupadas[asignaturaExistenteIndex].area.push(ctFormatedArea)

      const existeEspecialidad = asignaturasConEspecialidadesAgrupadas[asignaturaExistenteIndex].especialidades.find(ctEspecialidadExistente => ctEspecialidadExistente.id == ctFormatedEspecialidad.id);
      if (!existeEspecialidad) asignaturasConEspecialidadesAgrupadas[asignaturaExistenteIndex].especialidades.push(ctFormatedArea)

    } else {
      asignaturasConEspecialidadesAgrupadas.push(ctFormatedAsignatura)
    }

  })
  /* Agrupamos las especialidades dentro de las asignaturas */

  //Por el momento se pasara un area
  let listaAreas = []
  cargo.area.forEach(a => {
    listaAreas.push(createAreaEspecialidad(a))
  });
  let area = await Promise.all(listaAreas)

  let listaEspecialidades = [];
  cargo.especialidad.forEach(e => {
    listaEspecialidades.push(createEspecialidad(e));
  });
  let especialidad = await Promise.all(listaEspecialidades);
  let nivel = cargo.nivel ? await createAcciones(cargo.nivel) : null;

  let tipoEscuela = cargo.tipoEscuela.map(ctEsc => ({
    id: Number(ctEsc.tipo_escuela_id),
    nombre: ctEsc.tipo_escuela_nombre,
    area: {
      id: Number(ctEsc.area_id),
      nombre: ctEsc.area_nombre,
    }
  }))

  let estado = createEstado(cargo.estado);
  let createdAt = dateFormat(cargo.createdAt, "dd/mm/yyyy HH:MM");
  let udpatedAt = dateFormat(cargo.udpatedAt, "dd/mm/yyyy HH:MM");
  let acciones = cargo.acciones ? await createAcciones(cargo.acciones) : new Acciones();
  let onlyCreateAsignatura = cargo.only_create_asignatura == "true" ? true : false

  const relaciones = cargo?.relaciones?.map(ctData => ({
    id: ctData.relacion_id,
    apendice: { id: ctData.apendice_id, nombre: ctData.apendice_nombre },
    area: { id: ctData.area_id, nombre: ctData.area_nombre },
    cargo: { id: ctData.cargo_id, nombre: ctData.cargo_nombre },
    especialidad: { id: ctData.especialidad_id, nombre: ctData.especialidad_nombre },
    tipoEscuela: { id: ctData.tipo_escuela_id, nombre: ctData.tipo_escuela_nombre },
    asignatura: { id: ctData.asignatura_id, nombre: ctData.asignatura_nombre },
  })) ?? []

  return Object.assign(new Cargo(cargo.nombre), { ...cargo, relaciones, onlyCreateAsignatura, asignaturas: asignaturasConEspecialidadesAgrupadas, tipoEscuela, udpatedAt, createdAt, especialidad, area, estado, acciones, nivel });
}