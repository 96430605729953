import PrivateComponent from "../../../authentication/PrivateComponent";
import React, { useState } from "react";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { routePaths, tooltipsText } from "../../../utils/Constants";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import { IncumbenciasDetailModalTernas } from "../../details/ternas/IncumbenciasDetailModalTernas";
import Dialog from "../../../generics/dialog";
import { formatConcurrenciasMainTerna, formatIncumbenciasMainTerna, formatTernaToIncumbencia, getIncumbenciasByTerna } from "../../IncumbenciasService";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cleanIncumbenciasData, saveIncumbenciasData } from "../../../store/incumbencias/incumbenciasActions";
import IconButton from "../../../generics/IconButton";
import { PERMISOS_MAP } from "../../../utils/Permisos";


export const IncumbenciasSearchRowActionsTernas = ({ terna }) => {
    const [showNoIncumbenciasError, setShowNoIncumbenciasError] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const { tipoIncumbencias } = useSelector(st => st.data)

    const loadIncumbenciasToEdit = async (target, setLoading) => {
        setLoading(true);
        const resp = await getIncumbenciasByTerna(({ terna_id: terna.id, limit: 999, }));

        if (resp.cantidad == 0) {
            setShowNoIncumbenciasError(true)
            setLoading(false);
            return
        }

        const arrRawIncumbencias = []
        const arrRawConcurrencias = []

        resp.elementos.forEach(currentElement => {
            let formatedElement = {
                ...currentElement,
                terna: terna,
                // apendice: {
                //     id: currentElement.apendice_id,
                //     value: currentElement.apendice_id,
                //     nombre: currentElement.apendice_nombre,
                //     label: currentElement.apendice_nombre,
                // },
                area: { nombre: currentElement.area_nombre },
                cargo: {
                    codDad: currentElement.cargo_cod_dad,
                    nombre: currentElement.cargo,
                    id: currentElement.cargo_id,
                    estado: { nombre: currentElement.estado }
                },
                especialidad: {
                    nombre: currentElement.especialidad,
                    codDad: currentElement.especialidad_cod_dad,
                },
                id: currentElement.incumbencia_id,
                tipo_escuela: { nombre: currentElement.tipo_escuela },
                tipo_incumbencia: currentElement.descripcion,
                estado: currentElement.estado
            }

            if (currentElement.concurrencia) {
                arrRawConcurrencias.push(formatedElement)
            } else {
                formatedElement.asignatura = {
                    nombre: currentElement.asignatura,
                    id: currentElement.asignatura_id,
                    codDad: currentElement.asignatura_cod_dad,
                }
                arrRawIncumbencias.push(formatedElement)
            }
        })

        const formatedIncumbencias = await formatIncumbenciasMainTerna(arrRawIncumbencias, tipoIncumbencias, false);
        const formatedConcurrencias = await formatConcurrenciasMainTerna(terna, arrRawConcurrencias, tipoIncumbencias, false)

        dispatch(cleanIncumbenciasData());
        dispatch(saveIncumbenciasData({
            originalTernaSeleccionada: formatedIncumbencias.ternaSeleccionada.length > 0 ? formatedIncumbencias.ternaSeleccionada : formatedConcurrencias.ternaSeleccionada,
            originalCargoSeleccionado: formatedIncumbencias.cargoSeleccionado,
            originalConcurrenciasSeleccionadas: formatedConcurrencias.concurrenciasSeleccionadas
        }));

        history.push(routePaths.ABM_INCUMBENCIAS_EDIT_TERNAS)
    }

    const handleCreateWithCargoPrecargado = () => {
        const ternaToAdd = formatTernaToIncumbencia(terna);

        dispatch(saveIncumbenciasData({
            "ternaSeleccionada": [ternaToAdd],
            "concurrenciasSeleccionadas": null
        }))

        history.push({
            pathname: routePaths.ABM_INCUMBENCIAS_CREATE_TERNAS,
            state: {
                returnPath: routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS,
            }
        });
    }

    return (
        <>
            {/* <>
                {
                    (asignatura.acciones.alta || asignatura.acciones.baja) && !visibleCheckbox &&
                    <PrivateComponent permisos={asignatura.acciones.alta ? PERMISOS_MAP.ASIGNATURA_ALTA : PERMISOS_MAP.ASIGNATURA_BAJA}>
                        <CustomTooltip text={asignatura.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                            <DialogActivate
                                target={asignatura}
                                title={asignatura.acciones.alta ? "Dar de alta" : "Dar de baja"}
                                action={changeState}
                                size="md"
                                body={<span>
                                    ¿Estás seguro de dar de {asignatura.acciones.alta ? "alta " : "baja "}
                                    la asignatura
                                    <b> {asignatura ? asignatura.nombre : ""}</b>
                                    ?
                                </span>
                                }
                                isLoadingText={asignatura.acciones.alta ? "Dando de alta" : "Dando de baja"}
                                btnConfirmText={asignatura.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            >
                            </DialogActivate>
                        </CustomTooltip>
                    </PrivateComponent>
                }

                {
                    asignatura.acciones.eliminar &&
                    <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_ELIMINAR}>
                        <CustomTooltip text={tooltipsText.ELIMINAR}>
                            <Dialog
                                action={deleteElement}
                                target={asignatura}
                                title={"Eliminar asignatura"}
                                body={<span>
                                    ¿Estás seguro que quiere eliminar la asignatura
                                    <b> {asignatura ? asignatura.nombre : ""}</b>
                                    ?
                                </span>
                                }
                                size="md"
                                btnIcon={faTrash}
                                btnVariant={"danger"}
                                variant={"danger"}
                                btnConfirmText="Eliminar"
                                isLoadingText={"Eliminando"}
                            />
                        </CustomTooltip>
                    </PrivateComponent>
                }


                {asignatura.acciones.aprobar && !visibleCheckbox &&
                    <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_APROBAR}>
                        <CustomTooltip text={tooltipsText.APROBAR}>
                            <IconButton
                                icon={faCheckDouble}
                                onClick={() => redirectToApproval(asignatura)}
                            />
                        </CustomTooltip>
                    </PrivateComponent>
                }

                {
                    asignatura.acciones.publicar && !visibleCheckbox &&
                    <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_PUBLICAR}>
                        <CustomTooltip text={tooltipsText.PUBLICAR}>
                            <AsignaturaDetailModal
                                asignatura={asignatura}
                                withPublicar={true}
                                onSubmit={resolveCallBack}
                            ></AsignaturaDetailModal>
                        </CustomTooltip>
                    </PrivateComponent>
                }

                {
                    asignatura.acciones.editar &&
                    <PrivateComponent permisos={PERMISOS_MAP.ASIGNATURA_EDITAR}>
                        <CustomTooltip text={tooltipsText.EDITAR}>
                            <IconButton icon={faPen} onClick={() => redirectToEdit(asignatura)} />
                        </CustomTooltip>
                    </PrivateComponent>
                }

                <CustomTooltip text={tooltipsText.DETALLE}>
                    <AsignaturaDetailModal
                        asignatura={asignatura}
                    ></AsignaturaDetailModal>
                </CustomTooltip>

            </> */}
            {
                terna.activo &&
                <PrivateComponent permisos={PERMISOS_MAP.INCUMBENCIA_EDITAR}>
                    <Dialog
                        title="Editar incumbencias"
                        isLoadingText={"Cargando..."}
                        btnIcon={faPen}
                        btnTooltipText={tooltipsText.EDITAR}
                        onShow={loadIncumbenciasToEdit}
                        body={<>{showNoIncumbenciasError ?
                            <Alert variant="info">Esta terna no tiene incumbencias asignadas que editar.</Alert>
                            :
                            "Cargando incumbencias de la terna seleccionada"}
                        </>}
                    />
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <IncumbenciasDetailModalTernas terna={terna} withIconSearch={true}></IncumbenciasDetailModalTernas>
            </CustomTooltip>

            {
                terna.activo &&
                <CustomTooltip text={tooltipsText.ALTA}>
                    <IconButton icon={faPlus} onClick={handleCreateWithCargoPrecargado} />
                </CustomTooltip>
            }
        </>
    );
};