/* 
    const reactSelectsInputs = [
        { name: "especialidadSeleccionada", state: especialidadSeleccionada, setter: setEspecialidadSeleccionada },
        { name: "asignaturaSeleccionada", state: asignaturaSeleccionada, setter: setAsignaturaSeleccionada },
    ]
*/

const resetAllReactSelectStates = (reactSelectsInputs) => {
    reactSelectsInputs.forEach(currentReactSelect => currentReactSelect.setter(currentReactSelect.initialValue ?? ""))
}

const getAllReactSelectStates = (reactSelectsInputs) => {
    const reactSelectValues = {};
    reactSelectsInputs.forEach(currentSelectData => reactSelectValues[currentSelectData.name] = currentSelectData.state)
    return reactSelectValues
}

const putAllReactSelectStates = (reactSelectsInputs, reactSelectValues) => {
    Object.keys(reactSelectValues).forEach(currentSelectName => {
        const reactSelectStateObject = reactSelectsInputs.find(el => el.name == currentSelectName);
        reactSelectStateObject.setter(reactSelectValues[currentSelectName]);
    })
}

export const reactSelectFormUtils = {
    resetAllReactSelectStates,
    getAllReactSelectStates,
    putAllReactSelectStates,
}


















