import React, { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Alert, } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";
import { IncumbenciasDetailBodyTernas } from "./IncumbenciasDetailBodyTernas";
import IconButton from "../../../generics/IconButton";
import { getIncumbenciasByTerna } from "../../IncumbenciasService";
import { Loader } from "../../../ui/Loader";
import { TicketsCreateModal } from "../../../tickets/abm/TicketsCreateModal";
import { ticketsUtils } from "../../../tickets/TicketsUtils";
import { useDispatch, useSelector } from "react-redux";
import { adaptGetIncumbenciasByTernaToIncumbenciasDetailModalTernas } from "../../IncumbenciasAdapter";
import { clear } from "../../../store/alerts/alertActions";
import { PERMISOS_MAP } from "../../../utils/Permisos";
import PrivateComponent from "../../../authentication/PrivateComponent";

export const TIPOS_UNIFICACION = {
    "TERNAS": "nivel_ternas",
    "INCUMBENCIAS": "nivel_incumbencias",
}

export const IncumbenciasDetailModalTernas = ({
    terna,
    withIconSearch = false,
    buttonText,
    elementosPrimerPagina,
    cantidadElementos,
    ...props
}) => {
    const [show, setShow] = useState(false);
    const [incumbencias, setIncumbencias] = useState();
    const [cantidadIncumbencias, setCantidadIncumbencias] = useState();
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);

    const [resolveMessage, setResolveMessage] = useState();
    const dispatch = useDispatch();
    const { modulosTickets } = useSelector(st => st.data)

    const handleClose = () => {
        clearErrorMsg()
        dispatch(clear())
        setShow(false);
    }

    const setErrorMsg = (children) => {
        setResolveMessage({ type: "warning", children })
    }
    const setSuccessMsg = (children) => {
        setResolveMessage({ type: "success", children })
    }
    const clearErrorMsg = () => {
        setResolveMessage("")
    }

    const searchIncumbencias = async (filtros) => {
        /* EN EL MODAL DE DETALLE DE INCUMBENCIA SI DABAMOS BAJA A UNA INCUMBENCIA DESAPARECIA, CON EL ALL LAS MUESTRA Y PODEMOS DARLE ALTA */
        const filtrosDefault = { terna_id: terna.id, all: true }
        try {
            setSearching(true)
            const resp = await getIncumbenciasByTerna({ ...filtrosDefault, ...filtros });
            const elementos = adaptGetIncumbenciasByTernaToIncumbenciasDetailModalTernas(resp.elementos)
            setIncumbencias(elementos);
            setSearching(false)
            setCantidadIncumbencias(resp.cantidad);
        }
        catch (err) {
            setErrorMsg("Error al solicitar las incumbencias")
        }
    }

    const handleShow = async () => {
        setShow(true);
        if (elementosPrimerPagina) {
            setIncumbencias(elementosPrimerPagina);
            setCantidadIncumbencias(cantidadElementos);
        } else {
            if (!terna.unificado) await searchIncumbencias({ page: 1 })
        }
        setPage(1);
    };

    const changePage = async (event, value) => {
        setSearching(true)
        setPage(Number(value));
        const resp = await getIncumbenciasByTerna({
            page: value,
            terna_id: terna.id,
            all: true,
        });
        const elementos = adaptGetIncumbenciasByTernaToIncumbenciasDetailModalTernas(resp.elementos);

        setSearching(false)
        setIncumbencias(elementos);
        setCantidadIncumbencias(resp.cantidad);
    }

    const recargarIncumbencias = async () => {
        console.log("RECARGANDO")
        await searchIncumbencias({ page })
    }

    const resolveCallback = (type, children) => {
        setResolveMessage(!type ? null : { children, type })
    }

    return (
        <>
            {buttonText ?
                <Button
                    onClick={handleShow}
                    variant={"primary"}
                >
                    {buttonText}
                </Button>
                :
                <IconButton {...props} icon={withIconSearch && faSearch} onClick={handleShow} />
            }
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="font-weight-light">Titulo: </span><span className="mr-2">{terna.titulo.nombre}</span>
                        <span className="font-weight-light">Procedencia: </span><span className="mr-2">{terna.procedencia.nombre}</span>
                        <span className="font-weight-light">Resolucion: </span><span className="mr-2">{terna.resolucion.nombre}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {terna.unificado ?
                        <p>Esta terna se encuentra unificada. Para ver sus incumbencias, ver las de la terna:
                            <br></br>
                            <br></br>
                            {terna.unificacion.type == TIPOS_UNIFICACION.TERNAS && <>
                                <b>Titulo: </b>{terna.unificacion.terna_destino.titulo.nombre}<br />
                                <b>Procedencia: </b>{terna.unificacion.terna_destino.procedencia.nombre}<br />
                                <b>Resolucion: </b>{terna.unificacion.terna_destino.resolucion.nombre}
                            </>}
                        </p>
                        :
                        <>
                            {(resolveMessage &&
                                <Alert
                                    variant={resolveMessage.type}
                                    dismissible
                                    onClose={() => setResolveMessage(null)}
                                >{
                                        resolveMessage.children}
                                </Alert>
                            )}


                            {(!searching && cantidadIncumbencias == 0) &&
                                <Alert variant="info">Esta terna no tiene incumbencias asignadas.</Alert>
                            }

                            {(!searching && cantidadIncumbencias > 0) &&
                                <IncumbenciasDetailBodyTernas
                                    incumbencias={incumbencias}
                                    setErrorMsg={setErrorMsg}
                                    setSuccessMsg={setSuccessMsg}
                                    clearErrorMsg={clearErrorMsg}
                                    recargarIncumbencias={recargarIncumbencias}
                                />
                            }

                            {searching && <Loader></Loader>}
                        </>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 d-flex flex-row-reverse justify-content-between align-items-center position-relative">
                        <Button variant="secondary" onClick={handleClose}>
                            Volver
                        </Button>

                        {cantidadIncumbencias > 0 &&
                            <div className="w-100 d-flex justify-content-center position-absolute pe-none">
                                <Paginacion className={"mb-0"} totalItems={cantidadIncumbencias} currentPage={page} onChangePage={changePage} />
                            </div>
                        }
                        <PrivateComponent permisos={PERMISOS_MAP.TICKET_CREAR}>
                            <TicketsCreateModal
                                resolveCallback={resolveCallback}
                                objectId={terna.id}
                                objectApendice={terna.apendice.nombre}
                                modelObjectId={modulosTickets.find(modulo => modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS).id}
                                nombreModuloTicket={ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS}
                                iconType={"primaryButton"}
                                onlyAlta
                                zIndex1050
                            />
                        </PrivateComponent>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}