import React from 'react'
import { getTernas } from '../../../../ternas/TernaService'
import TitulosSearch from '../../../../titulos/shared/TitulosSearch'
import { estadosConstans, incumbenciasConstants } from '../../../../utils/Constants'
import { IncumbenciasABMTable } from '../../../abm/IncumbenciasABMTable'
import { IncumbenciasSearchTernaRow } from '../../../abm/IncumbenciasSearchTernaRow'
import { tipoDocumentoUnificador } from '../UnificadorIncumbenciasABM'

export const TernasSearch = ({
    selectDocument,
    handleClickSeleccionar,
    handleChangeSeleccionar,
    returnCallBack,
    elementos,
}) => {

    const controlIds = {
        titulo: "nombre",
        procedencia: "procedencia",
        resolucion: "resolucion",
        nivel: "nivel",
    }

    const renderSearchTernaRow = (
        terna,
        handleSearch,
        onChangeSeleccionado,
        filtros,
        handleSelectItem,
    ) => {
        return (
            selectDocument == tipoDocumentoUnificador.UNIFICAR ?
                <IncumbenciasSearchTernaRow
                    elemento={terna}
                    selectMulti={true}
                    handleSelectItem={onChangeSeleccionado}
                    selectedElements={elementos}
                    selected={elementos?.some(ctElemento => ctElemento.id == terna.id)}
                />
                :
                <IncumbenciasSearchTernaRow
                    elemento={terna}
                    handleSelectItem={onChangeSeleccionado}
                />
        )
    }

    const renderTableSearchTerna = (
        elementos,
        cantidadElementos,
        page,
        changePage,
        renderTableRows
    ) => {
        return (
            <IncumbenciasABMTable
                cantidadElementos={cantidadElementos}
                changePage={changePage}
                elementos={elementos}
                page={page}
                renderTableRows={renderTableRows}
                tipo={incumbenciasConstants.TERNAS_INCUMBENCIA}
                showCondicionCol={false}
                showFolioCol={false}
                showApendiceCol={false}
            />
        )
    }

    return (
        <>
            <TitulosSearch
                title={`Buscar terna (${selectDocument})`}
                renderRow={renderSearchTernaRow}
                renderTable={renderTableSearchTerna}
                getTitulos={getTernas}
                hidePlusFAB={true}
                controlIds={controlIds}
                nombreElementoResponse="data"
                conCrear={false}
                masInputs={true}
                hideChecks={true}
                conGenerarReporte={false}
                defaultInputsForm={{ estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.PENDIENTE_MIGRACION, estadosConstans.MIGRADO] }}
                urlSeleccionar={true} /* No se usa en este caso, pero sino no muestra boton seleccionar */
                handleChangeSeleccionar={handleChangeSeleccionar}
                handleSeleccionar={handleClickSeleccionar}
                returnCallBack={returnCallBack}
            />
        </>
    )
}
