import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { ShortcutButton } from "./ui/ShortcutButton";
import * as AuthService from "./authentication/AuthService";
import { menuDashboardOptions } from "./utils/menuDashboardOptions";
import PrivateComponent from "./authentication/PrivateComponent";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  // componentDidMount() {
  //   AuthService.getPublicContent().then(
  //     response => {
  //       this.setState({
  //         content: ""//response.data
  //       });
  //     },
  //     error => {
  //       this.setState({
  //         // content:
  //         //   (error.response && error.response.data) ||
  //         //   error.message ||
  //         //   error.toString()
  //       });
  //     }
  //   );
  // }

  render() {
    return (
      <div className="flex-grow-1 m-0 container mx-auto">
        <h1 className="text-center mb-4">Inicio</h1>
        <h3 className="text-danger">{this.state.content}</h3>
        <Row className="d-flex justify-content-center flex-wrap">

          {menuDashboardOptions.map((currentElement, index) => (currentElement.menuTitle ?
            <PrivateComponent key={"Dash" + currentElement.title + index} permisos={currentElement.permisoRequired}>
              <Col xs={6} md={4} lg={4}>
                <div className="d-flex justify-content-center my-4">
                  <ShortcutButton to={currentElement.path} icon={currentElement.menuIcon} title={currentElement.title} />
                </div>
              </Col>
            </PrivateComponent>
            :
            <PrivateComponent key={"Dash" + currentElement.title + index} permisos={currentElement.permisoRequired}>
              <Col xs={6} md={4} lg={4}>
                <div className="d-flex justify-content-center my-4">
                  <ShortcutButton
                    to={currentElement.path}
                    icon={currentElement.icon}
                    title={currentElement.title} />
                </div>
              </Col>
            </PrivateComponent>
          )
          )}
        </Row>
      </div >
    );
  }
}
