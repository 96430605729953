import axios from 'axios'
import {apiURLs} from '../utils/Constants'
import authHeader from "../utils/auth-header";

export const getRoles = async (roleId) => {
    
    let query
    if(roleId && Number.isInteger(roleId / 1)){
        query = axios.get(apiURLs.API_ROLES_URL + `/${roleId}`, { headers: authHeader() })
    }else{
        query = axios.get(apiURLs.API_ADMIN_ROLES_URL, { headers: authHeader() })
    }
    return await query.then(resp => {
        return resp.data
    }).catch(e => { return [] });
    
}

export const getRolesNames = async (roleId) => {

    let response = await getRoles(roleId)
    return response.map( role => {
        return { id:role.id, nombre:role.nombre};
    } )
}



