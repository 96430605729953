import React from 'react'

export const FormsOptionsAddCargos = ({ otherOptions, handleChangeSeleccionarCargo }) => {
    return (<>
        {otherOptions == 1 &&
            <div>formsOptionsAddCargos 11111</div>
        }

        {otherOptions == 2 &&
            <div>formsOptionsAddCargos 22222</div>
        }
    </>
    )
}
