import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import CargosSearch from '../../cargos/search/CargosSearch';
import { error } from '../../store/alerts/alertActions';
import { replaceArr, saveIncumbenciasData } from '../../store/incumbencias/incumbenciasActions';
import { clearFilters } from '../../store/search/searchActions';
import { getTernas } from "../../ternas/TernaService";
import TitulosSearch from '../../titulos/shared/TitulosSearch';
import { estadosConstans, incumbenciasConstants, typeOfActions } from '../../utils/Constants';
import { formatCargoToIncumbencia, formatTernaToIncumbencia } from '../IncumbenciasService';
import { FormsOptionsAddCargos } from './FormsOptionsAddCargos';
import { IncumbenciasABMForm } from './IncumbenciasABMForm';
import { IncumbenciasABMTable } from './IncumbenciasABMTable';
import { IncumbenciasSearchTernaRow } from './IncumbenciasSearchTernaRow';
import { NavOptionsAddCargos } from './NavOptionsAddCargos';

export const IncumbenciasABM = ({
    title,
    onSubmit,
    typeOfAction = typeOfActions.CREATE,
    hideApendice,
    hideButtonsMainElement,
    hideButtonsSecondaryElement,
    hideBuscadoresMainElement,
    hideBuscadoresSecondaryElement,
    showDeletedElementTable,
    showFabButtons,
}) => {

    const { mainElement } = useParams();
    const [selectDocument, setSelectDocument] = useState()
    const isTernaMainElement = mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA;

    const dispatch = useDispatch();
    const location = useLocation();

    const { ternaSeleccionada, cargoSeleccionado, concurrenciasSeleccionadas } = useSelector(st => st.incumbencias)
    const [concurrenciaId, setConcurrenciaId] = useState()
    const [checkedElement, setCheckedElement] = useState();
    const [apendiceSeleccionado, setApendiceSeleccionado] = useState("")

    const formatNewCargos = async (arrCargos, isLoadingCallBack) => {
        let arrNewCargos = [];
        let arrOldCargos = [];
        let arrFormatedNewCargos = [];

        arrCargos.forEach(ctCargo => ctCargo.type ? arrOldCargos.push(ctCargo) : arrNewCargos.push(ctCargo))

        if (arrNewCargos.length > 0) {
            const formatCargosPromises = arrNewCargos.map(ctCargo => formatCargoToIncumbencia(ctCargo, isLoadingCallBack))
            arrFormatedNewCargos = await Promise.all(formatCargosPromises)
        }
        return [...arrOldCargos, ...arrFormatedNewCargos]
    }

    /*
        Si el cargo tiene asignatura/s:
        CARGO -> ASIGNATURA -> ESPECIALIDAD
                            -> AREA           -> TIPO ESCUELA
        
        Si no tiene asignatura:
        CARGO -> ASIGNATURA (Sin asignatura)
            -> ESPECIALIDAD
            -> AREA          -> TIPO ESCUELA
    */

    const handleChangeSeleccionarCargoIncumbencia = async (arrCargos, isLoadingCallBack) => {
        if (arrCargos) {
            const arrFormatedCargos = await formatNewCargos(arrCargos, isLoadingCallBack)
            dispatch(replaceArr(arrFormatedCargos, "cargoSeleccionado"))
        }
        dispatch(clearFilters())
        setSelectDocument("")
    }

    const handleChangeSeleccionarCargoConcurrencia = async (arrCargos, isLoadingCallBack) => {
        if (arrCargos) {
            const arrFormatedCargos = await formatNewCargos(arrCargos, isLoadingCallBack)
            dispatch(replaceArr(arrFormatedCargos, "concurrenciasSeleccionadas", { concurrenciaId: concurrenciaId, subPath: "cargosSeleccionados" }))
        }
        dispatch(clearFilters())
        setSelectDocument("")
    }

    const handleChangeSeleccionarTernaIncumbencia = (filtros, urlSeleccionar, newTerna) => {
        if (checkedElement) {
            let arrNewTernas = [];
            let arrOldTernas = [];
            let arrFormatedNewTernas = [];

            checkedElement.forEach(ctTerna => ctTerna.terna ? arrOldTernas.push(ctTerna) : arrNewTernas.push(ctTerna))

            if (arrNewTernas.length > 0) arrFormatedNewTernas = arrNewTernas.map(ctTerna => formatTernaToIncumbencia(ctTerna))
            dispatch(replaceArr([...arrOldTernas, ...arrFormatedNewTernas], "ternaSeleccionada"))
            setCheckedElement("")
        }
        dispatch(clearFilters())
        setSelectDocument("")
    }

    const handleChangeSeleccionarTernaConcurrencia = (filtros, urlSeleccionar, newTerna) => {
        if (checkedElement) {
            dispatch(replaceArr(checkedElement, "concurrenciasSeleccionadas", { concurrenciaId: concurrenciaId, subPath: "ternasSeleccionadas" }))
            setCheckedElement("")
        }
        dispatch(clearFilters())
        setSelectDocument("")
    }

    const controlIds = {
        titulo: "nombre",
        procedencia: "procedencia",
        resolucion: "resolucion",
        nivel: "nivel",
    }

    const renderTableSearchTerna = (
        elementos,
        cantidadElementos,
        page,
        changePage,
        renderTableRows
    ) => {
        return (
            <IncumbenciasABMTable
                elementos={elementos}
                cantidadElementos={cantidadElementos}
                page={page}
                changePage={changePage}
                renderTableRows={renderTableRows}
                tipo={incumbenciasConstants.TERNAS_INCUMBENCIA}
                showCheckboxCol={true}
                showCondicionCol={false}
                showFolioCol={false}
                showApendiceCol={false}
                showActionsCol={true}
                showPaginacion={true}
            />
        )
    }

    const renderSearchTernaRow = (
        terna,
        handleSearch,
        onChangeSeleccionado,
        filtros,
        handleSelectItem,
    ) => {
        const arrTernas = incumbenciasConstants.TERNAS_INCUMBENCIA == selectDocument ? ternaSeleccionada : concurrenciasSeleccionadas[concurrenciaId].ternasSeleccionadas
        const isSelectedTerna = incumbenciasConstants.TERNAS_INCUMBENCIA == selectDocument ?
            ctElemento => ctElemento.terna.id == terna.id
            :
            ctElemento => ctElemento.id == terna.id

        return (
            <IncumbenciasSearchTernaRow
                tipo={incumbenciasConstants.TERNAS_INCUMBENCIA}
                elemento={terna}
                mainElement={mainElement}
                filtros={filtros}
                handleSelectItem={onChangeSeleccionado}
                selectMulti={true}
                selectedElements={arrTernas}
                selected={arrTernas?.some(isSelectedTerna)}
            />
        )
    }

    const handleClickCheckTerna = (evento, terna, setChecked) => {
        // const arrTernas = arrayTernas
        // const arrTernas = incumbenciasConstants.TERNAS_INCUMBENCIA == selectDocument ? ternaSeleccionada : concurrenciasSeleccionadas[concurrenciaId].ternasSeleccionadas

        const isAllowedAddTerna = (array) => {
            const cantidadMaximaTernas = isTernaMainElement ? 2 : 3
            return array.length >= cantidadMaximaTernas
        }

        if (evento.target.checked) {
            setCheckedElement(st => {
                if (selectDocument == incumbenciasConstants.TERNAS_CONCURRENCIA && isAllowedAddTerna(st)) {
                    dispatch(error(<><b>Limite de ternas seleccionadas alcanzado:</b> Seleccionando mas de 2 ternas en este formulario, se supera el limite de 3 ternas por concurrencia </>))
                    setChecked(false)
                    return st
                }
                setChecked(st => !st)
                return st ? [...st, terna] : [terna]
            })
        } else {
            setCheckedElement(st => {
                const isSameRawTerna = ctTerna => ctTerna.id == terna.id
                const isSameFormatedTerna = ctElemento => ctElemento.terna?.id == terna.id
                return st.filter(ct => ct.id ? !isSameRawTerna(ct) : !isSameFormatedTerna(ct))
            })
            setChecked(st => !st)
        }
    }

    const returnCallBack = () => {
        setCheckedElement("")
        dispatch(clearFilters())
        setSelectDocument("")
    }

    const changeSelectedDocument = ({ documentSelected, checkedElements = [] }) => {
        setCheckedElement(checkedElements)
        setSelectDocument(documentSelected)
    }

    const showActionsColMainElement = typeOfAction == typeOfActions.CREATE;

    useEffect(() => {
        dispatch(saveIncumbenciasData({ typeOfAction }))
    }, [])

    return <>
        {!selectDocument && (
            <IncumbenciasABMForm
                mainElement={mainElement || incumbenciasConstants.TERNAS_INCUMBENCIA}
                changeSelectedDocument={changeSelectedDocument}
                hideApendice={hideApendice}
                title={title}
                onSubmit={onSubmit}
                apendiceSeleccionado={apendiceSeleccionado}
                setApendiceSeleccionado={setApendiceSeleccionado}

                hideButtonsMainElement={location.state?.buttonsConfig ? location.state.buttonsConfig.hideButtonsMainElement : hideButtonsMainElement}
                hideButtonsSecondaryElement={location.state?.buttonsConfig ? location.state.buttonsConfig.hideButtonsSecondaryElement : hideButtonsSecondaryElement}
                hideBuscadoresMainElement={location.state?.buttonsConfig ? location.state.buttonsConfig.hideBuscadoresMainElement : hideBuscadoresMainElement}
                hideBuscadoresSecondaryElement={location.state?.buttonsConfig ? location.state.buttonsConfig.hideBuscadoresSecondaryElement : hideBuscadoresSecondaryElement}

                showFabButtons={showFabButtons}
                showDeletedElementTable={showDeletedElementTable}
                showActionsColMainElement={location.state?.buttonsConfig ? location.state.buttonsConfig.showActionsColMainElement : showActionsColMainElement}
                setConcurrenciaId={setConcurrenciaId}
            />)
        }

        {
            (
                selectDocument == incumbenciasConstants.CARGOS_INCUMBENCIA
                ||
                selectDocument == incumbenciasConstants.CARGOS_CONCURRENCIA
            ) && (
                <CargosSearch
                    title="Buscar cargo"
                    handleChangeSeleccionarCargo={
                        selectDocument == incumbenciasConstants.CARGOS_INCUMBENCIA ?
                            handleChangeSeleccionarCargoIncumbencia
                            :
                            handleChangeSeleccionarCargoConcurrencia
                    }
                    returnCallBack={returnCallBack}
                    defaultFiltros={{ min: false, all: false, }}
                    hidePlusFAB={true}
                    visibleCheckbox={true}
                    selectMulti={true}
                    selectedElements={selectDocument == incumbenciasConstants.CARGOS_INCUMBENCIA ? cargoSeleccionado : concurrenciasSeleccionadas[concurrenciaId].cargosSeleccionados}
                    hideChecks={true}
                    defaultInputsForm={{ estado: [estadosConstans.BORRADOR, estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.PENDIENTE_MIGRACION, estadosConstans.MIGRADO] }}
                    navOtherOptions={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA && ((otherOptions, setOtherOptions) => (
                        <NavOptionsAddCargos
                            otherOptions={otherOptions}
                            setOtherOptions={setOtherOptions}
                        />))}
                    formsOtherOptions={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA && ((otherOptions, handleChangeSeleccionarCargo) => (
                        <FormsOptionsAddCargos
                            otherOptions={otherOptions}
                            handleChangeSeleccionarCargo={handleChangeSeleccionarCargo}
                        />))}
                />
            )}

        {(
            selectDocument == incumbenciasConstants.TERNAS_INCUMBENCIA
            ||
            selectDocument == incumbenciasConstants.TERNAS_CONCURRENCIA
        ) && (
                <TitulosSearch
                    title="Buscar terna"
                    renderRow={renderSearchTernaRow}
                    renderTable={renderTableSearchTerna}
                    getTitulos={getTernas}
                    hidePlusFAB={true}
                    controlIds={controlIds}
                    nombreElementoResponse="data"
                    defaultFiltros={{ all: false }}
                    conCrear={false}
                    masInputs={true}
                    hideChecks={true}
                    conGenerarReporte={false}
                    defaultInputsForm={{ estado: [estadosConstans.BORRADOR, estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.PENDIENTE_MIGRACION, estadosConstans.MIGRADO] }}
                    urlSeleccionar={true} /* No se usa en este caso, pero sino no muestra boton seleccionar */
                    handleChangeSeleccionar={handleClickCheckTerna}
                    handleSeleccionar={
                        selectDocument == incumbenciasConstants.TERNAS_INCUMBENCIA ?
                            handleChangeSeleccionarTernaIncumbencia
                            :
                            handleChangeSeleccionarTernaConcurrencia
                    }
                    returnCallBack={returnCallBack}
                />
            )}
    </>
}