import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants"
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import EspecialidadesABM from "./EspecialidadesABM"
import { useLocation } from "react-router-dom";
import Especialidad from "../../dtos/especialidad/Especialidad";
import { saveFilters } from "../../store/search/searchActions";

const EspecialidadesABMEdit = (props) => {
  let location = useLocation();
  const [especialidad, setEspecialidad] = useState();

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos data de los filtrosGuardados para forzar refresh al cargar la data en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.data
    dispatch(saveFilters(newFiltrosGuardados))
  };

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.especialidad) {
      let especialidad = location.state.especialidad;
      if (!(especialidad instanceof Especialidad)) {
        especialidad = Object.assign(new Especialidad(especialidad.nombre), especialidad);
      }
      setEspecialidad(especialidad);
    }
  }, [dispatch, location.state]);

  return (
    <EspecialidadesABM especialidad={especialidad} title="Editar Especialidad" typeOfAction={typeOfActions.EDIT} onSubmit={handleSubmit} returnPath={routePaths.ABM_ESPECIALIDADES_SEARCH} />
  );
};

export default EspecialidadesABMEdit;
