import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLogged, useAuthorization } from "./AuthService";

const PrivateRoute = ({ children, permisos, ...rest }) => {
    const hasAuthorization = useAuthorization(permisos)
    if (!hasAuthorization) console.log("NO AUTORIZADO -> REDIRECCIONAMOS", { permisos })

    return (
        hasAuthorization ?
            <Route {...rest}>
                {children}
            </Route>
            :
            <Redirect to={isLogged() ? "/" : "/login"} />
    )
}

export default PrivateRoute