import Estado from "../Estado"

class NivelEspecialidad{
    constructor(){
        this.id = "";
        this.cod_sgd = "";
        this.descripcion = "";
        this.estado = Estado;
    }
}

export default NivelEspecialidad

export const createNivelEspecialidad = (props) => {
    let estado = Object.assign(new Estado(props.estado?props.estado.nombre:""), props.estado)
    return Object.assign(new NivelEspecialidad(), {...props, estado})
}
