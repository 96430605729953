import React from 'react'
import TitulosSearch from '../../../titulos/shared/TitulosSearch'
import { IncumbenciasSearchTernaRow } from '../../abm/IncumbenciasSearchTernaRow'
import { IncumbenciasABMTable } from '../../abm/IncumbenciasABMTable'
import { estadosConstans, incumbenciasConstants } from '../../../utils/Constants'
import { getTernas } from '../../../ternas/TernaService'

const renderSearchTernaRow = (
    terna,
    handleSearch,
    onChangeSeleccionado,
    filtros,
    handleSelectItem,
) => {
    return (
        <IncumbenciasSearchTernaRow
            elemento={terna}
            handleSelectItem={onChangeSeleccionado}
        />
    )
}

const renderTableSearchTerna = (
    elementos,
    cantidadElementos,
    page,
    changePage,
    renderTableRows
) => {
    return (
        <IncumbenciasABMTable
            cantidadElementos={cantidadElementos}
            changePage={changePage}
            elementos={elementos}
            page={page}
            renderTableRows={renderTableRows}
            tipo={incumbenciasConstants.TERNAS_INCUMBENCIA}
            showCondicionCol={false}
            showFolioCol={false}
            showApendiceCol={false}
        />
    )
}

export const ClonadorTernasSearch = ({
    selectDocument,
    handleClickSeleccionar,
    handleChangeSeleccionar,
    returnCallBack,
}) => {
    const controlIds = {
        titulo: "nombre",
        procedencia: "procedencia",
        resolucion: "resolucion",
        nivel: "nivel",
    }

    return (
        <>
            <TitulosSearch
                title={`Buscar terna (${selectDocument})`}
                renderRow={renderSearchTernaRow}
                renderTable={renderTableSearchTerna}
                getTitulos={getTernas}
                hidePlusFAB={true}
                controlIds={controlIds}
                nombreElementoResponse="data"
                conCrear={false}
                masInputs={true}
                hideChecks={true}
                conGenerarReporte={false}
                // defaultInputsForm={{ estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.MIGRADO] }}
                urlSeleccionar={true} /* No se usa en este caso, pero sino no muestra boton seleccionar */
                handleChangeSeleccionar={handleChangeSeleccionar}
                handleSeleccionar={handleClickSeleccionar}
                returnCallBack={returnCallBack}
            />
        </>
    )
}
