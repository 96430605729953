import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import EspecialidadesSearchRowActions from "./EspecialidadesSearchRowActions";
import { Badge } from "react-bootstrap";

const EspecialidadesSearchRow = ({ especialidad, ...props }) => {
  return (
    <>
      <tr key={especialidad.id}>
        <td>{especialidad.nombre}</td>
        <td>{especialidad.codDad}</td>
        <td>{especialidad.getNombreArea()}</td>
        <td>{especialidad.getDescripcionNivel()}</td>
        <td><Badge variant={especialidad.estado ? especialidad.estado.color : "primary"}>{especialidad.estado.nombre}</Badge></td>
        <td>
          <div className="table-button-group">
            <EspecialidadesSearchRowActions especialidad={especialidad}></EspecialidadesSearchRowActions>
          </div>
        </td>
      </tr>
    </>
  );
};

export default EspecialidadesSearchRow;
