import PrivateComponent from "../../authentication/PrivateComponent";
import EscuelaDetailModal from "../details/EscuelaDetailModal";
import { activate, deactivate } from "../EscuelaService";
import DialogActivate from "../../ui/DialogActivate";
import React, { useState } from "react";
import { faPen, faTrash, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { routePaths, tooltipsText } from "../../utils/Constants";
import IconButton from "../../generics/IconButton";
import { useDispatch } from "react-redux";
import { error } from "../../store/alerts/alertActions";
import { Redirect } from "react-router-dom";
import Dialog from "../../generics/dialog";
import { CustomTooltip } from "../../ui/CustomTooltip";
import { deleteEscuela } from "../EscuelaService";
import { searchFab } from "../../store/screen/screenActions";
import { PERMISOS_MAP } from "../../utils/Permisos";

const EscuelaSearchRowActions = ({ escuela, ...props }) => {
    const [redirect, setRedirect] = useState("");

    const dispatch = useDispatch();

    const resolveCallBack = () => {
        dispatch(searchFab(true))
    }

    const redirectToEdit = (cargo) => {
        setRedirect(routePaths.ABM_ESCUELA_EDIT);
    };

    const redirectToApproval = (cargo) => {
        setRedirect(routePaths.ABM_ESCUELA_APPROVAL);
    };

    const changeState = async (escuela) => {
        try {
            if (escuela.activo) {
                await deactivate(escuela, resolveCallBack)
            } else {
                await activate(escuela, resolveCallBack)
            }
        } catch (e) {
            dispatch(error(e.response.data.message));
        }
    };

    const handleDelete = async (escuela) => {
        await deleteEscuela(escuela);
        dispatch(searchFab(true))
    }

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        escuela: escuela,
                        abmTitle: "Editar",
                        submitValue: "Aceptar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <>

            {(escuela.acciones.alta || escuela.acciones.baja) &&
                <PrivateComponent permisos={escuela.acciones.alta ? PERMISOS_MAP.TIPO_ESCUELA_ALTA : PERMISOS_MAP.TIPO_ESCUELA_BAJA}>
                    <CustomTooltip text={escuela.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                        <DialogActivate
                            target={escuela}
                            title={escuela.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            action={changeState}
                            size="md"
                            body={<span>
                                ¿Estás seguro de dar de {escuela.acciones.alta ? "alta " : "baja "}
                                el cargo
                                <b> {escuela ? escuela.nombre : ""}</b>
                                ?
                            </span>
                            }
                            isLoadingText={escuela.acciones.alta ? "Dando de alta" : "Dando de baja"}
                            btnConfirmText={escuela.acciones.alta ? "Dar de alta" : "Dar de baja"}
                        >
                        </DialogActivate>
                    </CustomTooltip>
                </PrivateComponent>
            }
            {escuela.acciones.eliminar &&
                <PrivateComponent permisos={PERMISOS_MAP.TIPO_ESCUELA_ELIMINAR}>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={handleDelete}
                            target={escuela}
                            title={"Eliminar cargo"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar el tipo de escuela
                                <b> {escuela ? escuela.nombre : ""}</b>
                                ?
                            </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                            isLoadingText={"Eliminando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {escuela.acciones.aprobar &&
                <PrivateComponent permisos={PERMISOS_MAP.TIPO_ESCUELA_APROBAR}>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <IconButton
                            icon={faCheckDouble}
                            onClick={() => redirectToApproval(escuela)}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {escuela.acciones.publicar &&
                <PrivateComponent permisos={PERMISOS_MAP.TIPO_ESCUELA_PUBLICAR}>
                    <CustomTooltip text={tooltipsText.PUBLICAR}>
                        <EscuelaDetailModal
                            escuela={escuela}
                            withPublicar={true}
                            onSubmit={resolveCallBack}
                        ></EscuelaDetailModal>
                    </CustomTooltip>
                </PrivateComponent>

            }
            {escuela.acciones.editar &&
                <PrivateComponent permisos={PERMISOS_MAP.TIPO_ESCUELA_EDITAR}> 
                    <CustomTooltip text={tooltipsText.EDITAR}>
                        <IconButton icon={faPen} onClick={() => redirectToEdit(escuela)} />
                    </CustomTooltip>
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <EscuelaDetailModal escuela={escuela}></EscuelaDetailModal>
            </CustomTooltip>
        </>
    );
};

export default EscuelaSearchRowActions;
