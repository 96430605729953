import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clear, error } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import { Loader } from "../../ui/Loader";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { getCargos } from "../CargosService";
import CargosFormSearch from "./CargosFormSearch";
import CargosSearchTable from "./CargosSearchTable";

const CargosSearch = ({
  title,
  visibleCheckbox = false,
  handleChangeSeleccionarCargo,
  navOtherOptions,
  formsOtherOptions,
  returnCallBack,
  hidePlusFAB = false,
  defaultInputsForm,
  hideChecks,
  defaultFiltros = [],
  selectMulti = false,
  selectedElements,
}) => {

  const [cargos, setCargos] = useState(null);
  const [cantidadCargos, setCantidadCargos] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [otherOptions, setOtherOptions] = useState(0);

  const filtrosGuardados = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      // El aprobar borra data para forzar refresh de data con mismos filtros guardados
      if (!filtrosGuardados.data) {
        return await searchCargos(filtrosGuardados)
      }

      setPage(filtrosGuardados.page);
      setCargos(filtrosGuardados.data);
      setCantidadCargos(filtrosGuardados.cantidad);
    }
  }

  const handleSearch = async (data) => {
    searchCargos(data);
  };

  const searchCargos = async (data = {}) => {
    try {
      let filtros = data.inputsForm;
      const filtrosComunes = { all: true, min: true }

      setSearching(true);
      const response = await getCargos({ ...filtros, ...filtrosComunes, ...defaultFiltros });
      setPage(response.page);
      setCargos(response.data);
      setCantidadCargos(response.cantidad);
      const newData = { ...data, ...response, inputsForm: { ...data.inputsForm, page: response.page } }
      dispatch(saveFilters(newData));
    }
    catch (err) {
      dispatch(error(err.response.data.message));
    }
    finally {
      setSearching(false);
    }
  }

  const handleChangePage = (event, value) => {
    dispatch(clear());
    searchCargos({ ...filtrosGuardados, inputsForm: { ...filtrosGuardados.inputsForm, page: value } });
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
  }, []);

  return (
    <ScreenWithFabButtons
      returnCallBack={returnCallBack}
      hidePlusButton={hidePlusFAB}
      hideSearchButton={false}
      hideCleanButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !cargos}
    >

      <h1>{title ? title : "Cargos"}</h1>

      {navOtherOptions && navOtherOptions(otherOptions, setOtherOptions)}

      {otherOptions == 0 && <>
        <Row>
          <Col>
            <CargosFormSearch
              setCargos={setCargos}
              onSubmit={handleSearch}
              defaultInputsForm={defaultInputsForm}
              hideChecks={hideChecks}
            ></CargosFormSearch>
          </Col>
        </Row>
        <Row>
          <Col>
            {cargos && !searching ? (
              <CargosSearchTable
                cargos={cargos}
                selectMulti={selectMulti}
                cantidadCargos={cantidadCargos}
                changePage={handleChangePage}
                page={page}
                visibleCheckbox={visibleCheckbox}
                handleChangeSeleccionarCargo={handleChangeSeleccionarCargo}
                selectedElements={selectedElements}
              ></CargosSearchTable>
            ) : (
              searching && <Loader></Loader>
            )}
          </Col>
        </Row>
      </>
      }

      {navOtherOptions && formsOtherOptions(otherOptions, handleChangeSeleccionarCargo)}

    </ScreenWithFabButtons >
  );
};

export default CargosSearch;
