import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { Badge, Col, Row, Table } from 'react-bootstrap';
import IconButton from '../../generics/IconButton';
import Paginacion from '../../generics/Paginacion';
import { CustomTooltip } from '../../ui/CustomTooltip';
import { tooltipsText } from '../../utils/Constants';

const FormTableRow = ({ elemento, getDetailByTerna }) => {
  const relacionados = []
  if (elemento.troncal) relacionados.push("T")
  if (elemento.final) relacionados.push("F")

  return (
    <tr>
      <td>{elemento.titulo.nombre}</td>
      <td>{elemento.procedencia.nombre}</td>
      <td>{elemento.resolucion.nombre}</td>
      <td>{elemento.apendice}</td>
      <td>{relacionados.join("/")}</td>
      <td>{elemento.createdAt}</td>
      <td>
        <Badge variant={elemento.estado.color}>
          {elemento.estado.nombre}
        </Badge>
      </td>
      <td>
        <CustomTooltip text={tooltipsText.INFORME}>
          <IconButton icon={faArrowRight} onClick={() => getDetailByTerna(elemento)} />
        </CustomTooltip>
      </td>
    </tr>
  )
}

const FormTableHeader = () => {
  return (
    <tr>
      <th>Nombre del titulo</th>
      <th>Procedencia</th>
      <th>Resolución</th>
      <th>Apendice</th>
      <th>I/T/F</th>
      <th>Fecha</th>
      <th>Estado</th>
      <th></th>
    </tr>
  )
}

export const FormTableIncumByTerna = ({
  getDetailByTerna,
  elementos,
  cantidadElementos,
  page,
  handleChangePage
}) => {

  return (
    <>
      <Row className="form-group">
        <Col>
          <Table responsive>
            <thead>
              <FormTableHeader />
            </thead>
            <tbody>
              {
                elementos.map((currentElemento, index) =>
                  <React.Fragment key={`FormTableRowByTerna${currentElemento.id}/${index}`}>
                    <FormTableRow
                      elemento={currentElemento}
                      getDetailByTerna={getDetailByTerna}
                    />
                  </React.Fragment>)
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Paginacion
        totalItems={cantidadElementos}
        currentPage={page}
        onChangePage={handleChangePage}
      />
    </>
  )
}
