import { faCheckDouble, faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../generics/dialog";
import { addCargoToArr, addTernaToArr, addVarianteToArr, removeCargoFromArr, removeTernaFromArr, removeVarianteFromArr } from "../../store/incumbencias/incumbenciasActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import Input from "../../ui/Input";
import { incumbenciasConstants, typeOfActions } from "../../utils/Constants";
import { isValidNumeric, isValidPuntaje } from "../../utils/commons";
import { IncumbenciasModalSelectVarianteCargo } from "../details/cargos/IncumbenciasModalSelectVarianteCargo";

export const idsTipoIncumbenciaConPuntaje = [
  4, //"OT"
  7, //"DOC y MAES EDU"
]

const getMinWidthFolio = (folioLength) => {
  const maxChars = 7;
  const cantChars = folioLength > maxChars ? maxChars : folioLength;
  const pxPorCaracter = 9;
  const pxDeMargin = 22;

  return ((cantChars * pxPorCaracter) + pxDeMargin) + "px"
}

export const handleChangeCheckBox = (event, setterFn) => {
  const newEvent = {
    target: {
      id: event.target.id,
      value: event.target.checked,
    },
  };
  setterFn(newEvent);
};

const formatEventApendice = (e) => {
  console.log({ e })
  const option = e.target.selectedOptions[0]
  console.log({ option })
  return ({
    target: {
      id: e.target.id,
      value: !option || option.value == "" ? undefined : {
        id: option.value,
        nombre: option.text,
        value: option.value,
        label: option.text,
      }
    }
  })
}

export const CargoSimpleRow = ({
  currentCargo,
  handleChangeRowCargoInputs,
  removeCargoFromList,
  restoreCargoFromList,
  restoreActionIcon,
  mainElement,
  hideTipoEscuela,
  showCantidadIncumbencias,
  showActionsCol = true,
  showInputFolio,
  showInputCondicion,
  showSelectApendice,
  hideColumns = {},
}) => {

  const { tipoIncumbencias, apendices } = useSelector(st => st.data)

  return (
    <>
      <tr className="border-bottom-separator">
        <td>{currentCargo.cargo.codDad ?? "-"}</td>
        <td>{currentCargo.cargo.nombre ?? "-"}</td>
        {
          showSelectApendice && <td>
            asd
            <Input
              type="select"
              controlId="apendice_id"
              style={{ minWidth: "180px", }}
              value={currentCargo.apendice_id}
              onChange={(event) => handleChangeRowCargoInputs(event, currentCargo)}
              elements={apendices}
              required
            ></Input>
          </td>
        }

        {showInputFolio && <td>
          <Input
            type="input"
            controlId="folio"
            value={currentCargo.folio}
            required
            style={{
              minWidth: currentCargo.folio ? `${getMinWidthFolio(currentCargo.folio.length)}` : "auto",
            }}
            onChange={(event) => handleChangeRowCargoInputs(event, currentCargo, isValidNumeric)}
          ></Input>
        </td>}
        <td>{currentCargo.area.nombre ?? "-"}</td>
        {!hideTipoEscuela && <td>{currentCargo.tipoEscuela.nombre ?? "-"} </td>}
        <td>{currentCargo.asignatura?.codDad ?? "-"}</td>
        <td>{currentCargo.asignatura?.nombre ?? "SIN ASIGNATURA"}</td>
        <td>{currentCargo.especialidad?.codDad ?? "-"}</td>
        <td>{currentCargo.especialidad?.nombre ?? "SIN ESPECIALIDAD"}</td>
        {showInputCondicion &&
          <td>
            <Input
              type="select"
              controlId="condicion"
              style={{ width: "12rem" }}
              value={currentCargo.condicion}
              onChange={(event) => handleChangeRowCargoInputs(event, currentCargo)}
              elements={tipoIncumbencias}
              required
            ></Input>
            {tipoIncumbencias?.length > 0 &&
              idsTipoIncumbenciaConPuntaje.some(ctCondicionConPuntajeId => ctCondicionConPuntajeId == currentCargo.condicion) &&
              <>
                <Input
                  type="input"
                  controlId="puntaje"
                  label="Puntaje"
                  style={{ width: "12rem" }}
                  value={currentCargo.puntaje}
                  onChange={(event) => handleChangeRowCargoInputs(event, currentCargo, isValidPuntaje)}
                  description={"(Del 0 al 3)"}
                  required
                ></Input>
                <Input
                  type="checkbox"
                  controlId="sumaOT"
                  name="sumaOT"
                  label="Solo aplica para Otros titulos"
                  value="0"
                  checked={currentCargo.sumaOT}
                  onChange={(event) => handleChangeCheckBox(event, handleChangeRowCargoInputs, currentCargo)}
                ></Input>
              </>
            }
          </td>
        }
        {
          !hideColumns.estado &&
          <td>
            <Badge variant={currentCargo.cargo.estado ? currentCargo.cargo.estado.color : "primary"}>{currentCargo.cargo.estado.nombre ?? "-"}</Badge>
          </td>
        }
        {showCantidadIncumbencias && <td>{currentCargo.cantidadIncumbencias}</td>}
        {showActionsCol &&
          <td>
            <div className="table-button-group">
              {restoreActionIcon ?
                <CustomTooltip text={"Restaurar cargo eliminado"}>
                  <Dialog
                    action={restoreCargoFromList}
                    target={currentCargo}
                    title={"Restaurar cargo"}
                    body={<span>¿Estás seguro que quiere restaurar el cargo de la lista de eliminados?</span>}
                    size="md"
                    btnIcon={faCheckDouble}
                    btnVariant={"danger"}
                    variant={"warning"}
                    btnConfirmText="Restaurar"
                  />
                </CustomTooltip>
                :
                <CustomTooltip text={"Remover de la lista"}>
                  <Dialog
                    action={removeCargoFromList}
                    target={currentCargo}
                    title={"Eliminar cargo"}
                    body={<span>¿Estás seguro que quiere remover el cargo de la lista?</span>}
                    size="md"
                    btnIcon={faTrash}
                    btnVariant={"danger"}
                    variant={"danger"}
                    btnConfirmText="Eliminar"
                  />
                </CustomTooltip>
              }
            </div>
          </td>
        }
      </tr >
    </>
  );
};

export const CargoMultiRow = ({
  currentKey,
  currentCargo,
  handleChangeRowCargoInputs,
  index,
  removeCargoFromList,
  removeVarianteFromCargo,
  restoreActionIcon,
  restoreCargoFromList,
  restoreVarianteFromCargo,
  mainElement,
  handleClickFinalizarCargaFunction,
  showButtons,
  showInputFolio,
  showInputCondicion,
  showSelectApendice,
  showActionsCol,
  changeCargoInActionsSection,
  validated,
  showCantidadIncumbencias,
  renderCustomDetailModal,
  hideTipoEscuela,
  hideColumns = {},
}) => {
  const { tipoIncumbencias, apendices } = useSelector(st => st.data)

  const getBackgroundColorToVariante = (currentVariante) => {
    return currentVariante?.acciones?.editar === false ? { backgroundColor: "#f5f5f5" } : {}
  }

  const getBackgroundColorToCargo = (currentCargo) => {
    return currentCargo.variantes.every(ctVariante => ctVariante?.acciones?.editar === false) ? { backgroundColor: "#f5f5f5" } : {}
  }

  return currentCargo.variantes.length > 0 ? (
    <>
      {currentCargo.variantes.map((currentVariante, varianteIndex) => (
        <React.Fragment key={`${currentKey ?? "variante"}-variante-${varianteIndex}`}>
          <tr style={getBackgroundColorToCargo(currentCargo)}>
            {varianteIndex == 0 && (
              <>
                <td rowSpan={currentCargo.variantes.length + 1}>{currentCargo.cargo.codDad ?? "-"}</td>
                <td onClick={() => console.log({
                  currentVariante,
                  varianteIndex,
                  disabled: currentVariante?.acciones?.editar === false
                })}
                  rowSpan={currentCargo.variantes.length + 1}>{currentCargo.cargo.nombre ?? "-"}</td>
              </>
            )}
            {
              showSelectApendice &&
              <td>
                <Input
                  type="select"
                  controlId="apendice_id"
                  style={{ minWidth: "180px", }}
                  value={currentCargo.variantes[varianteIndex].apendice_id}
                  onChange={
                    (event) => handleChangeRowCargoInputs(event, currentCargo, null, "multi", varianteIndex)
                  }
                  elements={apendices ?? []}
                  disabled={!apendices}
                  required
                ></Input>
              </td>
            }
            {showInputFolio && (
              <td style={getBackgroundColorToVariante(currentVariante)}>
                <Input
                  type="input"
                  controlId="folio"
                  value={currentCargo.variantes[varianteIndex].folio}
                  required
                  style={{
                    minWidth: currentCargo.variantes[varianteIndex].folio ? `${getMinWidthFolio(currentCargo.variantes[varianteIndex].folio.length)}` : "auto",
                  }}
                  onChange={(event) => handleChangeRowCargoInputs(event, currentCargo, isValidNumeric, "multi", varianteIndex)}
                  disabled={currentVariante?.acciones?.editar === false}
                ></Input>
              </td>
            )}
            <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.area.nombre ?? "-"} </td>
            {!hideTipoEscuela && <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.tipoEscuela.nombre ?? "-"} </td>}
            <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.asignatura?.codDad ? currentVariante.asignatura?.codDad : "-"}</td>
            <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.asignatura?.nombre ?? "SIN ASIGNATURA"}</td>
            <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.especialidad?.codDad ? currentVariante.especialidad?.codDad : "-"}</td>
            <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.especialidad.nombre ?? "SIN ESPECIALIDAD"}</td>

            {showInputCondicion && (
              <td style={getBackgroundColorToVariante(currentVariante)}>
                <Input
                  type="select"
                  controlId="condicion"
                  style={{ width: "12rem", margin: 0 }}
                  value={currentCargo.variantes[varianteIndex].condicion}
                  onChange={(event) => handleChangeRowCargoInputs(event, currentCargo, null, "multi", varianteIndex)}
                  elements={tipoIncumbencias}
                  required
                  disabled={currentVariante?.acciones?.editar === false}
                ></Input>

                {tipoIncumbencias?.length > 0 &&
                  idsTipoIncumbenciaConPuntaje.some(ctCondicionConPuntajeId => ctCondicionConPuntajeId == currentVariante.condicion) &&
                  <>
                    <Input
                      type="input"
                      controlId="puntaje"
                      label="Puntaje"
                      style={{ width: "12rem" }}
                      value={currentCargo.variantes[varianteIndex].puntaje}
                      onChange={(event) => handleChangeRowCargoInputs(event, currentCargo, isValidPuntaje, "multi", varianteIndex)}
                      description={"(Del 0 al 3)"}
                      required
                      disabled={currentVariante?.acciones?.editar === false}
                    ></Input>
                    <Input
                      type="checkbox"
                      controlId="sumaOT"
                      name="sumaOT"
                      label="Solo aplica para Otros titulos"
                      value="0"
                      checked={currentCargo.variantes[varianteIndex].sumaOT}
                      onChange={(event) => handleChangeCheckBox(event, (event) => handleChangeRowCargoInputs(event, currentCargo, null, "multi", varianteIndex))}
                      disabled={currentVariante?.acciones?.editar === false}
                    ></Input>
                  </>
                }
              </td>
            )}
            {
              !hideColumns.estado &&
              <td style={getBackgroundColorToVariante(currentVariante)}>
                <Badge variant={currentCargo.cargo.estado ? currentCargo.cargo.estado.color : "primary"}>{" "}{currentCargo.cargo.estado?.nombre ?? "-"}</Badge>
              </td>
            }
            {showCantidadIncumbencias && <td style={getBackgroundColorToVariante(currentVariante)}>{currentVariante.cantidadIncumbencias}</td>}
            {showActionsCol && (
              <td style={getBackgroundColorToVariante(currentVariante)}>
                <div className="table-button-group">
                  {changeCargoInActionsSection && showButtons &&
                    <IncumbenciasModalSelectVarianteCargo
                      data={currentCargo}
                      modalTitle="Cargar variantes disponibles de:"
                      cargoIndex={varianteIndex}
                      withIconEdit={true}
                      mainElement={mainElement}
                      handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
                      hideTipoEscuela={hideTipoEscuela}
                    />
                  }
                  {restoreActionIcon ?
                    <CustomTooltip text={"Restaurar variante eliminada"}>
                      <Dialog
                        action={(cargo) => restoreVarianteFromCargo(cargo, varianteIndex)}
                        target={currentCargo}
                        title={"Restaurar cargo"}
                        body={<span>¿Estás seguro que quiere restaurar la variante de la lista de eliminados?</span>}
                        size="md"
                        btnIcon={faCheckDouble}
                        btnVariant={"warning"}
                        variant={"warning"}
                        btnConfirmText="Restaurar"
                      />
                    </CustomTooltip>
                    :
                    <CustomTooltip text={"Remover variante del cargo de la lista"}>
                      <Dialog
                        action={(cargo) => removeVarianteFromCargo(cargo, varianteIndex)}
                        target={currentCargo}
                        title={"Eliminar cargo"}
                        body={<span>¿Estás seguro que quiere remover el cargo de la lista?</span>}
                        size="md"
                        btnIcon={faTrash}
                        btnConfirmText="Eliminar"
                        btnVariant={changeCargoInActionsSection ? "danger" : ""}
                        variant={changeCargoInActionsSection ? "danger" : ""}
                        className={changeCargoInActionsSection ? "" : "text-warning"}
                        disabled={currentVariante?.acciones?.editar === false}
                      />
                    </CustomTooltip>
                  }
                </div>
              </td>
            )}
            {renderCustomDetailModal && <td>renderCustomDetailModal()</td>}
          </tr>
        </React.Fragment >
      ))
      }
      {
        !changeCargoInActionsSection &&
        <tr className="border-bottom-separator" style={getBackgroundColorToCargo(currentCargo)}>
          <td
            colSpan={6 + (showSelectApendice ? 1 : 0) + (showInputFolio ? 1 : 0) + (showInputCondicion ? 1 : 0) + (showCantidadIncumbencias ? 1 : 0) + (renderCustomDetailModal ? 1 : 0) + (!hideColumns.estado ? 1 : 0)
            }
            align="center"
          >
            {showButtons &&
              <IncumbenciasModalSelectVarianteCargo
                data={currentCargo}
                modalTitle="Cargar variantes disponibles de:"
                cargoIndex={index}
                buttonText={"Modificar variante"}
                mainElement={mainElement}
                handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
                hideTipoEscuela={hideTipoEscuela}
              />
            }
          </td>

          {showActionsCol &&
            <td>
              <div className="table-button-group">
                {restoreActionIcon ?
                  <CustomTooltip text={"Restaurar cargo eliminado"}>
                    <Dialog
                      action={restoreCargoFromList}
                      target={currentCargo}
                      title={"Restaurar cargo"}
                      body={<span>¿Estás seguro que quiere restaurar el cargo de la lista de eliminados?</span>}
                      size="md"
                      btnIcon={faCheckDouble}
                      btnVariant={"danger"}
                      variant={"warning"}
                      btnConfirmText="Restaurar"
                    />
                  </CustomTooltip>
                  :
                  <CustomTooltip text={"Remover todo el cargo de la lista"}>
                    <Dialog
                      action={removeCargoFromList}
                      target={currentCargo}
                      title={"Eliminar cargo"}
                      body={<span>¿Estás seguro que quiere remover el cargo de la lista?</span>}
                      size="md"
                      btnIcon={faTrash}
                      btnVariant={"danger"}
                      variant={"danger"}
                      btnConfirmText="Eliminar"
                      disabled={currentCargo.variantes.every(ctVariante => ctVariante?.acciones?.editar === false)}
                    />
                  </CustomTooltip>
                }
              </div>
            </td>
          }
        </tr >
      }
    </>
  ) : (
    <>
      <tr className="border-bottom-separator">
        <td rowSpan={currentCargo.variantes.length + 1}>{currentCargo.cargo.codDad ?? "-"}</td>
        <td>{currentCargo.cargo.nombre ?? "-"}</td>
        <td
          colSpan={6 + (showSelectApendice ? 1 : 0) + (showInputFolio ? 1 : 0) + (showInputCondicion ? 1 : 0) + (showCantidadIncumbencias ? 1 : 0) + (renderCustomDetailModal ? 1 : 0) + (!hideColumns.estado ? 1 : 0)}
          align="center"
        >
          {/* <td colSpan={7 + (showInputFolio ? 1 : 0) + (showInputCondicion ? 1 : 0)} align="center"> */}
          {showButtons &&
            <>
              <IncumbenciasModalSelectVarianteCargo
                data={currentCargo}
                modalTitle="Cargar variantes disponibles de:"
                cargoIndex={index}
                buttonText={"Seleccionar variante"}
                handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
                mainElement={mainElement}
                hideTipoEscuela={hideTipoEscuela}
              />
              {validated && <span id="customErrorMsg" class="invalid-feedback d-block position-relative">Es necesario seleccionar una variante para el cargo</span>}
            </>
          }
        </td>
        {showActionsCol &&
          <td>
            <div className="table-button-group">
              <CustomTooltip text={"Remover de la lista"}>
                <Dialog
                  action={removeCargoFromList}
                  target={currentCargo}
                  title={"Eliminar cargo"}
                  body={<span>¿Estás seguro que quiere remover el cargo de la lista?</span>}
                  size="md"
                  btnIcon={faTrash}
                  btnVariant={"danger"}
                  variant={"danger"}
                  btnConfirmText="Eliminar"
                />
              </CustomTooltip>
            </div>
          </td>
        }
      </tr>
    </>
  );
};

export const TernaSimpleRow = ({
  currentTerna,
  handleChangeRowTernaInputs,
  mainElement,
  restoreActionIcon,
  restoreTernaFromList,
  removeTernaFromList,
  showRowActions,
  showRowInputs,
  showInputFolio,
  showInputCondicion,
  showSelectApendice,
  hideColumns = {},
}) => {

  const { tipoIncumbencias, apendices } = useSelector(st => st.data)

  const getBackgroundColorToTerna = (currentTerna) => {
    return (currentTerna?.acciones?.editar === false) ? { backgroundColor: "#f5f5f5" } : {}
  }

  console.log({ currentTerna })

  return (
    <tr style={getBackgroundColorToTerna(currentTerna)}>
      {
        showSelectApendice && <td>
          <Input
            type="select"
            controlId="apendice_id"
            style={{ minWidth: "180px", }}
            value={currentTerna.apendice_id}
            onChange={(event) => handleChangeRowTernaInputs(event, currentTerna)}
            elements={apendices}
            required
          ></Input>

          {/* <Input
            type="react-select-sync"
            controlId="apendice"
            name="apendice"
            value={currentTerna.apendice}
            style={{ minWidth: "180px", }}
            methodOnChange={(event) => handleChangeRowTernaInputs(formatEventApendice(event), currentTerna)}
            syncElements={apendices ? formatApendicesToSelect(apendices) : []}
            config={{ isSearchable: true, isClearable: true }}
            required
          ></Input> */}
        </td>
      }

      {showInputFolio && (
        <td>
          <Input
            type="input"
            controlId="folio"
            value={currentTerna.folio}
            required
            onChange={(event) => handleChangeRowTernaInputs(event, currentTerna, isValidNumeric)}
            disabled={currentTerna?.acciones?.editar === false}
          ></Input>
        </td>
      )}
      <td onClick={() => console.log({ currentTerna })}>{currentTerna.terna.titulo.id}</td>
      <td>{currentTerna.terna.titulo.nombre}</td>
      <td>{currentTerna.terna.procedencia.nombre}</td>
      <td>{currentTerna.terna.resolucion.nombre}</td>

      {showRowInputs && (
        <td>
          <Input
            type="select"
            controlId="condicion"
            style={{ width: "12rem" }}
            value={currentTerna.condicion}
            onChange={(event) =>
              handleChangeRowTernaInputs(event, currentTerna)
            }
            elements={tipoIncumbencias}
            required
            disabled={currentTerna?.acciones?.editar === false}
          ></Input>
          {tipoIncumbencias?.length > 0 &&
            idsTipoIncumbenciaConPuntaje.some(ctCondicionConPuntajeId => ctCondicionConPuntajeId == currentTerna.condicion) &&
            (
              <div className="puntaje-options">
                <Input
                  type="input"
                  controlId="puntaje"
                  style={{ width: "12rem" }}
                  label="Puntaje"
                  value={currentTerna.puntaje}
                  onChange={(event) => handleChangeRowTernaInputs(event, currentTerna, isValidPuntaje)}
                  description={"(Del 0 al 3)"}
                  required
                  disabled={currentTerna?.acciones?.editar === false}
                ></Input>

                <Input
                  type="checkbox"
                  controlId="sumaOT"
                  name="sumaOT"
                  label="Solo aplica para Otros titulos"
                  value="0"
                  checked={currentTerna.sumaOT}
                  onChange={(event) => handleChangeCheckBox(event, (event) => handleChangeRowTernaInputs(event, currentTerna, null))}
                  disabled={currentTerna?.acciones?.editar === false}
                ></Input>
              </div>
            )}
        </td>
      )}
      {
        !hideColumns.estado &&
        <td>
          <Badge variant={currentTerna.terna.estado ? currentTerna.terna.estado?.color : "primary"}>{" "}{currentTerna.terna.estado?.nombre}</Badge>
        </td>
      }
      {showRowActions && <td>
        <div className="table-button-group">
          {restoreActionIcon ?
            <CustomTooltip text={"Restaurar terna eliminada"}>
              <Dialog
                action={restoreTernaFromList}
                target={currentTerna}
                title={"Restaurar terna eliminada"}
                body={<span>¿Estás seguro que quiere restaurar la terna de la lista?</span>}
                size="md"
                btnIcon={faCheckDouble}
                btnVariant={"warning"}
                variant={"warning"}
                btnConfirmText="Restaurar"
              />
            </CustomTooltip>
            :
            <CustomTooltip text={"Remover de la lista"}>
              <Dialog
                action={removeTernaFromList}
                target={currentTerna}
                title={"Eliminar terna"}
                body={<span>¿Estás seguro que quiere remover la terna de la lista?</span>}
                size="md"
                btnIcon={faTrash}
                btnVariant={"danger"}
                variant={"danger"}
                btnConfirmText="Eliminar"
                disabled={currentTerna?.acciones?.editar === false}
              />
            </CustomTooltip>
          }
        </div>
      </td>
      }
    </tr>
  );
};

export const IncumbenciasSelectRow = ({
  elemento,
  handleChangeRowCargoInputs,
  handleChangeRowTernaInputs,
  tipo,
  mainElement,
  showButtons,
  restoreActionIcon,
  validated,
  handleClickFinalizarCargaFunction,
  showCantidadIncumbencias,
  renderCustomDetailModal,
  hideTipoEscuela,

  showActionsCol,
  hideColumns = {},
}) => {

  const { typeOfAction } = useSelector(st => st.incumbencias);

  const dispatch = useDispatch();

  const removeTernaFromList = (currentTerna) => {
    if (typeOfAction == typeOfActions.EDIT) dispatch(addTernaToArr(currentTerna, "deletedElements"));
    dispatch(removeTernaFromArr(currentTerna, "ternaSeleccionada"));
  }

  const restoreTernaFromList = (currentTerna) => {
    if (typeOfAction == typeOfActions.EDIT) dispatch(addTernaToArr(currentTerna, "ternaSeleccionada"));
    dispatch(removeTernaFromArr(currentTerna, "deletedElements"));
  }

  const removeCargoFromList = (currentCargo) => {
    console.log("removeCargoFromList", { currentCargo })
    if (typeOfAction == typeOfActions.EDIT) dispatch(addCargoToArr(currentCargo, "deletedElements", { verificarPermisoEditar: true }));
    dispatch(removeCargoFromArr(currentCargo, "cargoSeleccionado", { verificarPermisoEditar: true }));
  }

  const restoreCargoFromList = (currentCargo) => {
    if (typeOfAction == typeOfActions.EDIT) dispatch(addCargoToArr(currentCargo, "cargoSeleccionado"));
    dispatch(removeCargoFromArr(currentCargo, "deletedElements"));
  }

  const removeVarianteFromCargo = (currentCargo, varianteToRemoveIndex) => {
    if (typeOfAction == typeOfActions.EDIT) dispatch(addVarianteToArr(currentCargo.variantes[varianteToRemoveIndex], currentCargo, "deletedElements"));
    dispatch(removeVarianteFromArr(currentCargo, varianteToRemoveIndex, "cargoSeleccionado", typeOfAction == typeOfActions.EDIT));
  }

  const restoreVarianteFromCargo = (currentCargo, varianteToRemoveIndex) => {
    if (typeOfAction == typeOfActions.EDIT) dispatch(addVarianteToArr(currentCargo.variantes[varianteToRemoveIndex], currentCargo, "cargoSeleccionado"));
    dispatch(removeVarianteFromArr(currentCargo, varianteToRemoveIndex, "deletedElements", typeOfAction == typeOfActions.EDIT));
  }

  return (
    <>
      {tipo == incumbenciasConstants.TERNAS_INCUMBENCIA &&
        elemento.map((currentTerna, index) => (
          <React.Fragment key={`ternaMap${currentTerna.terna.id}${index}`}>
            <TernaSimpleRow
              currentTerna={currentTerna}
              handleChangeRowTernaInputs={handleChangeRowTernaInputs}
              mainElement={mainElement}
              restoreActionIcon={restoreActionIcon}
              removeTernaFromList={removeTernaFromList}
              restoreTernaFromList={restoreTernaFromList}
              showRowActions={showActionsCol || mainElement != incumbenciasConstants.TERNAS_INCUMBENCIA}
              showRowInputs={mainElement != incumbenciasConstants.TERNAS_INCUMBENCIA}
              hideColumns={hideColumns}
              showInputFolio={mainElement != incumbenciasConstants.TERNAS_INCUMBENCIA}
              showSelectApendice={mainElement != incumbenciasConstants.TERNAS_INCUMBENCIA && typeOfAction != typeOfActions.CREATE}

            />
          </React.Fragment>
        ))}

      {tipo == incumbenciasConstants.CARGOS_INCUMBENCIA &&
        elemento.map((currentCargo, index) => (
          <React.Fragment key={`cargosMap"${currentCargo.id}${index}`}>
            {currentCargo.type == "simple" && (
              <CargoSimpleRow
                currentCargo={currentCargo}
                handleChangeRowCargoInputs={handleChangeRowCargoInputs}
                removeCargoFromList={removeCargoFromList}
                restoreCargoFromList={restoreCargoFromList}
                restoreActionIcon={restoreActionIcon}
                mainElement={mainElement}
                hideTipoEscuela={hideTipoEscuela}
                showCantidadIncumbencias={showCantidadIncumbencias}
                showInputFolio={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA}
                showInputCondicion={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA}
                showSelectApendice={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA && typeOfAction != typeOfActions.CREATE}
                showActionsCol={showActionsCol}
                hideColumns={hideColumns}
              />
            )}

            {currentCargo.type == "multi" && (
              <CargoMultiRow
                currentCargo={currentCargo}
                handleChangeRowCargoInputs={handleChangeRowCargoInputs}
                index={index}
                removeCargoFromList={removeCargoFromList}
                removeVarianteFromCargo={removeVarianteFromCargo}
                restoreCargoFromList={restoreCargoFromList}
                restoreVarianteFromCargo={restoreVarianteFromCargo}
                restoreActionIcon={restoreActionIcon}
                mainElement={mainElement}
                showInputFolio={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA}
                showInputCondicion={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA}
                showSelectApendice={mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA && typeOfAction != typeOfActions.CREATE}
                showButtons={showButtons}
                showActionsCol={showActionsCol || mainElement != incumbenciasConstants.CARGOS_INCUMBENCIA}
                handleClickFinalizarCargaFunction={handleClickFinalizarCargaFunction}
                validated={validated}
                showCantidadIncumbencias={showCantidadIncumbencias}
                renderCustomDetailModal={renderCustomDetailModal}
                hideTipoEscuela={hideTipoEscuela}
                hideColumns={hideColumns}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
};
