import React from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import TernaSearchRow from "./TernaSearchRow";
import Paginacion from "../../generics/Paginacion";

const TernaSearchTable = ({ ternas, cantidadTernas, page, changePage, ...props }) => {
  const renderTableRows = (ternas) => {
    return ternas.map((terna) => {
      return (
        <TernaSearchRow
          key={terna.id}
          onSubmit={props.onSubmit}
          terna={terna}
        ></TernaSearchRow>
      );
    });
  };

  return (
    <>
      {!ternas[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron ternas.</Alert>
          </Col>
        </Row>
      ) : (
        !!ternas && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nombre del título</th>
                      <th>Nivel</th>
                      <th>Procedencia</th>
                      <th>Resolución</th>
                      <th>Fecha</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(ternas)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadTernas} currentPage={page} onChangePage={changePage} onSubmit={props.onSubmit}/>
          </>
        )
      )}
      {!!ternas[0] && <Row>
        <Col>
        <Button
              variant="primary"
              content="Reporte"
              type="submit"
              disabled  
            >
              Informe Total
            </Button>
        </Col>
      </Row>}
    </>
  );
};

export default TernaSearchTable;