import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { DetailFact } from '../../../ui/DetailFact'

export const DetailCargo = ({
    cargo,
    asignatura,
    especialidad,
    tipoEscuela,
    area,
}) => {
    return (
        <div>
            <b>Cargo:</b>
            {area.nombre} |
            {tipoEscuela.nombre} |
            {cargo.nombre} |
            {asignatura.nombre} |
            {especialidad.nombre}
        </div>
    )
}
