import React from 'react';
import { Col, Row } from "react-bootstrap";
import { DetailFact } from "../../../../ui/DetailFact";

export const RelacionDetailBody = ({ relacionado, original, }) => {
    return (
        <>
            <Row className="form-group">
                <Col md={4}>
                    <DetailFact label="Apendice" input={relacionado.apendice.nombre} />
                </Col>
                <Col md={4}>
                    <DetailFact label="Folio" input={relacionado.folio} />
                </Col>
                <Col md={4}>
                    <DetailFact label="Estado" input={relacionado.estado.nombre} />
                </Col>
            </Row>
            <hr />
            <Row className="form-group">
                <Col>
                    <h3>Titulo principal</h3>
                </Col>
            </Row>
            <Row className="form-group">
                <Col md={6}>
                    <DetailFact label="Nombre del título" input={original.titulo.nombre} />
                </Col>
                <Col md={6}>
                    <DetailFact label="Procedencia" input={original.procedencia.nombre} />
                </Col>
                <Col md={6}>
                    <DetailFact label="Resolución" input={original.resolucion.nombre} />
                </Col>
                {original?.titulo.nivel?.nombre && (
                    <Col md={6}>
                        <DetailFact label="Nivel" input={original.titulo.nivel.nombre} />
                    </Col>
                )}
            </Row>
            <hr />
            <Row className="form-group">
                <Col>
                    <h3>Titulo relacionado</h3>
                </Col>
            </Row>
            <Row className="form-group">
                <Col md={6}>
                    <DetailFact label="Nombre del título" input={relacionado.terna.titulo.nombre} />
                </Col>
                <Col md={6}>
                    <DetailFact label="Procedencia" input={relacionado.terna.procedencia.nombre} />
                </Col>
                <Col md={6}>
                    <DetailFact label="Resolución" input={relacionado.terna.resolucion.nombre} />
                </Col>
                {relacionado?.terna?.titulo?.nivel?.nombre && (
                    <Col md={6}>
                        <DetailFact label="Nivel" input={relacionado.terna.titulo.nivel.nombre} />
                    </Col>
                )}
            </Row>
        </>
    );
};
