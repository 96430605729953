import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { createTitulo } from "../dtos/terna/Titulo";
import { adaptGetInformeCruzadoPorArea, adaptGetInformeTernasPorAreaCargo, adaptGetInformeTopologico, adaptGetTernasConIncumbencias } from "./InformesAdapter";

const { default: requestService } = require("../utils/request.service");
const { apiURLs, paginado } = require("../utils/Constants");

export const getTernasConIncumbencias = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
        titulo
        procedencia_id
        resolucion_id
        area_id
    */
    const uri = apiURLs.API_INFORMES_GET_TERNAS_CON_INCUMBENCIAS
    let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
    return {
        elementos: adaptGetTernasConIncumbencias(response.data.elementos),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };
}

export const getInformeCruzadoPorArea = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
        "area_id"
    */
    try {
        const uri = apiURLs.API_INFORMES_CRUZADOS_AREA_CARGO + "/porArea";
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
        return {
            elementos: adaptGetInformeCruzadoPorArea(response.data.elementos),
            cantidad: response.data.cantidad,
            page: response.data.page,
        };
    } catch (err) { throw new Error(err.message); }
}

export const getInformeCruzadoPorCargo = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
        "cargo_id"
    */
    try {
        const uri = apiURLs.API_INFORMES_CRUZADOS_AREA_CARGO + "/porCargo";
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
        return {
            elementos: response.data.elementos,
            cantidad: response.data.cantidad,
            page: response.data.page,
            columnas: response.data.columnas ?? [],
        };
    } catch (err) { throw new Error(err.message); }
}

export const getInformeTopologico = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
        "apendice"
        "folio"
        "area_id"
    */
    try {
        const uri = apiURLs.API_INFORMES_TOPOLOGICO;
        filtros.limit = 10;
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)

        return {
            elementos: adaptGetInformeTopologico(response.data.elementos),
            cantidad: response.data.cantidad,
            page: response.data.page,
        };
    } catch (err) { throw new Error(err.message); }
}

export const getOptionsInformeTernasPorAreaCargo = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
        "area_id"
        "tipo_escuela_id"
        "cargo_id"
        "asignatura_id"
        "especialidad_id"
    */
    try {
        const uri = apiURLs.API_INFORMES_TERNAS_AREA_CARGO;
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
        return {
            elementos: response.data.elementos,
            cantidad: response.data.cantidad,
            page: response.data.page,
        };
    } catch (err) { throw new Error(err.message); }
}

export const getInformeTernasPorAreaCargo = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
       "area_id"
       "tipo_escuela_id"
       "cargo_id"
       "asignatura_id"
       "especialidad_id"
   */
    try {
        const uri = apiURLs.API_INFORMES_TERNAS_AREA_CARGO + "/ternas";
        if (!filtros.limit) filtros.limit = 10;
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
        const elementos = adaptGetInformeTernasPorAreaCargo(response.data.elementos)
        return {
            elementos: elementos,
            cantidad: response.data.cantidad,
            page: response.data.page,
        };
    } catch (err) { throw new Error(err.message); }
}

const getTitulos = async (filtros = {}) => {
    filtros['limit'] = paginado.FILAS_POR_PAGINAS
    const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_TITULO, filtros)
    let titulos = []
    response.data.elementos.forEach(titulo => {
        titulos.push(createTitulo(titulo));
    });
    let titulosConcretos = await Promise.all(titulos);
    return { cantidad: response.data.cantidad, elementos: titulosConcretos, page: response.data.page };
}

const editTitulo = async (informe) => {
    let { nivel_id, nombre, supervisor_id, ...body } = informe
    await requestService.sendRequest('PATCH', apiURLs.API_TERNAS_TITULO + `/${informe.id}`, { nivel: nivel_id, nombre: nombre })
        .then(resp => {
            store.dispatch(success("La solicitud de edicion se envio correctamente."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}

const editProcedencias = async (informe) => {
    let { supervisor_id, nombre, folio, descripcion, ...body } = informe
    await requestService.sendRequest('PATCH', apiURLs.API_TERNAS_PROCEDENCIA + `/${informe.id}`, { nombre: nombre, folio: folio, descripcion: descripcion })
        .then(resp => {
            store.dispatch(success("La solicitud de edicion se envio correctamente."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}

const editResoluciones = async (informe) => {
    await requestService.sendRequest('PATCH', apiURLs.API_TERNAS_RESOLUCION + `/${informe.id}`, informe)
        .then(resp => {
            store.dispatch(success("La solicitud de edicion se envio correctamente."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}

const getResoluciones = async (filtros = {}) => {
    filtros['limit'] = paginado.FILAS_POR_PAGINAS
    const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_RESOLUCION, filtros)
    let elementos = []
    response.data.elementos.forEach(elemento => {
        elementos.push(createTitulo(elemento));
    });
    let elementosConcretos = await Promise.all(elementos);
    return { cantidad: response.data.cantidad, elementos: elementosConcretos, page: response.data.page };
}

const getProcedencias = async (filtros = {}) => {
    filtros['limit'] = paginado.FILAS_POR_PAGINAS
    const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_PROCEDENCIA, filtros)
    let elementos = []
    response.data.elementos.forEach(elemento => {
        elementos.push(createTitulo(elemento));
    });
    let elementosConcretos = await Promise.all(elementos);
    return { cantidad: response.data.cantidad, elementos: elementosConcretos, page: response.data.page };
}

const eliminarTitulo = async (informe) => {
    await requestService.sendRequest('DELETE', apiURLs.API_TERNAS_TITULO + `/${informe.id}`)
        .then(resp => {
            store.dispatch(success("Se eliminó correctamente el titulo."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}

const eliminarProcedencia = async (informe) => {
    await requestService.sendRequest('DELTE', apiURLs.API_TERNAS_PROCEDENCIA + `/${informe.id}`)
        .then(resp => {
            store.dispatch(success("Se eliminó correctamente la procedencia."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}

const eliminarResolucion = async (informe) => {
    await requestService.sendRequest('DELETE', apiURLs.API_TERNAS_RESOLUCION + `/${informe.id}`)
        .then(resp => {
            store.dispatch(success("Se eliminó correctamente la resolución."))
        }).catch(e => { store.dispatch(error(e.response.data.message)) })
}
export {
    getTitulos,
    getResoluciones,
    getProcedencias,
    eliminarTitulo,
    eliminarProcedencia,
    eliminarResolucion,
    editTitulo,
    editProcedencias,
    editResoluciones,
}