import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Dialog from '../../generics/dialog';
import { clear, success } from '../../store/alerts/alertActions';
import Input from '../../ui/Input';
import { handlerChange, isValidText } from '../../utils/commons';
import * as ternaService from "../TernaService";

export const OrganismoAddModal = ({
    handleClose,
    show = false,
    resolve,
}) => {
    const defaultValues = {
        nombre_organismo: "",
    }

    const [inputsForm, setInputsForm] = useState(defaultValues);
    const [validated, setValidated] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const isMountedRef = useRef(null);
    const dispatch = useDispatch();

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const handleValidation = async () => {
        setValidated(false);
        setErrorMsg("");
        const form = document.getElementById("formModalABMOrganismo");
        if (!form.checkValidity() === false) {
            try {
                setIsLoading(true)
                const newOrganismo = await ternaService.createNewOrganismo(inputsForm.nombre_organismo);
                await resolve(newOrganismo);
                dispatch(success(<>Se creó el organismo <b>{inputsForm.nombre_organismo}</b> correctamente.</>));
                handleClose()
            }
            catch (err) {
                setErrorMsg(err?.response?.data?.message ?? "Ha ocurrido un error al enviar la peticion.");
            }
            finally {
                setIsLoading(false)
            }
        }
        setValidated(true);
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handlerChange(setInputsForm, inputsForm, null, e);
        }
    };

    const guardar = async () => {
        await handleValidation()
    }

    useEffect(() => {
        isMountedRef.current = true;
        dispatch(clear());
        return () => (isMountedRef.current = false);
    }, [dispatch]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-z-1"
            backdropClassName="modal-z-1"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Crear Organismo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="formModalABMOrganismo"
                    noValidate
                    validated={validated}
                    onSubmit={preventDefault}
                >
                    {errorMsg && <Alert variant={"danger"}>{errorMsg}</Alert>}
                    <Row>
                        <Col>
                            <Input
                                type="input"
                                controlId="nombre_organismo"
                                label="Nombre del organismo"
                                value={inputsForm.nombre_organismo}
                                onChange={handleValidChange.bind(this, isValidText)}
                                maxLength="200"
                                required
                            ></Input>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
                    Volver
                </Button>
                <Dialog
                    action={guardar}
                    target={{}}
                    title={"Guardar"}
                    variant="primary"
                    size="md"
                    btnConfirmText="Guardar"
                    btnVariant="success"
                    btnText="Guardar"
                    isLoadingText={"Guardando"}
                    body={
                        <span>
                            ¿Estás seguro de guardar el organismo?
                        </span>
                    }
                />
            </Modal.Footer>
        </Modal>
    )
}
