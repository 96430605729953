import { faCheckDouble, faPen, faTrash, } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import PrivateComponent from "../../authentication/PrivateComponent";
import IconButton from "../../generics/IconButton";
import Dialog from "../../generics/dialog";
import { error } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import DialogActivate from "../../ui/DialogActivate";
import { routePaths, tooltipsText } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos";
import { activate, deactivate, deleteEspecialidad, } from "../EspecialidadesService";
import EspecialidadesDetailModal from "../details/EspecialidadesDetailModal";

const EspecialidadesSearchRowActions = ({ especialidad, ...props }) => {
    const [redirect, setRedirect] = useState("");

    const dispatch = useDispatch();

    const resolveCallBack = () => {
        dispatch(searchFab(true))
    }

    const redirectToEdit = (especialidad) => {
        setRedirect(routePaths.ABM_ESPECIALIDADES_EDIT);
    };

    const redirectToApproval = (especialidad) => {
        setRedirect(routePaths.ABM_ESPECIALIDADES_APPROVAL);
    };

    const changeState = async (especialidad) => {
        try {
            if (especialidad.activo) {
                await deactivate(especialidad, resolveCallBack)
            } else {
                await activate(especialidad, resolveCallBack)
            }
        } catch (e) {
            dispatch(error(e.response.data.message));
        }
    };

    const handleEliminar = async (target) => {
        try {
            await deleteEspecialidad(target);
            resolveCallBack()
        }
        catch (err) {
            dispatch(error(err.response.data.message));
        }
    }

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        especialidad: especialidad,
                        abmTitle: "Editar",
                        submitValue: "Aceptar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <>

            {(especialidad.acciones.alta || especialidad.acciones.baja) &&
                <PrivateComponent permisos={especialidad.acciones.alta ? PERMISOS_MAP.ESPECIALIDAD_ALTA : PERMISOS_MAP.ESPECIALIDAD_BAJA}>
                    <CustomTooltip text={especialidad.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                        <DialogActivate
                            target={especialidad}
                            title={(especialidad.acciones.alta ? "Dar de alta" : "Dar de baja") + " especialidad"}
                            action={changeState}
                            size="md"
                            body={<span>
                                ¿Estás seguro de dar de {(especialidad.acciones.alta ? "alta " : "baja ")}
                                la especialidad
                                <b> {especialidad ? especialidad.nombre : ""}</b>
                                ?
                            </span>
                            }
                            btnConfirmText={especialidad.acciones.alta ? "Dar de alta" : "Dar de baja"}
                            isLoadingText={especialidad.acciones.alta ? "Dando de alta" : "Dando de baja"}
                        >
                        </DialogActivate>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {especialidad.acciones.eliminar &&
                <PrivateComponent permisos={PERMISOS_MAP.ESPECIALIDAD_ELIMINAR}>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={handleEliminar}
                            target={especialidad}
                            title={"Eliminar especialidad"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar la especialidad
                                <b> {especialidad ? especialidad.nombre : ""}</b>
                                ?
                            </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                            isLoadingText={"Eliminando"}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }

            {especialidad.acciones.aprobar &&
                <PrivateComponent permisos={PERMISOS_MAP.ESPECIALIDAD_APROBAR}>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <IconButton
                            icon={faCheckDouble}
                            onClick={() => redirectToApproval(especialidad)}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }


            {especialidad.acciones.publicar &&
                <PrivateComponent permisos={PERMISOS_MAP.ESPECIALIDAD_PUBLICAR}>
                    <CustomTooltip text={tooltipsText.PUBLICAR}>
                        <EspecialidadesDetailModal
                            especialidad={especialidad}
                            withPublicar={true}
                            onSubmit={resolveCallBack}
                        ></EspecialidadesDetailModal>
                    </CustomTooltip>
                </PrivateComponent>
            }

            {especialidad.acciones.editar &&
                <PrivateComponent permisos={PERMISOS_MAP.ESPECIALIDAD_EDITAR}>
                    <CustomTooltip text={tooltipsText.EDITAR}>
                        <IconButton icon={faPen} onClick={() => redirectToEdit(especialidad)} />
                    </CustomTooltip>
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <EspecialidadesDetailModal
                    especialidad={especialidad}
                ></EspecialidadesDetailModal>
            </CustomTooltip>

        </>
    );
};

export default EspecialidadesSearchRowActions;
