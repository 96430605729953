import React, { useState, useEffect } from "react";

import { routePaths } from "../../utils/Constants";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Escuela, { createEscuela } from "../../dtos/escuela/Escuela";
import { Row, Col, Button, Container } from "react-bootstrap";
import EscuelaABMForm from "./EscuelaABMForm";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import IconTextButton from "../../generics/IconTextButton";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { saveFilters } from "../../store/search/searchActions";

const EscuelaABMApproval = (props) => {
  let location = useLocation();
  const [escuela, setEscuela] = useState();
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos data de los filtrosGuardados para forzar refresh al cargar la data en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.data
    dispatch(saveFilters(newFiltrosGuardados))
  };

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.escuela) {
      let escuela = location.state.escuela;
      if (!(escuela instanceof Escuela)) {
        escuela = createEscuela(escuela)
      }
      setEscuela(escuela);
    }
  }, [dispatch, location.state]);

  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
    >
      <Row>
        <Col>
          <Row className="form-group">
            <Col md="12">
              <h1>Aprobar Tipo de Escuela
                {/* <IconTextButton
                  className="btn-icon-rounded"
                  icon={faPen}
                  type="submit"
                  variant="secondary"
                  text={disabled ? "Modificar Tipo de Escuela" : "Cerrar Modificación"}
                  onClick={() => setDisabled(!disabled)} >
                </IconTextButton> */}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <EscuelaABMForm
                onSubmit={handleSubmit}
                escuela={escuela}
                disabled={disabled}
                isAprproval={true}
                returnPath={routePaths.ABM_ESCUELA_SEARCH}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};

export default EscuelaABMApproval;
