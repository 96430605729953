import React from 'react'
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export const NavOptionsAddCargos = ({ otherOptions, setOtherOptions }) => {

    return (
        <>
            <h3>Agregar cargo/s</h3>
            <ButtonGroup className="mb-2">
                <ToggleButton
                    id={`radio-1`}
                    className="menu-radio"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="individualmente"
                    checked={otherOptions == 0}
                    onChange={() => setOtherOptions(0)}
                >
                    Individualmente
                </ToggleButton>
                <ToggleButton
                    id={`radio-2`}
                    className="menu-radio mb-2"
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value="cargos"
                    disabled
                    checked={otherOptions == 1}
                    onChange={() => { }}
                >
                    Por categoria
                </ToggleButton>
            </ButtonGroup>
            <hr></hr>
        </>
    )
}
