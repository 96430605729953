import Estado from "../Estado"

class AreaEspecialidad {
    constructor(nombre) {
        this.nombre = nombre;
        this.cod_area = null;
        this.area = "";
        this.area_sgd = "";
        this.estado = Estado
    }
}

export default AreaEspecialidad

export const createAreaEspecialidad = (props) => {
    let estado = props?.estado ? Object.assign(new Estado(props.estado ? props.estado.nombre : ""), props.estado) : ""
    return Object.assign(new AreaEspecialidad(), { ...props, estado })
}
