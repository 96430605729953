import Estado, {createEstado} from "./Estado"

class Nivel{
    constructor(){
        this.id = "";
        this.descripcion = "";
        this.estado = Estado;
    }
}

export default Nivel

export const createNivel = (props) => {
    let estado = props.estado ? createEstado(props.estado) : null
    return Object.assign(new Nivel(), {...props, estado})
}
