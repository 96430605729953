import React from 'react';
import { Row, Col, Badge, Table } from "react-bootstrap";
import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";
import { DetailNumber } from "../../ui/DetailNumber";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons";

const CargoDetailBody = ({ cargo, ...props }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            <Badge variant="primary">{cargo.getNombreEstado()}</Badge>
            {cargo.getFechaCreacion() && (

              <small>
                Creado el: <b>{cargo.getFechaCreacion()}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />
      <Row>
        <Col md={6} className="form-group">
          <DetailFact label="Nombre" input={cargo.nombre} />
        </Col>
        <Col md={6} className="form-group">
          <DetailFact label="Area" input={cargo.getNombreAreas()} />
        </Col>
      </Row>
      <Row>
        {/*
         <Col md={4} lg={3} className="form-group">
          <DetailFact label="Tipo de cargo" input={cargo.getNombreTipoCargo()} />
        </Col> 
        */}

        <Col md={4} lg={3} className="form-group">
          <DetailFact label="Para asignaturas" input={cargo.asignatura ? "SI" : "NO"} />
        </Col>


        {cargo.getNombreNivel() && (

          <Col md={4} lg={3} className="form-group">
            <DetailFact label="Nivel" input={cargo.getNombreNivel()} />
          </Col>

        )}
        {cargo.resolucion && (
          <DetailFact label="Resolución" input={cargo.getNombreResolucion()} />
        )}
        <Col lg={6} className="form-group">
          <DetailFact label="Especialidad" input={cargo.getNombreEspecialidades()} />
        </Col>
        <Col className="form-group">
          <DetailFact label="Tipos de escuela" input={cargo.getNombreTipoEscuela()} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="form-group">
          <DetailNumber label="Código DAD" input={cargo.codDad} />
        </Col>
        {cargo.apendice && (
          <Col className="form-group">
            <DetailNumber label="Apéndice" input={cargo.apendice.id} />
          </Col>
        )}
        {cargo.folio && (
          <Col className="form-group">
            <DetailNumber label="Folio" input={cargo.folio} />
          </Col>
        )}
      </Row>
      {cargo.descripcion && (
        <Row>
          <Col md={12} className="form-group">
            <hr />
            <label>Descripción: </label>
            <p>{cargo.descripcion}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CargoDetailBody;
