import React, { useState } from "react";
import IconButton from "../../generics/IconButton";
import { faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Spinner } from "react-bootstrap";
import AsignaturaDetailBody from "../details/AsignaturaDetailBody";
import { publish } from "../AsignaturaService";
import Dialog from "../../generics/dialog";

const AsignaturaDetailModal = ({ asignatura, withPublicar = false, ...props }) => {
  const [show, setShow] = useState(false);
  const [isFetching, setIsFetching] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handlePublish = async (asignatura, searchFunction) => {
    try {
      setIsFetching(true)
      await publish(asignatura)
      handleClose();
      searchFunction();
    }
    catch (err) {
      console.log("err", err)
    }
    finally {
      setIsFetching(false)
    }
  };

  return (
    <>
      <IconButton {...props} icon={withPublicar ? faUpload : faSearch} onClick={handleShow} >
        {props.children}
      </IconButton>
      <Modal
        show={show}
        onHide={!isFetching ? handleClose : null}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={isFetching}
        backdrop={isFetching ? 'static' : true}
        centered
      >
        <Modal.Header closeButton={!isFetching}>
          <Modal.Title>
            <span className="font-weight-light">Detalle:</span> {asignatura.nombre}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsignaturaDetailBody asignatura={asignatura}></AsignaturaDetailBody>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isFetching}
          >
            Volver
          </Button>
          {withPublicar &&
            <Button variant="primary"
              onClick={() => { handlePublish(asignatura, props.onSubmit) }}
              disabled={isFetching}
            >
              {isFetching ?
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant={"secondary"}
                  />
                  Publicando
                </>
                :
                "Publicar"}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AsignaturaDetailModal