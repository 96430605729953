import React from 'react';
import PrivateComponent from "../authentication/PrivateComponent";
import Dialog from "../generics/dialog";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
//import ModalBody from 'react-bootstrap/ModalBody'

const DialogActivate = ({ validation, target, action, permisos, title, body, size, isLoadingText, ...props }) => {

  return (
    <PrivateComponent permisos={permisos}>
      <Dialog
        validation={validation}
        action={action}
        target={target}
        title={title ? title :
          (target.activo ? "Deshabilitar" : "Habilitar")
        }
        btnIcon={target.activo ? faArrowDown : faArrowUp}
        variant={target.activo ? "danger" : "success"}
        size={size}
        btnConfirmText={target.activo ? "Deshabilitar" : "Habilitar"}
        body={body ? body :
          "¿Esta seguro que quiere " + (target.activo ? "deshabilitar" : "habilitar")}
        isLoadingText={isLoadingText}
        {...props}
      />
    </PrivateComponent>
  );
};

export default DialogActivate
