import { incumbenciasTypes } from "../../actions/types";

export const saveIncumbenciasData = (data) => ({
    type: incumbenciasTypes.SAVE_DATA,
    payload: data,
});

export const cleanIncumbenciasData = () => ({
    type: incumbenciasTypes.CLEAN_DATA,
});

export const replaceArr = (arrElements, arrWithAll, extraConfig) => ({
    type: incumbenciasTypes.REPLACE_ARR,
    payload: { arrElements, arrWithAll, extraConfig },
})

export const addTernaToArr = (ternaToAdd, arrWithAllTernas, overwrite) => ({
    type: incumbenciasTypes.ADD_TERNA,
    payload: { ternaToAdd, arrWithAllTernas, overwrite },
});

export const addCargoToArr = (cargoToAdd, arrWithAllCargos, config = { overwrite: false, verificarPermisoEditar: false }) => ({
    type: incumbenciasTypes.ADD_CARGO,
    payload: { cargoToAdd, arrWithAllCargos, overwrite: config.overwrite, verificarPermisoEditar: config.verificarPermisoEditar },
});

export const addVarianteToArr = (varianteToAdd, cargoWithVariante, arrWithAllCargos) => ({
    type: incumbenciasTypes.ADD_VARIANTE,
    payload: { varianteToAdd, cargoWithVariante, arrWithAllCargos },
});

export const addVariantesInCargosArrWithIndex = (variantesToAdd, arrWithAllCargos, cargoIndex, overwrite) => ({
    type: incumbenciasTypes.ADD_VARIANTES_WITH_INDEX,
    payload: { variantesToAdd, arrWithAllCargos, cargoIndex, overwrite },
});

export const removeCargoFromArr = (cargoToRemove, arrWithAllCargos, config = { verificarPermisoEditar: false }) => ({
    type: incumbenciasTypes.REMOVE_CARGO,
    payload: { cargoToRemove, arrWithAllCargos, verificarPermisoEditar: config.verificarPermisoEditar },
});

export const removeVarianteFromArr = (currentCargo, varianteToRemoveIndex, arrWithAllCargos, removeCargoIfIsEmpty) => ({
    type: incumbenciasTypes.REMOVE_VARIANTE,
    payload: { currentCargo, varianteToRemoveIndex, arrWithAllCargos, removeCargoIfIsEmpty },
});

export const removeTernaFromArr = (ternaToRemove, arrWithAllTernas) => ({
    type: incumbenciasTypes.REMOVE_TERNA,
    payload: { ternaToRemove, arrWithAllTernas },
});

export const addConcurrenciaEmptyRow = () => ({
    type: incumbenciasTypes.ADD_CONCURRENCIA_EMPTY_ROW,
});

export const addTernaToConcurrencia = (concurrenciaIndex, terna) => ({
    type: incumbenciasTypes.ADD_TERNA_TO_CONCURRENCIA,
    payload: { concurrenciaIndex, terna }
});

export const addCargoToConcurrencia = (concurrenciaIndex, cargo) => ({
    type: incumbenciasTypes.ADD_CARGO_TO_CONCURRENCIA,
    payload: { concurrenciaIndex, cargo }
});

export const addCargoVarianteToConcurrencia = (concurrenciaIndex, cargoIndex, variante) => ({
    type: incumbenciasTypes.ADD_CARGO_VARIANTE_TO_CONCURRENCIA,
    payload: { concurrenciaIndex, cargoIndex, variante }
});

export const removeTernaFromConcurrencia = (concurrenciaIndex, ternaId) => ({
    type: incumbenciasTypes.REMOVE_TERNA_FROM_CONCURRENCIA,
    payload: { concurrenciaIndex, ternaId }
});

export const removeCargoFromConcurrencia = (concurrenciaIndex, cargoIndex) => ({
    type: incumbenciasTypes.REMOVE_CARGO_FROM_CONCURRENCIA,
    payload: { concurrenciaIndex, cargoIndex }
});

export const addInputValueToConcurrenciaMainTerna = (concurrenciaIndex, cargoIndex, varianteIndex, inputData, typeOfCargo, arrWithAllCargos) => ({
    type: incumbenciasTypes.ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_TERNA,
    payload: { concurrenciaIndex, cargoIndex, varianteIndex, inputData, typeOfCargo, arrWithAllCargos }
});

export const addInputValueToConcurrenciaMainCargo = (concurrenciaIndex, inputData, arrWithAllCargos) => ({
    type: incumbenciasTypes.ADD_INPUT_VALUE_TO_CONCURRENCIA_MAIN_CARGO,
    payload: { concurrenciaIndex, inputData, arrWithAllCargos }
});

export const removeConcurrencia = (concurrenciaIndex, arrWithAllCargos, config = { verificarPermisoEditar: false }) => ({
    type: incumbenciasTypes.REMOVE_CONCURRENCIA,
    payload: { concurrenciaIndex, arrWithAllCargos, verificarPermisoEditar: config.verificarPermisoEditar }
});

export const removeCargoConcurrenciaFromArr = (currentConcurrenciaIndex, currentCargoIndex, arrWithAllCargos, config = { verificarPermisoEditar: false }) => {
    return {
        type: incumbenciasTypes.REMOVE_CARGO_CONCURRENCIA,
        payload: { currentConcurrenciaIndex, currentCargoIndex, arrWithAllCargos, verificarPermisoEditar: config.verificarPermisoEditar }
    }
}

export const addCargoConcurrenciaToArr = (concurrenciaToAdd, cargoToAdd, arrWithAllCargos, config = { overwrite: false, verificarPermisoEditar: false }) => {
    return {
        type: incumbenciasTypes.ADD_CARGO_CONCURRENCIA,
        payload: { concurrenciaToAdd, cargoToAdd, arrWithAllCargos, overwrite: config.overwrite, verificarPermisoEditar: config.verificarPermisoEditar }
    }
}

export const removeVarianteConcurrenciaFromArr = (currentConcurrenciaIndex, currentCargo, varianteToRemoveIndex, arrWithAllCargos, removeCargoIfIsEmpty) => {
    return {
        type: incumbenciasTypes.REMOVE_VARIANTE_CONCURRENCIA,
        payload: { currentConcurrenciaIndex, currentCargo, varianteToRemoveIndex, arrWithAllCargos, removeCargoIfIsEmpty }
    }
}

export const addVarianteConcurrenciaToArr = (concurrenciaToAdd, cargoToAdd, varianteToAdd, arrWithAllCargos) => {

    return {
        type: incumbenciasTypes.ADD_VARIANTE_CONCURRENCIA,
        payload: { concurrenciaToAdd, cargoToAdd, varianteToAdd, arrWithAllCargos }
    }
}

export const addArrConcurrencias = (arrConcurrencias, arrWithAllCargos, config = { verificarPermisoEditar: false, isTernaMainElement: false }) => ({
    type: incumbenciasTypes.ADD_ARR_CONCURRENCIAS,
    payload: { arrConcurrencias: JSON.parse(JSON.stringify(arrConcurrencias)), arrWithAllCargos, verificarPermisoEditar: config.verificarPermisoEditar, isTernaMainElement: config.isTernaMainElement }
});