import { createAreaEspecialidad } from "../especialidad/AreaEspecialidad";
import { createEspecialidad } from "../especialidad/Especialidad";
import dateFormat from 'dateformat';
import { createEstado } from "../Estado";
import Acciones, {createAcciones} from "../Acciones";

class Escuela{
    constructor(nombre) {
        this.id = 0;
        this.nombre = "";
        this.campo1 = "";
        this.estado = null;
        this.area = [];
        this.acciones = [];
        this.tipoEscuela = [];
        this.createdAt = "";
        this.udpatedAt = "";
    }
    
    getFechaCreacion() {
      return this.createdAt.split(" ")[0];
    }

    getNombreNivel() {
      return this.nivel ?  this.nivel.descripcion : "";
    }

    getNombreAreas() {
      return this.area.map(a => a.nombre).join(" - ");
    }

    getNombreEstado() {
      return this.estado.nombre;
    }

    getNombreCampo1(){
      return this.campo1;
    }
}

export default Escuela

export const createEscuela = async (escuela) => {
    
    let acciones = escuela.acciones ? await createAcciones(escuela.acciones):new Acciones();
    
    let listaAreas = createAreaEspecialidad(escuela.area)
    let area = []
    area.push(listaAreas);
    let id_tipo_escuela = escuela.id_tipo_escuela;

    let nivel = escuela.nivel ? await createAcciones(escuela.nivel) : null;

    let estado = createEstado(escuela.estado);
    let createdAt = dateFormat(escuela.createdAt,"dd/mm/yyyy HH:MM")
    // let udpatedAt = dateFormat(escuela.udpatedAt,"dd/mm/yyyy HH:MM")
    // udpatedAt, esto va dentro de la creacion del objeto
    return Object.assign(new Escuela(escuela.nombre), {...escuela,  createdAt, area, estado,acciones,nivel, id_tipo_escuela});
}

export const createCodigo = async (codigo) => {
  return Object.assign({
    "id": codigo.valor,
    "nombre": codigo.valor
  })
}