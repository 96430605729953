import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PrivateComponent from "../../authentication/PrivateComponent";
import * as escuelaService from "../../escuela/EscuelaService";
import InputDatePicker from "../../generics/InputDatePicker";
import Dialog from "../../generics/dialog";
import { clear } from "../../store/alerts/alertActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { routePaths } from "../../utils/Constants";
import { handlerChange, isValidText } from "../../utils/commons";
import * as apendiceService from "../ApendiceService";

const ApendiceABMForm = ({ apendice, returnPath, isAprproval = false, ...props }) => {
  const [inputsForm, setInputsForm] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);

  const isMountedRef = useRef(null);

  const [validated, setValidated] = useState(false);
  const [wasModified, setWasModified] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  if (inputsForm.length < 1 && apendice) {
    setInputsForm(apendice);
  }

  const submitResolve = (cargo) => {
    history.push(routePaths.ABM_APENDICE_SEARCH);
  }

  const handleSubmit = (event) => {
    history.push(routePaths.ABM_APENDICE_SEARCH)
    handleValidation(escuelaService.approve);
  };

  const handlePublish = (event) => {
    history.push(routePaths.ABM_APENDICE_SEARCH)
    handleValidation(escuelaService.publish);
  };

  const handleEnviarRevision = async (analista) => {
    handleValidation(escuelaService.sendToRevice);
  };

  const handleCrear = async (event) => {
    await handleValidation(() => apendiceService.saveDraft(inputsForm, submitResolve));
  };

  const handleEnviarAutorizar = async (supervisor) => {
    handleValidation(escuelaService.sendToAuthorize);
  };

  const handleValidation = async (resolve) => {
    const form = document.getElementById("formCreateApendice");
    if (!form.checkValidity() === false) {
      if (wasModified) {
        if (apendice) {
          if (await apendiceService.editApendice(inputsForm, resolve))
            setWasModified(false);
        } else {
          if (await apendiceService.createNewApendice(inputsForm, resolve))
            setWasModified(false);
        }
      } else {
        resolve(inputsForm);
      }
      setWasModified(false);
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, setWasModified, e);
    }
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const setFechaDesde = (date) => {
    setInputsForm({
      ...inputsForm,
      fecha_desde: date
    });
    setFechaInicio(date)
  }

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    return () => (isMountedRef.current = false);
  }, [dispatch]);

  return (
    <Row>
      <Col>
        {true ? (
          <Form
            id="formCreateApendice"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            <Row>
              <Col sm={6} md={6} lg={6}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre de apéndice"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  maxLength="200"
                  required
                  {...props}
                ></Input>
              </Col>

              <Col sm={6} md={6} lg={6}>
                <InputDatePicker
                  label={"Fecha Inicio"}
                  selected={fechaInicio}
                  onChange={date => setFechaDesde(date)}
                  required
                />
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                {wasModified &&
                  <PrivateComponent>
                    <Dialog
                      action={handleCrear}
                      target={apendice}
                      title={"Guardar"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="secondary"
                      btnText="Guardar"
                      isLoadingText={"Guardando"}
                      body={<span>
                        ¿Estás seguro de mantener el apendice
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""} </b>
                        como activo?
                      </span>}
                    />
                  </PrivateComponent>
                }

                {isAprproval &&
                  <PrivateComponent>
                    <Dialog
                      action={handleSubmit}
                      target={apendice}
                      title="Confirmar aprobación"
                      variant="success"
                      size="md"
                      btnConfirmText="Aprobar"
                      btnText="Aprobar"
                      btnVariant="success"
                      body={<span>¿Estás seguro de aprobar el apendice
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""}</b>?
                      </span>}
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {false &&
                  <PrivateComponent>
                    <Dialog
                      action={handlePublish}
                      target={apendice}
                      title="Publicar modificaciones"
                      size="md"
                      btnConfirmText="Confirmar y enviar"
                      btnText="Publicar modificaciones"
                      btnVariant="success"
                      body={
                        "Al publicar las modificaciones el apendice volverá " +
                        "a estar en estado Publicado con todas las modificaciones que hayas realizado"
                      }
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default ApendiceABMForm;
