import React from 'react'

export const TicketIcon = ({ style }) => {
    return (
        <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="3.2"
            viewBox="0 1 20 20"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="12" y1="18" x2="12" y2="12"></line>
            <line x1="9" y1="15" x2="15" y2="15"></line>
        </svg>
    )
}
