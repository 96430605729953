import React from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import EspecialidadesSearchRow from "./EspecialidadesSearchRow";
import { info } from "../../store/alerts/alertActions";
import Paginacion from "../../generics/Paginacion";

const EspecialidadesSearchTable = ({ especialidades, cantidadEspecialidades, page, changePage, ...props }) => {
  const renderTableRows = (especialidades) => {
    return especialidades.map((especialidad) => {
      return (
        <EspecialidadesSearchRow
          key={especialidad.id}
          especialidad={especialidad}
        ></EspecialidadesSearchRow>
      );
    });
  };

  return (
    <>
      {!especialidades[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron especialidades.</Alert>
          </Col>
        </Row>
      ) : (
        !!especialidades && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nombre de la especialidad</th>
                      <th>Código DAD</th>
                      <th>Área</th>
                      <th>Nivel</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(especialidades)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadEspecialidades} currentPage={page} onChangePage={changePage} />
          </>
        )
      )}
    </>
  );
};

export default EspecialidadesSearchTable;
