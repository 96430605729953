import { typesConstants } from "../../utils/Constants";

const initialState = { }

const searchReduce = (state = initialState, action) => {
    switch (action.type) {
        case typesConstants.SAVE_FILTER:
          return action.filters ? action.filters : state
        case typesConstants.CLEAR_FILTER:
          return { };
        default:
          return state;
    }
}

export default searchReduce