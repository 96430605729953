class Usuario {
  constructor(username) {
    this.id = ""
    this.username = username;
    this.nombre = "";
    this.apellido = "";
    this.tipo_documento = null;
    this.documento = "";
    this.sexo = null;
    this.rol = [];
    this.nombreCompleto = "";
  }

  hasRol(rol) {
    return this.rol.find(r => r.id == rol.id)
  }

}

export default Usuario;
