import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import CargosSearchRowActions from "./CargosSearchRowActions";
import { Badge } from "react-bootstrap";
import Input from "../../ui/Input";
import { TdWithVerMas } from "../../ui/TdWithVerMas";

const CargosSearchRow = ({
  cargo,
  visibleCheckbox,
  handleOnChangeCargo,
  selectMulti,
  selected,
}) => {
  return (
    <>
      <tr key={cargo.id}>
        {visibleCheckbox && (
          <td>
            <div className="form-checkbox-container">
              <Input
                type={selectMulti ? "checkbox" : "radio"}
                name="cargo"
                className="option-row"
                value={cargo.id}
                onChange={(e) => handleOnChangeCargo(e, cargo)}
                defaultChecked={selected}
              ></Input>
            </div>
          </td>
        )}
        <td>{cargo.nombre}</td>
        <td>{cargo.codDad}</td>
        <td>{cargo.getNombreNivel()}</td>
        <TdWithVerMas data={cargo.getNombreAreas() || "-"} />
        <TdWithVerMas data={cargo.getNombreEspecialidades() || "-"} />
        <td>{cargo.getFechaCreacion()}</td>
        <td><Badge variant={cargo.estado ? cargo.estado.color : "primary"}> {cargo.getNombreEstado()}</Badge></td>
        <td>
          <div className="table-button-group">
            <CargosSearchRowActions cargo={cargo} visibleCheckbox={visibleCheckbox}></CargosSearchRowActions>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CargosSearchRow;
