import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = ({ icon, className, variant, size, children, ...props }) => {
  return (
    <Button
      className={`iconButton ${className ? className : ""}`}
      variant={variant ? variant : "primary"}
      size={size ? size : "sm"}
      {...props}
    >
      {children ?? <FontAwesomeIcon icon={icon} />}
    </Button>
  );
};

export default IconButton;
