import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import { createAsignatura } from "../dtos/asignatura/Asignatura";
import { getCargo, getCargos } from "../cargos/CargosService";
const { apiURLs, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");


const getAsignaturas = async (filtros = {}) => {
  let asignaturas = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS //TODO: Hasta que haya paginado
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_ASIGNATURA, filtros)

  response.data.elementos.forEach(asignatura => {
    asignaturas.push(createAsignatura(asignatura));
  });

  let asignaturasConcretas = await Promise.all(asignaturas);
  return { cantidad: response.data.cantidad, page: response.data.page, elementos: asignaturasConcretas };
}

const getAsignaturasByVariantes = async (filtros = {}) => {
  let asignaturas = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS //TODO: Hasta que haya paginado
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_ASIGNATURA + "/xx/getAsignaturasByVariantes", filtros)
  response.data.elementos.forEach(asignatura => {
    asignaturas.push(createAsignatura(asignatura));
  });
  let asignaturasConcretas = await Promise.all(asignaturas);
  return { cantidad: response.data.cantidad, page: response.data.page, elementos: asignaturasConcretas };
}


//TODO getAsignatura {apiURLs.API_MAESTROS_ASIGNATURA/id}

const createNewAsignatura = async (asignatura, resolve) => {
  let { codDad, cargo_especialidad, areas, tipoEscuela, ...body } = asignatura;
  // let list = [];

  // if (cargo_especialidad) {
  //   for (let idCargo in cargo_especialidad) {
  //     cargo_especialidad[idCargo].forEach(e => {
  //       list.push({ cargo_id: parseInt(idCargo), especialidad_id: e.value });
  //     });
  //   }
  // }

  const paramsFormated = { cod_dad: codDad, area: areas, cargo_especialidad, tipo_escuela: tipoEscuela, ...body }

  await requestService.sendRequest('POST', apiURLs.API_MAESTROS_ASIGNATURA, paramsFormated)
    .then(res => {
      resolve(res.data);
    }).catch(e => {
      store.dispatch(error(e.response.data.message))
    });
}

const saveDraft = async (asignatura, resolve) => {
  resolve(asignatura);
  await store.dispatch(success(<>Se guardó correctamente la asignatura <b>{asignatura.nombre}</b> como borrador.</>))
}

const changeState = async (asignatura, newState) => {
  await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_ASIGNATURA + `/${asignatura.id}/estado`, { estado: newState })
}

const deleteAsignatura = async (asignatura, resolve) => {
  await requestService.sendRequest('DELETE', apiURLs.API_MAESTROS_ASIGNATURA + `/${asignatura.id}`)
    .then(resp => {
      resolve();
      store.dispatch(success(<>Se elimino la asignatura <b>{asignatura.nombre}</b> correctamente.</>))
    }).catch(e => {
      store.dispatch(error(e.response.data.message))
    })
}

const editAsignatura = async (asignatura, resolve) => {
  await requestService.sendRequest('PATCH', apiURLs.API_MAESTROS_ASIGNATURA + `/${asignatura.id}`, {
    ...asignatura,
  }).then(resp => {
    resolve(resp.data)
  }).catch(e => {
    store.dispatch(error(e.response.data.message))
  });
}

const deactivate = async (asignatura, resolve) => {
  await changeState(asignatura, estadosConstans.DESHABILITADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se desactivo la asignatura <b>{asignatura.nombreInvertido}</b> correctamente.</p>))
}

const activate = async (asignatura, resolve) => {
  await changeState(asignatura, estadosConstans.BORRADOR)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se activo la asignatura <b>{asignatura.nombreInvertido}</b> correctamente.</p>))
}

const publish = async (asignatura, resolve) => {
  await changeState(asignatura, estadosConstans.PUBLICADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se publico la asignatura <b>{asignatura.nombreInvertido}</b> correctamente.</p>))
}

const approve = async (asignatura, resolve) => {
  await changeState(asignatura, estadosConstans.APROBADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se aprobo la asignatura <b>{asignatura.nombreInvertido}</b> correctamente.</p>))
}

/*  */
const sendToAuthorize = async (asignatura) => {
  await changeState(asignatura, estadosConstans.PARA_SER_AUTORIZADO, "Se envió a autorizar la asignatura correctamente.");
}

const sendToRevice = async (asignatura) => {
  changeState(asignatura, estadosConstans.EN_REVISION, "Se envió a revisar la especialidad correctamente.")
}

export {
  getAsignaturas,
  getAsignaturasByVariantes,
  createNewAsignatura,
  deleteAsignatura,
  editAsignatura,
  deactivate,
  activate,
  publish,
  saveDraft,
  sendToAuthorize,
  sendToRevice,
  approve
}