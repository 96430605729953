import React from 'react'
import CargosSearch from '../../../cargos/search/CargosSearch'
import { estadosConstans } from '../../../utils/Constants'

export const ClonadorCargosSearch = ({
    selectDocument,
    handleClickSeleccionar,
    returnCallBack,
}) => {
    return (
        <>
            <CargosSearch
                title={`Buscar cargo ${selectDocument}`}
                handleChangeSeleccionarCargo={handleClickSeleccionar}
                returnCallBack={returnCallBack}
                defaultFiltros={{ min: false }}
                hidePlusFAB={true}
                visibleCheckbox={true}
                hideChecks={true}
                // defaultInputsForm={{ estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.MIGRADO] }}
            />
        </>
    )
}
