import Nivel, { createNivel } from "../Nivel"
import Acciones, {createAcciones} from "../Acciones";

class Titulo {
    constructor( ) {
        this.activo = true;
        this.id = "";
        this.nivel = Nivel;
        this.nombre = "";
        this.acciones = [];
    }

    getNombreNivel(){
        return this.nivel ? this.nivel.descripcion : "";
    }
}

export default Titulo

export const createTitulo = async (titulo) => {
    let nivel = titulo.nivel ? await createNivel(titulo.nivel):{};
    let acciones = titulo.acciones ? await createAcciones(titulo.acciones):new Acciones();
    const r = Object.assign(new Titulo(), {...titulo, nivel,acciones })

    return r;
}

