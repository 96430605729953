import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import Dialog from '../../../generics/dialog'
import { ticketsUtils } from '../../../tickets/TicketsUtils'
import { TicketsCreateModal } from '../../../tickets/abm/TicketsCreateModal'
import { CustomTooltip } from '../../../ui/CustomTooltip'
import DialogActivate from '../../../ui/DialogActivate'
import { tooltipsText } from '../../../utils/Constants'
import { IncumbenciaRowActions } from '../../shared/IncumbenciaRowActions'
import PrivateComponent from '../../../authentication/PrivateComponent'
import { PERMISOS_MAP } from '../../../utils/Permisos'

export const IncumbenciasDetailRowActions = ({
    incumbencia,
    resolveTicket,
    setErrorMsg,
    clearErrorMsg,
    setSuccessMsg,
    recargarIncumbencias,
}) => {

    const resolveCallBack = async () => {
        await recargarIncumbencias()
    }

    return (
        <>
            {
                incumbencia?.acciones &&
                <IncumbenciaRowActions
                    incumbencia={incumbencia}
                    setErrorMsg={setErrorMsg}
                    setSuccessMsg={setSuccessMsg}
                    clearErrorMsg={clearErrorMsg}
                    resolveCallBack={resolveCallBack}
                />
            }
            <PrivateComponent permisos={PERMISOS_MAP.TICKET_CREAR}>
                <CustomTooltip text={tooltipsText.TICKET}>
                    <TicketsCreateModal
                        objectId={incumbencia.id}
                        objectApendice={incumbencia.apendice.nombre}
                        nombreModuloTicket={ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS}
                        zIndex1050
                        resolveCallback={resolveTicket}
                    />
                </CustomTooltip>
            </PrivateComponent>
        </>
    )
}
