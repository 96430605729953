import React from 'react';
import { Row, Col, Badge, Table } from "react-bootstrap";
import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";
import { DetailNumber } from "../../ui/DetailNumber";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons";
import { RelacionesAsignaturaTable } from '../abm/RelacionesAsignatura/RelacionesAsignaturaTable';

const AsignaturaDetailBody = ({ asignatura, ...props }) => {

  const renderTableRows = (cargosEspecialidad) => {
    return cargosEspecialidad.map((cargoEspecialidad) => {
      return (
        <tr key={cargoEspecialidad.getNombreCargo()}>
          <td>{cargoEspecialidad.getNombreCargo()}</td>
          <td>{cargoEspecialidad.getNombreEspecialidades()}</td>
        </tr>
      );
    });
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            <Badge variant="primary">{asignatura.getNombreEstado()}</Badge>
            {asignatura.createdAt && (
              <small>
                Creado el: <b>{asignatura.createdAt}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />

      <Row>
        <Col xs={12} lg={6} className="form-group">
          <DetailFact label="Nombre" input={asignatura.nombre} />
        </Col>

        {asignatura.descripcion && (
          <Col md={3} lg={4} className="form-group">
            <DetailFact label="Resolución" input={asignatura.resolucion} />
          </Col>
        )}
        {asignatura.getNombreNivel() && (
          <Col md={3} lg={2} className="form-group">
            <DetailFact label="Nivel" input={asignatura.getNombreNivel()} />
          </Col>
        )}
        <Col md={9} lg={6} className="form-group">
          <DetailFact label="Área" input={asignatura.getNombreAreas()} />
        </Col>
      </Row>
      {asignatura.descripcion && (
        <Row>
          <Col md={12} className="form-group">
            <label>Descripción: </label>
            <p>{asignatura.descripcion}</p>
          </Col>
        </Row>
      )}
      <hr />
      <Row>
        <Col className="form-group">
          <DetailNumber label="Código DAD" input={asignatura.codDad} />
        </Col>
        {asignatura.apendice && (
          <Col className="form-group">
            <DetailNumber label="Apéndice" input={asignatura.apendice.id} />
          </Col>
        )}
        {asignatura.folio && (
          <Col className="form-group">
            <DetailNumber label="Folio" input={asignatura.folio} />
          </Col>
        )}
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h3>Relaciones</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <RelacionesAsignaturaTable elementos={asignatura.relaciones} />
        </Col>
      </Row>
    </>
  );
};

export default AsignaturaDetailBody;
