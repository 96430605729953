import React from "react";
import { Badge } from "react-bootstrap";
import { IncumbenciasSearchRowActionsTernas } from "./IncumbenciasSearchRowActionsTernas";

export const IncumbenciasSearchRowTernas = ({ terna, ...props }) => {
    return (
        <>
            <tr>
                <td>{terna.apendice?.id || "-"}</td>
                <td>{terna.folio || "-"}</td>
                <td>{terna.titulo.nombre}</td>
                <td>{terna.procedencia.nombre}</td>
                <td>{terna.resolucion.nombre}</td>
                <td><Badge variant={terna.estado ? terna.estado.color : "primary"}> {terna.estado.nombre}</Badge></td>
                <td>
                    <div className="table-button-group">
                        <IncumbenciasSearchRowActionsTernas terna={terna} onSubmit={props.onSubmit}></IncumbenciasSearchRowActionsTernas>
                    </div>
                </td>
            </tr>
        </>
    );
};
