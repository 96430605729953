import React from "react";
import UserABMRow from "./UserABMRow";
import { Table, Row, Col } from "react-bootstrap";
import Paginacion from "../../generics/Paginacion";

const UserABMTable = ({ cantidadUsuarios, page, changePage, ...props }) => {

  const renderUserTableRows = (users) => {
    return users.map((user) => {
      return (
        <UserABMRow key={user.id} onSubmit={props.onSubmit} user={user}></UserABMRow>
      );
    });
  };

  return (
    <>
      {!props.users[0]
        ? null
        : !!props.users && (
          <>
            <Row className="form-group">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Correo Electrónico</th>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>Roles</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderUserTableRows(props.users)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadUsuarios} currentPage={page} onChangePage={changePage}/>
          </>
        )}
    </>
  );
};

export default UserABMTable;
