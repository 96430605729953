import React from "react";
import { Link } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearFilters } from "../store/search/searchActions";

export const ShortcutButton = ({ icon, className, variant, title, children, ...props }) => {
    const dispatch = useDispatch()

    return (
        <Link
            className={`shortcutButton ${className}`}
            {...props}
        >
            <Button
                variant={variant ? variant : "secondary"}>
                <Image src={icon} />
            </Button>
            <h3>{title}</h3>
        </Link>
    );
};

