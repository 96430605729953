export const adaptUnificacionTerna = (rawUnificacion) => {
    console.log({ rawUnificacion })
    return {
        terna: {
            id: rawUnificacion.terna_origen_id,
            titulo: {
                id: rawUnificacion.terna_origen_titulo_id,
                nombre: rawUnificacion.terna_origen_titulo_nombre,
            },
            procedencia: {
                id: rawUnificacion.terna_origen_procedencia_id,
                nombre: rawUnificacion.terna_origen_procedencia_nombre,
            },
            resolucion: {
                id: rawUnificacion.terna_origen_resolucion_id,
                nombre: rawUnificacion.terna_origen_resolucion_nombre,
            },
        },
        unificaciones: rawUnificacion.unificaciones.map(ctUn => ({
            id: ctUn.id,
            terna: {
                id: ctUn.terna_destino_id,
                titulo: {
                    id: ctUn.terna_destino_titulo_id,
                    nombre: ctUn.terna_destino_titulo_nombre,
                },
                procedencia: {
                    id: ctUn.terna_destino_procedencia_id,
                    nombre: ctUn.terna_destino_procedencia_nombre,
                },
                resolucion: {
                    id: ctUn.terna_destino_resolucion_id,
                    nombre: ctUn.terna_destino_resolucion_nombre,
                },
            }
        }))
    }
}