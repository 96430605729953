import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { DetailFact } from "../../ui/DetailFact";

import UserService from "../../utils/user.service";
import * as AuthService from '../../authentication/AuthService'

class Profile extends Component {
  state = {
    content: ""
  };

  componentDidMount() {
    UserService.getUserBoard(AuthService.getUserId()).then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  render() {
    const { user: currentUser } = this.props;

    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      <Container>
        <Row className="form-group">
          <Col>
            <h1>Datos de Usuario</h1>
          </Col>
        </Row>
        <Row className="form-group">
          <Col md={6} lg={3}>
            <DetailFact label="Nombre de usuario" input={this.state.content.username} />
          </Col>
          <Col md={6} lg={3}>
            <DetailFact label="Nombre y apellido" input={this.state.content.nombre + " " + this.state.content.apellido} />
          </Col>
          <Col md={4} lg={2}>
            <DetailFact label="Activo" input={this.state.content.activo ? "SI" : "NO"} />
          </Col>
        </Row>
        <Row className="form-group">
          <Col md={6} lg={2}>
            <DetailFact label="Tipo de doc" input={this.state.content.tipoDocumento ? this.state.content.tipoDocumento['descripcion'] : ""} />
          </Col>
          <Col md={6} lg={3}>
            <DetailFact label="Número" input={this.state.content.documento} />
          </Col>
          <Col md={4} lg={2}>
            <DetailFact label="Sexo" input={this.state.content.sexo == null ? "N/A" : this.state.content.sexo} />
          </Col>
        </Row>

      
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Profile);
