import React from 'react';
import DatePicker from "react-datepicker";
import Input from "../ui/Input";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es';
registerLocale('es', es)

const InputDatePicker = ({ label, selected, onChange, disabled, required }) => {

    return <DatePicker
        readOnly={disabled}
        popperPlacement="bottom"
        isClearable
        customInput={<Input disabled={disabled} label={label} />}
        dateFormat="dd/MM/yyy"
        locale="es"
        selected={selected}
        onChange={onChange}
        required={required}
    />

};

export default InputDatePicker;
