import React from "react";
import { Row, Col, Card, Accordion, Badge } from "react-bootstrap";
import { EquivalenciaAccordion } from "./EquivalenciaAccordion";

const EquivalenciaSearchRow = ({ titulo, onSubmit, ...props }) => {

  const datos = {
    tituloPrimario: "Título original",
    tituloSecundario: "Título equivalente",
    primario: "original",
    secundario: "equivalentes",
  };

  return (
    <Accordion className="form-group">
      <Card key={titulo.id}>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <Row>
            <Col xs={12} md={8} lg={9} xl={10}>
              <h5>{titulo[datos.primario].titulo.nombre} </h5>

              <span className="accordion-header-text">
                {titulo[datos.primario].procedencia.nombre}
              </span>

              <span className="accordion-header-text">
                {titulo[datos.primario].resolucion.nombre}
              </span>

              <span className="accordion-header-text">
                {titulo[datos.primario].createdAt}
              </span>
            </Col>
          </Row>
        </Accordion.Toggle>
        <EquivalenciaAccordion
          titulo={titulo}
          datos={datos}
        />
      </Card>
    </Accordion>
  );
};

export default EquivalenciaSearchRow;
