import axios from "axios";
import { apiURLs, paginado, tipoSexo } from '../utils/Constants'
import authHeader from "../utils/auth-header";
import RequestService from "./request.service"
import Usuario from "../dtos/Usuario";

class UserService {

  getPublicContent() {
    return axios.get(apiURLs.API_URL + "prueba");
  }

  getUserBoard(id) {
    return axios.get(apiURLs.API_ADMIN_USUARIOS_URL + '/' + id, { headers: authHeader() });
  }

  async getTiposDocumento() {
    return await axios.get(apiURLs.API_URL + "/genericos/tipo_documento", { headers: authHeader() })
      .then(resp => {
        return resp.data;
      }).catch(e => { return [] })
  }

  getTiposSexo() {
    return [tipoSexo.FEMENINO, tipoSexo.MASCULINO];
  }

  async getUsers(filtro) {
    let users = []
    let { ...filtros } = filtro;
    if (filtros["rol"]) {
      filtros["rol"] = filtros["rol"].map(r => r.id)
    }
    filtros['limit'] = paginado.FILAS_POR_PAGINAS
    let response = await RequestService.sendRequest('GET', apiURLs.API_ADMIN_USUARIOS_URL, filtros)
    response.data.usuarios.forEach(user => {
      users.push(Object.assign(new Usuario(user.nombre), { ...user, nombreCompleto: `${user.nombre} ${user.apellido}` }))
    });
    return { cantidad: response.data.cantidad, data: users };
  }

}

export default new UserService();
