import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import Paginacion from "../../../generics/Paginacion";

export const EquivalenciaABMTable = ({
  titulos,
  cantidadTitulos,
  page,
  changePage,
  renderTableRows,
  visibleCheckbox = true,
  visiblePaginacion = true,
  visibleInputs = false,
  ...props
}) => {
  return (
    <>
      <Row className="form-group">
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>Nombre del título</th>
                <th style={{ maxWidth: '25%' }}>Procedencia</th>
                <th>Resolución</th>
                <th>Nivel</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderTableRows(titulos)}</tbody>
          </Table>
        </Col>
      </Row>
      {visiblePaginacion && (
        <Paginacion
          totalItems={cantidadTitulos}
          currentPage={page}
          onChangePage={(event, value) => {
            changePage(event, value);
          }}
        />
      )}
    </>
  );
};