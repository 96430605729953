import dateFormat from 'dateformat';

const adaptGetModulosTickets = (arrElementos) => {
    return arrElementos.map(ctElemento => {
        return {
            ...ctElemento,
            value: ctElemento.id,
            label: ctElemento.nombre,
        }
    })
}

const adaptGetTipoTickets = (arrElementos) => {
    return arrElementos.map(ctElemento => {
        return {
            ...ctElemento,
            value: ctElemento.id,
            label: ctElemento.nombre,
        }
    })
}

const adaptGetTicket = (elemento) => {
    let formatedResponse = {
        apendice: elemento.apendice,
        id: elemento.id,
        createdAt: dateFormat(elemento.created_at, "dd/mm/yyyy"),
        updatedAt: dateFormat(elemento.updated_at, "dd/mm/yyyy"),
        finishedAt: elemento.finished_at ? dateFormat(elemento.finished_at, "dd/mm/yyyy") : null,
        assignedBy: {
            ...elemento.assigned_by,
            nombreCompleto: `${elemento.assigned_by.nombre} ${elemento.assigned_by.apellido}`
        },
        assignedTo: {
            ...elemento.assigned_to,
            nombreCompleto: `${elemento.assigned_to.nombre} ${elemento.assigned_to.apellido}`
        },
        createdBy: {
            ...elemento.created_by,
            nombreCompleto: `${elemento.created_by.nombre} ${elemento.created_by.apellido}`,
        },
        modulo: elemento.model,
        estado: elemento.estado,
        observacion: elemento.observacion,
        observacionCierre: elemento.observacion_cierre,
        objectId: elemento.object_id,
        object: elemento.object ?
            {
                ...elemento.object,
                modulo: elemento.model_object ? { ...elemento.model_object } : { ...elemento.model }
            }
            : null,
        tipo: elemento.tipo,
    }

    if (formatedResponse.modulo.nombre == "Incumbencias" && formatedResponse.object) {
        formatedResponse.object.concurrencia = Boolean(Number(formatedResponse.object.concurrencia));

        formatedResponse.object.terna = formatedResponse.object.terna ? {
            ...formatedResponse.object.terna,
            titulo: { id: formatedResponse.object.terna.titulo_id, nombre: formatedResponse.object.terna.titulo_nombre },
            procedencia: { id: formatedResponse.object.terna.procedencia_id, nombre: formatedResponse.object.terna.procedencia_nombre },
            resolucion: { id: formatedResponse.object.terna.resolucion_id, nombre: formatedResponse.object.terna.resolucion_nombre },
        } : null

        formatedResponse.object.terna2 = formatedResponse.object.terna2 ? {
            ...formatedResponse.object.terna2,
            titulo: { id: formatedResponse.object.terna2.titulo_id, nombre: formatedResponse.object.terna2.titulo_nombre },
            procedencia: { id: formatedResponse.object.terna2.procedencia_id, nombre: formatedResponse.object.terna2.procedencia_nombre },
            resolucion: { id: formatedResponse.object.terna2.resolucion_id, nombre: formatedResponse.object.terna2.resolucion_nombre },
        } : null

        formatedResponse.object.terna3 = formatedResponse.object.terna3 ? {
            ...formatedResponse.object.terna3,
            titulo: { id: formatedResponse.object.terna3.titulo_id, nombre: formatedResponse.object.terna3.titulo_nombre },
            procedencia: { id: formatedResponse.object.terna3.procedencia_id, nombre: formatedResponse.object.terna3.procedencia_nombre },
            resolucion: { id: formatedResponse.object.terna3.resolucion_id, nombre: formatedResponse.object.terna3.resolucion_nombre },
        } : null
    }
    return formatedResponse;
}

const adaptGetTickets = (arrElementos) => {
    return arrElementos.map(ctElemento => {
        return adaptGetTicket(ctElemento)
    })
}

/* ADAPT PAYLOADS */

const adaptPayloadCreateTicket = ({ createdBy, assignedBy, assignedTo, tipoId, objectId, observacion, modelId, apendice, model_object_id, id }) => {
    return {
        "id": id,
        "created_by": createdBy,
        "assigned_by": assignedBy,
        "assigned_to": assignedTo,
        "tipo_id": tipoId,
        "object_id": objectId,
        "observacion": observacion,
        "model_id": modelId,
        "model_object_id": model_object_id,
        "apendice": apendice,
    }
}


export const ticketsAdapter = {
    adaptGetModulosTickets,
    adaptGetTipoTickets,
    adaptGetTicket,
    adaptGetTickets,
    adaptPayloadCreateTicket,
}
