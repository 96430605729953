import React from 'react';
import { useEffect } from 'react';
import { Row, Col, Badge } from "react-bootstrap";
//import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";

const TernaDetailBody = ({ terna, ...props }) => {

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            <Badge variant={terna.estado ? terna.estado.color : "primary"}>{terna.estado?.nombre}</Badge>

            {terna.createdAt && (
              <small>
                Creado el: <b>{terna.createdAt}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="form-group">
        <Col>
          <h3>Terna</h3>
        </Col>
      </Row>
      <Row className="form-group">
        <Col md={6}>
          <DetailFact label="Nombre del título" input={terna.titulo.nombre} />
        </Col>
        <Col md={6}>
          <DetailFact label="Procedencia" input={terna.procedencia.nombre} />
        </Col>
        <Col md={6}>
          <DetailFact label="Resolución" input={terna.resolucion.nombre} />
        </Col>
        {terna?.titulo?.nivel?.nombre && (
          <Col md={6}>
            <DetailFact label="Nivel" input={terna.titulo.nivel.nombre} />
          </Col>
        )}
      </Row>
      <Row className="form-group">
        <Col md={2}>
          <Badge variant="success">{terna.final}</Badge>
        </Col>
        <Col md={2}>
          {terna.troncal != "" && terna.troncal != "NO" && (
            <Badge variant="success">{terna.troncal}</Badge>
          )}
        </Col>
      </Row>

      {
        terna.folioBaja && terna.apendiceBaja &&
        <Row className="form-group">
          <Col xs={12}>
            <hr />
          </Col>
          <Col md={6}>
            <DetailFact label="Apendice de baja" input={terna.apendiceBaja.nombre} />
          </Col>
          <Col md={6}>
            <DetailFact label="Folio de baja" input={terna.folioBaja} />
          </Col>
        </Row>
      }

      {
        (terna.nota || terna.regla || terna.observaciones) && (

          <Row>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12} md={12} lg={12}>
              <h3>Observaciones</h3>
            </Col>

            {terna.nota && (
              <Col xs={12} md={12} lg={12}>
                <DetailFact label="Nota" input={terna.nota && terna.nota.descripcion} />
              </Col>
            )}

            {false && (
              <Col xs={12} md={12} lg={12}>
                <DetailFact label="Regla" input={terna.regla && terna.regla.descripcion} />
              </Col>
            )}

            {terna.observaciones && (
              <Col md={12}>
                <DetailFact label="Observaciones" input={terna.observaciones} />
              </Col>
            )}
          </Row>
        )
      }
    </>
  );
};

export default TernaDetailBody;
