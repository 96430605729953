import React, { useEffect, useState } from "react"
import { Badge } from "react-bootstrap"
import Input from "../../ui/Input"

export const IncumbenciasSearchTernaRow = ({
    elemento,
    visibleCheckbox = true,
    visibleNota = false,
    handleSelectItem,
    selectMulti,
    selectedElements,
    selected,
}) => {
    const [checked, setChecked] = useState(selected)

    return (
        <React.Fragment key={elemento.id}>
            <tr>
                {visibleCheckbox && (
                    <td>
                        <div className="form-checkbox-container">
                            <Input
                                type={selectMulti ? "checkbox" : "radio"}
                                name="titulo"
                                className="option-row"
                                value={elemento.id}
                                onChange={(e) => handleSelectItem(e, elemento, setChecked, selectedElements)}
                                checked={checked}
                            // defaultChecked={selected}
                            ></Input>
                        </div>
                    </td>)
                }
                <td>{elemento.titulo.id}</td>
                <td>{elemento.titulo.nombre}</td>
                <td>{elemento.procedencia.nombre}</td>
                <td>{elemento.resolucion.nombre}</td>
                <td><Badge variant={elemento.estado ? elemento.estado.color : "primary"}> {elemento.estado.nombre}</Badge></td>
            </tr>
            {visibleNota && <tr>
                <td></td>
                <td colSpan="4">
                    <b>Nota: </b> {elemento.nota && elemento.nota.descripcion ? elemento.nota.descripcion : "-"}
                </td>
            </tr>
            }
        </React.Fragment>
    )
}