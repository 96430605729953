import React from 'react'
import { Col } from 'react-bootstrap'
import { ShortcutButton } from './ShortcutButton'
import { ScreenWithFabButtons } from './ScreenWithFabButtons'
import { useLocation } from 'react-router-dom'
import PrivateComponent from '../authentication/PrivateComponent'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { clearFilters } from '../store/search/searchActions'


export const MenuDashboard = ({ menu }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearFilters());
    }, [dispatch])

    return (
        <ScreenWithFabButtons>
            <h1 className="text-center mb-4">{menu.menuTitle}</h1>
            <div className="d-flex justify-content-center flex-wrap">
                {menu.options.map((menuItem, index) =>
                    <PrivateComponent key={"DashItem" + menuItem.title + index} permisos={menuItem.permisoRequired}>
                        <Col xs={6} md={4} lg={4}>
                            <div className="d-flex justify-content-center my-4" key={menuItem.title}>
                                <ShortcutButton
                                    to={{
                                        pathname: menuItem.path,
                                        state: { returnPath: location.pathname }
                                    }}
                                    icon={menuItem.icon}
                                    title={menuItem.title} />
                            </div>
                        </Col>
                    </PrivateComponent>
                )}
            </div>
        </ScreenWithFabButtons>
    )
}
