import React, { useEffect, useRef, useState } from 'react';
import { Alert, Badge, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Paginacion from '../../generics/Paginacion';
import Dialog from '../../generics/dialog';
import { TrEnConcurrenciaCon } from '../../incumbencias/details/ternas/TrEnConcurrenciaCon';
import { error } from '../../store/alerts/alertActions';
import { OPCIONES_DESCARGAS, agregarDescargas } from '../../store/descargas/descargasActions.';
import { saveFilters } from '../../store/search/searchActions';
import { CustomTooltip } from '../../ui/CustomTooltip';
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { estadosConstans, routePaths, tooltipsText } from '../../utils/Constants';
import { getInformeTernasPorAreaCargo } from '../InformesService';
import { DetailForm } from './DetailFormSearch';
import { DetailModalByAreaCargo } from './DetailModalByAreaCargo';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

const THead = () => {
    return <tr>
        <th>Titulo</th>
        <th>Procedencia</th>
        <th>Resolucion</th>
        <th>Calidad</th>
        <th>Pto</th>
        <th>Apendice</th>
        <th>Folio</th>
        <th>Fecha</th>
        <th>Estado</th>
        <th></th>
    </tr>
}

const FormRow = ({ element, cargo }) => {
    return <>
        <tr>
            <td>{element.terna.titulo.nombre}</td>
            <td>{element.terna.procedencia.nombre}</td>
            <td>{element.terna.resolucion.nombre}</td>
            <td>{element.calidad}</td>
            <td>{element.puntaje}</td>
            <td>{element.apendice.nombre}</td>
            <td>{element.folio}</td>
            <td>{element.createdAt}</td>
            <td>
                {element.estado ?
                    <Badge variant={element.estado.color}>
                        {element.estado.nombre}
                    </Badge>
                    :
                    "-"
                }
            </td>
            <td>
                <CustomTooltip text={tooltipsText.DETALLE}>
                    <DetailModalByAreaCargo incumbencia={element} />
                </CustomTooltip>
            </td>
        </tr>

        {element.terna.regla &&
            <tr style={{ border: "none" }}>
                <td className="border-0 pt-1" colSpan={10}><p style={{ marginBottom: "0.4rem" }}><b>Regla: </b> {element.terna.regla}</p></td>
            </tr>
        }

        {element.concurrencia &&
            <TrEnConcurrenciaCon data={element} colSpan={10} />
        }
    </>
}

const FormTable = ({
    elementos,
    cantidadElementos,
    page,
    handleChangePage
}) => {
    return <>
        <Row className="form-group">
            <Col>
                <Table responsive>
                    <thead>
                        <THead />
                    </thead>

                    <tbody>
                        {elementos.map((currentElement, index) =>
                            <React.Fragment key={`FormTableRowDetailByAreaCargo-${index}`}>
                                <FormRow element={currentElement} />
                            </React.Fragment>
                        )}
                    </tbody>

                </Table >

            </Col>
        </Row>

        <Paginacion
            totalItems={cantidadElementos}
            currentPage={Number(page)}
            onChangePage={handleChangePage}
        />
    </>
}


export const DetailByAreaCargo = () => {
    const [cachedData, setCachedData] = useState();

    const [elementos, setElementos] = useState();
    const [cantidadElementos, setCantidadElementos] = useState();
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);

    const cantidadMaximaElementos = 100;
    const generarEnFormatoPDF = cantidadElementos <= cantidadMaximaElementos

    const defaultInputsForm = {
        condicion: "",
        estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.MIGRADO],
    }

    const [inputsForm, setInputsForm] = useState(defaultInputsForm);
    const location = useLocation();
    const selectedElement = location.state?.selectedElement;

    const history = useHistory();
    const searchState = useSelector(st => st.search)
    const dispatch = useDispatch();
    const isMountedRef = useRef(true);

    const filtrosDefault = {
        area_id: selectedElement.area_id,
        tipo_escuela_id: selectedElement.nombre_escuela_id,
        cargo_id: selectedElement.cargo_id,
        asignatura_id: selectedElement.asignatura_id,
        especialidad_id: selectedElement.especialidad_id,
    }

    const resetForm = () => {
        setInputsForm(defaultInputsForm)
        setSearching(false)
        dispatch(saveFilters({ ...searchState, inputsFormDetail: filtrosDefault }));

        if (cachedData) {
            setElementos(cachedData.elementos);
            setCantidadElementos(cachedData.cantidad);
            setPage(cachedData.page);
        }
    }

    const getInforme = async (filtros = {}) => {

        if (!filtros.page) filtros.page = 1;

        let params = { ...filtrosDefault, ...filtros }
        setSearching(true);
        dispatch(saveFilters({ ...searchState, inputsFormDetail: params }));

        try {
            const resp = await getInformeTernasPorAreaCargo(params, isMountedRef)

            const elementos = resp.elementos;
            const cantidad = resp.cantidad;
            const page = resp.page;

            setElementos(elementos);
            setCantidadElementos(cantidad);
            setPage(page);
            return resp
        }
        catch (err) {
            dispatch(error(err.message))
        }
        finally { setSearching(false); }
    }

    const handleClickGenerarPDF = async () => {
        try {
            const filtros = { ...searchState.inputsFormDetail }
            const fileName = `${location.state?.selectedElement.cargo}-${location.state?.selectedElement.asignatura ?? "SIN ASIGNATURA"}-${location.state?.selectedElement.especialidad}`
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo / Detalle (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName,
            }]));

        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarCSV = async () => {
        try {
            const filtros = { ...searchState.inputsFormDetail }
            const fileName = `${location.state?.selectedElement.cargo}-${location.state?.selectedElement.asignatura ?? "SIN ASIGNATURA"}-${location.state?.selectedElement.especialidad}`
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo / Detalle (CSV)",
                opcion: OPCIONES_DESCARGAS.CSV.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName,
            }]));

        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarInformativo = async () => {
        try {
            const filtros = { ...searchState.inputsFormDetail, informativo: true }
            const fileName = `${location.state?.selectedElement.cargo}-${location.state?.selectedElement.asignatura ?? "SIN ASIGNATURA"}-${location.state?.selectedElement.especialidad}`
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo / Detalle informativo (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName,
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const cacheData = async (callback) => {
        const response = await callback()
        setCachedData(response)
    }

    const handleChangePage = (event, value) => {
        const filtros = { ...searchState.inputsFormDetail, page: value }
        dispatch(saveFilters({ ...searchState, inputsFormDetail: filtros }));
        getInforme(filtros)
        setPage(value);
    }

    useEffect(() => {
        if (selectedElement) {
            cacheData(getInforme);
        } else {
            console.log("Se recargo navegador / entro x URL -> return a searchTernaPorAreaCargo")
            history.push(routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO)
        }

        return () => { isMountedRef.current = false }
    }, [])

    return (
        <ScreenWithFabButtons
            returnState={location.state?.returnData}
            hideSearchButton={false}
            hideCleanButton={false}
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !elementos}
        >
            <h1>Informes</h1>
            <h4 className="mb-4">{location.state?.selectedElement.cargo} | {location.state?.selectedElement.asignatura ?? "SIN ASIGNATURA"} | {location.state?.selectedElement.especialidad} | {location.state?.selectedElement.area}</h4>
            <Row>
                <DetailForm
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    resetForm={resetForm}
                    handleSearh={getInforme}
                />
            </Row>
            <Row>
                <Col>
                    {!searching && <>
                        {cantidadElementos > 0 &&
                            <FormTable
                                handleChangePage={handleChangePage}
                                elementos={elementos}
                                cantidadElementos={cantidadElementos}
                                page={page}
                            />
                        }

                        {cantidadElementos === 0 &&
                            <Alert variant="info">No hay ternas que brinden incumbencias para los filtros seleccionados.</Alert>}
                        {(cantidadElementos > 0 || cantidadElementos === 0) &&
                            <>
                                <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                                    <Dialog
                                        action={handleClickGenerarCSV}
                                        title={"Informe Total (.CSV)"}
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Generar"
                                        btnVariant="primary"
                                        btnText="Informe Total (.CSV)"
                                        isLoadingText={"Generando informe"}
                                        body={<span>
                                            ¿Estás seguro de generar el informe?
                                        </span>}
                                    />
                                    {generarEnFormatoPDF &&
                                        <Dialog
                                            action={handleClickGenerarPDF}
                                            title={"Informe Total"}
                                            variant="primary"
                                            size="md"
                                            btnConfirmText="Generar"
                                            btnVariant="primary"
                                            btnText="Informe Total"
                                            isLoadingText={"Generando informe"}
                                            body={<span>
                                                ¿Estás seguro de generar el reporte?
                                            </span>}
                                        />
                                    }
                                </PrivateComponent>
                                <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORMATIVO}>
                                    <Dialog
                                        action={handleClickGenerarInformativo}
                                        title={"Informe para Destinatarios"}
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Generar"
                                        btnVariant="primary"
                                        btnText="Informe para Destinatarios"
                                        isLoadingText={"Generando informe"}
                                        body={<span>
                                            ¿Estás seguro de generar el informe para destinatarios?
                                        </span>}
                                    />
                                </PrivateComponent>
                            </>
                        }
                    </>
                    }
                    {searching && <Loader></Loader>}
                </Col>
            </Row>

        </ScreenWithFabButtons>
    )
}
