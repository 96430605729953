import React, { useEffect, useState } from 'react'
import Input from '../../../ui/Input'
import { useSelector } from 'react-redux'
import { idsTipoIncumbenciaConPuntaje } from '../../abm/IncumbenciasSelectRow'
import { handlerChange } from '../../../utils/commons'

export const CondicionGeneralForm = ({
    state,
    setter,
    validated,
}) => {
    const defaultInputsForm = {
        condicion: "",
        puntaje: "",
        sumaOT: false,
        folio: "",
    };

    const { tipoIncumbencias } = useSelector(st => st.data)

    const handleChange = (e) => handlerChange(setter, state, null, e)
    const handleChangeCheckBox = (e) => handlerChange(setter, state, null, { target: { id: e.target.id, value: e.target.checked } })

    useEffect(() => {
        setter(defaultInputsForm)
    }, [])

    return (
        <div className="d-flex">
            <div className="mr-2">
                <Input
                    type="input"
                    controlId="folio"
                    style={{ width: "12rem" }}
                    label="Folio"
                    value={state.folio}
                    onChange={handleChange}
                    validated={validated}
                    required
                ></Input>
            </div>
            <div className="ml-4 mr-2">
                <Input
                    type="select"
                    controlId="condicion"
                    label="Condicion"
                    style={{ width: "12rem" }}
                    value={state.condicion}
                    onChange={handleChange}
                    elements={tipoIncumbencias}
                    validated={validated}
                    required
                ></Input>
            </div>
            {tipoIncumbencias?.length > 0 &&
                idsTipoIncumbenciaConPuntaje.some(ctCondicionConPuntajeId => ctCondicionConPuntajeId == state.condicion) &&
                (
                    <>
                        <div className="ml-4 mr-2">
                            <Input
                                type="input"
                                controlId="puntaje"
                                style={{ width: "12rem" }}
                                label="Puntaje"
                                value={state.puntaje}
                                onChange={handleChange}
                                description={"(Del 0 al 3)"}
                                validated={validated}
                                required
                            ></Input>
                        </div>
                        <div className="ml-4 mr-2">
                            <Input
                                type="checkbox"
                                controlId="sumaOT"
                                name="sumaOT"
                                label="Solo aplica para Otros titulos"
                                value="0"
                                checked={state.sumaOT}
                                onChange={handleChangeCheckBox}
                            ></Input>
                        </div>
                    </>
                )}
        </div>
    )
}
