import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Table } from 'react-bootstrap'
import IconButton from '../../../generics/IconButton'

export const RelacionesCargosTable = ({ elementos = [], handleRemove, hideInputs = {} }) => {
    return (
        <>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Area</th>
                        {!hideInputs.tipoEscuela && <th>Tipo de escuela</th>}
                        {!hideInputs.especialidad && <th>Especialidad</th>}
                        {!hideInputs.asignatura && <th>Asignatura</th>}
                        {handleRemove && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        (elementos.length > 0) ?
                            <>
                                {elementos.map((relacion, index) =>
                                    <tr>
                                        <td>{relacion.area.nombre}</td>
                                        {!hideInputs.tipoEscuela && <td>{relacion.tipoEscuela.nombre}</td>}
                                        {!hideInputs.especialidad && <td>{relacion.especialidad.nombre}</td>}
                                        {!hideInputs.asignatura && <td>{relacion.asignatura?.nombre ?? "SIN ASIGNATURA"} </td>}
                                        {handleRemove && <td><IconButton icon={faTrash} onClick={() => handleRemove(index)} /></td>}
                                    </tr>
                                )}
                            </>
                            :
                            <>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    {!hideInputs.especialidad && <td>-</td>}
                                    {handleRemove && <td>-</td>}
                                </tr>
                            </>
                    }
                </tbody>
            </Table>
        </>
    )
}
