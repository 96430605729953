import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { descargasTypes } from "../../actions/types"
import { success } from "../alerts/alertActions";
import store from "../store"

export const OPCIONES_DESCARGAS = {
    TESTEO: {
        CANCELAR: "CANCELAR",
        COMPLETAR: "COMPLETAR",
    },
    PDF: {
        INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA: "PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA",
        INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA: "PDF_INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA",
        INFORME_TOPOLOGICO: "PDF_INFORME_TOPOLOGICO",
        INFORME_BY_AREA_CARGO_PRIMERA_VISTA: "PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA",
        INFORME_BY_AREA_CARGO_SEGUNDA_VISTA: "PDF_INFORME_BY_AREA_CARGO_SEGUNDA_VISTA",
        INFORME_CRUZADO: "PDF_INFORME_CRUZADO",
        INFORME_CRUZADO_POR_AREA_VER_CALIDAD: "PDF_INFORME_CRUZADO_POR_AREA_VER_CALIDAD",
        INFORME_TERNA_TITULOS: "PDF_INFORME_TERNA_TITULOS",
        INFORME_TERNA_RESOLUCIONES: "PDF_INFORME_TERNA_RESOLUCIONES",
        INFORME_TERNA_PROCEDENCIAS: "PDF_INFORME_TERNA_PROCEDENCIAS",
    },
    CSV: {
        INFORME_BY_AREA_CARGO_PRIMERA_VISTA: "CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA",
        INFORME_BY_AREA_CARGO_SEGUNDA_VISTA: "CSV_INFORME_BY_AREA_CARGO_SEGUNDA_VISTA",
        INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA: "CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA",
        INFORME_CRUZADO: "CSV_PDF_INFORME_CRUZADO",
        INFORME_TERNA_TITULOS: "CSV_INFORME_TERNA_TITULOS",
        INFORME_TOPOLOGICO: "CSV_INFORME_TOPOLOGICO",
        INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA: "INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA",
    },
}

export const agregarDescargas = (arrDescargas) => {
    store.dispatch(success(<><FontAwesomeIcon icon={faCloudDownloadAlt} size="s" /> <b><u>Descargas iniciadas:</u></b> <br></br> {arrDescargas.map(ctDescarga => <>{ctDescarga.title}<br></br></>)}</>));
    return {
        type: descargasTypes.AGREGAR_DESCARGAS,
        payload: { arrDescargas },
    }
}
export const iniciarDescargas = (arrDescargas) => {
    return {
        type: descargasTypes.INICIAR_DESCARGAS,
        payload: { arrDescargas },
    }
}

export const cancelarDescargas = (arrDescargas) => {
    return {
        type: descargasTypes.CANCELAR_DESCARGAS,
        payload: { arrDescargas },
    }
}

export const reiniciarDescargas = (arrDescargas) => {
    return {
        type: descargasTypes.REINICIAR_DESCARGAS,
        payload: { arrDescargas },
    }
}

export const completarDescargas = (arrDescargas) => {
    return {
        type: descargasTypes.COMPLETAR_DESCARGAS,
        payload: { arrDescargas },
    }
}

export const toggleShowDescripcion = (arrDescargas) => {
    return {
        type: descargasTypes.TOGGLE_SHOW_DESCRIPCION_DESCARGAS,
        payload: { arrDescargas },
    }
}

export const turnOnAlert = () => {
    return {
        type: descargasTypes.TURN_ON_ALERT,
    }
}

export const turnOffAlert = () => {
    return {
        type: descargasTypes.TURN_OFF_ALERT,
    }
}

