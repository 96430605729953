import Acciones, {createAcciones} from "../Acciones";

class Procedencia{
    constructor(nombre){
        this.id = "";
        this.nombre = nombre;
        this.acciones = [];
    }
}

export default Procedencia

export const createProcedencia = async (props) => {
    let acciones = props.acciones ? await createAcciones(props.acciones):new Acciones();
    return Object.assign(new Procedencia(props.nombre), {...props,acciones})
}
