import React from 'react'
import { IncumbenciaRowActions } from '../../shared/IncumbenciaRowActions'

export const IncumbenciasDetailCargoRowActions = ({
  incumbencia,
  setErrorMsg,
  clearErrorMsg,
  setSuccessMsg,
  resolveCallBack,
}) => {

  return (
    <>
      {
        incumbencia?.acciones &&
        <IncumbenciaRowActions
          incumbencia={incumbencia}
          setErrorMsg={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
          clearErrorMsg={clearErrorMsg}
          resolveCallBack={resolveCallBack}
        />
      }
    </>
  )
}


