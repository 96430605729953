export const adaptGetCargos = (arrElementos) => {
    return arrElementos.map(ctElemento => {
        const permite_variante = !(
            typeof (ctElemento.permite_variante) == "object" &&
            ctElemento.permite_variante.length == 1
        )

        const unica_variante = !permite_variante ?
            {
                asignatura: {
                    id: ctElemento.permite_variante[0].asignatura_id,
                    nombre: ctElemento.permite_variante[0].asignatura_nombre,
                    cod_dad: ctElemento.permite_variante[0].asignatura_cod_dad,
                },
                especialidad: {
                    id: ctElemento.permite_variante[0].especialidad_id,
                    nombre: ctElemento.permite_variante[0].especialidad_nombre,
                    cod_dad: ctElemento.permite_variante[0].especialidad_cod_dad,
                },
                area: {
                    id: ctElemento.permite_variante[0].area_id,
                    nombre: ctElemento.permite_variante[0].area_nombre,
                },
                tipoEscuela: {
                    id: ctElemento.permite_variante[0].tipo_escuela_id,
                    nombre: ctElemento.permite_variante[0].tipo_escuela_nombre,
                },
            } : null

        let formatedResponse = {
            ...ctElemento,
            permite_variante,
            unica_variante,
            nivel: { id: ctElemento.nivel[0].id, descripcion: ctElemento.nivel[0].nombre },
            estado: ctElemento.estado[0],
            tipoCargo: ctElemento.tipoCargo[0],
            apendice: ctElemento.apendice[0],
            asignatura: ctElemento.asignatura != "false",
            activo: ctElemento.activo != "false",
            migrar: ctElemento.migrar != "false",
        }
        return formatedResponse
    })
}