import React, { useEffect, useRef, useState } from 'react'
import { ButtonGroup, Col, Form, Row, ToggleButton } from 'react-bootstrap'
import Input from '../../ui/Input'
import { handlerChange, isValidText } from '../../utils/commons'
import * as ternaService from "../../ternas/TernaService"
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import { useDispatch, useSelector } from 'react-redux'
import { cleanFab, searchFab } from '../../store/screen/screenActions'
import { saveFilters } from '../../store/search/searchActions'
import { reactSelectFormUtils } from '../../utils/reactSelectFormUtils'
import CheckBoxEstados from '../../generics/CheckBoxEstados'
import { defaultEstadosSearchIncumByTerna } from './SearchIncumByTerna'

export const FormSearchIncumByTerna = ({
  searchTernas,
  inputsForm = {},
  setInputsForm,
  cleanResults,
  hideChecks,
}) => {

  const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
  const [tituloSeleccionado, setTituloSeleccionado] = useState("");
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [resetChecks, setResetChecks] = useState(0);

  const [mostrarRelaciones, setMostrarRelaciones] = useState(false)

  const handleChangeMostrarRelaciones = (mostrarRelaciones) => {
    let eTarget = { target: { id: "from_ternas", value: mostrarRelaciones } };
    handleChange(eTarget);
    setMostrarRelaciones(mostrarRelaciones)
  }

  const reactSelectsInputs = [
    { name: "resolucionSeleccionada", state: resolucionSeleccionada, setter: setResolucionSeleccionada },
    { name: "procedenciaSeleccionada", state: procedenciaSeleccionada, setter: setProcedenciaSeleccionada },
    { name: "tituloSeleccionado", state: tituloSeleccionado, setter: setTituloSeleccionado },
    { name: "areaSeleccionada", state: areaSeleccionada, setter: setAreaSeleccionada },
  ]

  const cleanRelatedFiltersRef = useRef(false)

  const handleChangeCheck = (e) => {
    const newValues = { [e.target.name]: e.target.checked }
    if (e.target.name == "titulos" && e.target.checked == false && inputsForm["otros_titulos"] != true) newValues["otros_titulos"] = true
    if (e.target.name == "otros_titulos" && e.target.checked == false && inputsForm["titulos"] != true) newValues["titulos"] = true

    setInputsForm({
      ...inputsForm,
      ...newValues,
    });
  };

  const screenState = useSelector(st => st.screen);
  const searchState = useSelector(st => st.search);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const resetForm = () => {
    reactSelectFormUtils.resetAllReactSelectStates(reactSelectsInputs);
    cleanResults();
    setMostrarRelaciones(false)
    setResetChecks(st => st + 1)
  }

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      const reactSelectValues = reactSelectFormUtils.getAllReactSelectStates(reactSelectsInputs);
      let filtros = { ...inputsForm, page: 1 };
      if (!filtros.titulos) filtros.titulos = false;
      if (!filtros.otros_titulos) filtros.otros_titulos = false;
      setInputsForm(filtros)
      dispatch(saveFilters({ reactSelectValues, filtros }));
      searchTernas(filtros);
      dispatch(searchFab(false));
    }
  }, [screenState])

  useEffect(() => {
    if (Object.keys(searchState).length > 0) {
      if (searchState.reactSelectValues) reactSelectFormUtils.putAllReactSelectStates(reactSelectsInputs, searchState.reactSelectValues);
      if (searchState.filtros) setInputsForm(searchState.filtros);
    }
    cleanRelatedFiltersRef.current = true;
  }, []);

  return (
    <Form
      onSubmit={(e) => e.preventDefault()}
      className="w-100"
    >
      <Row>
        <Col sm={12} md={6} lg={4}>
          <Input
            type="react-select"
            controlId="titulo"
            name="titulo"
            label="Nombre de título"
            value={tituloSeleccionado}
            methodOnChange={handleChange}
            elements={ternaService.getTitulos}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setTituloSeleccionado}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            withBusquedaAvanzada={true}
          ></Input>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Input
            type="react-select"
            controlId="procedencia"
            name="procedencia"
            label="Procedencia"
            value={procedenciaSeleccionada}
            methodOnChange={handleChange}
            elements={ternaService.getProcedencias}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setProcedenciaSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            relatedFilters={{ titulo: tituloSeleccionado.value }}
            cleanRelatedFiltersRef={cleanRelatedFiltersRef}
            withBusquedaAvanzada={true}
            paramsFilter={{ titulo_busquedaAvanzada: !!inputsForm.titulo_busquedaAvanzada }}
          ></Input>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <Input
            type="react-select"
            controlId="resolucion"
            name="resolucion"
            label="Resolución"
            countCaracters={2}
            value={resolucionSeleccionada}
            methodOnChange={handleChange}
            elements={ternaService.getResolucionesCombo}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setResolucionSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            relatedFilters={{ procedencia: procedenciaSeleccionada.value }}
            paramsFilter={{
              titulo: tituloSeleccionado.value ?? "",
              titulo_busquedaAvanzada: !!inputsForm.titulo_busquedaAvanzada,
              procedencia_busquedaAvanzada: !!inputsForm.procedencia_busquedaAvanzada,
            }}
            cleanRelatedFiltersRef={cleanRelatedFiltersRef}
            withBusquedaAvanzada={true}
          ></Input>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <ButtonGroup className="mb-2">
            <ToggleButton
              id={`conRelaciones`}
              className="menu-radio"
              type="radio"
              variant="secondary"
              name="radio"
              value="conRelaciones"
              checked={mostrarRelaciones}
              onChange={() => handleChangeMostrarRelaciones(true)}
            >
              Todas
            </ToggleButton>
            <ToggleButton
              id={`sinRelaciones`}
              className="menu-radio"
              type="radio"
              variant="secondary"
              name="radio"
              value="sinRelaciones"
              checked={!mostrarRelaciones}
              onChange={() => handleChangeMostrarRelaciones(false)}
            >
              Con Incumbencias
            </ToggleButton>
          </ButtonGroup>
        </Col>
        {
          mostrarRelaciones && <>
            <Col sm={12} md={6} lg={4}>
              <Input
                type="react-select"
                controlId="area"
                name="area"
                label="Área"
                value={areaSeleccionada}
                methodOnChange={handleChange}
                elements={especialidadesService.getAreas}
                nameElementsResponse="data"
                propertiesForFormat={{ value: "id", label: "nombre" }}
                setterElementSelect={setAreaSeleccionada}
                config={{ isSearchable: true, isClearable: true }}
              ></Input>
            </Col>
            <Col sm={6} md={6} lg={4}>
              <p className="font-weight-bold">Tipo de documento:</p>
              <Row>
                <Col md={6}>
                  <Input
                    type="checkbox"
                    controlId="titulos"
                    name="titulos"
                    label="Títulos"
                    value="1"
                    checked={inputsForm.titulos}
                    onChange={handleChangeCheck}
                    groupStyle={{ justifyContent: "start", marginBottom: "0", width: "fit-content" }}
                  ></Input>
                </Col>
                <Col md={6}>
                  <Input
                    type="checkbox"
                    controlId="otros_titulos"
                    name="otros_titulos"
                    label="Otros Títulos"
                    value="1"
                    checked={inputsForm.otros_titulos}
                    onChange={handleChangeCheck}
                    groupStyle={{ justifyContent: "start", marginBottom: "0", width: "fit-content" }}
                  ></Input>
                </Col>
              </Row>
            </Col>
          </>
        }
        {
          !hideChecks &&
          <Col sm={12} md={12} lg={8} key={resetChecks}>
            <CheckBoxEstados
              inputsForm={inputsForm}
              setInputsForm={setInputsForm}
            />
          </Col>
        }
      </Row >
    </Form >
  )
}
