import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants"
import { clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import TernasABM from "./TernasABM";
import { useHistory, useLocation } from "react-router-dom";
import Terna, { createTerna } from "../../dtos/terna/Terna";

export const TernaABMTicket = (props) => {
    const [terna, setTerna] = useState();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleSubmit = async (user) => {
        console.log("CLICK HANDLESUBMIT")
        //await processEditUser(user)
        // dispatch(success("Se edito la especialidad con exito."));
    }

    const loadEditData = async (currentTerna) => {
        let ternaConcret;

        if (currentTerna instanceof Terna) {
            ternaConcret = currentTerna
        } else {
            ternaConcret = await createTerna(currentTerna);
        }

        setTerna(ternaConcret);
    }

    useEffect(() => {
        dispatch(clear());
        const currentTerna = location.state.terna
        if (currentTerna) {
            loadEditData(currentTerna);
        } else {
            history.push(routePaths.ABM_TERNA_SEARCH)
        }
    }, []);

    return (
        <TernasABM
            terna={terna}
            title="Generar Ticket: Modificación Terna"
            typeOfAction={typeOfActions.GENERAR_TICKET_MOD}
            onSubmit={handleSubmit}
            returnPath={routePaths.ABM_TERNA_SEARCH}
        />
    );
}; 