import { apiURLs, estadosConstans, incumbenciasConstants, paginado, routePaths } from "../utils/Constants";
import { error, multiple, success, warning } from "../store/alerts/alertActions";
import store from "../store/store";
import React from "react";
import { getEscuelas } from "../escuela/EscuelaService";
import { history } from "../helpers/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { addData } from "../store/data/dataActions";
import { adaptCreatEditIncumbencia, adaptGetIncumbencias, adaptGetIncumbenciasByTerna, adaptUnificacionIncumbencia } from "./IncumbenciasAdapter";
const { default: requestService } = require("../utils/request.service");

export const clonarIncumbenciasTerna = async (filtros) => {
    // Formato payload a enviar:
    // {
    // "terna_origen_id",
    // "folio",
    // "apendice_id",
    // "terna_destino_id": 
    // }
    return await requestService.sendRequest('POST', apiURLs.API_CLONAR_INCUMBENCIAS_TERNA, filtros)
}

export const unificarTernas = async (filtros) => {
    return await requestService.sendRequest('POST', apiURLs.API_UNIFICAR_TERNAS, filtros)
}

export const relacionarTernas = async (filtros) => {
    return await requestService.sendRequest('POST', apiURLs.API_RELACIONAR_TERNAS, filtros)
}

export const getUnificacionesIncumbencias = async (filtros = {}) => {
    const response = await requestService.sendRequest('GET', apiURLs.API_INCUMBENCIAS_UNIFICACION, filtros)
    console.log({ response })
    return {
        elementos: response.data.elementos.map(ctEl => adaptUnificacionIncumbencia(ctEl)),
        page: response.data.page,
        cantidad: response.data.cantidad,
    }
}


export const clonarIncumbenciasCargo = async (filtros) => {
    // Formato payload a enviar:
    // {
    // "apendice_id": 41,
    // "folio": "123456789",
    // "cargo_origen_id": 149,
    // "asignatura_origen_id": 12,
    // "especialidad_origen_id": 131,
    // "cargo_destino_id": 150,
    // "asignatura_destino_id": 1,
    // "especialidad_destino_id": 1
    // }
    return await requestService.sendRequest('POST', apiURLs.API_CLONAR_INCUMBENCIAS_CARGO, filtros)
}

const cambiarDescripcionTipoIncumbenciaXId = (ctDescripcion, tipoIncumbencias) => {
    const result = tipoIncumbencias.find(ctTipo => {
        return ctDescripcion == ctTipo.descripcion
    })
    return result.id
}

export const formatConcurrenciasMainTerna = async (mainTerna, elementosFromFetch, tipoIncumbencias, definirTipoCargosRestantes, serializar) => {
    if (elementosFromFetch.length == 0) return {
        concurrenciasSeleccionadas: [],
        ternaSeleccionada: []
    }

    const formatTernasConcurrentesFromConcurrencia = (ctElemento, mainTerna) => {
        if (serializar) {
            const arrTernas = []
            const serializeTerna = (terna) => {
                return {
                    ...terna,
                    titulo: { nombre: terna.titulo, id: terna.id },
                    procedencia: { nombre: terna.procedencia },
                    resolucion: { nombre: terna.resolucion },
                }
            }
            if (ctElemento.terna2?.titulo) arrTernas.push(serializeTerna(ctElemento.terna2))
            if (ctElemento.terna3?.titulo) arrTernas.push(serializeTerna(ctElemento.terna3))
            return arrTernas
        } else {
            const arrTernas = [ctElemento.terna]
            if (ctElemento.terna2_id) {
                arrTernas.push({
                    id: ctElemento.terna2_id,
                    titulo: { nombre: ctElemento.titulo_terna_2, id: ctElemento.terna2_id },
                    procedencia: { nombre: ctElemento.procedencia_terna_2 },
                    resolucion: { nombre: ctElemento.resolucion_completa_terna_2 },
                    estado: { nombre: ctElemento.estado }
                })
            }
            if (ctElemento.terna3_id) {
                arrTernas.push({
                    id: ctElemento.terna3_id,
                    titulo: { nombre: ctElemento.titulo_terna_3, id: ctElemento.terna3_id },
                    procedencia: { nombre: ctElemento.procedencia_terna_3 },
                    resolucion: { nombre: ctElemento.resolucion_completa_terna_3 },
                    estado: { nombre: ctElemento.estado }
                })
            }
            return arrTernas.filter(ctTerna => ctTerna.id != mainTerna.id)
        }
    }

    const ternaSeleccionada = [formatTernaToIncumbencia(mainTerna)];

    const concurrenciasFromFetch = [];
    elementosFromFetch.forEach(ctElemento => {
        /* Agrupamos concurrencias por ternas concurrentes */
        const combinacionTernas = {
            terna_id: ctElemento.terna.id,
            terna2_id: ctElemento.terna2_id ?? ctElemento.terna2?.id,
            terna3_id: ctElemento.terna3_id ?? ctElemento.terna3?.id,
        }

        const elementIndex = concurrenciasFromFetch.findIndex(element => {
            /* Buscamos si ya existe algun element con "combinacionTerna" */
            return (
                element.combinacionTernas.terna_id == combinacionTernas.terna_id &&
                element.combinacionTernas.terna2_id == combinacionTernas.terna2_id &&
                element.combinacionTernas.terna3_id == combinacionTernas.terna3_id
            )
        });

        if (elementIndex >= 0) {
            concurrenciasFromFetch[elementIndex].cargosSeleccionados.push(ctElemento)
        } else {
            concurrenciasFromFetch.push({
                combinacionTernas,
                cargosSeleccionados: [ctElemento],
                ternasSeleccionadas: formatTernasConcurrentesFromConcurrencia(ctElemento, mainTerna),
            })
        }
    })

    const AgroupCargosFromConcurrencias = (concurrenciasFromFetch) => {
        console.log({ concurrenciasFromFetch })
        return concurrenciasFromFetch.map(ctConcurrencia => {
            const arrCargos = [];
            ctConcurrencia.cargosSeleccionados.forEach(ctCargo => {
                const elementIndex = arrCargos.findIndex(element => {
                    /* Buscamos si ya existe algun element con "combinacionTerna" */
                    return ctCargo.cargo.id == element.cargo.id
                });

                let ctVariante;
                const apendice_id = ctCargo.apendice_id ?? ctCargo.apendice

                if (serializar) {
                    ctVariante = {
                        ...ctCargo,
                        asignatura: {
                            nombre: ctCargo.asignatura,
                            id: ctCargo.asignatura_id,
                            codDad: ctCargo.asignatura_cod_dad,
                        },
                        apendice_id,
                        especialidad: { nombre: ctCargo.especialidad },
                        area: { nombre: ctCargo.area },
                        tipoEscuela: { nombre: ctCargo.tipo_escuela },
                        condicion: cambiarDescripcionTipoIncumbenciaXId(ctCargo.tipo_incumbencia, tipoIncumbencias),
                        puntaje: ctCargo.puntaje,
                        sumaOT: ctCargo.suma_ot,
                        folio: ctCargo.folio,
                        estado: { nombre: ctCargo.cargo.estado },
                    }
                } else {
                    ctVariante = {
                        ...ctCargo,
                        apendice_id,
                        asignatura: { nombre: ctCargo.asignatura },
                        especialidad: ctCargo.especialidad,
                        area: ctCargo.area,
                        tipoEscuela: ctCargo.tipo_escuela,
                        condicion: cambiarDescripcionTipoIncumbenciaXId(ctCargo.tipo_incumbencia, tipoIncumbencias),
                        puntaje: ctCargo.puntaje,
                        sumaOT: ctCargo.suma_ot,
                        folio: ctCargo.folio,
                        estado: ctCargo.cargo.estado,
                    }
                }
                console.log({ ctVariante, ctCargo })
                if (elementIndex >= 0) {
                    arrCargos[elementIndex].variantes.push(ctVariante)
                } else {
                    arrCargos.push({
                        cargo: ctCargo.cargo,
                        type: "multi",
                        variantes: [ctVariante]
                    })
                }

            })
            return {
                ...ctConcurrencia,
                cargosSeleccionados: arrCargos,
            };
        })
    }

    const concurrenciasWithCargosAgrupados = AgroupCargosFromConcurrencias(concurrenciasFromFetch)

    return { concurrenciasSeleccionadas: concurrenciasWithCargosAgrupados, ternaSeleccionada };
}

export const formatConcurrenciasMainCargo = async (mainCargo, elementosFromFetch, tipoIncumbencias) => {
    if (elementosFromFetch.length == 0) return {
        concurrenciasSeleccionadas: [],
        cargoSeleccionado: []
    }

    console.log("formatConcurrenciasMainCargo", { mainCargo, elementosFromFetch, tipoIncumbencias })
    const infoCargo = {
        area: elementosFromFetch[0].area,
        asignatura: elementosFromFetch[0].asignatura,
        especialidad: elementosFromFetch[0].especialidad,
        tipoEscuela: elementosFromFetch[0].tipoEscuela,
    }

    let formatedMainCargo = await formatCargoToIncumbencia(mainCargo, () => { }, true)
    if (formatedMainCargo.variantes) {
        formatedMainCargo.variantes = [infoCargo]
    } else {
        formatedMainCargo = { ...formatedMainCargo, ...infoCargo }
    }
    const cargoSeleccionado = [formatedMainCargo];

    const concurrenciasFromFetch = [];
    elementosFromFetch.forEach(ctElemento => {
        const ternasSeleccionadas = [];
        if (ctElemento.terna?.id) ternasSeleccionadas.push(ctElemento.terna)
        if (ctElemento.terna2?.id) ternasSeleccionadas.push(ctElemento.terna2)
        if (ctElemento.terna3?.id) ternasSeleccionadas.push(ctElemento.terna3)

        console.log("formatConcurrenciasMainCargo1", { ctElemento, })
        const apendice_id = ctElemento.apendice.id ?? ctElemento.apendice_id
        const inputs = {
            id: ctElemento.id,
            folio: ctElemento.folio,
            sumaOT: ctElemento.sumaOT,
            apendice_id,
            condicion: cambiarDescripcionTipoIncumbenciaXId(ctElemento.calidad, tipoIncumbencias),
            puntaje: ctElemento.puntaje,
        }

        const cargosSeleccionados = [];

        concurrenciasFromFetch.push({
            ternasSeleccionadas,
            inputs,
            cargosSeleccionados,
            acciones: ctElemento.acciones,
            estado: ctElemento.estado,
        })
    })
    return { concurrenciasSeleccionadas: concurrenciasFromFetch, cargoSeleccionado };
}

export const formatIncumbenciasMainTerna = async (elementosFromFetch, tipoIncumbencias, definirTipoCargosRestantes) => {
    if (!tipoIncumbencias?.length > 0) throw (new Error("Faltan los tipos de Incumbencias"));
    if (elementosFromFetch.length == 0) return {
        cargoSeleccionado: [],
        ternaSeleccionada: []
    }

    const ternaSeleccionada = [formatTernaToIncumbencia(elementosFromFetch[0].terna)];

    /* Agrupamos incumbencias por cargo */
    const cargosFromFetch = [];
    elementosFromFetch.forEach(currentElement => {
        currentElement = {
            ...currentElement,
            tipoEscuela: currentElement.tipo_escuela,
            condicion: tipoIncumbencias.find(ctCond => ctCond.descripcion == currentElement.tipo_incumbencia).id,
            sumaOT: currentElement.suma_ot,
        }
        const elementIndex = cargosFromFetch.findIndex(element => element.id == currentElement.cargo.id);
        if (elementIndex >= 0) {
            cargosFromFetch[elementIndex].data.push(currentElement)
        } else {
            cargosFromFetch.push({ id: currentElement.cargo.id, data: [currentElement] })
        }
    });

    const cargosMulti = [];
    const cargosSinDefinir = [];
    cargosFromFetch.forEach(currentElement => {
        if (currentElement.data.length >= 2) {
            cargosMulti.push(currentElement);
        } else {
            cargosSinDefinir.push(currentElement);
        }
    })

    /* Formateamos los multi */
    let allCargosMulti = [];
    cargosMulti.forEach(currentGroup => {
        allCargosMulti.push({
            cargo: currentGroup.data[0].cargo,
            type: "multi",
            variantes: currentGroup.data
        })
    })

    /* Definimos si es simple o multi los cargos sin definir */
    let cargosRestantes = [];
    if (definirTipoCargosRestantes) {
        const cargosPorDefinir = cargosSinDefinir.map(currentElement => {
            const cargoFormated = {
                ...currentElement.data[0].cargo,
                asignaturas: currentElement.data[0].asignaturasDelCargo
            }
            return formatCargoToIncumbencia(cargoFormated);
        })
        const cargosDefinidos = await Promise.all(cargosPorDefinir);
        cargosRestantes = [...cargosDefinidos];
    } else {
        /* Ponemos a todos los sinDefinir como multis */
        cargosRestantes = cargosSinDefinir.map(currentCarg => {
            return {
                type: "multi",
                cargo: currentCarg.data[0].cargo,
                variantes: []
            }
        })
    }

    /* A las restantes les agregamos la info de su incumbencia */
    const allCargosRestantes = [];
    cargosRestantes.forEach(currentRestante => {
        const cargoIndex = cargosSinDefinir.findIndex(currentSinDefinir => currentSinDefinir.data[0].cargo.id == currentRestante.cargo.id)
        if (currentRestante.type == "multi") {
            allCargosRestantes.push({ ...currentRestante, variantes: cargosSinDefinir[cargoIndex].data })
        } else {
            allCargosRestantes.push({ ...currentRestante, ...cargosSinDefinir[cargoIndex].data[0] })
        }
    })

    const cargoSeleccionado = [...allCargosMulti, ...allCargosRestantes];

    return { ternaSeleccionada, cargoSeleccionado }
}

export const formatIncumbenciasMainCargo = async (elementosFromFetch, tipoIncumbencias) => {
    console.log({ elementosFromFetch, tipoIncumbencias })
    if (!tipoIncumbencias?.length > 0) throw (new Error("Faltan los tipos de Incumbencias"));
    if (elementosFromFetch.length == 0) return {
        cargoSeleccionado: [],
        ternaSeleccionada: []
    }

    let cargoFormated = await formatCargoToIncumbencia(elementosFromFetch[0].cargo, () => { }, true);

    const incumbenciaData = {
        ...elementosFromFetch[0],
        tipoEscuela: elementosFromFetch[0].tipoEscuela,
    }

    if (cargoFormated.type == "multi") {
        cargoFormated.variantes = [incumbenciaData];
    } else {
        cargoFormated = { ...cargoFormated, ...incumbenciaData }
    }

    const cargoSeleccionado = [cargoFormated];

    const ternaSeleccionada = elementosFromFetch.map((currentElement, index) => {
        console.log({ currentElement })
        return {
            ...currentElement,
            apendice_id: currentElement.apendice.id,
            condicion: tipoIncumbencias.find(ctCond => ctCond.descripcion == currentElement.calidad).id,
            sumaOT: currentElement.sumaOT,
            asignaturas: currentElement.asignaturasDelCargo
        }
    })
    console.log({ ternaSeleccionada, cargoSeleccionado })
    return { ternaSeleccionada, cargoSeleccionado }
}

export const formatTernaToIncumbencia = (newTerna) => {
    const ternaToAdd = {
        condicion: "",
        puntaje: "",
        terna: newTerna,
    }
    return ternaToAdd;
}

export const formatCargoToIncumbencia = async (newCargo, isLoadingCallBack, forceMulti) => {
    console.log({ newCargo })
    const cargoToAdd = {
        type: "simple",
        cargo: newCargo,
    }

    const resolveMulti = () => {
        cargoToAdd["type"] = "multi";
        cargoToAdd["variantes"] = [];
        console.log(2, { cargoToAdd })

        return cargoToAdd;
    }

    const resolveSimple = () => {
        cargoToAdd["condicion"] = "";
        cargoToAdd["asignatura"] = newCargo.unica_variante.asignatura;
        cargoToAdd["especialidad"] = newCargo.unica_variante.especialidad;
        cargoToAdd["area"] = newCargo.unica_variante.area;
        cargoToAdd["tipoEscuela"] = newCargo.unica_variante.tipoEscuela;
        console.log(1, { cargoToAdd })

        return cargoToAdd;
    }

    if (isLoadingCallBack) isLoadingCallBack(true);
    if (forceMulti) return resolveMulti()

    return (newCargo.permite_variante) ? resolveMulti() : resolveSimple()
}

export const getTipoIncumbencias = async () => {
    let response = await requestService.sendRequest('GET', apiURLs.API_TIPO_INCUMBENCIAS)

    const tiposFormated = response.data.map(ctElemento => {
        return {
            ...ctElemento,
            value: ctElemento.id,
            label: ctElemento.descripcion,
        }
    })
    store.dispatch(addData({ tipoIncumbencias: tiposFormated }));
}

export const getIncumbenciasByTerna = async (filtros = {}, isMountedRef) => {
    /* FILTROS:
    terna_id
    area_id
    page
    */
    try {
        const uri = apiURLs.API_MAESTROS_INCUMBENCIA + "/getByTerna";
        let response = await requestService.sendRequest('GET', uri, filtros, isMountedRef)
        return {
            elementos: adaptGetIncumbenciasByTerna(response.data.elementos),
            cantidad: response.data.cantidad,
            info: response.data.info,
            page: response.data.page,
        };
    } catch (err) { throw new Error(err.message); }
}

const getIncumbencias = async (filtros, minInfo) => {
    /* TODO: El endpoint devuelve un monton de info de mas de la que se necesita y en casos ralentiza las vistas: 
    minInfo hace que el adapter elimine la informacion de mas */
    filtros["limit"] = filtros.limit || paginado.FILAS_POR_PAGINAS;
    let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_INCUMBENCIA, filtros)
    return {
        elementos: adaptGetIncumbencias(response.data.elementos, true),
        cantidad: response.data.cantidad,
        page: response.data.page,
    };

}

const makeTernaResultMsg = (currentTerna, html) => {
    const descriptionMsg = [
        { "Id": currentTerna.id },
        { "Titulo": currentTerna.titulo },
        { "Procedencia": currentTerna.procedencia },
        { "Resolucion": currentTerna.resolucion },
    ]

    return descriptionMsg.map((currentDescData, index) => {
        const title = Object.keys(currentDescData)[0];
        const description = Object.values(currentDescData)[0];

        return html ?
            <React.Fragment key={"makeTernaResultMsg" + index}>
                <b>-{title}: </b>{description}. <br></br>
            </ React.Fragment>
            :
            `-${title}: ${description}.`
    })
}

const makeCargoResultMsg = (currentCargo, html) => {
    const descriptionMsg = [
        { "Cargo": currentCargo.cargo.nombre },
        { "Asignatura": currentCargo.asignatura ?? "SIN ASIGNATURA" },
        { "Especialidad": currentCargo.especialidad ?? "SIN ESPECIALIDAD" },
        { "Area": currentCargo.area },
        { "Tipo escuela": currentCargo.tipo_escuela },
    ]

    return descriptionMsg.map((currentDescData, index) => {
        const title = Object.keys(currentDescData)[0];
        const description = Object.values(currentDescData)[0];

        return html ?
            <React.Fragment key={"makeCargoResultMsg" + index}>
                <b>-{title}: </b>{description}.<br></br>
            </ React.Fragment>
            :
            `-${title}: ${description}.`
    })
}

const makeConcurrenciaResultMsg = (concurrencia, html) => {
    if (html) {
        return <React.Fragment>
            <br></br><br></br>
            En concurrencia con:<br></br> <br></br>
            {concurrencia.terna2.id && makeTernaResultMsg(concurrencia.terna2, true)}
            {concurrencia.terna3.id && <>
                <br></br>
                {makeTernaResultMsg(concurrencia.terna3, true)}
            </>}
        </ React.Fragment>
    } else {
        const arrResp = [
            "En concurrencia con:",
        ]

        if (concurrencia.terna2.id) arrResp.push(makeTernaResultMsg(concurrencia.terna2))
        if (concurrencia.terna3.id) arrResp.push(makeTernaResultMsg(concurrencia.terna3))

        return arrResp
    }
}

const makeIncumbenciaDataMsg = (incumbencia, html) => {
    if (html) {
        return <React.Fragment>
            <b>-Calidad:</b> {incumbencia.tipo_incumbencia} ({Number(incumbencia.puntaje)} punto{incumbencia.puntaje != 1 ? "s" : ""}{incumbencia.suma_ot ? ". Suma para otros titulos" : ""})
        </ React.Fragment>
    } else {
        return `-Calidad: ${incumbencia.tipo_incumbencia} (${Number(incumbencia.puntaje)} punto${incumbencia.puntaje != 1 ? "s" : ""}${incumbencia.suma_ot ? ". Suma para otros titulos" : ""})`
    }
}

const ResultDetailLink = ({ data }) => {
    const addLineBreak = (arr) => {
        let msg = ""
        arr.forEach(currentElement => {
            if (typeof (currentElement) == 'string') {
                msg = msg + currentElement + "\n"
                return
            }
            msg = msg + addLineBreak(currentElement) + "\n"
        })
        return msg
    }
    const fileName = "detalle-incumbencias.txt"
    const detailData = addLineBreak(data);
    return <a href={"data:application/octet-stream," + encodeURIComponent(detailData)} download={fileName} className="text-dark text-decoration-underline">
        <FontAwesomeIcon icon={faDownload} className="mr-1" />
        Descargar detalle
    </a>
}

const separadorCorto = "----------"
const separadorLargo = "------------------------------"

export const showResolveMessageMainCargo = (data) => {
    const cantidadMaximaMensajes = window.Configs.MAX_RESULT_MESSAGES_INCUMBENCIAS;

    const arrIncumbenciasAgregadas = data.incumbencias?.agregados ?? [];
    const arrIncumbenciasExistentes = data.incumbencias?.existentes ?? [];
    const arrConcurrenciasAgregadas = data.concurrencias?.agregados ?? [];
    const arrConcurrenciasExistentes = data.concurrencias?.existentes ?? [];

    const cantidadIncumbenciasAgregadas = arrIncumbenciasAgregadas.length;
    const cantidadIncumbenciasExistentes = arrIncumbenciasExistentes.length;
    const cantidadConcurrenciasAgregadas = arrConcurrenciasAgregadas.length;
    const cantidadConcurrenciasExistentes = arrConcurrenciasExistentes.length;

    let incumbenciasAgregadas;
    let incumbenciasExistentes;
    let concurrenciasAgregadas;
    let concurrenciasExistentes;

    /* INCUMBENCIAS AGREGADAS */
    if (cantidadIncumbenciasAgregadas > 0) {
        if (cantidadIncumbenciasAgregadas < cantidadMaximaMensajes) {
            incumbenciasAgregadas = <>
                <b>Se agregaron correctamente las siguientes incumbencias con el cargo:</b><br></br><br></br>
                <p>{makeCargoResultMsg(arrIncumbenciasAgregadas[0], true)}</p>
                <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                {arrIncumbenciasAgregadas.map(currentElement => <p>
                    {makeTernaResultMsg(currentElement.terna, true)}
                    {makeIncumbenciaDataMsg(currentElement, true)}
                </p>)}
            </>
        } else {
            let mensajeIncumbencias = []

            arrIncumbenciasAgregadas.forEach((currentElement, index) => {
                mensajeIncumbencias.push([
                    // ...makeCargoResultMsg(currentElement),
                    ...makeTernaResultMsg(currentElement.terna),
                    makeIncumbenciaDataMsg(currentElement)
                ])
                // if (index < arrConcurrenciasAgregadas.length - 1) mensajeIncumbencias.push("-------------a------------\n");
            })

            incumbenciasAgregadas = <div className="d-flex justify-content-between">
                <b>Se agregaron correctamente {cantidadIncumbenciasAgregadas} incumbencias nuevas</b><br></br>
                <ResultDetailLink
                    data={[
                        `Se agregaron correctamente ${cantidadIncumbenciasAgregadas} incumbencias nuevas:\n`,
                        makeCargoResultMsg(arrIncumbenciasAgregadas[0]),
                        "-------------------------\n",
                        ...mensajeIncumbencias,
                    ]}
                />
            </div>
        }
    }

    /* INCUMBENCIAS EXISTENTES */
    if (cantidadIncumbenciasExistentes > 0) {
        if (cantidadIncumbenciasExistentes < cantidadMaximaMensajes) {
            incumbenciasExistentes = <>
                <b>Ya existen las siguientes incumbencias con el cargo:</b><br></br><br></br>
                <p>{makeCargoResultMsg(arrIncumbenciasExistentes[0], true)}</p>
                <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                {arrIncumbenciasExistentes.map(currentElement => <p>
                    {makeTernaResultMsg(currentElement.terna, true)}
                    {makeIncumbenciaDataMsg(currentElement, true)}
                </p>)}
            </>
        } else {
            let mensajeIncumbencias = []

            arrIncumbenciasExistentes.forEach((currentElement, index) => {
                mensajeIncumbencias.push([
                    ...makeTernaResultMsg(currentElement.terna),
                    makeIncumbenciaDataMsg(currentElement)
                ])
                // if (index < arrConcurrenciasAgregadas.length - 1) mensajeIncumbencias.push("-------------------------\n");
            })

            incumbenciasExistentes = <div className="d-flex justify-content-between">
                <b>No se agregaron {cantidadIncumbenciasExistentes} incumbencias, ya que se encontraban previamente creadas.</b><br></br>
                <ResultDetailLink
                    data={[
                        `No se agregaron ${cantidadIncumbenciasExistentes} incumbencias, ya que se encontraban previamente creadas:\n`,
                        makeCargoResultMsg(arrIncumbenciasExistentes[0]),
                        "-------------------------\n",
                        ...mensajeIncumbencias,
                    ]}
                />
            </div>
        }
    }

    /* CONCURRENCIAS AGREGADAS */
    if (cantidadConcurrenciasAgregadas > 0) {
        if (cantidadConcurrenciasAgregadas < cantidadMaximaMensajes) {
            concurrenciasAgregadas = <>
                <b>Se agregaron correctamente las siguientes concurrencias con el cargo:</b><br></br><br></br>
                <p>{makeCargoResultMsg(arrConcurrenciasAgregadas[0], true)}</p>
                <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                {arrConcurrenciasAgregadas.map((currentElement, index) => <p>
                    {makeTernaResultMsg(currentElement.terna, true)}
                    {makeIncumbenciaDataMsg(currentElement, true)}
                    {makeConcurrenciaResultMsg(currentElement, true)}
                    {(index < arrConcurrenciasAgregadas.length - 1) && <hr style={{ borderTopColor: "#a6a4a4" }}></hr>}
                </p>)}
            </>
        } else {
            let mensajeConcurrencias = []

            arrConcurrenciasAgregadas.forEach((currentElement, index) => {
                mensajeConcurrencias.push([
                    ...makeTernaResultMsg(currentElement.terna),
                    makeIncumbenciaDataMsg(currentElement),
                ])
                const concurrenciasRelacionadas = makeConcurrenciaResultMsg(currentElement)
                if (index < arrConcurrenciasAgregadas.length - 1) concurrenciasRelacionadas.push("-------------------------");
                mensajeConcurrencias.push(concurrenciasRelacionadas);
            })
            concurrenciasAgregadas = <div className="d-flex justify-content-between">
                <b>Se agregaron correctamente {cantidadConcurrenciasAgregadas} concurrencias nuevas</b><br></br>
                <ResultDetailLink
                    data={[
                        `Se agregaron correctamente ${cantidadConcurrenciasAgregadas} concurrencias nuevas:\n`,
                        makeCargoResultMsg(arrConcurrenciasAgregadas[0]),
                        // makeTernaResultMsg(arrConcurrenciasAgregadas[0].terna),
                        "-------------------------\n",
                        ...mensajeConcurrencias
                    ]}
                />
            </div>
        }
    }

    /* CONCURRENCIAS EXISTENTES */
    if (cantidadConcurrenciasExistentes > 0) {
        if (cantidadConcurrenciasExistentes < cantidadMaximaMensajes) {
            concurrenciasExistentes = <>
                <b>Ya existen las siguientes concurrencias con el cargo:</b><br></br><br></br>
                <p>{makeCargoResultMsg(arrConcurrenciasExistentes[0], true)}</p>
                <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                {arrConcurrenciasExistentes.map((currentElement, index) => <p>
                    {makeTernaResultMsg(currentElement.terna, true)}
                    {makeIncumbenciaDataMsg(currentElement, true)}
                    {makeConcurrenciaResultMsg(currentElement, true)}
                    {(index < arrConcurrenciasExistentes.length - 1) && <hr style={{ borderTopColor: "#a6a4a4" }}></hr>}
                </p>)}
            </>
        } else {
            const mensajeConcurrencias = []

            arrConcurrenciasExistentes.forEach((currentElement, index) => {
                mensajeConcurrencias.push([
                    ...makeTernaResultMsg(currentElement.terna),
                    makeIncumbenciaDataMsg(currentElement)
                ])

                const concurrenciasRelacionadas = makeConcurrenciaResultMsg(currentElement)
                if (index < arrConcurrenciasExistentes.length - 1) concurrenciasRelacionadas.push("-------------------------");
                mensajeConcurrencias.push(concurrenciasRelacionadas);
            })

            concurrenciasExistentes = <div className="d-flex justify-content-between">
                <b>No se agregaron {cantidadConcurrenciasExistentes} concurrencias, ya que se encontraban previamente creadas.</b><br></br>
                <ResultDetailLink
                    data={[
                        `No se agregaron ${cantidadConcurrenciasExistentes} concurrencias, ya que se encontraban previamente creadas:\n`,
                        makeCargoResultMsg(arrConcurrenciasExistentes[0]),
                        "-------------------------\n",
                        ...mensajeConcurrencias,
                    ]}
                />
            </div>
        }
    }
    const msgMultipleIncumbencias = () => {
        const arrMsg = [];
        if (incumbenciasAgregadas) arrMsg.push(success(incumbenciasAgregadas))
        if (incumbenciasExistentes) arrMsg.push(warning(incumbenciasExistentes))
        if (concurrenciasAgregadas) arrMsg.push(success(concurrenciasAgregadas))
        if (concurrenciasExistentes) arrMsg.push(warning(concurrenciasExistentes))
        return arrMsg
    };
    store.dispatch(multiple(msgMultipleIncumbencias()));


}

export const showResolveMessageMainTerna = (data) => {
    const cantidadMaximaMensajes = window.Configs.MAX_RESULT_MESSAGES_INCUMBENCIAS;

    const arrIncumbenciasAgregadas = data.incumbencias?.agregados ?? [];
    const arrIncumbenciasExistentes = data.incumbencias?.existentes ?? [];
    const arrConcurrenciasAgregadas = data.concurrencias?.agregados ?? [];
    const arrConcurrenciasExistentes = data.concurrencias?.existentes ?? [];

    const cantidadIncumbenciasAgregadas = arrIncumbenciasAgregadas.length;
    const cantidadIncumbenciasExistentes = arrIncumbenciasExistentes.length;
    const cantidadConcurrenciasAgregadas = arrConcurrenciasAgregadas.length;
    const cantidadConcurrenciasExistentes = arrConcurrenciasExistentes.length;

    const agruparPorTerna = (arrIncumbencias, { nombreGrupo }) => {
        const arrIncumbenciasAgrupadas = [];

        arrIncumbencias.forEach(ctIncumbencia => {
            const grupoIndex = arrIncumbenciasAgrupadas.findIndex(ctGrupo => ctIncumbencia.terna.id == ctGrupo.terna.id)
            const existeGrupo = grupoIndex >= 0;
            if (existeGrupo) {
                arrIncumbenciasAgrupadas[grupoIndex][nombreGrupo].push(ctIncumbencia)
            } else {
                arrIncumbenciasAgrupadas.push({
                    terna: ctIncumbencia.terna,
                    [nombreGrupo]: [ctIncumbencia],
                })
            }
        })

        return arrIncumbenciasAgrupadas
    }

    const arrIncumbenciasAgregadasAgrupadasPorTerna = agruparPorTerna(arrIncumbenciasAgregadas, { nombreGrupo: "incumbencias" });
    const arrIncumbenciasExistentesAgrupadasPorTerna = agruparPorTerna(arrIncumbenciasExistentes, { nombreGrupo: "incumbencias" });
    const arrConcurrenciasAgregadasAgrupadasPorTerna = agruparPorTerna(arrConcurrenciasAgregadas, { nombreGrupo: "concurrencias" });
    const arrConcurrenciasExistentesAgrupadasPorTerna = agruparPorTerna(arrConcurrenciasExistentes, { nombreGrupo: "concurrencias" });

    let msgSuccessIncumbenciasAgregadas;
    let msgWarningIncumbenciasExistentes;
    let msgSuccessConcurrenciasAgregadas;
    let msgWarningConcurrenciasExistentes;

    const getMsgIncumbencias = ({
        cantidadIncumbencias,
        arrIncumbenciasAgrupadas,
        generarMsgUnaTernaPrincipalConMenorCantidadIncumbencias,
        generarMsgUnaTernaPrincipalConMayorCantidadIncumbencias,
        generarMsgVariasTernasPrincipales
    }) => {
        if (cantidadIncumbencias > 0) {
            if (cantidadIncumbencias < cantidadMaximaMensajes && arrIncumbenciasAgrupadas.length == 1) {
                return (
                    <>
                        <b>{generarMsgUnaTernaPrincipalConMenorCantidadIncumbencias()}</b><br></br><br></br>
                        <p>{makeTernaResultMsg(arrIncumbenciasAgrupadas[0].terna, true)}</p>
                        <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                        {arrIncumbenciasAgrupadas[0].incumbencias.map(currentElement => <p>
                            {makeCargoResultMsg(currentElement, true)}
                            {makeIncumbenciaDataMsg(currentElement, true)}
                        </p>)}
                    </>
                )
            } else {
                let mensajeIncumbencias = []
                arrIncumbenciasAgrupadas.forEach(ctGrupo => {
                    mensajeIncumbencias.push(
                        generarMsgUnaTernaPrincipalConMayorCantidadIncumbencias(ctGrupo.incumbencias.length) + ":\n",
                        makeTernaResultMsg(ctGrupo.terna),
                        separadorCorto + "\n",
                    )
                    ctGrupo.incumbencias.forEach(ctIncumbencia => {
                        mensajeIncumbencias.push([
                            ...makeCargoResultMsg(ctIncumbencia),
                            makeIncumbenciaDataMsg(ctIncumbencia)
                        ])
                    })
                    mensajeIncumbencias.push(separadorLargo + "\n");
                })

                return (
                    <div className="d-flex justify-content-between">
                        {arrIncumbenciasAgrupadas.length > 1 ?
                            <b>{generarMsgVariasTernasPrincipales(cantidadIncumbencias, arrIncumbenciasAgrupadas.length)}</b>
                            :
                            <b>{generarMsgUnaTernaPrincipalConMayorCantidadIncumbencias(cantidadIncumbencias)}</b>
                        }
                        <br></br>
                        <ResultDetailLink data={mensajeIncumbencias} />
                    </div>
                )
            }
        }
    }

    if (cantidadIncumbenciasAgregadas > 0) msgSuccessIncumbenciasAgregadas = getMsgIncumbencias({
        cantidadIncumbencias: cantidadIncumbenciasAgregadas,
        arrIncumbenciasAgrupadas: arrIncumbenciasAgregadasAgrupadasPorTerna,
        generarMsgUnaTernaPrincipalConMenorCantidadIncumbencias: () => "Se agregaron correctamente las siguientes incumbencias con la terna:",
        generarMsgUnaTernaPrincipalConMayorCantidadIncumbencias: (cantidad) => `Se agregaron correctamente ${cantidad} incumbencias nuevas para la terna`,
        generarMsgVariasTernasPrincipales: (cantidad, cantidadTernasPrincipales) => `Se agregaron correctamente ${cantidad} incumbencias nuevas para ${cantidadTernasPrincipales} ternas distintas`,
    })

    if (cantidadIncumbenciasExistentes > 0) msgWarningIncumbenciasExistentes = getMsgIncumbencias({
        cantidadIncumbencias: cantidadIncumbenciasExistentes,
        arrIncumbenciasAgrupadas: arrIncumbenciasExistentesAgrupadasPorTerna,
        generarMsgUnaTernaPrincipalConMenorCantidadIncumbencias: () => "Ya existen las siguientes incumbencias con la terna:",
        generarMsgUnaTernaPrincipalConMayorCantidadIncumbencias: (cantidad) => `No se agregaron ${cantidad} incumbencias para la terna, ya que se encontraban previamente creadas`,
        generarMsgVariasTernasPrincipales: (cantidad, cantidadTernasPrincipales) => `No se agregaron ${cantidad} incumbencias para ${cantidadTernasPrincipales} ternas distintas, ya que se encontraban previamente creadas`,
    })

    const getMsgConcurrencias = ({
        cantidadConcurrencias,
        arrConcurrenciasAgrupadas,
        generarMsgUnaTernaPrincipalConMenorCantidadConcurrencias,
        generarMsgUnaTernaPrincipalConMayorCantidadConcurrencias,
        generarMsgVariasTernasPrincipales
    }) => {
        if (cantidadConcurrencias < cantidadMaximaMensajes && arrConcurrenciasAgrupadas.length == 1) {
            return (
                <>
                    <b>{generarMsgUnaTernaPrincipalConMenorCantidadConcurrencias()}</b><br></br><br></br>
                    <p>{makeTernaResultMsg(arrConcurrenciasAgrupadas[0].terna, true)}</p>
                    <hr style={{ borderTopColor: "#a6a4a4" }}></hr>
                    {arrConcurrenciasAgrupadas[0].concurrencias.map((currentElement, index) => <p>
                        {makeCargoResultMsg(currentElement, true)}
                        {makeIncumbenciaDataMsg(currentElement, true)}
                        {makeConcurrenciaResultMsg(currentElement, true)}
                        {(index < arrConcurrenciasAgrupadas[0].concurrencias.length - 1) && <hr style={{ borderTopColor: "#a6a4a4" }}></hr>}
                    </p>)}
                </>
            )
        } else {
            let mensajeConcurrencias = []
            arrConcurrenciasAgrupadas.forEach(ctGrupo => {
                mensajeConcurrencias.push(
                    generarMsgUnaTernaPrincipalConMayorCantidadConcurrencias(ctGrupo.concurrencias.length) + ":\n",
                    makeTernaResultMsg(ctGrupo.terna),
                    separadorCorto + "\n",
                )
                ctGrupo.concurrencias.forEach((ctConcurrencia, index) => {
                    mensajeConcurrencias.push([
                        ...makeCargoResultMsg(ctConcurrencia),
                        makeIncumbenciaDataMsg(ctConcurrencia)
                    ])
                    const concurrenciasRelacionadas = makeConcurrenciaResultMsg(ctConcurrencia);
                    concurrenciasRelacionadas.push((index < ctGrupo.concurrencias.length - 1) ? separadorCorto : separadorLargo)
                    mensajeConcurrencias.push(concurrenciasRelacionadas);

                })
            })
            return (
                <div className="d-flex justify-content-between">
                    {arrConcurrenciasAgrupadas.length > 1 ?
                        <b>{generarMsgVariasTernasPrincipales(cantidadConcurrencias, arrConcurrenciasAgrupadas.length)}</b>
                        :
                        <b>{generarMsgUnaTernaPrincipalConMayorCantidadConcurrencias(cantidadConcurrencias)}</b>
                    }
                    <br></br>
                    <ResultDetailLink data={mensajeConcurrencias} />
                </div>
            )
        }
    }

    if (cantidadConcurrenciasAgregadas > 0) msgSuccessConcurrenciasAgregadas = getMsgConcurrencias({
        cantidadConcurrencias: cantidadConcurrenciasAgregadas,
        arrConcurrenciasAgrupadas: arrConcurrenciasAgregadasAgrupadasPorTerna,
        generarMsgUnaTernaPrincipalConMenorCantidadConcurrencias: () => "Se agregaron correctamente las siguientes concurrencias con la terna:",
        generarMsgUnaTernaPrincipalConMayorCantidadConcurrencias: (cantidad) => `Se agregaron correctamente ${cantidad} concurrencias nuevas para la terna`,
        generarMsgVariasTernasPrincipales: (cantidad, cantidadTernasPrincipales) => `Se agregaron correctamente ${cantidad} concurrencias nuevas para ${cantidadTernasPrincipales} ternas distintas`,
    })

    if (cantidadConcurrenciasExistentes > 0) msgWarningConcurrenciasExistentes = getMsgConcurrencias({
        cantidadConcurrencias: cantidadConcurrenciasExistentes,
        arrConcurrenciasAgrupadas: arrConcurrenciasExistentesAgrupadasPorTerna,
        generarMsgUnaTernaPrincipalConMenorCantidadConcurrencias: () => "Ya existen las siguientes concurrencias con la terna:",
        generarMsgUnaTernaPrincipalConMayorCantidadConcurrencias: (cantidad) => `No se agregaron ${cantidad} concurrencias para la terna, ya que se encontraban previamente creadas`,
        generarMsgVariasTernasPrincipales: (cantidad, cantidadTernasPrincipales) => `No se agregaron ${cantidad} concurrencias para ${cantidadTernasPrincipales} ternas distintas, ya que se encontraban previamente creadas`,
    })

    const msgMultipleIncumbencias = () => {
        const arrMsg = [];
        if (msgSuccessIncumbenciasAgregadas) arrMsg.push(success(msgSuccessIncumbenciasAgregadas))
        if (msgWarningIncumbenciasExistentes) arrMsg.push(warning(msgWarningIncumbenciasExistentes))
        if (msgSuccessConcurrenciasAgregadas) arrMsg.push(success(msgSuccessConcurrenciasAgregadas))
        if (msgWarningConcurrenciasExistentes) arrMsg.push(warning(msgWarningConcurrenciasExistentes))
        return arrMsg
    };
    store.dispatch(multiple(msgMultipleIncumbencias(), { scroll: false }));
}

/* 
 */
const armarPayloadMainTerna = (objData) => {

    const ternaSeleccionada = objData.ternaSeleccionada ?? [];
    const cargoSeleccionado = objData.cargoSeleccionado ?? [];
    const concurrenciasSeleccionadas = objData.concurrenciasSeleccionadas ?? [];
    // "terna_id": 1,
    // "cargos_incumbencias": [
    //     {
    //         "cargo_id": 1,
    //         "asignatura_id": 289,
    //         "especialidad_id": 12,
    //         "tipo_incumbencia_id": 1,
    //         "puntaje": 1.05,
    //         "suma_ot": true,
    //         "area_id": 1,
    //         "tipo_escuela_id": 214,
    //         "folio": 1
    //     },
    //  ],
    // "concurrencias": [
    //     {
    //         "terna2_id": 3,
    //         "terna3_id": 2,
    //         "cargo_id": 1,
    //         "asignatura_id": 289,
    //         "especialidad_id": 12,
    //         "tipo_incumbencia_id": 1,
    //         "puntaje": 1.05,
    //         "suma_ot": true,
    //         "area_id": 1,
    //         "tipo_escuela_id": 214,
    //         "folio": 1
    //     },
    //  ],

    const ternas = ternaSeleccionada.map(ctElemento => ctElemento.terna.id);
    const cargos_incumbencias = []

    cargoSeleccionado.forEach(currentItem => {
        const cargo_id = currentItem.cargo.id;

        const getParams = (ctPath) => ({
            asignatura_id: ctPath.asignatura?.id || null,
            especialidad_id: ctPath.especialidad?.id || null,
            tipo_incumbencia_id: ctPath.condicion,
            puntaje: ctPath.puntaje || null,
            suma_ot: ctPath.sumaOT,
            area_id: ctPath.area?.id || null,
            tipo_escuela_id: ctPath.tipoEscuela.id || null,
            folio: ctPath.folio,
            incumbencia_id: ctPath.id,
        })

        if (currentItem.type == "simple") {
            cargos_incumbencias.push({
                cargo_id,
                ...getParams(currentItem)
            })
        }
        if (currentItem.type == "multi") {
            currentItem.variantes.forEach(currentChild => {
                cargos_incumbencias.push({
                    cargo_id,
                    ...getParams(currentChild)
                })
            })
        }
    })

    const concurrencias = [];
    concurrenciasSeleccionadas.forEach(currentItem => {
        const terna2_id = currentItem.ternasSeleccionadas[0].id;
        const terna3_id = currentItem.ternasSeleccionadas[1]?.id ?? null;

        currentItem.cargosSeleccionados.forEach(ctCargo => {
            const cargo_id = ctCargo.cargo.id;

            const getParams = (ctPath) => ({
                especialidad_id: ctPath.especialidad?.id,
                asignatura_id: ctPath.asignatura.id,
                tipo_incumbencia_id: ctPath.condicion,
                puntaje: ctPath.puntaje ?? null,
                suma_ot: ctPath.sumaOT ?? null,
                area_id: ctPath.area.id,
                tipo_escuela_id: ctPath.tipoEscuela.id,
                folio: ctPath.folio,
            })

            if (ctCargo.type == "simple") {
                concurrencias.push({
                    terna2_id,
                    terna3_id,
                    cargo_id,
                    ...getParams(ctCargo)
                })
            }

            if (ctCargo.type == "multi") {
                ctCargo.variantes.forEach(ctVariante => {
                    concurrencias.push({
                        terna2_id,
                        terna3_id,
                        cargo_id,
                        ...getParams(ctVariante)
                    })
                })
            }
        })
    })

    return {
        apendice_id: objData.apendice_id,
        ternas,
        cargos_incumbencias,
        concurrencias,
    };
}

const armarPayloadMainCargo = (objData) => {
    // "cargos": {
    //     "0":{
    //         "cargo_id": 596,
    //         "asignatura_id": 42,
    //         "especialidad_id": 1,
    //         "area_id": 6,
    //         "tipo_escuela_id": 11,
    //     }
    // },
    // "incumbencias": {
    //     "0": {
    //         "terna_id": 6925,
    //         "tipo_incumbencia_id": 1,
    //         "puntaje": null,
    //         "suma_ot": false,
    //         "folio": 4
    //     }
    // },
    // "concurrencias": {
    //     "0": {
    //         "terna_id": 6925,
    //         "terna2_id": 6925,
    //         "terna3_id": null,
    //         "tipo_incumbencia_id": 1,
    //         "puntaje": null,
    //         "suma_ot": false,
    //         "folio": 4
    //     },
    // }

    const ternasSeleccionadas = objData.ternaSeleccionada ?? [];
    const cargoSeleccionado = objData.cargoSeleccionado ?? [];
    const concurrenciasSeleccionadas = objData.concurrenciasSeleccionadas ?? [];

    const cargos = cargoSeleccionado.map(ctElemento => {
        const cargo_id = ctElemento.cargo.id;
        let asignatura_id;
        let especialidad_id;
        let area_id;
        let tipo_escuela_id;

        if (ctElemento.type == "simple") {
            asignatura_id = ctElemento.asignatura?.id || null;
            especialidad_id = ctElemento.especialidad?.id;
            area_id = ctElemento.area?.id;
            tipo_escuela_id = ctElemento.tipoEscuela?.id;
        } else if (ctElemento.type == "multi") {
            const varianteSeleccionada = ctElemento.variantes[0]
            asignatura_id = varianteSeleccionada.asignatura?.id || null;
            especialidad_id = varianteSeleccionada.especialidad?.id;
            area_id = varianteSeleccionada.area?.id;
            tipo_escuela_id = varianteSeleccionada.tipoEscuela?.id;
        }
        return {
            area_id,
            cargo_id,
            especialidad_id,
            asignatura_id,
            tipo_escuela_id,
        }
    });

    const incumbencias = ternasSeleccionadas.map(currentTerna => {
        return {
            "terna_id": currentTerna.terna?.id,
            "tipo_incumbencia_id": currentTerna.condicion,
            "puntaje": currentTerna.puntaje,
            "suma_ot": currentTerna.sumaOT,
            "folio": currentTerna.folio,
            "incumbencia_id": currentTerna.id,
        }
    })

    const concurrencias = concurrenciasSeleccionadas.map(currentItem => {
        return {
            "terna_id": currentItem.ternasSeleccionadas[0].id,
            "terna2_id": currentItem.ternasSeleccionadas[1].id,
            "terna3_id": currentItem.ternasSeleccionadas[2]?.id,
            "tipo_incumbencia_id": currentItem.inputs.condicion,
            "puntaje": currentItem.inputs.puntaje,
            "suma_ot": currentItem.inputs.sumaOT,
            "folio": currentItem.inputs.folio,
        }
    })

    return {
        apendice_id: objData.apendice_id,
        cargos,
        incumbencias,
        concurrencias,
    };
}

const createEditIncumbencia = async (editPayload, resolveFunc, typeReuest, apiURL, returnAction) => {
    return await requestService.sendRequest(typeReuest, apiURL, editPayload)
        .then(resp => {
            const respFormated = adaptCreatEditIncumbencia(resp.data);
            if (returnAction) returnAction(respFormated);
            if (resolveFunc) resolveFunc(respFormated);
            return respFormated
        }).catch(e => {
            console.log(e)
        })
}

const createNewIncumbencia = async (infoIncumbencia, mainElement, returnAction, params = {}) => {
    const isTernaMainElement = mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA;
    const createNewIncumbenciaApiUrl = apiURLs.API_MAESTROS_INCUMBENCIA + (isTernaMainElement ? "/crearIncumbencia" : "/crearIncumbencia/byCargo");
    const armarPayload = isTernaMainElement ? armarPayloadMainTerna : armarPayloadMainCargo;
    const showMessageFunction = isTernaMainElement ? showResolveMessageMainTerna : showResolveMessageMainCargo;
    const incumbencia = armarPayload(infoIncumbencia);
    return await createEditIncumbencia({ ...incumbencia, ...params }, showMessageFunction, 'POST', createNewIncumbenciaApiUrl, returnAction);
}

const getArrIdsDeletedIncumbencias = (deletedElements = []) => {
    const deletedIncumbecias = [];
    deletedElements.forEach(currentElement => {
        if (currentElement.id) {
            deletedIncumbecias.push(currentElement.id)
        } else {
            currentElement.variantes.forEach(currentVariante => deletedIncumbecias.push(currentVariante.id))
        }
    })
    return deletedIncumbecias;
}

const getArrIdsDeletedConcurrencias = (deletedElements = []) => {
    const deletedConcurrencias = [];
    deletedElements.forEach(currentElement => {
        if (currentElement.id) return deletedConcurrencias.push(currentElement.incumbencia_id)
        if (currentElement?.inputs?.id) return deletedConcurrencias.push(currentElement.inputs.id)
        currentElement.cargosSeleccionados.forEach(currentCargo => {
            return currentCargo.variantes.forEach(currentVariante => deletedConcurrencias.push(currentVariante.incumbencia_id))
        })
    })
    return deletedConcurrencias;
}

const getModifiedIncumbenciasMainTerna = (newIncumbencias, originalIncumbencias) => {
    const atributosModificables = ["condicion", "folio", "puntaje", "sumaOT", "apendice_id"]
    const allMods = []
    console.log("getModifiedIncumbenciasMainTerna", { newIncumbencias, originalIncumbencias })
    const getCurrentMods = (originalObj, currentObj) => {
        console.log("getCurrentMods", { originalObj, currentObj })
        let currentMods = {};
        atributosModificables.forEach(ctAtributo => {
            if (originalObj[ctAtributo] != currentObj[ctAtributo]) {
                console.log("ACA2!", { ctAtributo, currentObj, originalObj })
                currentMods = { ...currentMods, [ctAtributo]: currentObj[ctAtributo] }
            }
        })

        if (Object.keys(currentMods).length > 0) {
            const formatModsToFetch = (currentMods) => {
                if (currentMods["condicion"]) {
                    currentMods["tipo_incumbencia_id"] = currentMods["condicion"];
                    delete currentMods["condicion"];
                }
                if (currentMods["sumaOT"] === false || currentMods["sumaOT"] === true) {
                    currentMods["suma_ot"] = currentMods["sumaOT"];
                    delete currentMods["sumaOT"];
                }
                return currentMods
            }
            return formatModsToFetch(currentMods);
        }
    }

    newIncumbencias.forEach(ctCargo => {
        const originalCargo = originalIncumbencias.find(ctOrgCargo => ctOrgCargo.cargo.id == ctCargo.cargo.id)
        if (originalCargo) {
            if (ctCargo.type == "multi") {
                ctCargo.variantes.forEach(ctVariante => {
                    const originalVariante = originalCargo.variantes.find(ctOrgVariante => ctOrgVariante.id == ctVariante.id)
                    const currentMods = getCurrentMods(originalVariante, ctVariante)
                    if (currentMods) allMods.push({ incumbencia_id: ctVariante.id, ...currentMods });
                })
            } else {
                const currentMods = getCurrentMods(originalCargo, ctCargo)
                if (currentMods) allMods.push({ incumbencia_id: ctCargo.id, ...currentMods });
            }
        }
    })
    return allMods
}

const getModifiedIncumbenciasMainCargo = (newIncumbencias, originalIncumbencias) => {
    const atributosModificables = ["condicion", "folio", "puntaje", "sumaOT", "apendice_id"]
    const allMods = []

    newIncumbencias.forEach(ctIncumbencia => {
        const orgIncumbencia = originalIncumbencias.find(ctOrgIncumbencia => ctOrgIncumbencia.id == ctIncumbencia.id)
        if (orgIncumbencia) {
            let currentMods = {};
            atributosModificables.forEach(ctAtributo => {
                if (orgIncumbencia[ctAtributo] != ctIncumbencia[ctAtributo]) {
                    currentMods = { ...currentMods, [ctAtributo]: ctIncumbencia[ctAtributo] }
                }
            })
            if (Object.keys(currentMods).length > 0) {
                const formatModsToFetch = (currentMods) => {
                    if (currentMods["condicion"]) {
                        currentMods["tipo_incumbencia_id"] = currentMods["condicion"];
                        delete currentMods["condicion"];
                    }
                    if (currentMods["sumaOT"] === false || currentMods["sumaOT"] === true) {
                        currentMods["suma_ot"] = currentMods["sumaOT"];
                        delete currentMods["sumaOT"];
                    }
                    return currentMods
                }
                const modsFormatedToFetch = formatModsToFetch(currentMods);
                allMods.push({ incumbencia_id: ctIncumbencia.id, ...modsFormatedToFetch });
            }
        }
    })
    return allMods
}

const getModifiedConcurrenciasMainTerna = (newConcurrencias, originalConcurrencias) => {
    const atributosModificables = ["condicion", "folio", "puntaje", "sumaOT", "apendice_id"]
    const allMods = []
    newConcurrencias.forEach(ctConcurrencia => {
        const originalConcurrencia = originalConcurrencias.find(ctOrgConcurrencia => {
            return ctConcurrencia.combinacionTernas.terna_id == ctOrgConcurrencia.combinacionTernas.terna_id &&
                ctConcurrencia.combinacionTernas.terna2_id == ctOrgConcurrencia.combinacionTernas.terna2_id &&
                ctConcurrencia.combinacionTernas.terna3_id == ctOrgConcurrencia.combinacionTernas.terna3_id
        })

        if (originalConcurrencia) {
            ctConcurrencia.cargosSeleccionados.forEach(ctCargo => {
                const originalCargo = originalConcurrencia.cargosSeleccionados.find(ctOrgCargo => ctOrgCargo.cargo.id == ctCargo.cargo.id)
                if (ctCargo.type == "multi") {
                    ctCargo.variantes.forEach(ctVariante => {
                        let currentMods = {};
                        const originalVariante = originalCargo.variantes.find(ctOrgVariante => ctOrgVariante.id == ctVariante.id)
                        atributosModificables.forEach(ctAtributo => {
                            if (originalVariante[ctAtributo] != ctVariante[ctAtributo]) {
                                currentMods = { ...currentMods, [ctAtributo]: ctVariante[ctAtributo] }
                            }
                        })
                        if (Object.keys(currentMods).length > 0) {
                            const formatModsToFetch = (currentMods) => {
                                if (currentMods["condicion"]) {
                                    currentMods["tipo_incumbencia_id"] = currentMods["condicion"];
                                    delete currentMods["condicion"];
                                }
                                if (currentMods["sumaOT"] === false || currentMods["sumaOT"] === true) {
                                    currentMods["suma_ot"] = currentMods["sumaOT"];
                                    delete currentMods["sumaOT"];
                                }
                                return currentMods
                            }
                            const modsFormatedToFetch = formatModsToFetch(currentMods);
                            allMods.push({ incumbencia_id: ctVariante.id, ...modsFormatedToFetch });
                        }
                    })
                } else {
                    console.log("TODO: ES UN CARGO SIMPLE (CONCURRENCIA)")
                }
            })
        }
    })
    return allMods
}

const getModifiedConcurrenciasMainCargo = (newConcurrencias, originalConcurrencias) => {
    const atributosModificables = ["condicion", "folio", "puntaje", "sumaOT", "apendice_id"]
    const allMods = []

    newConcurrencias.forEach(ctNwConcurrencia => {
        const orgConcurrencia = originalConcurrencias.find(ctOrgConcurrencia => {
            return ctNwConcurrencia.inputs.id == ctOrgConcurrencia.inputs.id
        })
        let currentMods = {};
        atributosModificables.forEach(ctAtributo => {
            if (orgConcurrencia.inputs[ctAtributo] != ctNwConcurrencia.inputs[ctAtributo]) {
                currentMods = { ...currentMods, [ctAtributo]: ctNwConcurrencia.inputs[ctAtributo] }
            }
        })

        if (Object.keys(currentMods).length > 0) {
            const formatModsToFetch = (currentMods) => {
                if (currentMods["condicion"]) {
                    currentMods["tipo_incumbencia_id"] = currentMods["condicion"];
                    delete currentMods["condicion"];
                }
                if (currentMods["sumaOT"] === false || currentMods["sumaOT"] === true) {
                    currentMods["suma_ot"] = currentMods["sumaOT"];
                    delete currentMods["sumaOT"];
                }
                return currentMods
            }
            const modsFormatedToFetch = formatModsToFetch(currentMods);
            // const incumbencia_id = JSON.stringify(ctNwConcurrencia.inputs.id)
            const incumbencia_id = ctNwConcurrencia.inputs.id;
            allMods.push({ incumbencia_id, ...modsFormatedToFetch });
        }
    })
    return allMods
}

const editIncumbencia = async (
    originalIncumbencias,
    newIncumbencias,
    deletedElements,
    originalConcurrenciasSeleccionadas,
    concurrenciasSeleccionadas,
    deletedConcurrencias,
    mainElement,
    resolveAction,
    params = {},
) => {
    let arrDeletedIncumbenciasIds = deletedElements ? getArrIdsDeletedIncumbencias(deletedElements) : [];
    let arrDeletedConcurrenciasIds = deletedConcurrencias ? getArrIdsDeletedConcurrencias(deletedConcurrencias) : [];

    let arrModifiedIncumbencias = [];
    let arrModifiedConcurrencias = [];
    let mainElementData = {};

    if (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) {
        arrModifiedConcurrencias = getModifiedConcurrenciasMainTerna(concurrenciasSeleccionadas, originalConcurrenciasSeleccionadas);
        arrModifiedIncumbencias = getModifiedIncumbenciasMainTerna(newIncumbencias.cargoSelected, originalIncumbencias.cargoSelected);
        mainElementData = { terna_id: originalIncumbencias.ternaSelected[0].terna.id }
    } else {
        console.log({
            originalIncumbencias,
            newIncumbencias,
            deletedElements,
            originalConcurrenciasSeleccionadas,
            concurrenciasSeleccionadas,
            deletedConcurrencias,
            mainElement,
            resolveAction
        })
        const rootPath = originalIncumbencias.cargoSelected[0];
        const dataPath = rootPath.type == "simple" ? rootPath : rootPath.variantes[0]
        mainElementData = {
            cargo_id: rootPath.cargo.id,
            asignatura_id: dataPath.asignatura.id,
            especialidad_id: dataPath.especialidad.id,
            area_id: dataPath.area.id,
            tipo_escuela: dataPath.tipoEscuela.id,
        }
        arrModifiedConcurrencias = getModifiedConcurrenciasMainCargo(concurrenciasSeleccionadas, originalConcurrenciasSeleccionadas);
        arrModifiedIncumbencias = getModifiedIncumbenciasMainCargo(newIncumbencias.ternaSelected, originalIncumbencias.ternaSelected);
    }

    const modified_incumbencias = [...arrModifiedIncumbencias, ...arrModifiedConcurrencias]
    const deleted_incumbencias = [...arrDeletedIncumbenciasIds, ...arrDeletedConcurrenciasIds]

    const editPayload = {
        ...params,
        ...mainElementData,
        modified_incumbencias,
        deleted_incumbencias,
    }

    // if (modified_incumbencias.length == 0 && deleted_incumbencias.length == 0) {
    //     resolveAction();
    //     console.log("NO SE MODIFICO NADA")
    //     return true;
    // }

    return await createEditIncumbencia(editPayload, resolveAction, 'PATCH', apiURLs.API_MAESTROS_INCUMBENCIA);
}


const changeState = async (incumbencia, newState) => {
    await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_INCUMBENCIA + `/${incumbencia.id}/estado`, { estado: newState });
}

export const activateIncumbencia = async (incumbencia, { resolve, withMessage }) => {
    await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_INCUMBENCIA + `/${incumbencia.id}/switchAltaBajaIncumbencia`, { activo: true });
    if (resolve) resolve();
    if (withMessage) store.dispatch(success(<p>Se activo la incumbencia {/* <b>{incumbencia.nombreInvertido}</b> */} correctamente.</p>))
}

export const deactivateIncumbencia = async (incumbencia, { resolve, withMessage, params }) => {
    await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_INCUMBENCIA + `/${incumbencia.id}/switchAltaBajaIncumbencia`, { ...params, activo: false });
    if (resolve) resolve();
    if (withMessage) store.dispatch(success(<p>Se desactivo la incumbencia {/* <b>{incumbencia.nombreInvertido}</b> */} correctamente.</p>))
}

export const approvalIncumbencia = async (incumbencia, { resolve, withMessage }) => {
    await changeState(incumbencia, estadosConstans.APROBADO);
    if (resolve) resolve();
    if (withMessage) await store.dispatch(success(<p>Se aprobo la incumbencia correctamente.</p>));
}

export const publishIncumbencia = async (incumbencia, { resolve, withMessage }) => {
    await changeState(incumbencia, estadosConstans.PUBLICADO)
    if (resolve) resolve();
    if (withMessage) await store.dispatch(success(<p>Se publico la incumbencia correctamente.</p>));
}

export const deleteIncumbencia = async (incumbencia, { resolve, withMessage }) => {
    await requestService.sendRequest('DELETE', apiURLs.API_MAESTROS_INCUMBENCIA + `/${incumbencia.id}`)
    if (resolve) resolve();
    await store.dispatch(success(<p>Se elimino la incumbencia correctamente.</p>));
}


export default {
    editIncumbencia,
    getIncumbencias,
    createNewIncumbencia,
}