import PrivateComponent from "../../authentication/PrivateComponent";
import ApendiceDetailModal from "../details/ApendiceDetailModal";
import { activate, deactivate } from "../EscuelaService";
import DialogActivate from "../../ui/DialogActivate";
import React, { useState } from "react";
import { faPen, faTrash, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { routePaths, tooltipsText } from "../../utils/Constants";
import IconButton from "../../generics/IconButton";
import { useDispatch } from "react-redux";
import { error } from "../../store/alerts/alertActions";
import { Redirect } from "react-router-dom";
import Dialog from "../../generics/dialog";
import { CustomTooltip } from "../../ui/CustomTooltip";
import { deleteEscuela } from "../EscuelaService";

const ApendiceSearchRowActions = ({ apendice, ...props }) => {
    const [redirect, setRedirect] = useState("");

    const dispatch = useDispatch();

    const redirectToEdit = (apendice) => {
        setRedirect(routePaths.ABM_APENDICE_EDIT);
    };

    const redirectToApproval = (apendice) => {
        setRedirect(routePaths.ABM_ESCUELA_APPROVAL);
    };

    const changeState = async (apendice) => {
        if (apendice.activo) {
            deactivate(apendice, props.onSubmit).catch((e) => {
                dispatch(error(e.response.data.message));
            });
        } else {
            activate(apendice, props.onSubmit).catch((e) => {
                dispatch(error(e.response.data.message));
            });
        }
    };

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        escuela: apendice,
                        abmTitle: "Editar",
                        submitValue: "Aceptar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <>

            {/* {!apendice.acciones.eliminar &&
                <CustomTooltip text={apendice.activo ? tooltipsText.BAJA : tooltipsText.ALTA}>
                    <DialogActivate
                        target={apendice}
                        title={(apendice.activo ? "Dar de baja" : "Dar de alta")}
                        action={changeState}
                        size="md"
                        body={
                        <span>
                            ¿Estás seguro de dar de {(apendice.activo ? "baja " : "alta ")}
                        el apendice
                        <b> {apendice ? apendice.nombre : ""}</b>
                        ?
                        </span>
                        }

                        btnConfirmText={apendice.activo ? "Dar de baja" : "Dar de alta"}
                    >
                    </DialogActivate>
                </CustomTooltip>
            } */}
            {apendice.acciones.eliminar &&
                <PrivateComponent>
                    <CustomTooltip text={tooltipsText.ELIMINAR}>
                        <Dialog
                            action={deleteEscuela}
                            target={apendice}
                            title={"Eliminar Apendice"}
                            body={<span>
                                ¿Estás seguro que quiere eliminar el apendice
                <b> {apendice ? apendice.nombre : ""}</b>
            ?
                </span>
                            }
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                        />
                    </CustomTooltip>
                </PrivateComponent>
            }



            {/* EspinTodo: No necesita confirmacino aun. */}
            {/* {apendice.acciones.aprobar &&
                <PrivateComponent>
                    <CustomTooltip text={tooltipsText.APROBAR}>
                        <IconButton
                            icon={faCheckDouble}
                            onClick={() => redirectToApproval(apendice)}
                        />
                    </CustomTooltip>
                </PrivateComponent>
            } */}



            {apendice.acciones.publicar &&
                <CustomTooltip text={tooltipsText.PUBLICAR}>
                    <ApendiceDetailModal
                        apendice={apendice}
                        withPublicar={true}
                        onSubmit={props.onSubmit}
                    ></ApendiceDetailModal>
                </CustomTooltip>
            }
            {apendice.acciones.editar &&
                <PrivateComponent>
                    <CustomTooltip text={tooltipsText.EDITAR}>
                        <IconButton icon={faPen} onClick={() => redirectToEdit(apendice)} />
                    </CustomTooltip>
                </PrivateComponent>
            }

            <CustomTooltip text={tooltipsText.DETALLE}>
                <ApendiceDetailModal apendice={apendice}></ApendiceDetailModal>
            </CustomTooltip>



        </>
    );
};

export default ApendiceSearchRowActions;
