class Estado{
    constructor(){
        this.id = "";
        this.nombre = "";
        this.color = "";
    }
}

export default Estado

export const createEstado = (props) => {
    return Object.assign(new Estado(), {...props})
}
