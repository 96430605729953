import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col } from 'react-bootstrap'
import CuadroSeleccion from '../../../titulos/shared/CuadroSeleccion'
import { titulosIcon } from '../../../utils/menuDashboardOptions'
import { ClonadorTernaRow } from './ClonadorTernaRow'
import { ClonadorTernaTable } from './ClonadorTernaTable'

const renderTableSelectTernaOriginal = (elementos) => {
    return <ClonadorTernaTable>
        {elementos.map((ctTerna, index) => <React.Fragment key={"ClonadorTernaRowOrigen-" + index}>
            <ClonadorTernaRow
                elemento={ctTerna}
            />
        </React.Fragment>)}
    </ClonadorTernaTable>
}


const renderTableSelectTernaDestino = (elementos) => {
    return <ClonadorTernaTable>
        {elementos.map((ctTerna, index) => <React.Fragment key={"ClonadorTernaRowDestino-" + index}>
            <ClonadorTernaRow elemento={ctTerna} />
        </React.Fragment>)}
    </ClonadorTernaTable>
}

export const ClonadorTernasCards = ({
    original,
    destino,
    onClickSeleccionarOriginal,
    onClickSeleccionarDestino,
    invertirTernas,
    validated,
    getSelectCondicionComponent,
}) => {

    return (
        <div class="container">
            <Col>
                <div className="mb-3 position-relative" style={{ zIndex: 9 }}>
                    <CuadroSeleccion
                        title={"Terna original"}
                        secondButtonText={"Cambiar"}
                        icon={titulosIcon}
                        text={"Parece que todavía no hay una terna seleccionada"}
                        onClick={onClickSeleccionarOriginal}
                        validated={validated}
                        elementos={original}
                        renderTable={(elementos) => renderTableSelectTernaOriginal(elementos)}
                        ocultarBuscador={false}
                        firstButtonText={"Buscar"}
                        getSecondAdditionalContent={() => getSelectCondicionComponent({
                            componentLabel: "Condicion anterior",
                            componentKey: "condicion_origen",
                            checkRequiredKey: "condicion_destino",
                        })}
                    />
                    {(original.length > 0 || destino.length > 0) &&
                        <div style={{ position: "absolute", right: "1.25rem", bottom: "-0.5rem", transform: "translateY(50%)" }}>
                            <Button
                                onClick={(invertirTernas)}
                                type="submit"
                                variant={"primary"}
                            >
                                <FontAwesomeIcon icon={faArrowsAltV} /> Invetir Ternas
                            </Button>
                        </div>
                    }
                </div>

                <CuadroSeleccion
                    title={"Terna destino"}
                    secondButtonText={"Cambiar"}
                    icon={titulosIcon}
                    text={"Parece que todavía no hay una terna seleccionada"}
                    onClick={onClickSeleccionarDestino}
                    validated={validated}
                    elementos={destino}
                    renderTable={(elementos) => renderTableSelectTernaDestino(elementos)}
                    ocultarBuscador={false}
                    firstButtonText={"Buscar"}
                    getSecondAdditionalContent={() => getSelectCondicionComponent({
                        componentLabel: "Condicion nueva",
                        componentKey: "condicion_destino",
                        checkRequiredKey: "condicion_origen",
                        componentPuntajeKey: "puntaje_ot_destino",
                        withPuntaje: true,
                    })}
                />
            </Col>
        </div>
    )
}
