import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { clear } from "../../store/alerts/alertActions";
import { saveFilters, clearFilters } from "../../store/search/searchActions";
import TitulosFormSearch from "./TitulosFormSearch";
import { routePaths } from "../../utils/Constants";
import TituloSearchTable from "./TituloSearchTable";
import { Loader } from "../../ui/Loader";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";

const TitulosSearch = ({
  title,
  getTitulos,
  controlIds,
  renderRow,
  renderTable,
  nombreElementoResponse,
  conCrear = true,
  masInputs,
  conGenerarReporte,
  handleChangeSeleccionar,
  urlSeleccionar,
  handleSeleccionar,
  handleVolver,
  urlCreate,
  returnCallBack,
  hidePlusFAB = false,
  defaultInputsForm,
  hideChecks,
  cleanFiltersOnReturn = true,
  defaultFiltros = {},
}) => {
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [titulos, setTitulos] = useState(null);
  const [cantidadTitulos, setCantidadTitulos] = useState(0);

  const dispatch = useDispatch();

  const filtrosGuardados = useSelector((state) => state.search);

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0 && conCrear) {
      await handleSearch(filtrosGuardados);
      setPage(filtrosGuardados.page);
    }
  }

  const handleSearch = async (filtros = {}) => {
    try {
      setSearching(true);
      let filtrosLocal = { all: true, ...defaultFiltros, ...filtros };
      setPage(1);       //Reset page
      const response = await getTitulos(filtrosLocal);
      setTitulos(response[nombreElementoResponse]);
      setCantidadTitulos(response.cantidad);
    } catch (err) {
      console.log(err);
    } finally {
      setSearching(false);
    }
  };

  const handleChangePage = (event, value) => {
    filtrosGuardados["page"] = value;
    handleSearch(filtrosGuardados);
    setPage(value);
  };

  useEffect(() => {
    dispatch(clear());
    buscarSiHayFiltros();
    dispatch(clearFilters())
    return () => { if (cleanFiltersOnReturn) dispatch(clearFilters()) }
  }, [dispatch]);

  return (
    <ScreenWithFabButtons
      returnCallBack={returnCallBack}
      plusPath={urlCreate}
      hidePlusButton={hidePlusFAB}
      hideSearchButton={false}
      hideCleanButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !titulos}
    >
      <h1>{title}</h1>

      <Row>
        <Col>
          <TitulosFormSearch
            setTitulos={setTitulos}
            getTitulos={getTitulos}
            controlIds={controlIds}
            onSubmit={handleSearch}
            filtros={filtrosGuardados}
            masInputs={masInputs}
            defaultInputsForm={defaultInputsForm}
            hideChecks={hideChecks}
          ></TitulosFormSearch>
        </Col>
      </Row>
      <Row>
        <Col>
          {titulos && !searching ? (
            <TituloSearchTable
              titulos={titulos}
              cantidadTitulos={cantidadTitulos}
              changePage={handleChangePage}
              renderRow={renderRow}
              renderTable={renderTable}
              page={page}
              conGenerarReporte={false}
              handleChangeSeleccionar={handleChangeSeleccionar}
              urlSeleccionar={urlSeleccionar}
              filtros={filtrosGuardados}
              handleSeleccionar={handleSeleccionar}
              handleVolver={handleVolver}
              handleSearch={handleSearch}
            ></TituloSearchTable>
          ) : (
            searching && <Loader></Loader>
          )}
        </Col>
      </Row >
    </ScreenWithFabButtons >
  );
};

export default TitulosSearch;
