import React, { useState } from 'react'
import IconButton from '../../generics/IconButton'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { DetailFact } from '../../ui/DetailFact';
import { DetailNumber } from '../../ui/DetailNumber';


export const DetailModalByAreaCargo = ({ incumbencia }) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            <IconButton icon={faSearch} onClick={handleShow} />
            <Modal
                show={show}
                onHide={!isLoading ? handleClose : null}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={isLoading}
                backdrop={isLoading ? 'static' : true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="font-weight-light">Detalle:</span> {incumbencia.terna.titulo.nombre} | {incumbencia.terna.procedencia.nombre} | {incumbencia.terna.resolucion.nombre}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mx-lg-2">
                        <Col md={6}>
                            <Badge variant={incumbencia.estado.color}>{incumbencia.estado.nombre}</Badge>
                            <small>Creado el: <b>{incumbencia.createdAt}</b></small>
                        </Col>
                        <Col md={6}>
                            <p>IOL: SI</p>
                        </Col>
                        <Col md={6} className="form-group font-weight-bold">
                            {incumbencia.Municipal}
                        </Col>
                    </Row>
                    <hr className="mt-3" />
                    <Row className="mx-lg-2">
                        <Col md={4} className="form-group">
                            <DetailNumber label="Apendice" input={incumbencia.apendice.nombre} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailNumber label="Folio" input={incumbencia.folio} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailNumber label="Código DAD" input={incumbencia.cargo.codDad} />
                        </Col>
                        <Col md={6} className="form-group">
                            <DetailFact label="Área" input={incumbencia.area.nombre} />
                        </Col>
                        <Col md={6} className="form-group">
                            <DetailFact label="Nombre" input={incumbencia.cargo.nombre} />
                        </Col>
                        <Col md={6} className="form-group">
                            <DetailFact label="Asignatura" input={incumbencia.asignatura.nombre ?? "SIN ASIGNATURA"} />
                        </Col>
                        <Col md={6} className="form-group">
                            <DetailFact label="Especialidad" input={incumbencia.especialidad.nombre} />
                        </Col>
                    </Row>

                    <hr className="mt-3" />
                    <Row className="mx-lg-2">
                        <Col md={4} className="form-group">
                            <DetailFact label="Titulo" input={incumbencia.terna.titulo.nombre} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailFact label="Procedencia" input={incumbencia.terna.procedencia.nombre} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailFact label="Resolucion" input={incumbencia.terna.resolucion.nombre} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailFact label="Puntaje" input={incumbencia.puntaje} />
                        </Col>
                        <Col md={4} className="form-group">
                            <DetailFact label="Calidad" input={incumbencia.calidad} />
                        </Col>
                    </Row>
                    <hr className="mt-3" />

                    {/* <Row className="mx-lg-2">
                        <p className="px-3">
                            "En caso de poseer el título de Bachiller universitario en ciencias juridicas
                            y sociales, de la misma procedencia no debera valorar en el rubro Otros Titulos"
                        </p>
                    </Row> */}

                    <hr className="mt-3" />
                    <Row className="mx-lg-2">
                        <Col md={12} className="form-group">
                            <label>Escuela: </label> {incumbencia.tipoEscuela.nombre}
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="secondary" onClick={handleClose}>
                            Volver
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
