import React, { useState, useEffect } from "react";
import { getEspecialidades } from "../EspecialidadesService";
import { Row, Col } from "react-bootstrap";
import { Loader } from "../../ui/Loader";
import EspecialidadesFormSearch from "./EspecialidadesFormSearch";
import EspecialidadesSearchTable from "./EspecialidadesSearchTable";
import { useDispatch, useSelector } from "react-redux";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { saveFilters } from "../../store/search/searchActions";
import { clear, error } from "../../store/alerts/alertActions";

const EspecialidadesSearch = (props) => {
  const [especialidades, setEspecialidades] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(null);
  const [cantidadEspecialidades, setCantidadEspecialidades] = useState(null);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      // El aprobar borra data para forzar refresh de data con mismos filtros guardados
      if (!filtrosGuardados.data) {
        return await searchEspecialidades(filtrosGuardados)
      }

      setPage(filtrosGuardados.page);
      setEspecialidades(filtrosGuardados.data);
      setCantidadEspecialidades(filtrosGuardados.cantidad);
    }
  }

  const handleSearch = async (filters) => {
    searchEspecialidades(filters);
  };

  const searchEspecialidades = async (data = {}) => {
    try {
      const filtros = data.inputsForm;
      const filtrosComunes = { all: true }

      setSearching(true);
      const response = await getEspecialidades({ ...filtros, ...filtrosComunes })
      setPage(response.page);
      setEspecialidades(response.data);
      setCantidadEspecialidades(response.cantidad)
      const newData = { ...data, ...response, inputsForm: { ...data.inputsForm, page: response.page } }
      dispatch(saveFilters(newData));
    }
    catch (err) {
      dispatch(error(err.response.data.message));
    }
    finally {
      setSearching(false);
    }
  };

  const handleChangePage = (event, value) => {
    dispatch(clear());
    searchEspecialidades({ ...filtrosGuardados, inputsForm: { ...filtrosGuardados.inputsForm, page: value } });
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
  }, []);

  return (
    <ScreenWithFabButtons
      hidePlusButton={false}
      hideSearchButton={false}
      hideCleanButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !especialidades}
    >
      <Row>
        <Col>
          <h1>Especialidades</h1>
          <EspecialidadesFormSearch
            onSubmit={handleSearch}
            setEspecialidades={setEspecialidades}
          ></EspecialidadesFormSearch>

          {especialidades && !searching ? (
            <EspecialidadesSearchTable
              especialidades={especialidades}
              cantidadEspecialidades={cantidadEspecialidades}
              changePage={handleChangePage}
              page={page}
            ></EspecialidadesSearchTable>
          ) : (
            searching && <Loader></Loader>
          )}
        </Col>
      </Row>
    </ScreenWithFabButtons >
  );
};

export default EspecialidadesSearch;
