import React from "react";
import { Table, Row, Col, Alert, Button } from "react-bootstrap";
import ApendiceSearchRow from "./ApendiceSearchRow";
import Paginacion from "../../generics/Paginacion";

const ApendiceSearchTable = ({ apendices, cantidadApendices, page, changePage, ...props }) => {

  const renderTableRows = (apendices) => {
    return apendices.map((apendice) => {
      return (
        <ApendiceSearchRow
          key={apendice.id}
          onSubmit={props.onSubmit}
          apendice={apendice}
        ></ApendiceSearchRow>
      );
    });
  };

  return (
    <>
      {!apendices[0] ? (
        <Row className="form-group">
          <Col>
            <Alert variant="info">No se encontraron apendices.</Alert>
          </Col>
        </Row>
      ) : (
        !!apendices && (
          <>
            <Row className="form-group">
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Nº de Apendice</th>
                      <th>Nombre</th>
                      <th>Fecha inicio</th>
                      <th>Fecha finalizacion</th>
                      <th>Habilitado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows(apendices)}</tbody>
                </Table>
              </Col>
            </Row>
            <Paginacion totalItems={cantidadApendices} currentPage={page} onChangePage={changePage} />
          </>
        )
      )}
    </>
  );
};

export default ApendiceSearchTable;