import React, { useState, useEffect } from "react";
import { getAsignaturas } from "../AsignaturaService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Loader } from "../../ui/Loader";
import AsignaturaFormSearch from "./AsignaturaFormSearch";
import AsignaturaSearchTable from "./AsignaturaSearchTable";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { saveFilters } from "../../store/search/searchActions";
import { clear, error } from "../../store/alerts/alertActions";

const AsignaturasSearch = ({
  title,
  returnCallBack,
  visibleCheckbox,
  handleClickSeleccionar,
  defaultInputsForm,
  hideChecks,
}) => {
  const [asignaturas, setAsignaturas] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [cantidadAsignaturas, setCantidadAsignaturas] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const filtrosGuardados = useSelector((state) => state.search);

  const buscarSiHayFiltros = async () => {
    if (Object.keys(filtrosGuardados).length !== 0) {
      // El aprobar borra elementos para forzar refresh de elementos con mismos filtros guardados
      if (!filtrosGuardados.elementos) {
        return await searchAsignaturas(filtrosGuardados)
      }

      setPage(filtrosGuardados.page);
      setAsignaturas(filtrosGuardados.elementos);
      setCantidadAsignaturas(filtrosGuardados.cantidad);
    }
  }

  const handleSearch = async (data) => {
    searchAsignaturas(data);
  };

  const searchAsignaturas = async (data = {}) => {
    try {
      const filtros = data.inputsForm;
      const filtrosComunes = { all: true }

      setSearching(true);
      const response = await getAsignaturas({ ...filtros, ...filtrosComunes })
      setPage(response.page);
      setAsignaturas(response.elementos);
      setCantidadAsignaturas(response.cantidad);
      const newData = { ...data, ...response, inputsForm: { ...data.inputsForm, page: response.page } }
      dispatch(saveFilters(newData));
    }
    catch (err) {
      dispatch(error(err.response.data.message));
    }
    finally {
      setSearching(false);
    }
  };

  const handleChangePage = (event, value) => {
    dispatch(clear());
    searchAsignaturas({ ...filtrosGuardados, inputsForm: { ...filtrosGuardados.inputsForm, page: value } });
    setPage(value);
  };

  useEffect(() => {
    buscarSiHayFiltros();
  }, []);

  return (
    <ScreenWithFabButtons
      returnCallBack={returnCallBack}
      returnPath={location.state?.returnPath}
      hideSearchButton={false}
      hideCleanButton={false}
      hidePlusButton={false}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching || !asignaturas}
    >
      <h1>{title ? title : "Asignaturas"}</h1>

      <AsignaturaFormSearch
        setAsignaturas={setAsignaturas}
        onSubmit={handleSearch}
        defaultInputsForm={defaultInputsForm}
        hideChecks={hideChecks}
      ></AsignaturaFormSearch>

      {asignaturas && !searching ? (
        <AsignaturaSearchTable
          asignaturas={asignaturas}
          cantidadAsignaturas={cantidadAsignaturas}
          changePage={handleChangePage}
          page={page}
          visibleCheckbox={visibleCheckbox}
          handleClickSeleccionar={handleClickSeleccionar}
        ></AsignaturaSearchTable>)
        : (
          searching && <Loader></Loader>
        )}

    </ScreenWithFabButtons>

  );
};

export default AsignaturasSearch;
