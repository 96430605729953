import { descargasTypes } from "../../actions/types"
import { ESTADO_DESCARGA } from "../../descargas/DescargasPanel"

const initialState = {
    contadorDescargas: 0,
    colaDescargas: [],
    arrActivas: [],
    arrCanceladas: [],
    arrCompletadas: [],
    arrEliminadas: [],
    showAlert: false
}



const descargasReducer = (state = initialState, action) => {
    const { type, payload } = action
    // arrDescargas: [{title, opcion, filtros, descripcion, fileName}]
    // id, showDescripcion

    const enQueArrEstaLaDescarga = (descarga) => {
        const buscarEn = [
            "arrActivas",
            "arrCompletadas",
            "arrCanceladas",
            "arrEliminadas",
        ]

        return buscarEn.find(ctBusqueda => {
            return state[ctBusqueda].some(ctElemento => ctElemento.id == descarga.id)
        })
    }

    const moverArrDescargasDesdeHasta = (keyDesde, keyHasta) => {
        const arrDescargas = payload.arrDescargas
        
        const arrHasta = [...state[keyHasta], ...arrDescargas];
        const arrDesde = state[keyDesde].filter(ctDescarga => !arrDescargas.find(ctOriginal => ctOriginal.id == ctDescarga.id));
        return { arrHasta, arrDesde }
    }

    switch (type) {
        case descargasTypes.AGREGAR_DESCARGAS: {
            let contadorActual = state.contadorDescargas;
            const arrDescargasWithIds = payload.arrDescargas.map(ctDescarga => {
                const ctDescargaWithId = {
                    ...ctDescarga,
                    id: contadorActual + 1,
                    showDescripcion: false,
                }
                contadorActual = contadorActual + 1
                return ctDescargaWithId
            })
            return { ...state, colaDescargas: [...state.colaDescargas, ...arrDescargasWithIds], contadorDescargas: contadorActual };
        }

        case descargasTypes.INICIAR_DESCARGAS: {
            const { arrDesde, arrHasta } = moverArrDescargasDesdeHasta("colaDescargas", "arrActivas")
            return {
                ...state,
                colaDescargas: arrDesde,
                arrActivas: arrHasta,
            }
        }

        case descargasTypes.COMPLETAR_DESCARGAS: {
            const { arrDesde, arrHasta } = moverArrDescargasDesdeHasta("arrActivas", "arrCompletadas")

            return {
                ...state,
                showAlert: true,
                arrActivas: arrDesde,
                arrCompletadas: arrHasta,
            }
        }

        case descargasTypes.CANCELAR_DESCARGAS: {
            const moverDesdeArr = enQueArrEstaLaDescarga(payload.arrDescargas[0]);

            const moverHastaArr =
                moverDesdeArr == "arrActivas" ? "arrCanceladas" :
                    moverDesdeArr == "arrCompletadas" ? "arrEliminadas" :
                        moverDesdeArr == "arrCanceladas" ? "arrEliminadas"
                            : "arrEliminadas"

            const { arrDesde, arrHasta } = moverArrDescargasDesdeHasta(moverDesdeArr, moverHastaArr)
            return { ...state, [moverDesdeArr]: arrDesde, [moverHastaArr]: arrHasta }
        }

        case descargasTypes.REINICIAR_DESCARGAS: {
            const { arrDesde, arrHasta } = moverArrDescargasDesdeHasta("arrCanceladas", "colaDescargas")

            return {
                ...state,
                arrCanceladas: arrDesde,
                colaDescargas: arrHasta,
            }
        }

        case descargasTypes.TOGGLE_SHOW_DESCRIPCION_DESCARGAS: {
            const newState = {
                ...state,
                arrActivas: [...state.arrActivas],
                arrCanceladas: [...state.arrCanceladas],
                arrCompletadas: [...state.arrCompletadas],
            }
            const opciones = ["arrActivas", "arrCanceladas", "arrCompletadas"]
            payload.arrDescargas.forEach(ctDescarga => {
                let descargaIndex;
                const opcionIndex = opciones.findIndex(ctOpcion => {
                    const descargaIdx = state[ctOpcion].findIndex(ctOrgDescarga => ctOrgDescarga.id == ctDescarga.id)
                    if (descargaIdx >= 0) descargaIndex = descargaIdx;
                    return descargaIdx >= 0
                })
                if (descargaIndex >= 0 && opcionIndex >= 0) newState[opciones[opcionIndex]][descargaIndex].showDescripcion = !newState[opciones[opcionIndex]][descargaIndex].showDescripcion
            })
            return newState
        }

        case descargasTypes.TURN_ON_ALERT: {
            return { ...state, showAlert: true }
        }

        case descargasTypes.TURN_OFF_ALERT: {
            return { ...state, showAlert: false }
        }

        default:
            return state;
    }
}

export default descargasReducer;

