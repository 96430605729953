import React from 'react';
import { Row, Col, Badge, Table } from "react-bootstrap";
import { configGeneral } from "../../utils/Constants";
import { DetailFact } from "../../ui/DetailFact";
import { DetailNumber } from "../../ui/DetailNumber";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons";

const EscuelaDetailBody = ({ escuela, ...props }) => {

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="modalDetail-specs">
            <Badge variant="primary">{escuela.getNombreEstado()}</Badge>
            {escuela.getFechaCreacion() && (

              <small>
                Creado el: <b>{escuela.getFechaCreacion()}</b>
              </small>
            )}
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />
      <Row>
        <Col md={6} className="form-group">
          <DetailFact label="Nombre" input={escuela.nombre} />
        </Col>
        <Col md={6} className="form-group">
          <DetailFact label="Area" input={escuela.getNombreAreas()} />
        </Col>
      </Row>
      
      <hr />
      <Row>
        <Col className="form-group">
          <DetailNumber label="Nivel" input={escuela.campo1} />
        </Col>
      </Row>
      {escuela.descripcion && (
        <Row>
          <Col md={12} className="form-group">
            <hr />
            <label>Descripción: </label>
            <p> {escuela.descripcion}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EscuelaDetailBody;
