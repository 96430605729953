const { apiURLs, roleConstants } = require("./utils/Constants");
const { default: requestService } = require("./utils/request.service");

//TODO - Pasar los metodos de CargoService y especialidadesService aca, ya que son casi lo mismo

const getTiposEscuelas = async (filtros = {}) => {
  return await requestService.sendRequest('GET', apiURLs.API_MAESTROS_TIPO_ESCUELA, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getTiposDocumentos = async () => {
  return await requestService.sendRequest('GET', apiURLs.API_GENERICOS_TIPO_DOCUMENTO)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getTipoResoluciones = async (filtros = {}) => {
  const response = await requestService.sendRequest('GET', apiURLs.API_GENERICOS_TIPO_RESOLUCION, filtros)
  const formated = {
    data: response.data,
    cantidad: response.data.length,
    page: 1,
  }
  return formated
}

const getOrganismos = async (filtros = {}) => {
  const response = await requestService.sendRequest('GET', apiURLs.API_TERNAS_ORGANISMO, filtros)
  const formated = {
    data: response.data,
    cantidad: response.data.length,
    page: 1,
  }

  return formated
}

const getEstados = async () => {
  return await requestService.sendRequest('GET', apiURLs.API_GENERICOS_ESTADOS)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

export {
  getTiposEscuelas,
  getTiposDocumentos,
  getOrganismos,
  getTipoResoluciones,
  getEstados
}

