import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Table } from 'react-bootstrap'
import IconButton from '../../../generics/IconButton'

export const RelacionesAsignaturaTable = ({ elementos = [], handleRemove }) => {
    return (
        <>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Area</th>
                        <th>Tipo de escuela</th>
                        <th>Especialidad</th>
                        <th>Cargo</th>
                        {handleRemove && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        (elementos.length > 0) ?
                            <>
                                {elementos.map((relacion, index) =>
                                    <tr>
                                        <td>{relacion.area.nombre}</td>
                                        <td>{relacion.tipoEscuela.nombre}</td>
                                        <td>{relacion.especialidad.nombre}</td>
                                        <td>{relacion.cargo.nombre}</td>
                                        {handleRemove && <td><IconButton icon={faTrash} onClick={() => handleRemove(index)} /></td>}
                                    </tr>
                                )}
                            </>
                            :
                            <>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    {handleRemove && <td>-</td>}
                                </tr>
                            </>
                    }
                </tbody>
            </Table>
        </>
    )
}
