import { Form, Col, Row } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidNumeric, isValidText, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import * as cargoService from "../CargosService";

import CheckBoxEstados from "../../generics/CheckBoxEstados";
import { cleanFab, resetChecks, searchFab } from "../../store/screen/screenActions";

const CargosFormSearch = ({
  onSubmit,
  hideChecks,
  defaultInputsForm = {},
  setCargos
}) => {

  const initialValues = {
    cod_dad: "",
    ...defaultInputsForm
  }

  const [inputsForm, setInputsForm] = useState(initialValues);
  const [cargoSeleccionado, setCargoSeleccionado] = useState("");
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);
  const filtrosGuardados = useSelector((state) => state.search);

  const processSubmit = async () => {
    let data;

    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      data = filtrosGuardados
    } else {
      const filtrosDefault = { page: 1 }

      data = {
        inputsForm: { ...inputsForm, ...filtrosDefault },
        areaSeleccionada,
        cargoSeleccionado,
        nivelSeleccionado,
        especialidadSeleccionada,
      }
    }
    await onSubmit(data)
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChangeSelect = (isValid, e) => {
    if (isValid(e.target.label)) {
      let eTarget = { target: { id: e.target.id, value: e.target.label } };
      handleChange(eTarget);
    }
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, null, e);
    }
  };

  const resetForm = () => {
    setCargos(null);
    setInputsForm(initialValues);
    setCargoSeleccionado("")
    setEspecialidadSeleccionada("");
    setAreaSeleccionada("");
    setNivelSeleccionado("");
    dispatch(resetChecks(true));
  }

  const loadForm = () => {
    if (filtrosGuardados && Object.keys(filtrosGuardados).length !== 0) {
      setInputsForm(filtrosGuardados.inputsForm);
      setAreaSeleccionada(filtrosGuardados.areaSeleccionada);
      setCargoSeleccionado(filtrosGuardados.cargoSeleccionado)
      setNivelSeleccionado(filtrosGuardados.nivelSeleccionado);
      setEspecialidadSeleccionada(filtrosGuardados.especialidadSeleccionada);
    }
  }

  useEffect(() => {
    loadForm();
  }, [dispatch]);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchCargos">
            <Row className="form-group">
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nombre"
                  name="nombre"
                  label="Nombre del cargo"
                  value={cargoSeleccionado}
                  paramsFilter={{ min: true }}
                  methodOnChange={handleChange}
                  elements={cargoService.getCargos}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setCargoSeleccionado}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={2}>
                <Input
                  type="input"
                  controlId="cod_dad"
                  label="Código DAD"
                  value={inputsForm ? inputsForm.cod_dad : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Input
                  type="react-select"
                  controlId="nivel_id"
                  name="nivel_id"
                  label="Nivel"
                  value={nivelSeleccionado}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getNivelesCargo}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true }}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Input
                  type="react-select"
                  controlId="area"
                  name="area"
                  label="Área"
                  value={areaSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getAreas}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setAreaSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="especialidad"
                  name="especialidad"
                  label="Especialidad"
                  value={especialidadSeleccionada}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getEspecialidades}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setEspecialidadSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true, }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              {!hideChecks &&
                <Col sm={12} lg={8}>
                  <CheckBoxEstados
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                  />
                </Col>}
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default CargosFormSearch;
