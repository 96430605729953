import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { incumbenciasConstants, routePaths, typeOfActions } from "../../utils/Constants"
import { clear, success } from "../../store/alerts/alertActions";
import { IncumbenciasABM } from "./IncumbenciasABM";
import IncumbenciasService, { formatCargoToIncumbencia, formatConcurrenciasMainCargo, formatConcurrenciasMainTerna, formatTernaToIncumbencia } from "../IncumbenciasService";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { addArrConcurrencias, addCargoToArr, saveIncumbenciasData } from "../../store/incumbencias/incumbenciasActions";
import { adaptPostIncumbenciasByCargo } from "../IncumbenciasAdapter";

export const removeArrElementsFromArrIncumbencias = (arrOriginal, idPath, arrToRemove) => {
    //arrOriginal = ternaSeleccionada / cargoSeleccionada ([])
    //idPath = terna / cargo (strg)
    //arrToRemove = existentes o agregados (response fetch createIncumbencias)
    let arrWithAllDataRaw = JSON.parse(JSON.stringify(arrOriginal))
    const arrWithAllData = (typeof arrWithAllDataRaw == "object") ? Object.values(arrWithAllDataRaw) : arrWithAllDataRaw

    if (arrToRemove) {
        arrToRemove.forEach(currentExistente => {
            const currentExistenteIndex = arrWithAllData.findIndex(currentAgregado => currentAgregado[idPath].id == currentExistente[idPath].id);
            if (arrWithAllData[currentExistenteIndex].type == "multi") {
                const currentVarianteExistenteIndex = arrWithAllData[currentExistenteIndex].variantes.findIndex(currentVarianteAgregada => {
                    const isVarianteExistente = (
                        currentExistente.area == currentVarianteAgregada.area.nombre &&
                        (currentExistente.asignatura ? currentExistente.asignatura == currentVarianteAgregada.asignatura.nombre : true) && /* Tiene asignatura ? verificar : ignorar */
                        currentExistente.especialidad == currentVarianteAgregada.especialidad.nombre &&
                        currentExistente.tipo_escuela == currentVarianteAgregada.tipoEscuela.nombre
                    )
                    return isVarianteExistente;
                })
                arrWithAllData[currentExistenteIndex].variantes.splice(currentVarianteExistenteIndex, 1);
            } else {
                arrWithAllData.splice(currentExistenteIndex, 1);
            }
        })
        return arrWithAllData;
    }
}

export const removeArrElementsFromArrConcurrencias = (arrOriginal, isTernaMainElement, arrToRemove) => {
    let arrWithAllData = JSON.parse(JSON.stringify(arrOriginal))

    if (arrToRemove) {
        if (isTernaMainElement) {
            arrToRemove.forEach(currentExistente => {
                const currentExistenteIndex = arrWithAllData.findIndex(currentConcurrencia => {
                    return currentConcurrencia.ternasSeleccionadas[0].id == currentExistente["terna2"].id &&
                        currentConcurrencia.ternasSeleccionadas[1]?.id == currentExistente["terna3"]?.id
                })
                if (!(currentExistenteIndex >= 0)) return

                const currentExistenteCargoIndex = arrWithAllData[currentExistenteIndex]?.cargosSeleccionados?.findIndex(
                    ctCargo => ctCargo.cargo.id == currentExistente.cargo.id
                )

                if (!(currentExistenteCargoIndex >= 0)) return

                if (arrWithAllData[currentExistenteIndex].cargosSeleccionados[currentExistenteCargoIndex].type == "multi") {
                    const currentVarianteExistenteIndex =
                        arrWithAllData[currentExistenteIndex].cargosSeleccionados[currentExistenteCargoIndex].variantes.findIndex(currentVarianteAgregada => {
                            const isVarianteExistente = (
                                currentExistente.area == currentVarianteAgregada.area.nombre &&
                                (currentExistente.asignatura_id ? currentExistente.asignatura_id == currentVarianteAgregada.asignatura.id : true) && /* Tiene asignatura ? verificar : ignorar */
                                currentExistente.especialidad == currentVarianteAgregada.especialidad.nombre &&
                                currentExistente.tipo_escuela == currentVarianteAgregada.tipoEscuela.nombre
                            )
                            return isVarianteExistente;
                        })
                    arrWithAllData[currentExistenteIndex].cargosSeleccionados[currentExistenteCargoIndex].variantes.splice(currentVarianteExistenteIndex, 1);

                    if (arrWithAllData[currentExistenteIndex].cargosSeleccionados[currentExistenteCargoIndex].variantes.length == 0) {
                        arrWithAllData[currentExistenteIndex].cargosSeleccionados.splice(currentExistenteCargoIndex, 1)
                    }

                    if (arrWithAllData[currentExistenteIndex].cargosSeleccionados.length == 0) {
                        arrWithAllData.splice(currentExistenteIndex, 1)
                    }

                } else {
                    arrWithAllData[currentExistenteIndex].cargosSeleccionados.splice(currentExistenteCargoIndex, 1);
                }
            })
        } else {
            arrToRemove.forEach(currentExistente => {
                const currentExistenteIndex = arrWithAllData.findIndex(currentConcurrencia => {
                    return currentConcurrencia.ternasSeleccionadas[0].id == currentExistente["terna"].id &&
                        currentConcurrencia.ternasSeleccionadas[1]?.id == currentExistente["terna2"]?.id &&
                        currentConcurrencia.ternasSeleccionadas[2]?.id == currentExistente["terna3"]?.id
                })
                arrWithAllData.splice(currentExistenteIndex, 1);
            })
        }
        return arrWithAllData
    }
}

export const addIncumbenciasIds = (arrWithAllDataRaw, idPath, elementsIdsArr) => {
    //arrWithAllData = ternaSeleccionada / cargoSeleccionada ([])
    //idPath = terna / cargo (strg)
    //elementsIdsArr = existentes o agregados (response fetch createIncumbencias)
    const arrWithAllData = (typeof arrWithAllDataRaw == "object") ? Object.values(arrWithAllDataRaw) : arrWithAllDataRaw

    elementsIdsArr.forEach(currentAgregado => {
        const currentCargoIndex = arrWithAllData.findIndex(currentElement => currentElement[idPath].id == currentAgregado[idPath].id);
        if (arrWithAllData[currentCargoIndex].type == "multi") {
            const currentVarianteIndex = arrWithAllData[currentCargoIndex].variantes.findIndex(currentVarianteAgregada => {
                const isVarianteExistente = (
                    currentAgregado.area == currentVarianteAgregada.area.nombre &&
                    (currentAgregado.asignatura ? currentAgregado.asignatura.toUpperCase() == currentVarianteAgregada.asignatura.nombre : true) && /* Tiene asignatura ?verificar: ignorar */
                    currentAgregado.especialidad == currentVarianteAgregada.especialidad.nombre &&
                    currentAgregado.tipo_escuela == currentVarianteAgregada.tipoEscuela.nombre
                )
                return isVarianteExistente;
            });

            arrWithAllData[currentCargoIndex].variantes[currentVarianteIndex].id = currentAgregado.incumbencia_id;
        } else {
            arrWithAllData[currentCargoIndex].id = currentAgregado.incumbencia_id;
        }
    })
    return arrWithAllData
}

export const IncumbenciasABMEdit = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        originalTernaSeleccionada,
        originalCargoSeleccionado,
        originalConcurrenciasSeleccionadas,
        modifiedIncumbencias,
        cargoSeleccionado,
        ternaSeleccionada,
        concurrenciasSeleccionadas,
        deletedElements,
        deletedConcurrencias,
        incumbenciasCreateResult,
    } = useSelector(st => st.incumbencias);

    const { tipoIncumbencias } = useSelector(st => st.data)

    const { mainElement } = useParams();
    const secondarySelectedKey = (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ? "cargoSeleccionado" : "ternaSeleccionada";

    const handleSubmit = async (params) => {
        const resolveAction = () => {
            const returnPath = (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ?
                routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS :
                routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS;
            history.push(returnPath)
            dispatch(success("Se editaron correctamente las incumbencias."));
        }

        const newIncumbencias = {
            cargoSelected: cargoSeleccionado,
            ternaSelected: ternaSeleccionada
        }

        const originalIncumbencias = {
            cargoSelected: originalCargoSeleccionado,
            ternaSelected: originalTernaSeleccionada
        }

        await IncumbenciasService.editIncumbencia(
            originalIncumbencias,
            newIncumbencias,
            deletedElements,
            originalConcurrenciasSeleccionadas,
            concurrenciasSeleccionadas,
            deletedConcurrencias,
            mainElement,
            resolveAction,
            params
        );
    }

    const loadFormData = async () => {
        if (modifiedIncumbencias) {
            dispatch(saveIncumbenciasData({
                ternaSeleccionada: modifiedIncumbencias.ternaSeleccionada,
                cargoSeleccionado: modifiedIncumbencias.cargoSeleccionado,
                concurrenciasSeleccionadas: modifiedIncumbencias.concurrenciasSeleccionadas,
                modifiedIncumbencias: null,
            }))

            if (incumbenciasCreateResult) {
                if (incumbenciasCreateResult.incumbencias?.agregados?.length > 0) {
                    console.log("loadFormData", { incumbenciasCreateResult })
                    const createdElementsArr = removeArrElementsFromArrIncumbencias(
                        incumbenciasCreateResult.agregadosFromData[secondarySelectedKey],
                        (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ? "cargo" : "terna",
                        incumbenciasCreateResult.incumbencias.existentes,
                    );
                    console.log("loadFormData2", { createdElementsArr, ag: incumbenciasCreateResult.incumbencias.agregados })
                    const createdElementsArrWithIncumbenciasIds = addIncumbenciasIds(
                        createdElementsArr,
                        (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ? "cargo" : "terna",
                        incumbenciasCreateResult.incumbencias.agregados,
                    );
                    createdElementsArrWithIncumbenciasIds.forEach(currentCargo => dispatch(addCargoToArr(currentCargo, secondarySelectedKey)))
                    JSON.parse(JSON.stringify(createdElementsArrWithIncumbenciasIds)).forEach(
                        currentCargo => {
                            dispatch(addCargoToArr(currentCargo, "originalCargoSeleccionado"))
                        }
                    )
                }
                if (incumbenciasCreateResult.concurrencias?.agregados?.length > 0) {
                    const arrRawConcurrencias = incumbenciasCreateResult.concurrencias.agregados;

                    const formatedConcurrencias = (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ?
                        await formatConcurrenciasMainTerna(originalTernaSeleccionada, arrRawConcurrencias, tipoIncumbencias, false, true)
                        :
                        await formatConcurrenciasMainCargo(originalCargoSeleccionado, adaptPostIncumbenciasByCargo(arrRawConcurrencias), tipoIncumbencias)

                    dispatch(addArrConcurrencias(formatedConcurrencias.concurrenciasSeleccionadas, "originalConcurrenciasSeleccionadas"))
                    dispatch(addArrConcurrencias(formatedConcurrencias.concurrenciasSeleccionadas, "concurrenciasSeleccionadas"))
                }
            }
        } else {
            dispatch(saveIncumbenciasData({
                ternaSeleccionada: JSON.parse(JSON.stringify(originalTernaSeleccionada)),
                cargoSeleccionado: JSON.parse(JSON.stringify(originalCargoSeleccionado)),
                concurrenciasSeleccionadas: JSON.parse(JSON.stringify(originalConcurrenciasSeleccionadas)),
            }))
        }
    }

    const plusCallBack = () => {
        dispatch(saveIncumbenciasData({
            modifiedIncumbencias: { ternaSeleccionada, cargoSeleccionado, concurrenciasSeleccionadas },
            [secondarySelectedKey]: null,
            "concurrenciasSeleccionadas": null
        }))

        history.push({
            pathname: (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ? routePaths.ABM_INCUMBENCIAS_CREATE_TERNAS : routePaths.ABM_INCUMBENCIAS_CREATE_CARGOS,
            state: {
                returnPath: (mainElement == incumbenciasConstants.TERNAS_INCUMBENCIA) ? routePaths.ABM_INCUMBENCIAS_EDIT_TERNAS : routePaths.ABM_INCUMBENCIAS_EDIT_CARGOS,
                buttonsConfig: {
                    hideButtonsMainElement: true,
                    hideBuscadoresMainElement: true,
                }
            }
        });
    }

    useEffect(() => {
        dispatch(clear());

        if (originalTernaSeleccionada && (originalCargoSeleccionado || originalConcurrenciasSeleccionadas)) {
            loadFormData();
        } else {
            history.push(routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS);
        }
    }, []);

    return (
        <ScreenWithFabButtons
            containerFluid={true}
            hidePlusButton={false}
            plusCallBack={plusCallBack}
        >
            <IncumbenciasABM
                title="Editar Incumbencia"
                typeOfAction={typeOfActions.EDIT}
                onSubmit={handleSubmit}

                hideButtonsMainElement={true}
                hideBuscadoresMainElement={true}
                hideButtonsSecondaryElement={true}
                hideBuscadoresSecondaryElement={true}

                showFabButtons={false}
                showDeletedElementTable={true}
                deletedElements={deletedElements}
            />
        </ScreenWithFabButtons>

    );
};