import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { IncumbenciasSearchRowActionsCargos } from "./IncumbenciasSearchRowActionsCargos";
import { TdWithVerMas } from "../../../ui/TdWithVerMas";

export const IncumbenciasSearchRowCargos = ({ cargo, ...props }) => {
    const [redirect, setRedirect] = useState("");


    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        cargo: cargo,
                        abmTitle: "Editar",
                        submitValue: "Habilitar",
                    },
                }}
            ></Redirect>
        );
    }

    return (
        <tr>
            <td>{cargo.apendice?.id || "-"}</td>
            <td>{cargo.folio || "-"}</td>
            <td>{cargo.codDad || "-"}</td>
            <TdWithVerMas data={cargo.getNombreAreas() || "-"} />
            <TdWithVerMas data={cargo.getNombreTipoEscuela() || "-"} />
            <td>{cargo.nombre}</td>
            <td><Badge variant={cargo.estado ? cargo.estado.color : "primary"}> {cargo.estado.nombre}</Badge></td>
            <td>
                <div className="table-button-group">
                    <IncumbenciasSearchRowActionsCargos cargo={cargo} onSubmit={props.onSubmit}></IncumbenciasSearchRowActionsCargos>
                </div>
            </td>
        </tr>
    );
};