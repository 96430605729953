import { faCheckDouble, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import IconButton from "../../generics/IconButton";
import Dialog from "../../generics/dialog";
import { error } from "../../store/alerts/alertActions";
import { ticketsUtils } from "../../tickets/TicketsUtils";
import { TicketsCreateModal } from "../../tickets/abm/TicketsCreateModal";
import { CustomTooltip } from "../../ui/CustomTooltip";
import DialogActivate from "../../ui/DialogActivate";
import Input from "../../ui/Input";
import { routePaths, tooltipsText } from "../../utils/Constants";
import { PERMISOS_MAP } from "../../utils/Permisos";
import { activate, deactivate, deleteTerna } from "../TernaService";
import TernaDetailModal from "../details/TernaDetailModal";
import { handlerChange, isValidNumeric } from "../../utils/commons";

const TernaSearchRowActions = ({ terna, ...props }) => {
  const [redirect, setRedirect] = useState("");
  const dispatch = useDispatch();

  const [inputsForm, setInputsForm] = useState([]);
  const [apendiceSeleccionado, setApendiceSeleccionado] = useState()
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToEdit = (terna) => {
    setRedirect(routePaths.ABM_TERNA_EDIT);
  };

  const redirectToApproval = (terna) => {
    setRedirect(routePaths.ABM_TERNA_APPROVAL);
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e);
  }

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, null, e);
    }
  };

  const changeState = async (terna) => {
    try {
      setIsLoading(true)
      if (terna.activo) {
        await deactivate({ ...inputsForm, terna }, props.onSubmit)
      } else {
        await activate({ ...inputsForm, terna }, props.onSubmit)
      }
    } catch (err) {
      console.log({ err })
      dispatch(error(err.response.data.message));
    } finally {
      setIsLoading(false)
    }
  };


  const handleChangeStateValidation = () => {
    setValidated(true)
    console.log({ apendiceSeleccionado, inputsForm })
    if (!apendiceSeleccionado || !inputsForm.folio) {
      throw new Error("Form incompleto")
    }
  }

  const handleEliminar = async (target) => {
    try {
      await deleteTerna(target, props.onSubmit)
    }
    catch (err) {
      console.log("err", err)
    }
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            terna: terna,
            abmTitle: "Editar",
            submitValue: "Aceptar",
          },
        }}
      ></Redirect>
    );
  }

  return (
    <>
      {(terna.acciones.alta || terna.acciones.baja) &&
        <PrivateComponent permisos={terna.acciones.alta ? PERMISOS_MAP.TERNA_ALTA : PERMISOS_MAP.TERNA_BAJA}>
          <CustomTooltip text={terna.activo ? tooltipsText.BAJA : tooltipsText.ALTA}>
            <DialogActivate
              target={terna}
              title={(terna.activo ? "Dar de baja" : "Dar de alta")}
              action={changeState}
              validation={terna.activo ? handleChangeStateValidation : undefined}
              size="md"
              body={<div>
                <span>
                  ¿Estás seguro de dar de {(terna.activo ? "baja " : "alta ")}
                  la terna
                  <b> {terna ? terna.nombre : ""}</b>
                  ?
                </span>
                {
                  terna.activo
                  &&
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Row className="mt-4">
                      <Col>
                        <Input
                          type="react-select"
                          controlId="apendice_id"
                          name="apendice_id"
                          label="Apéndice"
                          value={apendiceSeleccionado}
                          methodOnChange={handleChange}
                          elements={apendiceService.getApendices}
                          nameElementsResponse="elementos"
                          propertiesForFormat={{ value: "id", label: "nombreInput" }}
                          setterElementSelect={setApendiceSeleccionado}
                          config={{ isSearchable: true }}
                          predictivoAsync={false}
                          required
                          validated={validated}
                          defaultIndexSelectedOptionInSyncSelect={"0"}
                          disabled={isLoading}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="input"
                          controlId="folio"
                          label="Folio"
                          maxLength="100"
                          value={inputsForm.folio ? inputsForm.folio : ""}
                          onChange={handleValidChange.bind(this, isValidNumeric)}
                          required
                          disabled={isLoading}
                        ></Input>
                      </Col>
                    </Row>
                  </Form>}
              </div>
              }

              btnConfirmText={terna.activo ? "Dar de baja" : "Dar de alta"}
              isLoadingText={terna.activo ? "Dando de baja" : "Dando de alta"}
            >
            </DialogActivate>
          </CustomTooltip>
        </PrivateComponent>
      }

      {terna.acciones.eliminar &&
        <PrivateComponent permisos={PERMISOS_MAP.TERNA_ELIMINAR}>
          <CustomTooltip text={tooltipsText.ELIMINAR}>
            <Dialog
              action={handleEliminar}
              target={terna}
              title={"Eliminar terna"}
              body={<span>
                ¿Estás seguro que quiere eliminar la terna
                <b> {terna ? terna.nombre : ""}</b>
                ?
              </span>
              }
              size="md"
              btnIcon={faTrash}
              btnVariant={"danger"}
              variant={"danger"}
              btnConfirmText="Eliminar"
              isLoadingText={"Eliminando"}
            />
          </CustomTooltip>
        </PrivateComponent>
      }
      {terna.acciones.aprobar &&
        <PrivateComponent permisos={PERMISOS_MAP.TERNA_APROBAR}>
          <CustomTooltip text={tooltipsText.APROBAR}>
            <IconButton
              icon={faCheckDouble}
              onClick={() => redirectToApproval(terna)}
            />
          </CustomTooltip>
        </PrivateComponent>
      }
      {terna.acciones.publicar &&
        <PrivateComponent permisos={PERMISOS_MAP.TERNA_PUBLICAR}>
          <CustomTooltip text={tooltipsText.PUBLICAR}>
            <TernaDetailModal
              terna={terna}
              withPublicar={true}
              onSubmit={props.onSubmit}
            ></TernaDetailModal>
          </CustomTooltip>
        </PrivateComponent>
      }
      {terna.acciones.editar &&
        <PrivateComponent permisos={PERMISOS_MAP.TERNA_EDITAR}>
          <CustomTooltip text={tooltipsText.EDITAR}>
            <IconButton icon={faPen} onClick={() => redirectToEdit(terna)} />
          </CustomTooltip>
        </PrivateComponent>
      }

      <CustomTooltip text={tooltipsText.DETALLE}>
        <TernaDetailModal
          terna={terna}
        ></TernaDetailModal>
      </CustomTooltip>

      <PrivateComponent permisos={PERMISOS_MAP.TICKET_CREAR}>
        <CustomTooltip text={tooltipsText.TICKET}>
          <TicketsCreateModal
            objectId={terna.id}
            objectApendice={terna.apendice.nombre}
            nombreModuloTicket={ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS}
          />
        </CustomTooltip>
      </PrivateComponent>
    </>
  );
}

export default TernaSearchRowActions;
