import React, { useEffect, useRef, useState } from "react";
import IconButton from "../../generics/IconButton";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, } from "react-bootstrap";
import { TicketsDetailBody } from "./TicketsDetailBody";
import { ticketsService } from "../TicketsService";
import { Loader } from "../../ui/Loader";
import { TicketsDetailClose } from "./TicketsDetailClose";
import Dialog from "../../generics/dialog";
import { useDispatch } from "react-redux";
import { success } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { CustomTooltip } from "../../ui/CustomTooltip";
import { tooltipsText } from "../../utils/Constants";

export const TicketsDetailModal = ({
    ticketId,
    modalFullSize,
    accionCerrarTicket,
}) => {
    const defaultInputsFormValues = {
        observacion: ""
    }

    const [show, setShow] = useState(false);
    const [ticket, setTicket] = useState();
    const [searching, setSearching] = useState(false);
    const [inputsForm, setInputsForm] = useState(defaultInputsFormValues);

    const isMountedRef = useRef();
    const formCloseRef = useRef();
    const [validated, setValidated] = useState(false)

    const handleClose = () => setShow(false);
    const dispatch = useDispatch();

    const loadTicketData = async () => {
        try {
            setSearching(true)
            const response = await ticketsService.getTicket(ticketId, isMountedRef)
            setTicket(response)
        }
        catch (err) {
            console.log("Error", err)
        }
        finally {
            setSearching(false)
        }
    }

    const handleCloseTicket = async () => {
        setValidated(true);

        if (formCloseRef.current.checkValidity()) {
            try {
                await ticketsService.closeTicket({ ...inputsForm, id: ticketId }, isMountedRef)
                handleClose();
                dispatch(searchFab(true))
                dispatch(success(<p>El ticket <b>#{ticketId}</b> fue cerrado correctamente</p>))
            }
            catch (err) {
                console.log("Error", err)
            }
        }
    }

    const handleShow = () => {
        setShow(true);
        if (!ticket) {
            loadTicketData();
        }
    };

    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, [])

    return (
        <>
            <CustomTooltip text={accionCerrarTicket ? tooltipsText.CERRAR_TICKET : tooltipsText.DETALLE}>
                <IconButton onClick={handleShow} icon={accionCerrarTicket ? faCheck : faSearch} />
            </CustomTooltip>

            <Modal
                show={show}
                onHide={!searching ? handleClose : null}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                dialogClassName={modalFullSize ? "modal-90w" : ""}
                centered
                keyboard={searching}
                backdrop={searching ? 'static' : true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Ticket #{ticketId}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {searching ?
                        <Loader></Loader>
                        :
                        <>
                            {ticket &&
                                <TicketsDetailBody
                                    ticket={ticket}
                                />
                            }

                            {accionCerrarTicket &&
                                <TicketsDetailClose
                                    inputsForm={inputsForm}
                                    setInputsForm={setInputsForm}
                                    validated={validated}
                                    formCloseRef={formCloseRef}
                                />
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>

                    {!searching &&
                        <>
                            {accionCerrarTicket &&
                                <Dialog
                                    preventFocus
                                    action={handleCloseTicket}
                                    title={"Cerrar ticket"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Cerrar"
                                    btnVariant="primary"
                                    btnText="Cerrar ticket"
                                    isLoadingText={"Cerrando ticket"}
                                    body={<span>
                                        ¿Estás seguro de cerrar el ticket?
                                    </span>}
                                />
                            }
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}