import { Form, Col, Row, Button } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidNumeric, isValidText, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import * as escuelaService from "../EscuelaService";
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import InputDatePicker from "../../generics/InputDatePicker";
import { cleanFab, searchFab } from "../../store/screen/screenActions";

const ApendiceFormSearch = (props) => {
  const [inputsForm, setInputsForm] = useState([]);
  // const [areaSeleccionada, setAreaSeleccionada] = useState("");
  // const [campoSeleccionado, setCampoSeleccionado] = useState("");
  // const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");
  // const [codigoSeleccionado, setCodigoSeleccionado] = useState();

  const isMountedRef = useRef(null);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);


  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaCierre, setFechaCierre] = useState(null);

  //Lleno los inputs si se vuelve atras
  if (inputsForm.length < 1 && props.filtros) {
    setInputsForm(props.filtros);
    // setAreaSeleccionada(props.filtros.areaSeleccionada);
    // setCampoSeleccionado(props.filtros.campoSeleccionado);
    // setEspecialidadSeleccionada(props.filtros.especialidadSeleccionada);
    // setCodigoSeleccionado(props.filtros.codigoSeleccionado);
  }

  const cargarInputsFormConSeleccionados = () => {
    inputsForm.fechaInicio = fechaInicio;
    inputsForm.fechaCierre = fechaCierre;
  }

  const processSubmit = async () => {
    //Para el paginado
    inputsForm["page"] = 1;
    cargarInputsFormConSeleccionados();
    dispatch(saveFilters(inputsForm));

    await props.onSubmit(inputsForm).catch((err) => {
      dispatch(error(err.response.data.message));
    });
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, null, e);
    }
  };

  const setFechaDesde = (date) => {
    setInputsForm({
      ...inputsForm,
      fecha_desde: date
    });
    setFechaInicio(date)
  }

  const setFechaHasta = (date) => {
    setInputsForm({
      ...inputsForm,
      fecha_hasta: date
    });
    setFechaCierre(date)
  }

  const resetForm = () => {
    setInputsForm({});
    setFechaInicio(null);
    setFechaCierre(null);
  }

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchApendice">
            <Row className="form-group">

              <Col sm={12} md={3} lg={2}>
                <Input
                  type="input"
                  controlId="apendiceId"
                  label="Nº Apendice"
                  value={inputsForm.apendiceId ? inputsForm.apendiceId : ""}
                  onChange={handleValidChange.bind(this, isValidNumeric)}
                ></Input>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre del Apendice"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                ></Input>
              </Col>

              <Col sm={6} md={4} lg={2}>
                <InputDatePicker
                  label={"Fecha de Inicio"}
                  selected={fechaInicio}
                  onChange={date => setFechaDesde(date)}
                />
              </Col>

              <Col sm={6} md={4} lg={2}>
                <InputDatePicker
                  label={"Fecha de Cierre"}
                  selected={fechaCierre}
                  onChange={date => setFechaHasta(date)}
                />
              </Col>

            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default ApendiceFormSearch;
