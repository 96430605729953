export const PERMISOS_MAP = {
    /* ASIGNATURAS */
    VER_MODULO_ASIGNATURA: { "VER_MODULO_ASIGNATURA": 0 },
    ASIGNATURA_CREAR: { "ASIGNATURA_CREAR": 0 },
    ASIGNATURA_EDITAR: { "ASIGNATURA_EDITAR": 0 },
    ASIGNATURA_ELIMINAR: { "ASIGNATURA_ELIMINAR": 0 },
    ASIGNATURA_BAJA: { "ASIGNATURA_BAJA": 0 },
    ASIGNATURA_ALTA: { "ASIGNATURA_ALTA": 0 },
    ASIGNATURA_APROBAR: { "ASIGNATURA_APROBAR": 0 },
    ASIGNATURA_PUBLICAR: { "ASIGNATURA_PUBLICAR": 0 },

    /* CARGOS */
    VER_MODULO_CARGO: { "VER_MODULO_CARGO": 0 },
    CARGO_CREAR: { "CARGO_CREAR": 0 },
    CARGO_EDITAR: { "CARGO_EDITAR": 0 },
    CARGO_ELIMINAR: { "CARGO_ELIMINAR": 0 },
    CARGO_BAJA: { "CARGO_BAJA": 0 },
    CARGO_ALTA: { "CARGO_ALTA": 0 },
    CARGO_APROBAR: { "CARGO_APROBAR": 0 },
    CARGO_PUBLICAR: { "CARGO_PUBLICAR": 0 },

    /* ESPECIALIDAD */
    VER_MODULO_ESPECIALIDAD: { "VER_MODULO_ESPECIALIDAD": 0 },
    ESPECIALIDAD_CREAR: { "ESPECIALIDAD_CREAR": 0 },
    ESPECIALIDAD_EDITAR: { "ESPECIALIDAD_EDITAR": 0 },
    ESPECIALIDAD_ELIMINAR: { "ESPECIALIDAD_ELIMINAR": 0 },
    ESPECIALIDAD_BAJA: { "ESPECIALIDAD_BAJA": 0 },
    ESPECIALIDAD_ALTA: { "ESPECIALIDAD_ALTA": 0 },
    ESPECIALIDAD_APROBAR: { "ESPECIALIDAD_APROBAR": 0 },
    ESPECIALIDAD_PUBLICAR: { "ESPECIALIDAD_PUBLICAR": 0 },

    /* TIPO ESCUELA */
    VER_MODULO_TIPO_ESCUELA: { "VER_MODULO_TIPO_ESCUELA": 0 },
    TIPO_ESCUELA_CREAR: { "TIPO_ESCUELA_CREAR": 0 },
    TIPO_ESCUELA_EDITAR: { "TIPO_ESCUELA_EDITAR": 0 },
    TIPO_ESCUELA_ELIMINAR: { "TIPO_ESCUELA_ELIMINAR": 0 },
    TIPO_ESCUELA_BAJA: { "TIPO_ESCUELA_BAJA": 0 },
    TIPO_ESCUELA_ALTA: { "TIPO_ESCUELA_ALTA": 0 },
    TIPO_ESCUELA_APROBAR: { "TIPO_ESCUELA_APROBAR": 0 },
    TIPO_ESCUELA_PUBLICAR: { "TIPO_ESCUELA_PUBLICAR": 0 },

    /* APENDICE */
    VER_MODULO_APENDICE: { "VER_MODULO_APENDICE": 0 },
    APENDICE_CREAR: { "APENDICE_CREAR": 0 },

    /* TERNAS */
    VER_MODULO_TERNA: { "VER_MODULO_TERNA": 0 },
    TERNA_CREAR: { "TERNA_CREAR": 0 },
    TERNA_EDITAR: { "TERNA_EDITAR": 0 },
    TERNA_ELIMINAR: { "TERNA_ELIMINAR": 0 },
    TERNA_BAJA: { "TERNA_BAJA": 0 },
    TERNA_ALTA: { "TERNA_ALTA": 0 },
    TERNA_APROBAR: { "TERNA_APROBAR": 0 },
    TERNA_PUBLICAR: { "TERNA_PUBLICAR": 0 },

    /* TITULO EQUIVALENTE */
    VER_MODULO_TITULO_EQUIVALENTE: { "VER_MODULO_TITULO_EQUIVALENTE": 0 },
    TITULO_EQUIVALENTE_CREAR: { "TITULO_EQUIVALENTE_CREAR": 0 },
    TITULO_EQUIVALENTE_EDITAR: { "TITULO_EQUIVALENTE_EDITAR": 0 },
    TITULO_EQUIVALENTE_ELIMINAR: { "TITULO_EQUIVALENTE_ELIMINAR": 0 },
    TITULO_EQUIVALENTE_BAJA: { "TITULO_EQUIVALENTE_BAJA": 0 },
    TITULO_EQUIVALENTE_ALTA: { "TITULO_EQUIVALENTE_ALTA": 0 },
    TITULO_EQUIVALENTE_APROBAR: { "TITULO_EQUIVALENTE_APROBAR": 0 },
    TITULO_EQUIVALENTE_PUBLICAR: { "TITULO_EQUIVALENTE_PUBLICAR": 0 },

    /* TITULO ITF */
    VER_MODULO_TITULO_ITF: { "VER_MODULO_TITULO_ITF": 0 },
    TITULO_ITF_CREAR: { "TITULO_ITF_CREAR": 0 },
    TITULO_ITF_EDITAR: { "TITULO_ITF_EDITAR": 0 },
    TITULO_ITF_ELIMINAR: { "TITULO_ITF_ELIMINAR": 0 },
    TITULO_ITF_BAJA: { "TITULO_ITF_BAJA": 0 },
    TITULO_ITF_ALTA: { "TITULO_ITF_ALTA": 0 },
    TITULO_ITF_APROBAR: { "TITULO_ITF_APROBAR": 0 },
    TITULO_ITF_PUBLICAR: { "TITULO_ITF_PUBLICAR": 0 },




    /* CONFIGURACIONES */
    VER_MODULO_CONFIGURACION: { "VER_MODULO_CONFIGURACION": 0 },

    /* SINCRO */
    VER_MODULO_SINCRONIZACION: { "VER_MODULO_SINCRONIZACION": 0 },







    /* INCUMBENCIA */
    VER_MODULO_INCUMBENCIA: { "VER_MODULO_INCUMBENCIA": 0 },
    INCUMBENCIA_CREAR: { "INCUMBENCIA_CREAR": 0 },
    INCUMBENCIA_EDITAR: { "INCUMBENCIA_EDITAR": 0 },
    INCUMBENCIA_ELIMINAR: { "INCUMBENCIA_ELIMINAR": 0 },
    INCUMBENCIA_BAJA: { "INCUMBENCIA_BAJA": 0 },
    INCUMBENCIA_ALTA: { "INCUMBENCIA_ALTA": 0 },
    INCUMBENCIA_APROBAR: { "INCUMBENCIA_APROBAR": 0 },
    INCUMBENCIA_PUBLICAR: { "INCUMBENCIA_PUBLICAR": 0 },
    INCUMBENCIA_CLONAR: { "INCUMBENCIA_CLONAR": 0 },

    /* USUARIO */
    VER_MODULO_USUARIO: { "VER_MODULO_USUARIO": 0 },
    USUARIO_CREAR: { "USUARIO_CREAR": 0 },
    USUARIO_EDITAR: { "USUARIO_EDITAR": 0 },
    USUARIO_ELIMINAR: { "USUARIO_ELIMINAR": 0 },
    USUARIO_HABILITAR: { "USUARIO_HABILITAR": 0 },
    USUARIO_DESHABILITAR: { "USUARIO_DESHABILITAR": 0 },

    /* INFORMES */
    VER_MODULO_INFORME: { "VER_MODULO_INFORME": 0 },
    EXPORTAR_INFORME: { "EXPORTAR_INFORME": 0 },
    EXPORTAR_INFORMATIVO: { "EXPORTAR_INFORMATIVO": 0 },

    /* TICKET */
    TICKET_VER: { "TICKET_VER": 0 },
    TICKET_BUSCAR: { "TICKET_BUSCAR": 0 },
    TICKET_CREAR: { "TICKET_CREAR": 0 },
    TICKET_EDITAR: { "TICKET_EDITAR": 0 },
    TICKET_CERRAR: { "TICKET_CERRAR": 0 },

}