import { Form, Col, Row, Button } from "react-bootstrap";
import Input from "../../ui/Input";
import React, { useState, useRef, useEffect } from "react";
import { isValidText, handlerChange } from "../../utils/commons";
import { Loader } from "../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import * as ternaService from "../TernaService"
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import { cleanFab, searchFab } from "../../store/screen/screenActions";

const TernaFormSearch = ({
  setTernas,
  filtros,
  onSubmit,
}) => {
  const [inputsForm, setInputsForm] = useState([]);
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
  const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");
  const [tituloSeleccionado, setTituloSeleccionado] = useState("");
  const [resetChecks, setResetChecks] = useState(false);

  const isMountedRef = useRef(null);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);

  /*   
    const cargarInputsFormConSeleccionados = () => {
    inputsForm.nivelSeleccionado = nivelSeleccionado;
    inputsForm.procedenciaSeleccionada = procedenciaSeleccionada;
    inputsForm.resolucionSeleccionada = resolucionSeleccionada;
  } 
  */

  const processSubmit = async () => {
    inputsForm["page"] = 1; //Para el paginado
    // cargarInputsFormConSeleccionados();
    dispatch(saveFilters(inputsForm));
    await onSubmit(inputsForm).catch((err) => {
      dispatch(error(err.response.data.message));
    });
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChangeSelect = (isValid, e) => {
    if (isValid(e.target.label)) {
      let eTarget = { target: { ...e.target, id: e.target.id, value: e.target.label } };
      handleChange(eTarget);
    }
  };

  const resetForm = () => {
    setTernas("")
    setInputsForm({});
    setNivelSeleccionado("");
    setProcedenciaSeleccionada("");
    setResolucionSeleccionada("");
    setTituloSeleccionado("");
    setResetChecks(true);
  }

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    return () => (isMountedRef.current = false);
  }, [dispatch]);


  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  useEffect(() => {
    //Lleno los inputs si se vuelve atras
    if (filtros) {
      setInputsForm({ ...filtros });
      setNivelSeleccionado(filtros.nivel_id ?? "");
      setProcedenciaSeleccionada(filtros.procedencia ?? "");
      setResolucionSeleccionada(filtros.resolucion ?? "");
      setTituloSeleccionado(filtros.nombre ?? "");
    }
  }, [])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchAsignaturas">
            <Row className="form-group">
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nombre"
                  name="nombre"
                  label="Nombre de título"
                  value={tituloSeleccionado}
                  methodOnChange={handleChange}
                  elements={ternaService.getTitulos}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setTituloSeleccionado}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="nivel_id"
                  name="nivel_id"
                  label="Nivel"
                  value={nivelSeleccionado}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getNivelesTerna}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  setterElementSelect={setNivelSeleccionado}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                // withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="procedencia"
                  name="procedencia"
                  label="Procedencia"
                  value={procedenciaSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternaService.getProcedencias}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setProcedenciaSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId="resolucion"
                  name="resolucion"
                  label="Resolución"
                  countCaracters={2}
                  value={resolucionSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternaService.getResolucionesCombo}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setResolucionSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} lg={8}>
                <CheckBoxEstados
                  inputsForm={inputsForm}
                  setInputsForm={setInputsForm}
                  resetChecks={resetChecks}
                  setResetChecks={setResetChecks}
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default TernaFormSearch;
