import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import EscuelaSearchRowActions from "./EscuelaSearchRowActions";
import { Badge } from "react-bootstrap";

const EscuelaSearchRow = ({ escuela, ...props }) => {
  const [redirect, setRedirect] = useState("");


  if (redirect) {
    return (

      <Redirect
        to={{
          pathname: redirect,
          state: {
            cargo: escuela,
            abmTitle: "Editar",
            submitValue: "Habilitar",
          },
        }}
      ></Redirect>
    );
  }
  return (
    <>
      <tr key={escuela.id}>
        <td>{escuela.nombre}</td>
        <td>{escuela.id_tipo_escuela}</td>
        <td>{escuela.campo1}</td>
        <td>{escuela.getNombreAreas()}</td>
        <td>{escuela.getFechaCreacion()}</td>
        <td><Badge variant={escuela.estado ? escuela.estado.color : "primary"}> {escuela.getNombreEstado()}</Badge></td>
        <td>
          <div className="table-button-group">
            <EscuelaSearchRowActions escuela={escuela}></EscuelaSearchRowActions>
          </div>
        </td>
      </tr>
    </>
  );
};

export default EscuelaSearchRow;
