import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Asignatura from "../../dtos/asignatura/Asignatura";
import { Row, Col } from "react-bootstrap";
import AsignaturasABMForm from "./AsignaturasABMForm";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import IconTextButton from "../../generics/IconTextButton";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { saveFilters } from "../../store/search/searchActions";

const AsignaturaABMApproval = (props) => {
  let location = useLocation();
  const [asignatura, setAsignatura] = useState();

  const [disabled, setDisabled] = useState(true);
  const [availableCerrarMod, setAvailableCerrarMod] = useState(true);
  const [discardChanges, setDiscardChanges] = useState(false);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos elementos de los filtrosGuardados para forzar refresh al cargar la elementos en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.elementos
    dispatch(saveFilters(newFiltrosGuardados))
  };

  const handleModificar = () => {
    if (!disabled && !availableCerrarMod) {
      setDiscardChanges(true);
      setAvailableCerrarMod(true);
    }
    setDisabled(st => !st)
  }

  useEffect(() => {
    dispatch(clear());
    if (location.state && location.state.asignatura) {
      let asignatura = location.state.asignatura;
      if (!(asignatura instanceof Asignatura)) {
        // TODO: Crear asignatura al recargar la página
      }
      setAsignatura(asignatura);
    }
  }, [dispatch, location.state]);

  useEffect(() => { }, [availableCerrarMod])
  return (
    <ScreenWithFabButtons
      returnPath={location.state && location.state.returnPath}
    >
      <Row>
        <Col>
          <Row className="form-group">
            <Col md="12">
              <h1>Aprobar Asignatura
                {/* <IconTextButton
                  className="btn-icon-rounded"
                  icon={faPen}
                  type="submit"
                  variant="secondary"
                  text={disabled ? "Modificar cargo" : (availableCerrarMod ? "Cerrar Modificación" : "Descartar cambios")}
                  onClick={handleModificar} >
                </IconTextButton>*/}
              </h1> 
            </Col>
          </Row>
          <Row>
            <Col>
              <AsignaturasABMForm
                typeOfAction={typeOfActions.APPROVAL}
                onSubmit={handleSubmit}
                asignatura={asignatura}
                returnPath={routePaths.ABM_ASIGNATURAS_SEARCH}
                disabled={disabled}
                setDisabled={setDisabled}
                availableCerrarMod={availableCerrarMod}
                setAvailableCerrarMod={setAvailableCerrarMod}
                discardChanges={discardChanges}
                setDiscardChanges={setDiscardChanges}
                setAsignatura={setAsignatura}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};

export default AsignaturaABMApproval;
