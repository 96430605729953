import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from '../../ui/Input'
import { handlerChange, isValidText } from '../../utils/commons';
import * as asignaturaService from "../../asignaturas/AsignaturaService"
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import * as cargoService from "../../cargos/CargosService";
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { useLocation } from 'react-router-dom';
import { reactSelectFormUtils } from '../../utils/reactSelectFormUtils';
import { clearFilters, saveFilters } from '../../store/search/searchActions';
import { getEscuelas } from '../../escuela/EscuelaService';
import CheckBoxEstados from '../../generics/CheckBoxEstados';

export const FormSearchByAreaCargo = ({
    inputsForm,
    setInputsForm,
    resetForm,
    searchAreaCargo,
}) => {

    const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");
    const [asignaturaSeleccionada, setAsignaturaSeleccionada] = useState("");
    const [cargoSeleccionado, setCargoSeleccionado] = useState("");
    const [areaSeleccionada, setAreaSeleccionada] = useState("");
    const [escuelaSeleccionada, setEscuelaSeleccionada] = useState("");

    const [resetChecks, setResetChecks] = useState(false);

    const reactSelectsInputs = [
        { name: "especialidadSeleccionada", state: especialidadSeleccionada, setter: setEspecialidadSeleccionada, initialValue: "" },
        { name: "asignaturaSeleccionada", state: asignaturaSeleccionada, setter: setAsignaturaSeleccionada, initialValue: "" },
        { name: "cargoSeleccionado", state: cargoSeleccionado, setter: setCargoSeleccionado, initialValue: "" },
        { name: "areaSeleccionada", state: areaSeleccionada, setter: setAreaSeleccionada, initialValue: "" },
        { name: "escuelaSeleccionada", state: escuelaSeleccionada, setter: setEscuelaSeleccionada, initialValue: "" },
    ]

    const cleanRelatedFiltersRef = useRef(false)

    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);
    const searchState = useSelector(st => st.search);

    const processSubmit = () => {
        searchAreaCargo(inputsForm);
    }

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e)
    };


    const handleChangeArea = (e) => {
        /* al cambiar guardar:
        area: 12 y area_nombre: "Media" 
        */
        handlerChange(setInputsForm, inputsForm, null, e)
        // let eTarget = { target: { id: "area_nombre", value: e.target.label } };
        // handlerChange(setInputsForm, inputsForm, null, eTarget)
    };


    // const handleChangeCheck = (e) => {
    //     setInputsForm({
    //         ...inputsForm,
    //         [e.target.name]: e.target.checked,
    //     });
    // };

    useEffect(() => {
        if (Object.keys(searchState).length > 0) {
            if (searchState.reactSelectValues) reactSelectFormUtils.putAllReactSelectStates(reactSelectsInputs, searchState.reactSelectValues);
            if (searchState.inputsForm) setInputsForm(searchState.inputsForm);
        }
        cleanRelatedFiltersRef.current = true;
    }, []);

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            setResetChecks(true);
            reactSelectFormUtils.resetAllReactSelectStates(reactSelectsInputs);
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            const reactSelectValues = reactSelectFormUtils.getAllReactSelectStates(reactSelectsInputs);
            dispatch(saveFilters({ inputsForm, reactSelectValues }));
            processSubmit();
            dispatch(searchFab(false));
        }
    }, [screenState])

    return (
        <>
            <Row>
                <Col sm={12} md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="area"
                        name="area"
                        label="Área"
                        value={areaSeleccionada}
                        methodOnChange={handleChangeArea}
                        elements={especialidadesService.getAreas}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setAreaSeleccionada}
                        config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                        withBusquedaAvanzada={true}
                    ></Input>
                </Col>
                <Col md={4} lg={4}>
                    <Input
                        type="react-select"
                        controlId="tipo_escuela"
                        name="tipo_escuela"
                        label="Tipo de escuela"
                        value={escuelaSeleccionada}
                        methodOnChange={handleChange}
                        elements={getEscuelas}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setEscuelaSeleccionada}
                        relatedFilters={{ area: areaSeleccionada.value }}
                        config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                        cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                        withBusquedaAvanzada={true}
                        paramsFilter={{
                            area_busquedaAvanzada: !!inputsForm.area_busquedaAvanzada,
                        }}
                    ></Input>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="cargo"
                        name="cargo"
                        label="Nombre del cargo"
                        value={cargoSeleccionado}
                        methodOnChange={handleChange}
                        elements={cargoService.getCargos}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setCargoSeleccionado}
                        config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                        relatedFilters={{ tipo_escuela: escuelaSeleccionada.value, }}
                        paramsFilter={{
                            min: true,
                            tipo_escuela_busquedaAvanzada: !!inputsForm.tipo_escuela_busquedaAvanzada,
                            area: areaSeleccionada.value ?? "",
                            area_busquedaAvanzada: !!inputsForm.area_busquedaAvanzada,
                        }}
                        cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                        withBusquedaAvanzada={true}
                    ></Input>
                </Col>

                <Col sm={12} md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="asignatura"
                        name="asignatura"
                        label="Nombre de la asignatura"
                        value={asignaturaSeleccionada}
                        methodOnChange={handleChange}
                        elements={asignaturaService.getAsignaturas}
                        nameElementsResponse="elementos"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setAsignaturaSeleccionada}
                        config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                        relatedFilters={{ cargo: cargoSeleccionado.value, }}
                        disabled={!cargoSeleccionado}
                        cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                        disableInputIfOnlyOneOption={true}
                        withBusquedaAvanzada={true}
                        paramsFilter={{ cargo_busquedaAvanzada: !!inputsForm.cargo_busquedaAvanzada, }}
                    ></Input>
                </Col>

                <Col sm={12} md={6} lg={4}>
                    <Input
                        type="react-select"
                        controlId="especialidad"
                        name="especialidad"
                        label="Especialidad"
                        value={especialidadSeleccionada}
                        methodOnChange={handleChange}
                        elements={especialidadesService.getEspecialidades}
                        nameElementsResponse="data"
                        propertiesForFormat={{ value: "id", label: "nombre" }}
                        setterElementSelect={setEspecialidadSeleccionada}
                        config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                        relatedFilters={{ asignatura: asignaturaSeleccionada.value }}
                        disabled={!cargoSeleccionado}
                        cleanRelatedFiltersRef={cleanRelatedFiltersRef}
                        disableInputIfOnlyOneOption={true}
                        withBusquedaAvanzada={true}
                        paramsFilter={{
                            asignatura_busquedaAvanzada: !!inputsForm.asignatura_busquedaAvanzada,
                            cargo: cargoSeleccionado.value ?? "",
                            cargo_busquedaAvanzada: !!inputsForm.cargo_busquedaAvanzada,
                        }}
                    ></Input>
                </Col>
                {/* <Col sm={12} className="mb-2rem">
                    <CheckBoxEstados
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        resetChecks={resetChecks}
                        setResetChecks={setResetChecks}
                    />
                </Col> */}
            </Row>
        </>
    )


}
