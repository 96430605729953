import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { clear } from "../../../store/alerts/alertActions";
import { routePaths, tiposTitulos } from "../../../utils/Constants";
import TitulosSearch from "../../shared/TitulosSearch";
import EquivalenciaTable from "./EquivalenciaTable";
import EquivalenciaSearchRow from './EquivalenciaSearchRow'
import { getEquivalentes } from "../equivalenciaService";

const EquivalenciaSearch = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clear());
  }, [dispatch]);

  const controlIds = {
    procedencia: "procedencia",
    resolucion: "resolucion",
    titulo: "titulo",
  };

  const renderRow = (titulo, handleSearch) => {
    return (
      <EquivalenciaSearchRow
        key={titulo.id}
        onSubmit={handleSearch}
        titulo={titulo}
      />
    );
  };

  const renderTable = (
    titulos,
    cantidadTitulos,
    page,
    changePage,
    renderTableRows
  ) => {
    return (
      <EquivalenciaTable
        titulos={titulos}
        cantidadTitulos={cantidadTitulos}
        page={page}
        changePage={changePage}
        renderTableRows={renderTableRows}
      />
    );
  };

  return (
    <TitulosSearch
      title="Equivalencias"
      renderRow={renderRow}
      renderTable={renderTable}
      getTitulos={getEquivalentes}
      controlIds={controlIds}
      nombreElementoResponse="elementos"
      urlCreate={routePaths.ABM_TITULOS_EQUIVALENTES_CREATE}
    />
  );
};

export default EquivalenciaSearch;
