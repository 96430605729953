import React, { useEffect, useRef } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { MultiSelect } from "../ui/MultiSelect";
import { ReactSelect } from './ReactSelect';

const Input = ({
  controlId,
  type,
  label,
  elements,
  syncElements,
  classNameLabel,
  classNameContainer,
  errorMessage = `El campo es obligatorio`,
  config = {
    isSearchable: false,
    isClearable: false,
    isMulti: false,
    withAsyncDelay: false,
  },
  controlSelectId,
  controlInputId,
  onChangeSelect,
  onChangeInput,
  onChangeTextArea,
  selectValue,
  inputValue,
  description,
  predictivoAsync,
  relatedFilters,
  cleanRelatedFiltersRef,
  defaultIndexSelectedOptionInSyncSelect,
  initialValue,
  inputTextLength,
  isLoading,
  maxOptionsBreakAsyncToSync,
  containerStyle = {},
  groupStyle = {},
  withBusquedaAvanzada, //Habilita la primer opcion "Busqueda avanzada: lo que escribiste usando + y -"
  reactSelectStyle,
  reactSelectStyleContainer,
  ...props
}) => {
  let inputElement = null;

  // const isMulti = async (methodOnChange) => {
  //   const resp = await methodOnChange();
  // }

  const isValidTextLength = (texto) => {
    return texto?.length <= inputTextLength
  }

  const handleChangeTextArea = (evento) => {
    if (inputTextLength && !isValidTextLength(evento.target.value)) return
    onChangeTextArea(evento)
  }

  const handleChangeInput = (evento) => {
    evento.target.isBusquedaAvanzada = true
    props.onChange(evento)
  }

  switch (type) {
    case "textarea":
      // inputElement = <textarea {...props} />;
      inputElement = <Form.Control as="textarea" {...props} onChange={handleChangeTextArea} style={{ marginBottom: 0, }} />;
      break;
    case "input":
      inputElement = <Form.Control type="input" {...props} onChange={handleChangeInput} />;
      break;
    case "email":
      inputElement = <Form.Control type="email" {...props} />;
      break;
    case "checkbox":
      inputElement = <Form.Check type="checkbox" {...props} />;
      break;
    case "radio":
      inputElement = <Form.Check type="radio" {...props} />;
      break;
    case "select":
      inputElement = (
        elements && <Form.Control as="select" {...props} disabled={elements.length == 0 || props.disabled }>
          {props.default != "none" && <option>{props.default}</option>}
          {withBusquedaAvanzada && <option value={props.value}><b>Busqueda avanzada: {props.value}</b></option>}
          {elements.map((element) => {
            return (
              <option key={element.id} value={element.id} disabled={element.disabled}>
                {element.nombre ? element.nombre : element.descripcion}
              </option>
            );
          })}
        </Form.Control>
      );
      break;
    case "select-multiple":
      inputElement = (
        <MultiSelect
          options={elements}
          isLoading={isLoading}
          {...props}
        />
      );
      break;
    case "react-select":
      inputElement = (
        <ReactSelect
          getByFilter={elements}
          methodOnChange={props.methodOnChange}
          controlId={controlId}
          additional={{ page: 1 }}
          isSearchable={config.isSearchable}
          isClearable={config.isClearable}
          withAsyncDelay={config.withAsyncDelay}
          isMulti={config.isMulti}
          isDisabled={props.disabled}
          errorMessage={errorMessage}
          predictivoAsync={predictivoAsync}
          relatedFilters={relatedFilters}
          cleanRelatedFiltersRef={cleanRelatedFiltersRef}
          defaultIndexSelectedOptionInSyncSelect={defaultIndexSelectedOptionInSyncSelect}
          initialValue={initialValue}
          maxOptionsBreakAsyncToSync={maxOptionsBreakAsyncToSync}
          withBusquedaAvanzada={withBusquedaAvanzada}
          syncElements={syncElements}
          isLoading={isLoading}
          {...props} />
      );
      break;
    case "react-select-sync":
      inputElement = (
        <ReactSelect
          syncElements={syncElements}
          methodOnChange={props.methodOnChange}
          controlId={controlId}
          isSearchable={config.isSearchable}
          isClearable={config.isClearable}
          isMulti={config.isMulti}
          isDisabled={props.disabled}
          errorMessage={errorMessage}
          isLoading={isLoading}
          {...props} />
      );
      break;
    case "react-select-group":
      inputElement = (
        <InputGroup>
          <ReactSelect
            getByFilter={elements}
            methodOnChange={onChangeSelect}
            controlId={controlSelectId}
            additional={{ page: 1 }}
            isSearchable={config.isSearchable}
            isClearable={config.isClearable}
            isMulti={config.isMulti}
            isDisabled={props.disabled}
            value={selectValue}
            style={reactSelectStyle}
            styleContainer={reactSelectStyleContainer}
            {...props} />
          <FormControl
            aria-describedby="basic-addon1"
            type="input"
            id={controlInputId}
            value={inputValue}
            onChange={onChangeInput}
            placeholder=""
            className="flex-xs-2"
          />
        </InputGroup>
      );
      break;
    default:
      inputElement = <Form.Control id={controlId} type="input" {...props} />;
      break;
  }

  const propsStyles = props.style ?? {}

  return (
    <div style={containerStyle} className={`${classNameContainer ? classNameContainer : ""}`}>
      <Form.Group controlId={controlId} className={"mb-2rem position-relative"}
        style={
          {
            ...propsStyles,
            height: type == "react-select" ? "73px" : "auto",
            ...groupStyle,
          }
        }
      >
        <label className={`react-select-label w-100 ${classNameLabel ? classNameLabel : ""} ${props.disabled ? "text-muted" : ""}`}>
          {label && (<>
            {label}{" "}
            {props.required ? <span className="text-danger"> * </span> : ""}
            {description ? <span className="input-description"> {description} </span> : ""}
          </>)}
          {inputElement}
        </label>

        {inputTextLength &&
          <p style={{ fontSize: "13px", position: "absolute", right: 0 }}>
            {props.value ? props.value.length : 0} /{inputTextLength} caracteres.
          </p>
        }

        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>

      </Form.Group>
    </div>
  );
};

export default Input;
