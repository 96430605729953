import Estado, { createEstado } from "../Estado"
import Resolucion, { createResolucion } from "../Resolucion"
import Titulo, { createTitulo } from "./Titulo"
import Regla, { createRegla } from "../Regla"
import Nota, { createNota } from "../Nota"
import Acciones, { createAcciones } from "../Acciones"
import Procedencia, { createProcedencia } from "./Procedencia"
import { formatearFechaConBarras } from '../../utils/commons';
import Nivel, { createNivel } from "../Nivel";
import Apendice, { createApendice } from "../apendice/Apendice"

class Terna {
    constructor() {
        this.activo = true;
        this.id = "";
        this.titulo = Titulo;
        this.nivel = Nivel;
        this.resolucion = Resolucion;
        this.procedencia = Procedencia;
        this.folioBaja = "";
        this.apendiceBaja = "";
        this.troncal = "";
        this.final = "";
        this.regla = Regla;
        this.nota = Nota;
        this.createdAt = "";
        this.updatedAt = "";
        this.observaciones = "";
        this.estado = Estado;
        this.acciones = [];
        this.unificado = false;
        this.unificacion = null;
    }

    getNombreTitulo() {
        return this.titulo ? this.titulo.nombre : "";
    }
    getNombreNivel() {
        return this.titulo ? this.titulo.getNombreNivel() : "";
    }
    getNombreResolucion() {
        return this.resolucion ? this.resolucion.nombre : "";
    }
    getNombreProcedencia() {
        return this.procedencia ? this.procedencia.nombre : "";
    }

    getNombreEstado() {
        return this.estado ? this.estado.nombre : "";
    }
}

export default Terna

export const createTerna = async (terna) => {

    const notaInfo = {
        nota: terna.nota,
        nota_custom: terna.nota_custom,
        nota_titulo_nombre: terna.nota_titulo_nombre,
        nota_titulo_id: terna.nota_titulo_id,
        es_nota_custom: terna.es_nota_custom,
    }

    delete terna.nota_custom;
    delete terna.nota_titulo_nombre;
    delete terna.nota_titulo_id;
    delete terna.es_nota_custom;

    let estado = await createEstado(terna.estado);
    let titulo = terna.titulo ? await createTitulo(terna.titulo) : {};
    let nivel = terna.titulo ? await createNivel(terna.titulo.nivel) : {};
    let resolucion = terna.resolucion ? await createResolucion(terna.resolucion) : {};
    let regla = terna.regla ? await createRegla(terna.regla) : {};
    let procedencia = terna.procedencia ? await createProcedencia(terna.procedencia) : {};
    let nota = await createNota(notaInfo);
    let acciones = terna.acciones ? await createAcciones(terna.acciones) : new Acciones();
    let createdAt = formatearFechaConBarras(terna.createdAt)
    let updatedAt = formatearFechaConBarras(terna.updatedAt)
    let folioBaja = terna.folio_baja
    let apendiceBaja = terna.apendice_baja ? await createApendice(terna.apendice_baja) : null;
    let unificado = terna.unificado;
    let unificacion = terna.unificado ? terna.unificacion : null;

    delete terna.folio_baja;
    delete terna.apendice_baja;

    const r = Object.assign(new Terna(), { ...terna, nota, estado, titulo, resolucion, procedencia, regla, nivel, createdAt, updatedAt, acciones, folioBaja, apendiceBaja, unificado, unificacion })

    return r;
}

