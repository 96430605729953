import { busquedaAvanzadaFlag } from "../ui/ReactSelect";

export const formatDate = (date) => {
    if (date == null) { return }
    let anio = date.getFullYear();
    let mes = (date.getMonth() + 1).toString().padStart(2, "0");
    let dia = date.getDate().toString().padStart(2, "0");
    return anio + "-" + mes + "-" + dia;
}

export const makeValidFileName = (fileName) => {
    var pattern = /[&\/\\#+()$~%'":*?<>{}]/g;

    return fileName.replace(pattern, '')
}

export const isValidText = (text) => {
    return true;
}

export const isValidNumeric = (text) => {
    return text.match("^[0-9]*$") != null
}

export const isValidFloatNumber = (text, nMin, nMax, nMaxDecimals) => {
    const expReg = `^[0]?([${nMin}-${nMax - 1}]${nMaxDecimals > 0 ? `[.]([0-9]{1,${nMaxDecimals}})?` : ""}|([0-${nMax}]${nMaxDecimals > 0 ? `([.][0]{0,${nMaxDecimals}})?` : ""}))$`;
    return text.match(expReg) != null
}

export const isValidPuntaje = (text) => {
    const nMin = 0;
    const nMax = 3;
    const nMaxDecimals = 2;
    //Permite de [0.00 a 3.00] (con o sin decimales, maximo 2 decimales)

    return isValidFloatNumber(text, nMin, nMax, nMaxDecimals)
}

export const scrollIntoViewRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

export const scrollIntoViewFormError = () => {
    const errorElements = document.querySelectorAll(".form-control:invalid, .form-check-input:invalid, .form-select:invalid, .card .border-danger");
    if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}


export const formatearFechaConBarras = (fecha) => {
    if (!fecha) return "";
    const date = new Date(fecha)
    if (date.getFullYear() === 1969) return "Sin fecha";
    return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear()
}

export const handlerChange = (setInputsForm, inputsForm, setWasModified, e) => {
    const { id, value, isBusquedaAvanzada } = e.target;
    setInputsForm(st => {
        let nwSt = {
            ...st,
            [id]: value,
            [id + busquedaAvanzadaFlag]: !!isBusquedaAvanzada,
        }
        if (!value) delete nwSt[id]
        return nwSt
    });
    if (setWasModified != null) setWasModified(true);
};


export const removeUndefinedKeys = (obj) => {
    const newObj = {}

    if (typeof (obj) == "object") {
        const objKeys = Object.keys(obj)
        objKeys.forEach(ctKey => {
            if (obj[ctKey] != undefined) newObj[ctKey] = obj[ctKey]
        })
    }

    return newObj
}
