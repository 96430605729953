import React from "react";
import { Badge } from "react-bootstrap";
import AsignaturaSearchRowActions from "./AsignaturaSearchRowActions";
import Input from "../../ui/Input";

const AsignaturaSearchRow = ({
  asignatura,
  visibleCheckbox,
  setSeleccion,
}) => {

  return (
    <>
      <tr key={asignatura.id}>
        {visibleCheckbox && (
          <td>
            <div className="form-checkbox-container">
              <Input
                type="radio"
                name="cargo"
                className="option-row"
                value={asignatura.id}
                onChange={() => setSeleccion(asignatura)}
                defaultChecked={false}
              ></Input>
            </div>
          </td>
        )}
        <td>{asignatura.nombre}</td>
        <td>{asignatura.codDad}</td>
        <td>{asignatura.getNombreAreas()}</td>
        <td>{asignatura.getFecha()}</td>
        <td><Badge variant={asignatura.estado ? asignatura.estado.color : "primary"}> {asignatura.getNombreEstado()}</Badge></td>
        <td>
          <div className="table-button-group">
            <AsignaturaSearchRowActions asignatura={asignatura} visibleCheckbox={visibleCheckbox}></AsignaturaSearchRowActions>
          </div>
        </td>
      </tr>
    </>
  );
};

export default AsignaturaSearchRow;
