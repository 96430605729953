import React from 'react';
import Select from "react-select";

export const MultiSelect = ({ onChange, isLoading, selectedOptions, options, ...props }) => {
  return (
    <>
      <Select
        value={selectedOptions}
        onChange={onChange}
        options={options}
        isMulti
        isDisabled={props.disabled}
        isLoading={isLoading}
        {...props}
      />
      {!props.disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
            padding: 0
          }}

          value={selectedOptions}
          required={props.required}
        />
      )}
    </>
  );
};
