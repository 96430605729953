import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UserProfileForm } from "./profile/UserProfileForm";
import IconButton from '../generics/IconButton' 

const UserDetailModal = (props) => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (props.user) {
      setUser(props.user);
    }
    setShow(true);
  };

  return (
    <>
      <IconButton {...props} icon={faSearch} onClick={handleShow} >
        {props.children}
      </IconButton>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
           Detalle del usuario {props.children} {user.nombre + " " + user.apellido}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserProfileForm user={user}></UserProfileForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserDetailModal;
