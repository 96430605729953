import React from 'react';
import { Row, Col } from "react-bootstrap";
import { DetailFact } from "../../ui/DetailFact";
import { DetailNumber } from "../../ui/DetailNumber";

export const UserProfileForm = (props) => {
  return (
    <>
      <Row>
        <Col xs={12} className="form-group">
          <DetailFact label="Nombre de usuario" input={props.user.username} />

        </Col>
        <Col xs={12} md={5} className="form-group">
          <DetailFact label="Nombre" input={props.user.name} />

        </Col>
        <Col xs={12} md={5} className="form-group">
          <DetailFact label="Apellido" input={props.user.apellido} />
        </Col>
        <Col xs={12} md={2} className="form-group">
          <DetailFact label="Activo" input={props.user.activo ? "SI" : "NO"} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12} className="form-group">
          <h3>Documentación</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={4} className="form-group">
          <DetailFact label="Tipo de doc" input={props.user.tipoDocumento ? props.user.tipoDocumento['descripcion'] : ""} />

        </Col>
        <Col xs={6} md={4} className="form-group">
          <DetailFact label="Número" input={props.user.documento} />
        </Col>
        <Col xs={6} md={4} className="form-group">
          <DetailFact label="Sexo" input={props.user.sexo == null ? "N/A" : props.user.sexo} />
        </Col>
      </Row>
    </>
  )
}