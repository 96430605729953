import { screenTypes } from "../../actions/types";

const initialState = {
    clean: false,
    search: false,
    resetChecks: false,
}

const screenReducer = (state = initialState, action) => {

    switch (action.type) {
        case screenTypes.CLEAN_FAB:
            return {
                ...state,
                clean: action.payload
            }
        case screenTypes.SEARCH_FAB:
            return {
                ...state,
                search: action.payload
            }

        case screenTypes.RESET_CHECKS:
            return {
                ...state,
                resetChecks: action.payload
            }

        case screenTypes.RESET_FAB:
            return initialState

        default:
            return state;
    }
}

export default screenReducer;

