import { faCheckDouble, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Accordion, Badge, Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PrivateComponent from "../../../authentication/PrivateComponent";
import IconButton from "../../../generics/IconButton";
import Dialog from "../../../generics/dialog";
import { error, success } from "../../../store/alerts/alertActions";
import { searchFab } from "../../../store/screen/screenActions";
import { CustomTooltip } from "../../../ui/CustomTooltip";
import DialogActivate from "../../../ui/DialogActivate";
import { routePaths, tooltipsText } from "../../../utils/Constants";
import { PERMISOS_MAP } from "../../../utils/Permisos";
import { deshabilitarEquivalencia, eliminarEquivalencia, habilitarEquivalencia, publicarEquivalencia } from "../equivalenciaService";
import { RelacionDetailModal } from "../../shared/details/RelacionDetailModal/RelacionDetailModal";

const EquivalenciaRelacionadoRow = ({
    relacionado,
    original,
    handlerEliminar,
    handlerEditar,
    handleAprobar,
}) => {
    const dispatch = useDispatch();

    const resolveCallBack = () => {
        dispatch(searchFab(true))
    }

    const changeState = async (relacion) => {
        try {
            if (relacion.activo) {
                await deshabilitarEquivalencia(relacion.id, resolveCallBack)
            } else {
                await habilitarEquivalencia(relacion.id, resolveCallBack)
            }
        } catch (e) {
            dispatch(error(e.response.data.message));
        }
    };

    const handlePublicar = async (relacionId) => {
        await publicarEquivalencia(relacionId, resolveCallBack)
    }

    return (
        <tr key={relacionado.terna.id}>
            <td>{relacionado.terna.titulo.nombre}</td>
            <td>{relacionado.terna.procedencia.nombre}</td>
            <td>{relacionado.terna.resolucion.nombre}</td>
            <td>
                <Badge variant={relacionado.estado ? relacionado.estado.color : "primary"} >
                    {relacionado.estado.nombre}
                </Badge>
            </td>
            <td>
                <div className="table-button-group">
                    {(relacionado.acciones.alta || relacionado.acciones.baja) &&
                        <PrivateComponent permisos={relacionado.acciones.alta ?
                            PERMISOS_MAP.TITULO_EQUIVALENTE_ALTA
                            :
                            PERMISOS_MAP.TITULO_EQUIVALENTE_BAJA}>
                            <CustomTooltip text={relacionado.acciones.alta ? tooltipsText.ALTA : tooltipsText.BAJA}>
                                <DialogActivate
                                    target={relacionado}
                                    title={(relacionado.acciones.alta ? "Dar de alta" : "Dar de baja")}
                                    action={changeState}
                                    size="md"
                                    body={<span>
                                        ¿Estás seguro de dar de {(relacionado.acciones.alta ? "alta " : "baja ")}
                                        la relacion equivalente?
                                    </span>
                                    }
                                    btnConfirmText={relacionado.acciones.alta ? "Dar de alta" : "Dar de baja"}
                                    isLoadingText={relacionado.acciones.alta ? "Dando de alta" : "Dando de baja"}
                                >
                                </DialogActivate>
                            </CustomTooltip>
                        </PrivateComponent>
                    }

                    {
                        relacionado.acciones.eliminar &&
                        <PrivateComponent permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_ELIMINAR}>
                            <CustomTooltip text={tooltipsText.ELIMINAR}>
                                <Dialog
                                    action={handlerEliminar}
                                    target={relacionado.terna}
                                    title={"Eliminar relación"}
                                    body={<span>
                                        ¿Estás seguro que quiere eliminar la relación entre títulos?
                                    </span>
                                    }
                                    size="md"
                                    btnIcon={faTrash}
                                    btnVariant={"danger"}
                                    variant={"danger"}
                                    btnConfirmText="Eliminar"
                                    isLoadingText={"Eliminando"}
                                />
                            </CustomTooltip>
                        </PrivateComponent>
                    }

                    {relacionado.acciones.aprobar &&
                        <PrivateComponent permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_APROBAR}>
                            <CustomTooltip text={tooltipsText.APROBAR}>
                                <IconButton
                                    icon={faCheckDouble}
                                    onClick={handleAprobar}
                                />
                            </CustomTooltip>
                        </PrivateComponent>
                    }

                    {relacionado.acciones.publicar &&
                        <PrivateComponent permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_PUBLICAR}>
                            <CustomTooltip text={tooltipsText.PUBLICAR}>
                                <RelacionDetailModal
                                    relacionado={relacionado}
                                    original={original}
                                    withPublicar={true}
                                    onSubmit={resolveCallBack}
                                    handlePublicar={handlePublicar}
                                ></RelacionDetailModal>
                            </CustomTooltip>
                        </PrivateComponent>
                    }

                    {relacionado.acciones.editar &&
                        <PrivateComponent PrivateComponent permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_EDITAR}>
                            <CustomTooltip text={tooltipsText.EDITAR}>
                                <IconButton icon={faPen} onClick={handlerEditar} />
                            </CustomTooltip>
                        </PrivateComponent>
                    }

                    <CustomTooltip text={tooltipsText.DETALLE}>
                        <RelacionDetailModal relacionado={relacionado} original={original} />
                    </CustomTooltip>
                </div>
            </td>
        </tr >
    )
}

export const EquivalenciaAccordion = ({
    titulo,
}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const handlerEditar = (relacionado) => {
        const historyState = {
            relacionEquivalente: {
                original: titulo.original,
                relacionado,
            }
        }
        history.push(routePaths.ABM_TITULOS_EQUIVALENTES_EDIT, historyState)
    };

    const handleAprobar = (relacionado) => {
        const historyState = {
            relacionEquivalente: {
                original: titulo.original,
                relacionado,
            }
        }
        history.push(routePaths.ABM_TITULOS_EQUIVALENTES_APPROVAL, historyState)
    }

    const handlerEliminar = async (id) => {
        try {
            await eliminarEquivalencia(id)
            dispatch(searchFab(true))
            dispatch(success("Se elimino correctamente la relacion equivalente."));
        }
        catch (err) {
            console.log({ err })
            dispatch(error("No se pudo eliminar la relacion equivalente."));
        }
    }

    return (
        <Accordion.Collapse eventKey="0">
            <Card.Body>
                <Row className="form-group">
                    <Col>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th width="35%">Nombre del título</th>
                                    <th width="30%">Procedencia</th>
                                    <th width="20%">Resolución</th>
                                    <th>Estado relacion</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {titulo.relacionados.map(relacionado =>
                                    <React.Fragment key={relacionado.id}>
                                        <EquivalenciaRelacionadoRow
                                            relacionado={relacionado}
                                            original={titulo.original}
                                            handlerEliminar={() => handlerEliminar(relacionado.id)}
                                            handleAprobar={() => handleAprobar(relacionado)}
                                            handlerEditar={() => handlerEditar(relacionado)}
                                        />
                                    </React.Fragment>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Accordion.Collapse>
    );
};