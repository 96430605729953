import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import alert from "./alerts/alertReducer";
import message from "./messages/messageReducer";
import search from "./search/SearchReducer";
import screen from "./screen/screenReducer";
import incumbencias from "./incumbencias/incumbenciasReducer";
import data from "./data/dataReducer";
import descargas from "./descargas/descargasReducer";

export default combineReducers({
  auth,
  alert,
  message,
  search,
  screen,
  incumbencias,
  data,
  descargas,
});
