import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants"
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import CargosABM from "./CargosABM"
import { useLocation } from "react-router-dom";
import Cargo, { createCargo } from "../../dtos/cargo/Cargo";
import { saveFilters } from "../../store/search/searchActions";

const CargosABMEdit = (props) => {
  let location = useLocation();
  const [cargo, setCargo] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos data de los filtrosGuardados para forzar refresh al cargar la data en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.data
    dispatch(saveFilters(newFiltrosGuardados))
  };

  const crearCargo = async (cargo) => {
    const cargoReloaded = await createCargo(cargo);
    return cargoReloaded;
  }

  const asignarCargo = (cargo) => {
    setCargo(cargo);
    if (cargo instanceof Cargo)
      setLoading(false);
  }

  useEffect(() => {
    dispatch(clear());
    setLoading(true);
    if (location.state && location.state.cargo) {
      let cargo = location.state.cargo;
      if (!(cargo instanceof Cargo)) {
        cargo = crearCargo(cargo)
      }
      asignarCargo(cargo);
    }
  }, [dispatch, location.state]);

  return (!loading &&
    <CargosABM cargo={cargo} title="Editar Cargo" typeOfAction={typeOfActions.EDIT} onSubmit={handleSubmit} returnPath={routePaths.ABM_CARGOS_SEARCH} />
  );
};

export default CargosABMEdit;
