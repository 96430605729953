import { Redirect } from "react-router-dom";
import React, { useState } from "react";
import TernaSearchRowActions from "./TernaSearchRowActions";
import { Badge } from "react-bootstrap";

const TernaSearchRow = ({ terna, ...props }) => {
    const [redirect, setRedirect] = useState("");


    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        terna: terna,
                        abmTitle: "Editar",
                        submitValue: "Habilitar",
                    },
                }}
            ></Redirect>
        );
    }
    
    return (
        <>
            <tr key={terna.id}>
                <td>{terna.getNombreTitulo()}</td>
                <td>{terna.getNombreNivel()}</td>
                <td>{terna.getNombreProcedencia()}</td>
                <td>{terna.getNombreResolucion()}</td>
                <td>{terna.createdAt}</td>
                <td><Badge variant={terna.estado ? terna.estado.color:"primary"}> {terna.getNombreEstado()}</Badge></td>
                <td>
                    <div className="table-button-group">
                        <TernaSearchRowActions terna={terna} onSubmit={props.onSubmit}></TernaSearchRowActions>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default TernaSearchRow;
