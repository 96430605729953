import React from 'react';
import { Badge } from 'react-bootstrap';

export const ClonadorTernaRow = ({
    elemento = {},
}) => {
    return (
        <tr className="border-bottom-separator">
            <td>{elemento.titulo?.nombre ?? "-"}</td>
            <td>{elemento.procedencia?.nombre ?? "-"}</td>
            <td>{elemento.resolucion?.nombre ?? "-"}</td>
            <td>{elemento.nivel?.descripcion ?? "-"}</td>
            <td>{elemento.createdAt ?? "-"}</td>
            <td>
                <Badge variant={elemento.estado?.color}>
                    {elemento.estado?.nombre}
                </Badge>
            </td>
            <td>{elemento.cantidadIncumbencias ?? "-"}</td>
            {/* <td>< 
                <CustomTooltip text={tooltipsText.DETALLE}>
                    <ClonadorTernaDetailModal
                        terna={elemento}
                        elementosPrimerPagina={elemento.incumbenciasPrimerPagina}
                        cantidadElementos={elemento.cantidadIncumbencias}
                        withIconSearch={true}
                    ></ClonadorTernaDetailModal>
                </CustomTooltip>
            </td> */}
        </tr>
    )
}
