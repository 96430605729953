import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as escuelaService from "../../../escuela/EscuelaService";
import Dialog from "../../../generics/dialog";
import { success } from "../../../store/alerts/alertActions";
import Input from "../../../ui/Input";

export const EscuelaAddModal = ({ handleClose, show = false, action }) => {
  const [validated, setValidated] = useState(false);
  const [codigoEscuela, setCodigoEscuela] = useState('');
  const [errorMsg, setErrorMsg] = useState("")

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setCodigoEscuela(value)
  };

  const handleValidation = async (resolve) => {
    setValidated(false);
    setErrorMsg("");
    const form = document.getElementById("formCreateCodigoEscuela");
    if (!form.checkValidity() === false) {
      try {
        await escuelaService.createCodigoEscuela({ codigoEscuela }, resolve);
        dispatch(success(<>Se creó el codigo de escuela <b>{codigoEscuela}</b> correctamente.</>));
        handleClose()
      }
      catch (err) {
        setErrorMsg(err?.response?.data?.message ?? "Ha ocurrido un error al enviar la peticion.");
      }
    }
    setValidated(true);
  };

  const guardar = async () => {
    await handleValidation(action)
  }

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const resetModal = () => {
    setValidated(false);
    setErrorMsg("");
    setCodigoEscuela('');
  }

  useEffect(() => {
    if (show) resetModal();
  }, [show])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Crear Codigo de Tipo de Escuela
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="formCreateCodigoEscuela"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            {errorMsg && <Alert variant={"danger"}>{errorMsg}</Alert>}
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Input
                  type="input"
                  controlId="codigoEscuela"
                  label="Codigo de Tipo de Escuela"
                  maxLength="200"
                  value={codigoEscuela}
                  onChange={handleChange}
                  required
                ></Input>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
          <Dialog
            action={guardar}
            target={{}}
            title={"Guardar como borrador"}
            variant="primary"
            size="md"
            btnConfirmText="Guardar"
            btnVariant="success"
            btnText="Guardar"
            isLoadingText={"Guardando"}
            body={
              <span>
                ¿Estás seguro de guardar el codigo de tipo de escuela?
              </span>
            }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}