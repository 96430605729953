import { typesConstants } from "../../utils/Constants";

const initialState = {};

const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case typesConstants.SET_MESSAGE:
      return { message: payload };

    case typesConstants.CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}

export default messageReducer