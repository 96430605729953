import React, { useState, useEffect } from "react";

import { routePaths, typeOfActions } from "../../utils/Constants"
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch, useSelector } from "react-redux";
import AsignaturasABM from "./AsignaturasABM";
import { useLocation } from "react-router-dom";
import Asignatura, { createAsignatura } from "../../dtos/asignatura/Asignatura";
import { saveFilters } from "../../store/search/searchActions";

const AsignaturaABMEdit = (props) => {
  let location = useLocation();
  const [asignatura, setAsignatura] = useState();

  const dispatch = useDispatch();
  const filtrosGuardados = useSelector((state) => state.search);

  const handleSubmit = async (user) => {
    //Borramos elementos de los filtrosGuardados para forzar refresh al cargar la elementos en la pantalla search
    const newFiltrosGuardados = { ...filtrosGuardados }
    delete newFiltrosGuardados.elementos
    dispatch(saveFilters(newFiltrosGuardados))
  }

  useEffect(() => {
    dispatch(clear());
    async function cargarAsignatura(asignatura) {
      // TODO: Ver por que no entra.
      const asignaturaConcret = await createAsignatura(asignatura);
      return asignaturaConcret;
    }

    if (location.state && location.state.asignatura) {
      let asignatura = location.state.asignatura;
      if (!(asignatura instanceof Asignatura)) {
        asignatura = cargarAsignatura(asignatura);
      }
      setAsignatura(asignatura);
    }
  }, [dispatch, location.state]);

  return (
    <AsignaturasABM asignatura={asignatura} title="Editar Asignatura" typeOfAction={typeOfActions.EDIT} onSubmit={handleSubmit} returnPath={routePaths.ABM_ASIGNATURAS_SEARCH} />
  );
};

export default AsignaturaABMEdit;
