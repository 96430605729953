import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import * as escuelaService from "../../escuela/EscuelaService";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import EspecialidadesDialog from "../../especialidades/dialogs/EspecialidadesABMDialog";
import Dialog from "../../generics/dialog";
import { validateReactInputs } from "../../helpers/validations";
import { clear } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { clearFilters } from "../../store/search/searchActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { formatElements } from '../../ui/ReactSelect';
import { routePaths, typeOfActions } from "../../utils/Constants";
import { handlerChange, isValidText } from "../../utils/commons";
import { EscuelaAddModal } from "./components/EscuelaAddModal";

export const OPCIONES_CAMPO1 = [
  { value: "NO DEFINIDO", label: "NO DEFINIDO" },
  { value: "MEDIO", label: "MEDIO" },
  { value: "INICIAL", label: "INICIAL" },
  { value: "ESPECIAL", label: "ESPECIAL" },
  { value: "CURRICULAR", label: "CURRICULAR" },
  { value: "PRIMARIO", label: "PRIMARIO" },
];

const EscuelaABMForm = ({ escuela, returnPath, typeOfAction = typeOfActions.CREATE, isAprproval = false, ...props }) => {
  const [inputsForm, setInputsForm] = useState([]);
  const [analysts, setAnalysts] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const isMountedRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [wasModified, setWasModified] = useState(false);
  const [areasSeleccionadas, setAreasSeleccionadas] = useState();
  const [codigoSeleccionado, setCodigoSeleccionado] = useState();
  const [campo1, setCampo1] = useState();

  const [showModalCodigoEscuela, setShowModalCodigoEscuela] = useState(false);

  const handleGuardarCodigoEscuela = (newCodigoTipoEscuela) => {
    setInputsForm(st => ({
      ...st,
      tipoEscuela: newCodigoTipoEscuela.valor
    }))
    setCodigoSeleccionado({ value: newCodigoTipoEscuela.valor, label: newCodigoTipoEscuela.valor })
    setWasModified(true);
  }

  const handleCloseModalTitulo = () => setShowModalCodigoEscuela(false);

  const dispatch = useDispatch();
  const history = useHistory();

  if (inputsForm.length < 1 && escuela) {
    setInputsForm({
      id: escuela.id,
      nombre: escuela.nombre,
      apendice: escuela.apendice,
      areas: escuela.area[0].id,
      tipoEscuela: escuela.id_tipo_escuela,
      campo1: {
        label: escuela.campo1, value: escuela.campo1,
      },

    });
    const formato = { value: "id", label: "nombre" };
    const codigoEscuela = new Object();
    codigoEscuela.id = escuela.id_tipo_escuela;
    codigoEscuela.nombre = escuela.id_tipo_escuela;

    setCampo1({ value: escuela.campo1, label: escuela.campo1 })
    setCodigoSeleccionado(formatElements([codigoEscuela], formato));
    setAreasSeleccionadas(formatElements(escuela.area, formato));
  }

  const getCurrentApendice = async () => {
    if (typeOfAction == typeOfActions.CREATE) {
      apendiceService.getCurrentApendice().then(result => {
        if (isMountedRef.current) {
          setInputsForm(data => ({ ...data, apendice: result }))
        }
      });
    }
  };

  const submitResolve = (cargo) => {
    history.push(routePaths.ABM_ESCUELA_SEARCH);
    dispatch(searchFab(true));
  }

  const submitResolveWithClearFilters = () => {
    dispatch(clearFilters())
    submitResolve()
  }

  const handleSubmit = async (event) => {
    await handleValidation(escuela => escuelaService.approve(escuela, submitResolve));
  };

  const handlePublish = async (event) => {
    await handleValidation(escuelaService.publish);
  };

  const handleEnviarRevision = async (analista) => {
    handleValidation(escuelaService.sendToRevice);
  };

  const handleBorrador = async (event) => {
    await handleValidation(escuela => escuelaService.saveDraft(
      escuela,
      typeOfAction == typeOfActions.CREATE ? submitResolveWithClearFilters : submitResolve
    ));
  };

  const handleEnviarAutorizar = async (supervisor) => {
    handleValidation(escuelaService.sendToAuthorize);
  };

  const handleValidation = async (resolve) => {
    const form = document.getElementById("formSearchEscuela");
    if (!form.checkValidity() === false) {
      if (wasModified) {
        const requiredReactInputsIds = [
          "areas",
          "tipoEscuela",
          "campo1"
        ];
        const isValidated = validateReactInputs(inputsForm, requiredReactInputsIds)
        if (isValidated) {
          if (escuela) {
            if (await escuelaService.editEscuela(inputsForm, resolve))
              setWasModified(false);
          } else {
            if (await escuelaService.createNewEscuela(inputsForm, resolve))
              setWasModified(false);
          }
        }
      } else {
        await resolve(inputsForm);
      }
      setWasModified(false);
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, setWasModified, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handlerChange(setInputsForm, inputsForm, setWasModified, e);
    }
  };

  const handleChangeCampo1 = (event) => {
    if (!event?.target?.value) return handleChange(event);
    const eTarget = { target: { id: event.target.id, value: { value: event.target.value, label: event.target.label } } };
    handleChange(eTarget);
  }

  const preventDefault = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    getCurrentApendice();

    return () => (isMountedRef.current = false);
  }, [dispatch]);

  return (
    <Row>
      <Col>
        {true ? (
          <Form
            id="formSearchEscuela"
            noValidate
            validated={validated}
            onSubmit={preventDefault}
          >
            <Row>
              <Col sm={12} md={8} lg={12}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Descripcion de Tipo de Escuela"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  maxLength="200"
                  required
                  {...props}
                ></Input>
              </Col>

              <Col sm={12} md={6} lg={11}>
                <Input
                  type="react-select"
                  controlId="areas"
                  name="areas"
                  label="Área"
                  value={areasSeleccionadas}
                  methodOnChange={handleChange}
                  elements={especialidadesService.getAreas}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setAreasSeleccionadas}
                  config={{
                    isSearchable: true,
                    isClearable: true,
                  }}
                  validated={validated}
                  required
                  {...props}
                ></Input>
              </Col>

              <Col xs={4} md={2} xl={1}>
                <Input
                  type="input"
                  controlId="apendice"
                  label="Apéndice"
                  classNameLabel="text-muted"
                  onChange={handleChange}
                  readOnly
                  value={inputsForm.apendice ? inputsForm.apendice.id : "---"}
                ></Input>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Input
                  type="react-select"
                  controlId="tipoEscuela"
                  name="tipoEscuela"
                  label="Codigo de Tipo de Escuela"
                  value={codigoSeleccionado}
                  methodOnChange={handleChange}
                  elements={escuelaService.getCodigoEscuela}
                  nameElementsResponse="data"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setCodigoSeleccionado}
                  config={{
                    isSearchable: false,
                    isClearable: false,
                  }}
                  validated={validated}
                  required
                  {...props}
                ></Input>
                <button className="btn btn-block btn-primary mb-2" disabled={props.disabled} onClick={setShowModalCodigoEscuela}> Crear Codigo Tipo de Escuela </button>
              </Col>
              <Col lg={6}>
                <Input
                  type="react-select-sync"
                  controlId="campo1"
                  name="campo1"
                  label="Tipo de Nivel"
                  value={campo1}
                  setterElementSelect={setCampo1}
                  methodOnChange={handleChangeCampo1}
                  syncElements={OPCIONES_CAMPO1}
                  disabled={OPCIONES_CAMPO1.length == 0}
                  config={{ isSearchable: true, isClearable: true }}
                  required
                  validated={validated}
                  {...props}
                ></Input>
              </Col>
            </Row>

            <Row className="my-4">
              <Col>
                {wasModified &&
                  <PrivateComponent>
                    <Dialog
                      action={handleBorrador}
                      target={escuela}
                      title={"Guardar como borrador"}
                      variant="primary"
                      size="md"
                      btnConfirmText="Guardar"
                      btnVariant="secondary"
                      btnText="Guardar como borrador"
                      isLoadingText={"Guardando"}
                      body={<span>
                        ¿Estás seguro de mantener el tipo de escuela
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""} </b>
                        como borrador?
                      </span>}
                    />
                  </PrivateComponent>
                }

                {false &&
                  <EspecialidadesDialog
                    onSubmit={handleEnviarAutorizar}
                    title="Enviar a autorizar"
                    items={analysts}
                    especialidad={escuela}
                    btnConfirmText="Enviar"
                    name="Supervisor"
                  >
                    Enviar a autorizar
                  </EspecialidadesDialog>
                }
                {false &&
                  <EspecialidadesDialog
                    onSubmit={handleEnviarRevision}
                    title="Enviar a revisar"
                    items={supervisors}
                    especialidad={escuela}
                    btnConfirmText="Enviar"
                    name="Analista"
                  >
                    Enviar a revisar
                  </EspecialidadesDialog>
                }
                {isAprproval &&
                  <PrivateComponent>
                    <Dialog
                      action={handleSubmit}
                      target={escuela}
                      title="Confirmar aprobación"
                      variant="success"
                      size="md"
                      btnConfirmText="Aprobar"
                      btnText="Aprobar"
                      btnVariant="success"
                      isLoadingText={"Aprobando"}
                      body={<span>¿Estás seguro de aprobar el tipo de escuela
                        <b> {inputsForm.nombre ? inputsForm.nombre : ""}</b>?
                      </span>}
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                {false &&
                  <PrivateComponent>
                    <Dialog
                      action={handlePublish}
                      target={escuela}
                      title="Publicar modificaciones"
                      size="md"
                      btnConfirmText="Confirmar y enviar"
                      btnText="Publicar modificaciones"
                      btnVariant="success"
                      body={
                        "Al publicar las modificaciones el tipo de escuela volverá " +
                        "a estar en estado Publicado con todas las modificaciones que hayas realizado"
                      }
                    />
                  </PrivateComponent>
                }
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}

        <EscuelaAddModal
          show={showModalCodigoEscuela}
          handleClose={handleCloseModalTitulo}
          action={handleGuardarCodigoEscuela}
        />

      </Col>
    </Row>
  );
};

export default EscuelaABMForm;
