import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { success, clear } from "../../store/alerts/alertActions";
import { useDispatch } from "react-redux";
import CargosABMForm from "./CargosABMForm";
import { ScreenWithFabButtons } from "../../ui/ScreenWithFabButtons";
import { useLocation } from "react-router-dom";

const CargosABM = ({ title, submitValue, cargo, onSubmit, ...props }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(clear());
    }, [dispatch]);

    const handleSubmit = async (cargo) => {
        dispatch(success("Se creó el cargo con éxito."));
    }

    return (
        <ScreenWithFabButtons
            returnPath={location.state?.returnPath}
        >
            <Row>
                <Col>
                    <Row className="form-group">
                        <Col>
                            <h1>{title ? title : "Crear Cargo"}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CargosABMForm
                                onSubmit={onSubmit ? onSubmit : handleSubmit}
                                cargo={cargo}
                                {...props}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ScreenWithFabButtons>
    );
};

export default CargosABM;
