import { faPen } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import IconButton from '../../generics/IconButton'
import { DetailFact } from '../../ui/DetailFact'
import { Loader } from '../../ui/Loader'
import { tooltipsText, typeOfActions } from '../../utils/Constants'
import { DetailCargo } from '../detail/components/DetailCargo'
import { DetailTerna } from '../detail/components/DetailTerna'
import { ticketsService } from '../TicketsService'
import { ticketsUtils } from '../TicketsUtils'
import { TicketsCreateModal } from './TicketsCreateModal'
import { CustomTooltip } from '../../ui/CustomTooltip'

export const TicketsEditModal = ({ ticket }) => {
    const [ticketData, setTicketData] = useState();
    const [searching, setSearching] = useState(false);

    const fetchTicketToEditModal = () => {
        return ({
            id: ticket.id,
            tipoTicket: { value: ticket.tipo.id, label: ticket.tipo.nombre },
            moduloTicket: { value: ticket.modulo.id, label: ticket.modulo.nombre },
            analistaId: ticket.assignedTo.id,
            analista: { value: ticket.assignedTo.id, label: ticket.assignedTo.nombreCompleto },
            observacion: ticket.observacion
        })
    }

    const loadTicketData = async () => {
        try {
            setSearching(true)
            const response = await ticketsService.getTicket(ticket.id)
            setTicketData(response)
        }
        catch (err) {
            console.log("Error", err)
        }
        finally {
            setSearching(false)
        }
    }

    useEffect(() => {
        if (!ticketData) {
            loadTicketData();
        }
    }, [])

    return (
        <TicketsCreateModal
            isLoadingTicket={searching}
            isDeletedTicket={ticket.tipo.nombre != "Alta" && !ticketData?.object}
            action={typeOfActions.EDIT}
            ticketId={ticket.id}
            iconType={"customButton"}
            iconJsx={
                <CustomTooltip text={tooltipsText.EDITAR}>
                    <IconButton icon={faPen}></IconButton>
                </CustomTooltip>
            }
            defaultInputs={fetchTicketToEditModal()}
            modalTitle={`Editar: Ticket #${ticket.id}`}
            onlyAlta={ticket.tipo.nombre == ticketsUtils.OPTIONS_TIPOS_TICKETS_NAME.ALTA}
            objDisableInputs={{
                moduloTicket: true
            }}
            detailSection={<div>
                {searching && <Loader></Loader>}

                {ticketData && !searching && ticketData.object &&
                    <>
                        <hr />
                        {
                            ticketData.object.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS &&
                            <>
                                <DetailTerna terna={ticketData.object} />
                                <hr />
                            </>
                        }
                        {
                            ticketData.object.modulo.nombre == ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS &&
                            <>
                                <DetailTerna terna={ticketData.object.terna} />
                                <hr />
                                <DetailCargo
                                    cargo={ticketData.object.cargo}
                                    asignatura={ticketData.object.asignatura}
                                    especialidad={ticketData.object.especialidad}
                                    tipoEscuela={ticketData.object.tipoEscuela}
                                    area={ticketData.object.area}
                                />
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <p><b>Folio:</b> {ticketData.object.folio}</p>
                                    <p><b>Calidad:</b> {ticketData.object.calidad}</p>
                                    <p><b>Puntaje:</b> {ticketData.object.puntaje}</p>
                                </div>
                                <hr />
                                {ticketData.object.concurrencia && <>
                                    <div>
                                        <b>En concurrencia con:</b>
                                        {ticketData.object.terna2 && <DetailTerna terna={ticketData.object.terna2} />}
                                        {ticketData.object.terna3 && <DetailTerna terna={ticketData.object.terna3} />}
                                    </div>
                                    <hr />
                                </>
                                }
                            </>
                        }
                    </>
                }
            </div>
            }
        />
    )
}
