import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import Cargo, { createCargo } from "../dtos/cargo/Cargo";
import Escuela, { createEscuela, createCodigo } from "../dtos/escuela/Escuela";
const { apiURLs, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");

//tener en cuenta cuando se agregue limite por el paginado que este metodo tmb se usa en selects
const getCargos = async (filtros = {}) => {
  let cargos = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_CARGO, filtros)
  response.data.elementos.forEach(cargo => {
    cargos.push(createCargo(cargo))
  });

  let cargosConcretos = await Promise.all(cargos)
  return { cantidad: response.data.cantidad, data: cargosConcretos };

}
const getEscuelas = async (filtros = {}) => {
  let escuelas = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_TIPO_ESCUELA, filtros)
  response.data.elementos.forEach(escuela => {
    escuelas.push(createEscuela(escuela))
  });

  let escuelasConcretas = await Promise.all(escuelas)
  return { cantidad: response.data.cantidad, page: response.data.page, data: escuelasConcretas };
}

const getEscuelasByVariantes = async (filtros = {}) => {
  let escuelas = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_TIPO_ESCUELA + "/xx/getTipoEscuelasByVariantes", filtros)
  response.data.elementos.forEach(escuela => {
    escuelas.push(createEscuela(escuela))
  });

  let escuelasConcretas = await Promise.all(escuelas)
  return { cantidad: response.data.cantidad, page: response.data.page, data: escuelasConcretas };
}

const getCodigoEscuela = async (filtros = {}) => {
  let codigos = [];

  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_TIPO_ESCUELA_CODIGO);
  response.data.elementos.forEach(codigo => {
    codigos.push(createCodigo(codigo));
  });

  let codigosConcretos = await Promise.all(codigos);
  return { cantidad: response.data.cantidad, data: codigosConcretos };
}


const getEscuela = async (idEscuela) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_TIPO_ESCUELA + `/${idEscuela}`)
  return response.data;
}

const editEscuela = async (escuela, resolve) => {
  escuela.area_id = escuela.areas;
  return await createEditEscuela(armarEscuela(escuela), resolve, 'PATCH', apiURLs.API_MAESTROS_TIPO_ESCUELA + `/${escuela.id}`);
}

const createCodigoEscuela = async (params, resolve) => {
  // const response = await requestService.sendRequest('POST', apiURLs.API_TERNAS_TITULO, params)
  const response = { data: { valor: params.codigoEscuela } }
  if (resolve) resolve(response.data);
  return response
}

const createNewEscuela = async (escuela, resolve) => {
  return await createEditEscuela(armarEscuela(escuela), resolve, 'POST', apiURLs.API_MAESTROS_TIPO_ESCUELA);
}

const createEditEscuela = async (escuela, resolve, typeReuest, apiURL) => {
  const cargoArmado = armarEscuela(escuela);
  await requestService.sendRequest(typeReuest, apiURL, cargoArmado)
    .then(resp => {
      resolve(resp.data)
      return true
    }).catch(e => {
      store.dispatch(error(e.response.data.message));
      return false
    })
}

/**
 * Crea el objeto que se envia al endpoit para crear o editar.
 * @param {} Escuela 
 * @returns 
 */
const armarEscuela = (escuela) => {
  let { nombre, tipoEscuela, areas, nivel, ...body } = escuela;
  let area = areas;
  //ESPINTODO: 
  return {
    nombre: nombre,
    tipo_escuela: tipoEscuela,
    area_id: area,
    ...body
  };
  // Controla que nivel tenga keys?
  //nivel: nivel && Object.keys(nivel).length===1 ? nivel: null,
}

const sendToAuthorize = async (cargo) => {
  await changeState(cargo, estadosConstans.PARA_SER_AUTORIZADO, "Se envió a autorizar el cargo correctamente.")
}

const saveDraft = async (escuela, resolve) => {
  if (resolve) resolve(escuela);
  await store.dispatch(success(<>Se guardó correctamente el tipo de escuela <b>{escuela.nombre}</b> como borrador.</>))
}

const sendToRevice = async (cargo) => {
  changeState(cargo, estadosConstans.EN_REVISION, "Se envió a revisar el tipo de escuela cargo correctamente.")
}

const approve = async (escuela, resolve) => {
  await changeState(escuela, estadosConstans.APROBADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se aprobo el tipo de escuela <b>{escuela.nombre}</b> correctamente.</p>))
}

const publish = async (escuela, resolve) => {
  await changeState(escuela, estadosConstans.PUBLICADO)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se publico el tipo de escuela <b>{escuela.nombre}</b> correctamente.</p>))
}

const changeState = async (escuela, newState, message) => {
  await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_TIPO_ESCUELA + `/${escuela.id}/estado`, { estado: newState })
}

const deleteEscuela = async (escuela) => {
  await requestService.sendRequest('DELETE', apiURLs.API_MAESTROS_TIPO_ESCUELA + `/${escuela.id}`)
    .then(resp => {
      store.dispatch(success("Se elimino correctamente el tipo de escuela."))
    }).catch(e => { store.dispatch(error(e.response.data.message)) })
}


const deactivate = async (escuela, resolve) => {
  await changeState(escuela, estadosConstans.DESHABILITADO)
  if (resolve) await resolve();
  await store.dispatch(success(<p>Se desactivo el tipo de escuela <b>{escuela.nombre}</b> correctamente.</p>))
}

const activate = async (escuela, resolve) => {
  await changeState(escuela, estadosConstans.BORRADOR)
  if (resolve) resolve();
  await store.dispatch(success(<p>Se activo el tipo de escuela <b>{escuela.nombre}</b> correctamente.</p>))
}


export {
  saveDraft,
  sendToAuthorize,
  createNewEscuela,
  editEscuela,
  getCargos,
  approve,
  sendToRevice,
  publish,
  activate,
  deactivate,
  getEscuelas,
  deleteEscuela,
  getCodigoEscuela,
  getEscuela,
  getEscuelasByVariantes,
  createCodigoEscuela,
}

