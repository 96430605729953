import React, { useState, useEffect } from "react";
import Input from "../../ui/Input";
import { getRolesNames } from "../../roles/RoleService";
import { Form, Button, Row, Col } from "react-bootstrap";
import UserService from "../../utils/user.service";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../utils/Constants";
import { error, clear } from "../../store/alerts/alertActions";
import { saveFilters } from "../../store/search/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../ui/Loader";
import { useRef } from "react";
import { isValidText, isValidNumeric, handlerChange } from "../../utils/commons";
import { formatElements } from '../../ui/ReactSelect';
import { cleanFab, searchFab } from "../../store/screen/screenActions";

const UserABMForm = ({
  user,
  validate = true,
  filtros,
  onSubmit,
  submitValue
}) => {
  const [rol, setRoles] = useState([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState("");
  const [documento, setDocumento] = useState("");
  const [tipoSexoSeleccionado, setTipoSexoSeleccionado] = useState("");
  const [inputsForm, setInputsForm] = useState([]);
  const [validated, setValidated] = useState(false);
  const isMountedRef = useRef(null);
  const [isChecked, setIsChecked] = useState(true);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);

  if ((inputsForm.length < 1 && user)) {
    setInputsForm(user);

    setTipoSexoSeleccionado(UserService.getTiposSexo().filter(sexo => sexo.value === user.sexo));
    setTipoDocumentoSeleccionado(formatElements(user.tipoDocumento, { value: "id", label: "descripcion" }));
    setDocumento(user.documento);
  }

  //Lleno los inputs si se vuelve atras
  if (inputsForm.length < 1 && filtros) {
    setInputsForm(filtros);

    setTipoSexoSeleccionado(UserService.getTiposSexo().filter(sexo => sexo.value === filtros.sexo));
    setTipoDocumentoSeleccionado(filtros.tipoDocumentoSeleccionado);
    setDocumento(filtros.documento);
  }

  const cargarInputsFormConSeleccionados = () => {
    inputsForm.tipoDocumentoSeleccionado = tipoDocumentoSeleccionado;
    inputsForm.documento = documento;
    inputsForm.tipoSexoSeleccionado = tipoSexoSeleccionado;
  }

  const getRolesBeforeToRender = async () => {
    getRolesNames().then((roles) => {
      if (isMountedRef.current) {
        setRoles(roles);
      }
    });
  };

  const hasRol = (rol) => {
    if (inputsForm.rol) {
      return inputsForm.rol.find((r) => r.id == rol.id) !== undefined;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate) {
      const form = event.currentTarget;
      if (!form.checkValidity() === false) {
        processSubmit();
      }
      setValidated(true);
    } else {
      processSubmit();
    }
  };

  const processSubmit = async () => {
    //Para el paginado
    inputsForm["page"] = 1;
    cargarInputsFormConSeleccionados();

    dispatch(saveFilters(inputsForm));

    await onSubmit(inputsForm).catch((err) => {
      dispatch(error(err.response.data.message));
    });
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const handleValidChange = (isValid, e) => {
    if (isValid(e.target.value)) {
      handleChange(e);
    }
  };

  const handleChangeDocumento = (e) => {
    handleValidChange(isValidNumeric, e);
    setDocumento(e.target.value);
  }

  const processRoles = async () => {
    let roles = [];
    setIsChecked(false)
    document
      .querySelectorAll("input[type=checkbox]:checked")
      .forEach(function (rol) {
        if (rol.id !== "checkboxAll") {
          roles.push({ id: rol.value });
        }
      });
    setInputsForm({
      ...inputsForm,
      rol: roles,
    });
  };

  const renderRolesCheckbox = () => {
    return rol.map((rol) => {
      return (
        <div key={rol.id} className="form-checkbox-container">
          <Input
            className="checkboxEstados"
            type="checkbox"
            name="roles[]"
            value={rol.id}
            label={rol.nombre}
            checked={hasRol(rol)}
            onChange={processRoles}
          ></Input>
        </div>
      );
    });
  };

  const setAll = (setAllTo) => {
    document
      .querySelectorAll(".checkboxEstados")
      .forEach((estado) => {
        if (!isChecked) {
          estado.childNodes[0].checked = false
        }
      });
    if (setAllTo) {
      setIsChecked(true)
    } else {
      setIsChecked(!isChecked)
    }
    setInputsForm({
      ...inputsForm,
      rol: [],
    });
  }

  const resetForm = () => {
    setTipoDocumentoSeleccionado("");
    setAll(true);
    setDocumento("");
    setTipoSexoSeleccionado("");
    setValidated(false);
    setInputsForm({});
  }

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(clear());
    getRolesBeforeToRender();
    return () => (isMountedRef.current = false);
  }, [dispatch]);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      if (validate) {
        const form = documento.querySelector("form")
        if (!form.checkValidity() === false) {
          processSubmit();
        }
        setValidated(true);
      } else {
        processSubmit();
      }
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {rol[0] ? (
          <Form
            noValidate
            validated={validated}
            id="formABMUsuario"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col sm={12} md={4}>
                <Input
                  type="email"
                  controlId="username"
                  label="Correo Electrónico Oficial"
                  value={inputsForm.username ? inputsForm.username : ""}
                  onChange={handleChange}
                  required={validate ? true : false}
                />
              </Col>
              <Col sm={12} md={4}>
                <Input
                  type="input"
                  controlId="nombre"
                  label="Nombre"
                  value={inputsForm.nombre ? inputsForm.nombre : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  required={validate ? true : false}
                ></Input>
              </Col>
              <Col sm={12} md={4}>
                <Input
                  type="input"
                  controlId="apellido"
                  label="Apellido"
                  value={inputsForm.apellido ? inputsForm.apellido : ""}
                  onChange={handleValidChange.bind(this, isValidText)}
                  required={validate ? true : false}
                ></Input>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={4} lg={2}>
                <Input
                  type="react-select"
                  controlId="sexo"
                  name="sexo"
                  label="Sexo"
                  value={tipoSexoSeleccionado}
                  methodOnChange={handleChange}
                  elements={UserService.getTiposSexo}
                  setterElementSelect={setTipoSexoSeleccionado}
                  required={validate ? true : false}
                ></Input>
              </Col>
              <Col sm={8} md={6} lg={4}>
                <Input
                  type="react-select-group"
                  name="documento"
                  label="Documento"
                  controlInputId="documento"
                  inputValue={documento}
                  onChangeInput={handleChangeDocumento}
                  controlSelectId="tipoDocumento"
                  selectValue={tipoDocumentoSeleccionado}
                  onChangeSelect={handleChange}
                  elements={UserService.getTiposDocumento}
                  setterElementSelect={setTipoDocumentoSeleccionado}
                  propertiesForFormat={{ value: "id", label: "descripcion" }}
                  config={{ isClearable: true }}
                  required={validate ? true : false}
                  reactSelectStyleContainer={{ width: "6rem" }}
                ></Input>
              </Col>
              <Col lg={6}>
                <div className="form-checkbox-row">
                  {renderRolesCheckbox()}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {submitValue && <Button
                  variant="primary"
                  content="Submit"
                  type="submit"
                  value={submitValue}
                >
                  {submitValue}
                </Button>}
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default UserABMForm;
