import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Row, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "../../generics/dialog";
import IconButton from "../../generics/IconButton";
import { TicketIcon } from "../../images/svg-jsx/TicketIcon";
import { error, success } from "../../store/alerts/alertActions";
import Input from "../../ui/Input";
import { handlerChange } from "../../utils/commons";
import { apiURLs, paginado, routePaths, typeOfActions } from "../../utils/Constants";
import userService from "../../utils/user.service";
import { ticketsAdapter } from "../TicketsAdapter";
import { ticketsService } from "../TicketsService";
import { ticketsUtils } from "../TicketsUtils";
import * as ternaService from "../../ternas/TernaService"
import requestService from "../../utils/request.service";
import Usuario from "../../dtos/Usuario";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FabButton from "../../ui/FabButton";
import { searchFab } from "../../store/screen/screenActions";


export const TicketsCreateModal = ({
    action = typeOfActions.CREATE,
    objectId,
    objectApendice,
    modelObjectId,
    nombreModuloTicket,
    iconType,  //fabButton, primaryButton, customButton o null
    iconJsx,
    onlyAlta,
    showModal,
    hideModalFunction,
    hideIconButton,
    zIndex1050,
    resolveCallback,
    defaultInputs,
    modalTitle,
    objDisableInputs = {},
    detailSection,
    isLoadingTicket,
    isDeletedTicket,
}) => {
    const defaultInputsValues = {
        tipoTicket: "",
        moduloTicket: "",
        analistaId: "",
        observacion: "",
        analista: "",
    }

    const { tipoTickets, modulosTickets } = useSelector(st => st.data)
    const { user } = useSelector(st => st.auth)

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [inputsForm, setInputsForm] = useState(defaultInputs ?? defaultInputsValues);
    const [modalError, setModalError] = useState();

    const isModalIncompleted = !inputsForm.tipoTicket || !inputsForm.observacion || !inputsForm.moduloTicket || !inputsForm.analistaId;
    const [analistaSeleccionado, setAnalistaSeleccionado] = useState(defaultInputs ? defaultInputs.analista : defaultInputsValues.analista);

    const [tipoTicketsOptions, setTipoTicketsOptions] = useState()

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, null, e);
    };

    const handleChangeSync = (event) => {
        const customEvent = {
            target: {
                id: event.target.id,
                value: event.target.value ? { value: event.target.value, label: event.target.label } : "",
            }
        };
        handleChange(customEvent);
    }

    const handleClose = () => {
        hideIconButton ?
            hideModalFunction()
            :
            setShow(false);
    }

    const loadModalOptions = () => {
        if (nombreModuloTicket && !tipoTickets.moduloTicket) {
            const moduloOptionDefault = modulosTickets.find(modulo => modulo.label == nombreModuloTicket)
            setInputsForm(st => ({ ...st, moduloTicket: moduloOptionDefault }))
        }
        setTipoTicketsOptions(onlyAlta ?
            ticketsUtils.filterStoredTiposTickets({ allStoredTipos: tipoTickets, arrNombresTiposToReturn: [ticketsUtils.OPTIONS_TIPOS_TICKETS_NAME.ALTA] }) :
            tipoTickets
        )
    }

    const handleShow = async () => {
        setShow(true);
        loadModalOptions();
    };

    const resetModalInfo = () => {
        setInputsForm(defaultInputsValues)
        setAnalistaSeleccionado(null)
    }

    const getUsuariosRolesAnalista = async () => {
        return await userService.getUsers({ rol: [{ id: 1 }] })
    }



    const createTicketParams = () => {
        return ticketsAdapter.adaptPayloadCreateTicket(
            {
                id: inputsForm.id, //Para el editar
                createdBy: user.userid,
                assignedBy: user.userid,
                assignedTo: inputsForm.analistaId,
                tipoId: inputsForm.tipoTicket.value,
                apendice: objectApendice,
                objectId: objectId,
                model_object_id: modelObjectId,
                observacion: inputsForm.observacion,
                modelId: inputsForm.moduloTicket.value,
            }
        )
    }

    const handleGenerar = async () => {
        setModalError(null)
        const params = createTicketParams()
        try {
            const resolveAction = () => {
                resolveCallback ?
                    resolveCallback("success", <p>Se creo el ticket de <b>{inputsForm.tipoTicket.label}</b> correctamente</p>)
                    :
                    dispatch(success(<p>Se creo el ticket de <b>{inputsForm.tipoTicket.label}</b> correctamente</p>))

                resetModalInfo();
                handleClose();
            }
            await ticketsService.createTicket(params, resolveAction)
        }
        catch (err) {
            setModalError(err.response.data.message)
        }
    }

    const handleEditar = async () => {
        setModalError(null)
        const params = createTicketParams()
        try {
            const resolveAction = () => {
                resolveCallback ?
                    resolveCallback("success", <p>Se edito el <b>ticket #{inputsForm.id}</b> correctamente</p>)
                    :
                    dispatch(success(<p>Se edito el <b>ticket #{inputsForm.id}</b> correctamente</p>))
                dispatch(searchFab(true))
                handleClose();
            }
            await ticketsService.editTicket(params, resolveAction)
        }
        catch (err) {
            setModalError(err.response.data.message)
        }
    }

    useEffect(() => {
        if (hideIconButton) {
            loadModalOptions()
        }
    }, [])

    return (
        <>
            {!hideIconButton && (
                iconType == "fabButton" ?
                    <FabButton
                        iconJsx={<TicketIcon style={{ width: "21px", marginBottom: "4px", marginLeft: "-2px" }} />}
                        size="lg"
                        className="FabButton"
                        onClick={handleShow}
                    ></FabButton>
                    :
                    iconType == "customButton" && iconJsx ?
                        <IconButton onClick={handleShow}>
                            {iconJsx}
                        </IconButton>
                        :
                        iconType == "primaryButton" ?
                            <Button
                                onClick={handleShow}
                                variant="primary"
                                content="Reporte"
                                type="submit"
                            >
                                <TicketIcon style={{ height: "16px", width: "13px", marginBottom: "3px", marginLeft: "-2px" }} /> Crear ticket
                            </Button>
                            :
                            <IconButton onClick={handleShow}>
                                <TicketIcon />
                            </IconButton>
            )
            }
            <Modal
                show={hideIconButton ? showModal : show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={`${zIndex1050 ? "modal-zIndex1050" : ""}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalTitle ?? "Generar nuevo ticket"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoadingTicket &&
                        isDeletedTicket ?
                        <p style={{ marginTop: "1rem" }}>El elemento ya fue eliminado</p>
                        :
                        <>
                            {modalError &&
                                <Alert variant="warning">{modalError}</Alert>
                            }
                            <Row>
                                <Col md={6} lg={4}>
                                    <Input
                                        type="react-select-sync"
                                        controlId="moduloTicket"
                                        name="moduloTicket"
                                        label="Modulo"
                                        value={inputsForm.moduloTicket}
                                        methodOnChange={handleChangeSync}
                                        syncElements={modulosTickets ?? []}
                                        config={{ isSearchable: true }}
                                        disabled={nombreModuloTicket || objDisableInputs.moduloTicket}
                                    ></Input>
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        type="react-select-sync"
                                        controlId="tipoTicket"
                                        name="tipoTicket"
                                        label="Tipo ticket"
                                        value={inputsForm.tipoTicket}
                                        disabled={onlyAlta || objDisableInputs.tipoTicket}
                                        methodOnChange={handleChangeSync}
                                        syncElements={tipoTicketsOptions}
                                        config={{ isSearchable: true }}
                                    ></Input>
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        type="react-select"
                                        controlId="analistaId"
                                        name="analistaId"
                                        label="Asignar analista"
                                        value={analistaSeleccionado}
                                        methodOnChange={handleChange}
                                        elements={getUsuariosRolesAnalista}
                                        nameElementsResponse="data"
                                        propertiesForFormat={{ value: "id", label: "nombreCompleto" }}
                                        setterElementSelect={setAnalistaSeleccionado}
                                        config={{ isSearchable: true, isClearable: true }}
                                    ></Input>
                                </Col>
                            </Row>

                            {detailSection}

                            <Row>
                                <Col>
                                    <Input
                                        type="textarea"
                                        controlId="observacion"
                                        name="observacion"
                                        label="Observaciones"
                                        rows={4}
                                        value={inputsForm.observacion}
                                        onChangeTextArea={handleChange}
                                    ></Input>
                                </Col>
                            </Row>
                        </>


                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>

                    {!isLoadingTicket && !isDeletedTicket &&
                        <>
                            {action == typeOfActions.CREATE &&
                                <Dialog
                                    action={handleGenerar}
                                    title={"Generar Ticket"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Generar"
                                    btnVariant="primary"
                                    btnText="Generar Ticket"
                                    disabled={isModalIncompleted}
                                    isLoadingText={"Generando ticket"}
                                    body={<span>
                                        ¿Estás seguro de generar el ticket?
                                    </span>}
                                />
                            }

                            {action == typeOfActions.EDIT &&
                                <Dialog
                                    action={handleEditar}
                                    title={"Editar Ticket"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Guardar"
                                    btnVariant="primary"
                                    btnText="Guardar cambios"
                                    disabled={isModalIncompleted}
                                    isLoadingText={"Guardando cambios"}
                                    body={<span>
                                        ¿Estás seguro de editar el ticket?
                                    </span>}
                                />
                            }
                        </>}
                </Modal.Footer>
            </Modal>
        </>
    );
}
