import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import CheckBoxEstados from "../../generics/CheckBoxEstados";
import InputDatePicker from "../../generics/InputDatePicker";
import { clear } from "../../store/alerts/alertActions";
import { cleanFab, searchFab } from "../../store/screen/screenActions";
import { saveFilters } from "../../store/search/searchActions";
import * as ternaService from "../../ternas/TernaService";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { handlerChange } from "../../utils/commons";

const TitulosFormSearch = ({
  controlIds,
  masInputs,
  hideChecks,
  defaultInputsForm,
  filtros,
  onSubmit,
  setTitulos,
  getTitulos,
}) => {
  const [inputsForm, setInputsForm] = useState(defaultInputsForm ? { ...defaultInputsForm } : []);
  const [tituloSeleccionado, setTituloSeleccionado] = useState("");
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
  const [resolucionSeleccionada, setresolucionSeleccionada] = useState("");
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetChecks, setResetChecks] = useState(false);

  const isMountedRef = useRef(null);

  const dispatch = useDispatch();
  const screenState = useSelector(st => st.screen);

  //Lleno los inputs si se vuelve atras
  if (inputsForm.length < 1 && filtros) {
  }

  const processSubmit = async () => {
    dispatch(saveFilters(inputsForm));
    await onSubmit(inputsForm);
  };

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e);
  };

  const handleValidChangeSelect = (isValid, e) => {
    if (isValid(e.target.label)) {
      let eTarget = { target: { id: e.target.id, value: e.target.label } };
      handleChange(eTarget);
    }
  };

  const setFechaDesde = (date) => {
    setInputsForm({
      ...inputsForm,
      fecha_desde: date,
    });
    setStartDate(date);
  };

  const setFechahasta = (date) => {
    setInputsForm({
      ...inputsForm,
      fecha_hasta: date,
    });
    setEndDate(date);
  };

  const resetForm = () => {
    setInputsForm(defaultInputsForm ?? {});
    setTitulos(null);
    setTituloSeleccionado("");
    setProcedenciaSeleccionada("");
    setresolucionSeleccionada("");
    setNivelSeleccionado("");
    setStartDate(null);
    setEndDate(null);
    setResetChecks(true);
  }

  useEffect(() => {
    async function initField() {
      isMountedRef.current = true;
      dispatch(clear());
      return () => (isMountedRef.current = false);
    }

    initField();
  }, [dispatch]);

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      processSubmit();
      dispatch(searchFab(false));
    }
  }, [screenState])

  return (
    <Row className="form-group">
      <Col>
        {true ? (
          <Form id="formSearchTroncales">
            <Row>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId={controlIds.titulo}
                  name="nombre"
                  label="Nombre de título"
                  value={tituloSeleccionado}
                  methodOnChange={handleChange}
                  elements={ternaService.getTitulos}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setTituloSeleccionado}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId={controlIds.procedencia}
                  name="procedencia"
                  label="Procedencia de título"
                  value={procedenciaSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternaService.getProcedencias}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setProcedenciaSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Input
                  type="react-select"
                  controlId={controlIds.resolucion}
                  name="resolucion"
                  label="Resolución de título"
                  value={resolucionSeleccionada}
                  methodOnChange={handleChange}
                  elements={ternaService.getResoluciones}
                  nameElementsResponse="elementos"
                  propertiesForFormat={{ value: "id", label: "nombre" }}
                  setterElementSelect={setresolucionSeleccionada}
                  config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                  withBusquedaAvanzada={true}
                ></Input>
              </Col>
            </Row>
            {masInputs && (
              <Row className="form-group">
                <Col sm={3}>
                  <Input
                    type="react-select"
                    controlId={controlIds.nivel}
                    name="nivel"
                    label="Nivel"
                    value={nivelSeleccionado}
                    methodOnChange={handleChange}
                    elements={especialidadesService.getNiveles}
                    nameElementsResponse="elementos"
                    propertiesForFormat={{ value: "id", label: "descripcion" }}
                    setterElementSelect={setNivelSeleccionado}
                    config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
                    countCaracters={0}
                  ></Input>
                </Col>
                <Col sm={2}>
                  <InputDatePicker
                    label="Desde"
                    selected={startDate}
                    onChange={(date) => setFechaDesde(date)}
                  />
                </Col>
                <Col sm={2}>
                  <InputDatePicker
                    label="Hasta"
                    selected={endDate}
                    onChange={(date) => setFechahasta(date)}
                  />
                </Col>
              </Row>
            )}
            {!hideChecks &&
              <Row className="form-group">
                <Col xs={12}>
                  <CheckBoxEstados
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    resetChecks={resetChecks}
                    setResetChecks={setResetChecks}
                  />
                </Col>
              </Row>
            }
          </Form>
        ) : (
          <Loader></Loader>
        )}
      </Col>
    </Row>
  );
};

export default TitulosFormSearch;
